import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { setCoreConfig } from '../appConfig/appConfig.actions';
import { LoggerService } from '@stukent/logger';
import { ElementService } from '@stukent/elements';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IAppConfig } from '../../models';
import { currentUser, setProfile } from '@stukent/user';

@Injectable()
export class CoreProfileEffects {

  private subscriptionKey = '';
  private jwt = '';
  private isSignalRInit = false;

  private authService: any;
  private userSubscription: Subscription;

  private appConfig: IAppConfig;

  private authServiceIncrement = 10;
  private authServiceCheckInMS = 40;

  private enableLogging = false;
  private componentName = 'Core Profile Effects';

  constructor(
    private actions$: Actions,
    private elementsService: ElementService,
    private logger: LoggerService,
    private store: Store
  ) {
    this.setAuthService();
  }

  $setJwtAndConfiguration = createEffect(() => this.actions$.pipe(
    ofType(setCoreConfig),
    map((value: any) => {

      if (value.config) {
        this.appConfig = value.config;

        this.subscriptionKey = this.appConfig.subscriptionKey;
        if (this.elementsService) {
          this.elementsService.setBaseUrl(this.appConfig.serviceUrls.elements2);
          this.elementsService.setElementBaseUrl(this.appConfig.serviceUrls.elementBase);
        }
      } else if (value.jwt) {
        this.jwt = value.jwt;
      }

      if (this.appConfig && this.jwt && this.subscriptionKey && !this.isSignalRInit) {
        this.isSignalRInit = true;
      }

    })
  ), { dispatch: false });

  $setLoggerUserId = createEffect(() => this.actions$.pipe(
    ofType(setProfile),
    map(({ profile }) => {
      this.logger.setUserId(profile.email);
    })
  ), { dispatch: false });

  private setAuthService(): void {

    // If AuthService is already set, return...
    if (this.authService) {
      this.logInfo('Auth Service already set');
      return;
    }

    this.authService = (window as any).__authService;

    if (!this.authService) {
      this.authServiceCheckInMS += this.authServiceIncrement;
      this.logInfo(`Auth Service not set, try in ${this.authServiceCheckInMS}ms`);
      setTimeout(this.setAuthService.bind(this), this.authServiceCheckInMS);
    } else {
      this.logInfo('Auth Service set, setting profile');

      if (!this.userSubscription) {
        this.userSubscription = this.authService.store.select(currentUser).subscribe(user => {
          if (user?.jwt) {
            this.jwt = user.jwt;
            // this.store.dispatch(setJwt({ jwt: user.jwt }));
            this.store.dispatch(setProfile({ profile: user }));
          }
        });
      }

    }
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

}
