import { Component, OnDestroy, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { ICourseProductTemplatePage, ICourseProductTemplateModule, ILocation } from '../../models';
import { currentLocation } from '../../reducers/location/location.selectors';
import { ILocationState } from '../../reducers/location/state';
import { ImmersiveReaderService } from '../../services/immersive-reader/immersive-reader.service';
import { toggleGlobalPage, navigateToModule, navigateToPage, updateModule } from '../../reducers/root';
import { CourseProductTemplateService } from '../../services/course-product-template/course-product-template.service';
import { LoggerService } from '@stukent/logger';
import { FeatureService, FeatureArea } from '@stukent/feature-toggle';
import { currentUser } from '@stukent/user';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sk-content-navigation',
  templateUrl: './content-navigation.component.html',
  styleUrls: ['./content-navigation.component.scss']
})
export class ContentNavigationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() globalPages: ICourseProductTemplatePage[];
  @Input() allModules: ICourseProductTemplateModule[];
  @Input() currentModule: ICourseProductTemplateModule;
  @Input() currentPage: ICourseProductTemplatePage;
  @Input() hideRunButton = false;
  @Input() continueButtonText = 'Continue';
  @Input() backButtonText = 'Back';
  @Input() moduleNavigationName = 'Chapter';
  @Input() pageNavigationName = '';
  @Input() allowModuleNavigation = false;

  private subscriptions: Subscription[] = [];

  private currentLocation: ILocation;

  public nextButtonHidden = false;
  public backButtonHidden = false;

  public previousModule: ICourseProductTemplateModule;
  public nextModule: ICourseProductTemplateModule;

  public previousPage: ICourseProductTemplatePage;
  public nextPage: ICourseProductTemplatePage;
  public visible = false;

  public allowModuleEdit = false;
  public editModalVisible = false;
  public currentModuleJSON: string;

  private enableLogging = false;
  private componentName = 'Content Navigation';

  constructor(
    private changeRef: ChangeDetectorRef,
    private store: Store,
    private logger: LoggerService,
    private immersiveReader: ImmersiveReaderService,
    private templateService: CourseProductTemplateService,
    private featuresService: FeatureService
  ) { }

  ngOnInit(): void {
    // Handle Locations changing...
    this.subscriptions.push(this.store.select(currentLocation)
      .subscribe(this.locationChanged.bind(this)));

    // Handle Profile Changed
    // Shows or hides the Edit Module Button based on features
    this.subscriptions.push(this.store.select(currentUser).subscribe(user => {
      this.featuresService.features$.subscribe(() => {
        this.allowModuleEdit = this.featuresService.isFeatureIsEnabled(FeatureArea.mimicApp, user, 'editModules');
      });

    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.allModules) {
      this.logInfo('All Modules changed');
      this.allModules = changes.allModules.currentValue;
      this.setNavigationButtons();

    }

    if (changes.currentModule) {
      this.logInfo('Current Module changed');

      this.currentModule = changes.currentModule.currentValue;

      if (this.currentModule) {
        this.visible = true;
        // Set the buttons again if location has not been set
        this.setNavigationButtons();

        this.changeRef.detectChanges();
      }
    }

    if (changes.currentPage) {
      this.logInfo('Current Page changed');

      this.currentPage = changes.currentPage.currentValue;
      if (this.currentPage) {
        this.nextButtonHidden = this.currentPage.properties?.continueButtonHidden;
        this.backButtonHidden = this.currentPage.properties?.backButtonHidden;
        this.changeRef.detectChanges();

        this.setNavigationButtons();

      }
    }

    if (changes.globalPages) {
      this.logInfo('Global Pages changed');

      this.globalPages = changes.globalPages.currentValue;
    }

    if (changes.hideRunButton) {
      this.hideRunButton = changes.hideRunButton.currentValue;
    }
  }

  private logInfo(message: string): void {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  public immersive() {
    this.logger.event('Immersive Reader button clicked');
    this.immersiveReader.launch();
  }

  public navigatePage(previous: boolean): void {

    let newPageId = this.nextPage?.id;
    if (previous) {
      newPageId = this.previousPage?.id;
    }

    if (newPageId) {
      this.logger.event(`${previous ? 'Previous' : 'Next'} page button clicked`, { pageId: newPageId });
      this.store.dispatch(navigateToPage({ pageId: newPageId }));
    }

  }

  public navigateModule(previous: boolean): void {

    let newModuleId: string;
    if (previous && this.previousModule) {
      newModuleId = this.previousModule.id;
    } else if (this.nextModule) {
      newModuleId = this.nextModule.id;
    }

    if (newModuleId) {
      this.logger.event(`${previous ? 'Previous' : 'Next'} module button clicked`, { moduleId: newModuleId });
      this.store.dispatch(navigateToModule({ moduleId: newModuleId }));
    }
  }

  // Opens a Global page in the drawer
  public openGlobalPage(page: ICourseProductTemplatePage) {

    const eventProps = { pageName: page.displayName };
    this.logger.event('Open Global Page button clicked', eventProps);

    this.store.dispatch(toggleGlobalPage({ pageId: page.id, isOpened: true }));
  }

  private setNavigationButtons(): void {

    this.logInfo('Setting Navigation Buttons');

    if (this.currentLocation) {


      if ((this.currentLocation.moduleId !== this.currentModule?.id &&
        this.allModules)
        || (!this.previousModule || !this.nextModule)) {
        this.logInfo('module changed');

        // Module Changed
        this.previousModule = this.templateService.getNextOrPreviousModule(this.allModules, this.currentLocation, true);
        this.nextModule = this.templateService.getNextOrPreviousModule(this.allModules, this.currentLocation, false);
      }

      if (this.currentModule) {
        // Page Changed
        const contentPages = this.currentModule.pages.filter(p => !p.isGlobal);

        this.previousPage = this.templateService.getNextOrPreviousPage(contentPages, this.currentLocation, true);
        this.nextPage = this.templateService.getNextOrPreviousPage(contentPages, this.currentLocation, false);
      }

      this.changeRef.detectChanges();

    }

  }

  private locationChanged(locationState: ILocationState): void {
    if (!locationState.isLoading) {
      // Update current Location

      this.logInfo('Location Changed');
      this.currentLocation = locationState.location;

      // Set Next, Previous
      this.setNavigationButtons();

    }

  }

  public openEditModal(): void {

    this.currentModuleJSON = JSON.stringify(this.currentModule, null, 2);

    this.editModalVisible = true;
    this.changeRef.detectChanges();
  }

  public closeEditModal(save: boolean): void {

    try {
      const updatedModule = JSON.parse(this.currentModuleJSON);
      updatedModule.id = this.currentModule.id;

      this.store.dispatch(updateModule({ updatedModule }));

    } catch (e) {
      window.alert(e);
    }

    this.editModalVisible = false;
    this.changeRef.detectChanges();

  }

}
