import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { BehaviorSubject } from 'rxjs';
import { LoggerService } from '@stukent/logger';

export interface IEdifyAppProduct {
  code: string;
  name: string;
  authors: string;
  versions: string;
}

@Injectable({
  providedIn: 'root'
})
export class EdifyProductsService {

  // Use a behaviorSubject for caching
  products$: BehaviorSubject<IEdifyAppProduct[]> = new BehaviorSubject<
    IEdifyAppProduct[]
  >([]);

  private productsLoaded = false;

  constructor(
    private baseService: BaseService,
    private logger: LoggerService,
  ) { }

  /**
   * Calls Edify Products API to return Product information as a IEdifyAppProduct array
   *
   * @returns Observable<IEdifyAppProduct[]>
   */
  loadProducts() {
    // Check if the products have been loaded.. If so don't do anything...
    if (!this.productsLoaded) {
      this.getProducts();
    } else {
      this.logger.info('Edify products already loaded');
    }
  }

  getUrl() {
    if (this.baseService.config.isInMemory) {
      return 'api/products';
    }
    return 'https://apif.stukent.com/original/catalog/v1/products/edifyapp';
  }

  private getProducts(): void {
    const url = this.getUrl();
    this.baseService.http
      .get<IEdifyAppProduct[]>(url)
      .subscribe((products) => {
        if (products?.length > 0) {
          this.productsLoaded = true;
          this.products$.next(products);
        }
      });
  }

}
