import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { InteractionElementComponent } from './interaction-element.component';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [InteractionElementComponent],
  imports: [
    CommonModule,
    NzButtonModule
  ],
  providers: [
    DatePipe
  ],
  exports: [
    InteractionElementComponent,
  ]
})
export class InteractionElementModule { }
