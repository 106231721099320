import { createSelector } from '@ngrx/store';
import { ICoreState } from './state';

export const coreFeature = state => state.core as ICoreState;

/**
 * @deprecated
 * Use selectContentTemplate instead
 */
export const contentTemplate = createSelector(
  coreFeature,
  core => core?.content
);

export const selectContentTemplate = createSelector(
  coreFeature,
  core => core?.content
);

export const selectContentTemplateIsPreview = createSelector(
  coreFeature,
  core => core?.content?.isPreview
);

export const selectContentTemplateModules = createSelector(
  coreFeature,
  core => core?.content?.modules
);

export const selectContentTemplateProperties = createSelector(
  coreFeature,
  core => core?.content?.properties
);

export const selectContentTemplateProperty = (name: string) =>
  createSelector(selectContentTemplateProperties, (properties) => {

    if (properties) {
      return properties[name];
    } else {
      return null;
    }

  });

export const selectElementConfiguration = (id: string) =>
  createSelector(selectContentTemplate, (content) => {

    if (content) {
      for (const m of content.modules) {
        for (const p of m.pages) {
          const foundElement = p.elements.find(e => e.id === id);
          if (foundElement) {
            return foundElement.config;
          }
        }
      }
    } else {
      return {};
    }

  });

/**
 * @deprecated
 * Use selectCourseProductInstance instead
 */
export const simInstance = createSelector(
  coreFeature,
  core => core?.instance
);

/*
* @deprecated
* use selectCourseProductInstance instead
*/
export const courseProductInstance = createSelector(
  coreFeature,
  core => core?.instance
);

export const selectCourseProductInstance = createSelector(
  coreFeature,
  core => core?.instance
);

export const selectCourseProductInstanceId = createSelector(
  coreFeature,
  core => core?.instance?.id
);

export const selectInstanceElements = createSelector(
  coreFeature,
  core => Object.values(core?.instance?.elements || {})
);

export const selectCurrentModuleInstanceElements = createSelector(
  coreFeature,
  core => Object.values(core?.instance?.elements || {}).filter(element => element.moduleId === core.location.location.moduleId)
);

/*
* @deprecated
* use selectInstanceLoading instead
*/
export const instanceLoading = createSelector(
  coreFeature,
  core => core?.instance?.isLoading
);

export const selectInstanceLoading = createSelector(
  coreFeature,
  core => core?.instance?.isLoading
);

export const selectCourseProductInstanceState = createSelector(
  courseProductInstance,
  instanceObj => instanceObj?.state
);

export const selectModuleState = (id: string) =>
  createSelector(courseProductInstance, (instanceObj) => instanceObj?.modules[id]?.state);

export const selectCurrentModuleState = createSelector(
  coreFeature,
  core => core.instance?.modules[core.location?.location?.moduleId]?.state
);

export const selectPageState = (id: string) =>
  createSelector(courseProductInstance, (instanceObj) => instanceObj?.pages[id]?.state);

export const selectCurrentPageState = createSelector(
  coreFeature,
  core => core.instance?.pages[core.location?.location?.pageId]?.state
);

export const selectElementState = (id: string) =>
  createSelector(courseProductInstance, (instanceObj) => instanceObj?.elements[id]);

export const selectElementStateObject = (id: string) =>
  createSelector(courseProductInstance, (instanceObj) => instanceObj?.elements[id]?.state);

export const selectElementComplete = (id: string) =>
  createSelector(courseProductInstance, (instanceObj) => instanceObj?.elements[id]?.isComplete);

/*
* @deprecated
* use selectCurrentContentModule instead
*/
export const currentContentModule = createSelector(
  coreFeature,
  core => {
    if (core.location?.location?.moduleId) {
      return core.content?.modules.find(m => m.id === core.location.location.moduleId);
    } else {
      return null;
    }
  }
);

export const selectCurrentContentModule = createSelector(
  coreFeature,
  core => {
    if (core.location?.location?.moduleId) {
      return core.content?.modules.find(m => m.id === core.location.location.moduleId);
    } else {
      return null;
    }
  }
);

export const selectCurrentContentModuleId = createSelector(
  coreFeature,
  core => {
    if (core.location?.location?.moduleId) {
      const currentModule = core.content?.modules.find(m => m.id === core.location.location.moduleId);
      if (currentModule) {
        return currentModule.id;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
);

export const selectCurrentContentPage = createSelector(
  coreFeature,
  core => {
    if (core.location?.location?.moduleId && core.location?.location?.pageId) {
      const currentModule = core.content?.modules.find(m => m.id === core.location.location.moduleId);
      return currentModule?.pages.find(p => p.id === core.location.location.pageId);
    } else {
      return null;
    }
  }
);

export const selectCurrentContentIteration = createSelector(
  coreFeature,
  core => {
    if (core.location?.location?.moduleId && core.location?.location?.pageId && core.location?.location?.iterationId) {
      const currentModule = core.content?.modules.find(m => m.id === core.location.location.moduleId);

      if (!currentModule) {
        return null;
      }
      const currentPage = currentModule.pages.find(p => p.id === core.location.location.pageId);

      if (currentPage?.iterations && currentPage.iterations.length > 0) {
        return currentPage.iterations.find(i => i.id === core.location.location.iterationId);
      } else {
        return null;
      }

    } else {
      return null;
    }
  }
);
