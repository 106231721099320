import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect, Effect } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { LoggerService } from '@stukent/logger';
import { setCourseProduct } from './course.actions';

@Injectable()
export class CourseProductEffects {

  constructor(
    private actions$: Actions,
    private logger: LoggerService
  ) {
  }
  $setCourseProduct = createEffect(() => this.actions$.pipe(
    ofType(setCourseProduct),
    // Set the course and product for the logger
    tap(({ newCourseCode, newProductCode }) => this.logger.setCourseProduct(newCourseCode, newProductCode))
  ), { dispatch: false });

}
