import { createSelector } from '@ngrx/store';
import { ICoreState } from '../root';

const coreFeature = state => state.core as ICoreState;

export const currentLocation = createSelector(
    coreFeature,
    core => core?.location
);

export const selectCurrentLocation = createSelector(
    coreFeature,
    core => core?.location.location
);

export const selectPreviousLocation = createSelector(
    coreFeature,
    core => core?.location.previous
);
