import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  scrollTo(selector: string, left?: number, top?: number) {
    let scrollElement: Element = null;

    if (selector) {
      try {
        scrollElement = document.querySelector(selector);
      } catch (e) {
        // invalid selector, ignore
        console.log(`${selector} threw an error: ${e}`);
      }
    }

    if (scrollElement) {
      scrollElement.scrollIntoView();
    } else {
      window.scrollTo(left || 0, top || 0);
    }
  }

  scroll(left?: number, top?: number) {
    this.scrollTo('html', left, top);
  }
}
