import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { setGuides, loadGuides } from './guides.actions';
import { GuidesService } from '../../services/guides/guides.service';
import { IGuide } from '../../models/guides.model';

@Injectable()
export class GuidesEffects {

  constructor(
    private actions$: Actions,
    private guidesService: GuidesService,
  ) {
  }

  loadGuides$ = createEffect(() => this.actions$.pipe(
    ofType(loadGuides),
    mergeMap(({ moduleId, pageId }) =>
      this.guidesService.getGuidesByPage(moduleId, pageId)
        .pipe(
          map((guide: IGuide) => setGuides({ guide })),
          catchError(() => EMPTY)
        )
    )
  ));
}
