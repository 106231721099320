import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { ILocation } from '../../models';
import { currentLocation } from '../../reducers/location/location.selectors';
import { addAnnotation, clearTempAnnotation, removeTempAnnotation, setTempAnnotation } from '../../reducers/annotation/annotation.actions';
import { IAnnotation } from '../../models/annotations.model';
import { currentUser, IUser } from '@stukent/user';
import { v4 as uuid } from 'uuid';
import { selectContentTemplateIsPreview } from '../../reducers/selectors';

@Component({
  selector: 'sk-annotation',
  templateUrl: './annotation.component.html',
  styleUrls: ['./annotation.component.scss']
})
export class AnnotationComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store
  ) { }

  private subscriptions: Subscription[] = [];
  visible = false;

  public isPreview = false;

  primaryNote: string;
  currentColor: string;

  annotation: IAnnotation = {};

  currentUser: IUser;
  currentLocation: ILocation;
  highlightColors = ['yellow', 'orange', 'red', 'blue', 'green', 'purple'];

  ngOnInit(): void {
    // Handle Locations changing...
    this.subscriptions.push(this.store.select(currentLocation)
      .subscribe(locationState => {
        if (!locationState.isLoading) {
          this.currentLocation = JSON.parse(JSON.stringify(locationState.location));
        }
      }));

    this.subscriptions.push(this.store.select(currentUser)
      .subscribe(u => this.currentUser = u));

    this.subscriptions.push(this.store.select(selectContentTemplateIsPreview)
      .subscribe(p => this.isPreview = p))

  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  addHighlight(color: string): void {
    if (this.currentColor !== color) {
      this.currentColor = color;
      this.annotation.color = color;

      this.store.dispatch(setTempAnnotation({
        annotation: JSON.parse(JSON.stringify(this.annotation))
      }));
    }
    document.getSelection().removeAllRanges();
  }

  saveAndClose() {
    // addNote
    this.addNote();

    const newAnnotation: IAnnotation = JSON.parse(JSON.stringify(this.annotation));
    newAnnotation.location = this.currentLocation;
    newAnnotation.location.iterationId = undefined;
    newAnnotation.id = uuid();
    this.store.dispatch(addAnnotation({
      annotation: newAnnotation
    }));

    this.store.dispatch(removeTempAnnotation());

    document.getSelection().removeAllRanges();
    this.visible = false;
  }

  cancel() {
    this.store.dispatch(clearTempAnnotation());
    document.getSelection().removeAllRanges();
    this.visible = false;
  }

  private addNote(): void {
    if (this.primaryNote?.length > 0) {
      if (!this.annotation.notes) {
        this.annotation.notes = [];
      }

      this.annotation.notes.push({
        content: this.primaryNote.trim().substring(0, 2000),
        createdBy: this.currentUser.email,
        created: new Date(Date.now())
      });

      this.primaryNote = null;
    }
  }

  // checks to see if the popover value is false when clicking outside the popover
  // needs to implement undoing highlight and rendering other annotations if overlap
  change(visible) {
    if (!visible) {
      this.cancel();
    }
  }

}
