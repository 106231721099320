import { PageState } from '../../element-states/page.state';
import { SimulationState } from '../../element-states/simulation.state';
import { ModuleState } from '../../element-states/module.state';
import { ElementState } from '../../element-states/element.state';
import { Input, OnDestroy, Component, ChangeDetectorRef } from '@angular/core';
import { IGenericState, AnyStore } from '../../models/element-state';
import { ConfigurationState } from '../../element-states/configuration.state';
import { Store } from '@ngrx/store';
import { ScoreState } from '../../element-states/score.state';

export interface IImportedElement {
  id: string;
  config: IGenericState;
}

@Component({ template: '' })
export class StatefulElementComponent implements OnDestroy {
  static Base = true;

  @Input()
  set ele(ele: IImportedElement) {
    this.elementState = new ElementState(this.store, this, ele.id);
    this.configurationState = new ConfigurationState(this.store, this, ele.id, ele.config);
  }

  simulationState: SimulationState;
  moduleState: ModuleState;
  scoreState: ScoreState;
  pageState: PageState;
  elementState: ElementState;
  configurationState: ConfigurationState;

  id: string;
  type: string;

  protected changeDetectorRef: ChangeDetectorRef;
  private store: Store<AnyStore>;

  constructor(changeDetectorRef: ChangeDetectorRef, store: Store<AnyStore>) {
    return this._constructor.apply(this, arguments);
  }

  /*
  * Fixes issue where elements cannot be displayed in production
  */
  static call(context, ...args) {
    return StatefulElementComponent.apply(context, args);
  }

  static apply(context, args) {
    return this.prototype._constructor.apply(context, args) || context;
  }

  _constructor(changeRef: ChangeDetectorRef, store: Store<AnyStore>) {
    this.changeDetectorRef = changeRef;
    this.store = store;
    this.simulationState = new SimulationState(store, this);
    this.moduleState = new ModuleState(store, this);
    this.pageState = new PageState(store, this);
    this.scoreState = new ScoreState(store, this);
  }

  ngOnDestroy() {
    // Sometimes ngOnDestroy can get called before initialization.
    // So we have to check.
    if (this.simulationState) { this.simulationState.destroy(); }
    if (this.moduleState) { this.moduleState.destroy(); }
    if (this.pageState) { this.pageState.destroy(); }
    if (this.scoreState) { this.scoreState.destroy(); }
    if (this.elementState) { this.elementState.destroy(); }
    if (this.configurationState) { this.configurationState.destroy(); }
  }

  forceTemplateUpdate() {
    if (this.changeDetectorRef) {
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
  }

}

