export class MimicCoreConfig {
  platform ? = 'mimic';
  isTest = false;
  allowHttpCallsInTest?: boolean;
  homeUrl ? = '';
  elements?: any[];
  isProduction?: boolean;
  isNext?: boolean;
  isInMemory?: boolean;
  configKey?: string;
  allowedModules?: any[];
  enableAnnotations = false;
  previewCourseCode?: string;
}
