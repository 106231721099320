import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IElementInstruction } from '../../models/instructions.model';
import { instructionsActiveElements, instructionsIsOpened } from '../../reducers/element-instructions/element-instructions.selectors';
import { toggleInstructions } from '../../reducers/element-instructions/element-instructions.actions';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sk-instructions-drawer',
  templateUrl: './element-instructions.component.html',
  styleUrls: ['./element-instructions.component.scss']
})
export class ElementInstructionsComponent implements OnInit, OnDestroy {
  drawerOpen = false;
  drawerFullscreen = false;
  instructions: IElementInstruction;
  instructionCacheKey: string;

  @ViewChild('expand') expand: TemplateRef<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    private changeRef: ChangeDetectorRef,
    private store: Store) {
  }

  ngOnInit(): void {

    // Listen to any instructions changes.
    this.subscriptions.push(this.store.select(instructionsActiveElements).subscribe(element => {
      this.instructions = element;
    }));

    this.subscriptions.push(this.store.select(instructionsIsOpened).subscribe(isOpen => {
      this.drawerOpen = isOpen;
      this.instructionCacheKey = this.getNewCacheBreak();
      this.changeRef.detectChanges();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  toggleDrawer(): void {
    this.drawerOpen = !this.drawerOpen;

    if (!this.drawerOpen) {
      this.store.dispatch(toggleInstructions({ elementType: this.instructions.elementType, isOpened: this.drawerOpen }));
    }
  }

  expandDrawer(): void {
    this.drawerFullscreen = !this.drawerFullscreen;
    this.changeRef.detectChanges();
  }

  getNewCacheBreak() {
    const rand = Math.floor(Math.random() * 1000);
    const time = (new Date()).getTime();
    return `${time}_${rand}`;
  }
}
