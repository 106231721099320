import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ICourseProductTemplateElement } from '../../models/course-product-template/element.model';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setSelectedModuleId, loadScores, showExistingScores } from '../../reducers/scores/scoring/scoring.actions';
import { ICourseProductTemplateModule, LoadingStatus } from '../../models';
import { ZendeskService } from '../../services/zendesk/zendesk.service';
import { toggleResults } from '../../reducers/view-toggle/view-toggle.actions';
import { appConfiguration, currentContentModule, currentCourseProduct, selectSelectedModuleId, selectShowExistingScores } from '../../reducers/selectors';
import { currentUser } from '@stukent/user';
import { IRankingStudent } from '@stukent/ranking';
import {
  selectAllModuleScores,
  selectResultsTemplate,
  selectScoringMessage,
  selectScoringProgress,
  selectSelectedModuleElements,
} from '../../reducers/selectors';
import { loadResultsTemplate } from '../../reducers/scores/template/template.actions';
import { LoggerService } from '@stukent/logger';

interface IRoundInformation {
  id: string;
  buttonTitle: string;
  title: string;
  disabled: boolean;
  processingTimeEstimate: number;
}

@Component({
  selector: 'sk-results-modal',
  templateUrl: './results-modal.component.html',
  styleUrls: ['./results-modal.component.scss']
})
export class ResultsModalComponent implements OnInit, OnDestroy {
  @Input() isVisible: boolean;
  @Input() templateModules: ICourseProductTemplateModule[];
  @Input() closeButtonText = 'Continue the Simulation';
  @Input() modulePrefix = 'Round';
  @Input() productHasRanking = false;

  public roundsInformation: IRoundInformation[] = [];
  private moduleIdsWithScores: string[] = [];

  public elements: {
    elements: ICourseProductTemplateElement[],
    cacheKey: string;
  };

  isProcessing = true;
  isCurrentModuleExcluded: boolean;

  rankingModules: string[] = [];

  public currentStudent: IRankingStudent[] = [];

  public scoresLoading = false;
  public scorerMessage: string;
  public selectedModuleId: string;
  public reloading = false;

  // TODO What is this used for (JKW)
  cleanModuleId: string;

  totalScorers: number;
  totalCompletedScorers: number;
  currentModuleTitle = '';
  scorerPercentage = 0;

  public showRanking = false;
  public rankingUrl: string;
  public productCode: string;
  public courseCode: string;

  public processingTime: number;
  public processingTimeRemaining: number;
  public processingIncrement = 'seconds';
  public processingTimer: string;

  private prrocessingTimeEstimate: number;
  private timerRunning = false;
  private timerInterval: NodeJS.Timeout;

  private resultsTemplateModules: ICourseProductTemplateModule[];
  private subscriptions: Subscription[] = [];

  private enableLogging = false;
  private componentName = 'Results Modal';

  constructor(
    private changeRef: ChangeDetectorRef,
    private store: Store,
    private zendesk: ZendeskService,
    private logger: LoggerService) {
  }

  ngOnInit(): void {

    this.logInfo('Initializing');

    // This could be done better, but it works for now (JKW)
    // Request things be loaded when the Product Id is available
    this.subscriptions.push(this.store.select(appConfiguration).subscribe(appConfig => {
      // get ranking url from app config
      this.productCode = appConfig.productCode;
      this.rankingUrl = appConfig.serviceUrls.ranking;

      if (appConfig.isLoaded) {
        // Once the configuration is loaded, load the user
        this.subscriptions.push(this.store.select(currentUser).subscribe(student => {
          if (!student.isLoading && this.currentStudent.length < 1) {
            // Once the user is loaded, load the user
            this.currentStudent.push({
              firstName: student.firstName,
              lastName: student.lastName,
              identifier: student.email
            } as IRankingStudent);

            // Once the user is loaded, load the courseCode
            if (!this.courseCode || this.courseCode.length === 0) {

              this.store?.select(currentCourseProduct).subscribe(course => {
                this.courseCode = course.courseCode;

                // Load the results template if it isn't, otherwise stuff just works
                this.subscriptions.push(this.store.select(selectResultsTemplate).subscribe(resultsTemplate => {

                  if ((this.elements?.elements || []).length === 0 && resultsTemplate.loadingState.status === LoadingStatus.notLoaded) {
                    // Ask for it to be loaded
                    this.store.dispatch(loadResultsTemplate());
                  } else {
                    // Get the information we need from the template
                    // Set the Round Information
                    this.resultsTemplateModules = resultsTemplate.modules;
                    this.setRoundsInformation();
                  }

                  // Do this here too since there are times where `this.roundsInformation` isn't ready in time, this will
                  // help with the race condition.
                  if (this.selectedModuleId) {
                    const roundInformation = this.roundsInformation.find(r => r.id === this.selectedModuleId);
                    if (roundInformation) {
                      this.currentModuleTitle = roundInformation.title || this.modulePrefix;
                      this.logInfo(`Setting Selected Module to ${this.selectedModuleId} and Title to: ${this.currentModuleTitle}`);
                    }
                  }
                }));
              });
            }
          }
        }));

      }

    }));

    // Handle Scoring Progress Messages Changing
    this.subscriptions.push(this.store.select(selectScoringMessage)
      .subscribe(x => {
        this.scorerMessage = x || 'Loading Results';
        this.changeRef.detectChanges();
      }));

    this.subscriptions.push(this.store.select(currentContentModule)
      .subscribe(module => {
        this.isCurrentModuleExcluded = !!module?.properties?.excludeFromResults;
      }));

    // Handle the Selected Module Changing
    this.subscriptions.push(this.store.select(selectSelectedModuleId)
      .subscribe(moduleId => {
        this.selectedModuleId = moduleId;
        // Set the Round title
        const roundInformation = this.roundsInformation.find(r => r.id === moduleId);

        if (roundInformation) {
          this.currentModuleTitle = roundInformation.title || this.modulePrefix;
          this.logInfo(`Setting Selected Module to ${this.selectedModuleId} and Title to: ${this.currentModuleTitle}`);
        }
      }));

    // Handle the selectScoringProgress Changing
    this.subscriptions.push(this.store.select(selectScoringProgress)
      .subscribe(progress => {
        if (progress) {

          this.logInfo(`processing: ${this.isProcessing} and scoring progress changed to: \n\n${JSON.stringify(progress, null, 2)}`);

          if (progress.isComplete) {
            // This is a hack to reload the scores (JKW)

            this.scoresLoading = true;

            this.scorerMessage = 'Loading Scores...';

            this.store.dispatch(loadScores());

            if (this.isCurrentModuleExcluded) {
              /*
              * If the current module is excluded from results, the results modal is never shown,
              * thus settings Processing to false never gets set back to true because the modal is never closed. So, here we keep it true
              */
              this.isProcessing = true;
            }
            else {
              /*
              * Otherwise, isProcessing is set to false and back to true on modal close,
              * so the next time scores are loaded it will show the scorer progress
              */
             this.killTimer();
             this.isProcessing = false;
            }
            this.totalScorers = 0;
            this.totalCompletedScorers = 0;
            this.scorerPercentage = 0;
          } else {

            let tempTotalScorers = 0;
            let tempTotalCompletedScorers = 0;

            const roundInformation = this.roundsInformation.find(r => r.id === this.selectedModuleId);

            this.prrocessingTimeEstimate = roundInformation?.processingTimeEstimate;
            this.setupTimerInformation();

            progress.scoringPasses?.forEach(pass => {
              pass.scorers?.forEach(scorer => {
                tempTotalScorers += 1;
                if (scorer.isComplete) {
                  tempTotalCompletedScorers += 1;
                }
              });
            });

            this.totalScorers = tempTotalScorers;
            this.totalCompletedScorers = tempTotalCompletedScorers;

            this.scorerPercentage = Math.ceil((this.totalCompletedScorers / this.totalScorers) * 100);

          }

        } else {
          this.logInfo('scoring progress changed with no message');
        }

      }));

    // Set the modules with Scores when scores change...
    this.subscriptions.push(this.store.select(selectAllModuleScores)
      .subscribe(moduleIdsWithScores => {
        if ((moduleIdsWithScores || []).length > 0) {

          this.moduleIdsWithScores = moduleIdsWithScores.filter(ms => {
            return ms.scores.some(s => s.context.simulationInstanceLocationType
              && s.context.simulationInstanceLocationType.toLowerCase() === 'module');
          }).map(m => m.moduleId);

          // Update the round information for any enable/disabled buttons based on the new information
          this.setRoundsInformation();
          this.reloading = false;
          this.scoresLoading = false;
          this.changeRef.detectChanges();
        }
      }));

    // Handle the Elements Changing
    this.subscriptions.push(this.store.select(selectSelectedModuleElements)
      .subscribe(moduleElements => {
        this.elements = {
          elements: moduleElements,
          cacheKey: Math.random().toString()
        };
      }));

    // Show the existing scores is requested.
    this.subscriptions.push(this.store.select(selectShowExistingScores)
      .subscribe(shoouldShowExistingScores => {
        this.logInfo(`Request to show Exising scores is: ${shoouldShowExistingScores}`);
        if (shoouldShowExistingScores) {
          this.isProcessing = false;

          this.killTimer();
          this.changeRef.detectChanges();
          // TODO, remove this once scores are loading correctly (JKW)
          this.store.dispatch(showExistingScores({ showExistingScores: false }));

        }
      }
      ));

    this.logInfo('Initialized');

  }

  ngOnDestroy() {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.showRanking = false;
  }

  private logInfo(message: string): void {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  private setRoundsInformation(): void {

    if (!this.templateModules || !this.resultsTemplateModules) {
      return;
    }

    let scoreableRoundsCount = 1;

    // Build the Rounds Information from the Course Product Template Modules
    this.templateModules.forEach(m => {
      // don't include the round if the settings say not to

      let excludeFromResults = m.properties?.excludeFromResults;
      if (m.properties?.excludeFromRoundCount && excludeFromResults === undefined) {
        excludeFromResults = true;
        this.logger.warn(`Incorrect Configuration for Round: ${m.title}: excludeFromRoundCount was set to true, but excludeFromResults was not set.`);
      }

      // Backwards compatility - Once module.disabled is fully implemented, '|| m.properties?.disableRound' can be removed (NH)
      if (!( m?.disabled || excludeFromResults || m.properties?.disableRound)) {

        const resultsTemplateModule =
          this.resultsTemplateModules.find((scoreModule: ICourseProductTemplateModule) => scoreModule.id === m.id);

        // If there is a module in the Results Module Template, add it to the RoundsInformation
        if (resultsTemplateModule) {
          // See if it has scores, otherwise disable it.
          const disabled = (this.moduleIdsWithScores || []).filter(x => x === m.id).length === 0;

          // Check if the Round Information exists already
          const existingRoundInformation = this.roundsInformation.find(r => r.id === m.id);

          if (existingRoundInformation) {
            // It it does, just update the disabled
            existingRoundInformation.disabled = disabled;

          } else {
            // For Edify, checks for Final Exam/Midterm Exam to add correct button title
            const updatedButtonTitle = m.properties?.isCoursewareExam ? m.displayName : `${this.modulePrefix} ${scoreableRoundsCount++}`;

            // Add the new one...
            this.roundsInformation.push({
              buttonTitle: updatedButtonTitle,
              title: m.displayName || m.name, // Ensure we get a name to display
              id: m.id,
              disabled,
              processingTimeEstimate: resultsTemplateModule.properties?.processingTimeEstimate || 0
            });
          }

        }
      }
    });

    // get the total number of modules by id for ranking
    this.rankingModules = [];
    this.roundsInformation.forEach(round => {
      this.rankingModules.push(round.id);
    });
  }

  private setupTimerInformation(): void {

    if (this.prrocessingTimeEstimate > 0 && !this.timerRunning) {

      this.timerRunning = true;

      this.processingTimeRemaining = this.prrocessingTimeEstimate;
      if (this.prrocessingTimeEstimate > 60) {
        this.processingIncrement = 'minutes';
        this.processingTime = Math.ceil(this.prrocessingTimeEstimate / 60);
        this.processingTimer = `${this.processingTime}:00`;

      } else {
        this.processingTime = this.prrocessingTimeEstimate;
        this.processingTimer = `0:${this.processingTime}`;

      }

      this.timerInterval = setInterval(this.updateTimer.bind(this), 1000);

    }

  }

  // Clear Timer information
  private killTimer(): void {
    clearInterval(this.timerInterval);
    this.timerRunning = false;
    this.processingTimeRemaining = this.prrocessingTimeEstimate;
  }

  private updateTimer(): void {
    if (!this.timerRunning) {
      return;
    }

    this.processingTimeRemaining -= 1;

    // Stop at 0
    if (this.processingTimeRemaining < 0 ) {
      this.processingTimeRemaining = 0;
      this.killTimer();
    }

    if (this.processingTimeRemaining > 60) {

      const minutes = Math.ceil(this.processingTimeRemaining / 60);
      const seconds = this.processingTimeRemaining - ((minutes - 1) * 60);

      this.processingTimer = `${minutes - 1}:${seconds < 10 ? '0' + seconds : seconds}`;

    } else {

      this.processingTimer = `0:${this.processingTimeRemaining < 10 ? '0' + this.processingTimeRemaining : this.processingTimeRemaining}`;

    }

    this.changeRef.detectChanges();
  }

  public trackByRoundId(item: IRoundInformation): string {
    return item.id;
  }

  public closeResults(): void {
    this.showRanking = false;
    this.isProcessing = true;
    this.killTimer();
    this.store.dispatch(toggleResults({ isResultsOpen: false }));
  }

  public changeSelectedRound(round: IRoundInformation): void {
    // switch back from ranking if we are on ranking
    if (round.disabled) {
      return;
    }
    this.showRanking = false;
    this.logInfo(`changing to module ${round.id} results`);

    this.store.dispatch(setSelectedModuleId({ moduleId: round.id }));
  }

  public reloadScores(): void {
    this.reloading = true;
    this.scoresLoading = true;
    this.store.dispatch(loadScores());
  }

  public contactSupport(): void {
    this.logger.event('VTA on Results Modal clicked');
    this.zendesk.show();
  }

  public changeToRanking(): void {
    this.logger.event('Ranking on Results Modal clicked');
    this.showRanking = true;
  }

}
