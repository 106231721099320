import { Injectable } from '@angular/core';
import { InjectionToken } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { IMarketSegment } from '../models/market-segments';
import { LoggerService } from '@stukent/logger';
import { BUHIConfigurationService } from './configuration.service';

export declare interface IBUHISocialMediaMarketSegmentService {
  margetSegments$: BehaviorSubject<IMarketSegment[]>;
  init();
  loadMarketSegments();
}

export const BuhiSocialMediaMarketSegments: InjectionToken<IBUHISocialMediaMarketSegmentService> =
  new InjectionToken<IBUHISocialMediaMarketSegmentService>('IBUHISocialMediaMarketSegmentService');

@Injectable({
  providedIn: 'root'
})
export class BUHISocialMediaMarketDataService {

  private urlRoot: string;
  private headers: HttpHeaders;

  // Use a behaviorSubject for caching
  margetSegments$: BehaviorSubject<IMarketSegment[]> = new BehaviorSubject<IMarketSegment[]>([]);

  private segmentsLoaded = false;

  private enableLogging = false;
  private componentName = 'Market Segments Service';

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {

  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  loadMarketSegments() {
    // Check if the keywords have been loaded.. If so don't do anything...
    if (!this.segmentsLoaded) {
      this.getMarketSegments();
    } else {
      this.logInfo(`market segments already loaded`);
    }
  }

  /**
   * Calls Buhi Ecosystem API to return Social Media Market Segment information as a IMarketSegment array
   *
   * @returns Observable<IMarketSegment[]>
   */
  private getMarketSegments() {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    this.http.get<IMarketSegment[]>(`${this.urlRoot}/socialmedia/market-segments`, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(segments => {
        if (segments?.length > 0) {
          this.segmentsLoaded = true;
          this.margetSegments$.next(segments);
        }
      });
  }

  private handleError(error) {
    return throwError(`Failed calling Social Media Market Segments Data service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
