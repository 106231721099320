import {
  Component, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy, Input, ChangeDetectorRef, Output, EventEmitter, OnDestroy
} from '@angular/core';
import { Store } from '@ngrx/store';
import { FeatureArea, FeatureService } from '@stukent/feature-toggle';
import { LoggerService } from '@stukent/logger';
import { currentUser } from '@stukent/user';
import { Subscription } from 'rxjs';
import { StukentProductsService, IStukentProduct, currentCourse, IStukentProductCategory } from '../../../../../mimic-core/src/public-api';

@Component({
  selector: 'mimic-product-drawer',
  templateUrl: './products-drawer.component.html',
  styleUrls: ['./products-drawer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductsDrawerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() visible = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private subscriptions: Subscription[] = [];

  showPreview = false;
  showBeta = false;

  productCategories: IStukentProductCategory[];

  private currentProductCode: string;
  private currentCourseCode: string;

  private allowProductsLoad = false;

  private enableLogging = false;
  private componentName = 'Mimic Products Drawer:';

  constructor(
    private store: Store,
    private logger: LoggerService,
    public productsService: StukentProductsService,
    private changeRef: ChangeDetectorRef,
    public featuresService: FeatureService
  ) { }

  ngOnInit(): void {
    this.logInfo('Initializing');

    this.store.select(currentCourse).subscribe(course => {
      this.currentProductCode = course.productCode;
      this.currentCourseCode = course.courseCode;
    });

    // Handle Profile Changed
    // Shows or hides Product Menu for User based on features
    this.subscriptions.push(this.store.select(currentUser).subscribe(user => {
      this.featuresService.features$.subscribe(() => {
        this.showPreview = this.featuresService.isFeatureIsEnabled(FeatureArea.mimicApp, user, 'previewProducts');
        this.showBeta = this.featuresService.isFeatureIsEnabled(FeatureArea.mimicApp, user, 'betaProducts');
        this.allowProductsLoad = true;
        this.loadProducts();
      });

    }));

    this.logInfo('Initialized');
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visible.currentValue) {
      this.loadProducts();
    }
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName} ${message}`);
    }
  }

  private loadProducts() {
    if (this.allowProductsLoad && !this.productCategories && this.visible) {

      this.productsService.loadProducts();

      this.productsService.products$.subscribe(productCategories => {
        this.productCategories = productCategories;
        // Remove Products that the user cannot see
        this.productCategories.forEach(c => {
          c.products?.forEach(p => {
            if (p.status === 'beta' && !this.showBeta) {
              c.products = c.products.filter(pr => pr.code !== p.code);
            } else if (p.status === 'preview' && !this.showPreview) {
              c.products = c.products.filter(pr => pr.code !== p.code);
            }
          });
        });


        // Remove Categories with no products
        this.productCategories.forEach(c => {
          if ((c.products || []).length === 0) {
            this.productCategories = this.productCategories.filter(ca => ca.title !== c.title);
          }
        });
        this.doChangeDetection();
      });

    }
  }

  changeProduct(newProduct: IStukentProduct): void {
    if (!this.currentProductCode) {
      this.logInfo(`Product Changed to: ${newProduct.code}`);
    }

    if (this.currentProductCode !== newProduct.code) {
      const newUrl = `${window.location.protocol}/${newProduct.code}/${this.currentCourseCode}`;
      window.location.href = newUrl;
    } else {
      this.logger.warn(`${this.componentName} no product code found for ${newProduct.title}`);
    }
  }

  private doChangeDetection() {
    this.changeRef.detectChanges();
  }

  toggleVisible() {
    this.visible = !this.visible;
    this.visibleChange.emit(this.visible);
  }

}
