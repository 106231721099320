import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, EMPTY, of } from 'rxjs';
import * as SignalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MimicCoreConfig } from '../../config';
import { ISignalRMessage, INotificationMessage, ISignalRConnection } from '../../models/signalr.model';
import { map, catchError, mergeAll, take } from 'rxjs/operators';
import { LoggerService } from '@stukent/logger';
import { appConfiguration, currentCourseProduct } from '../../reducers/selectors';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: SignalR.HubConnection;
  private url: string;
  private courseCode: string;
  private productCode: string;
  private jwtToken: string;
  private subscriptionKey: string;

  private isInitialized = false;

  status: SignalR.HubConnectionState;

  constructor(
    private http: HttpClient,
    private store: Store,
    private notificationService: NzNotificationService,
    config: MimicCoreConfig,
    private logger: LoggerService
  ) {
    if (!config.isTest) {
      this.store.select(appConfiguration).subscribe(x => {
        this.url = x.serviceUrls?.signalr;
        this.productCode = x.productCode;
        this.init()?.pipe(take(1)).subscribe(y => { });
      });
    }

    this.store.select(currentCourseProduct).subscribe(x => {
      this.courseCode = x?.courseCode;
      this.init()?.pipe(take(1)).subscribe(y => { });
    });

  }

  setup(jwtToken: string, subscriptionKey: string) {
    this.jwtToken = jwtToken;
    this.subscriptionKey = subscriptionKey;
    this.init()?.pipe(take(1)).subscribe(x => { });
  }

  private init(): Observable<SignalR.HubConnection> {

    if (!this.isInitialized && this.jwtToken && this.subscriptionKey && this.courseCode && this.productCode) {

      this.isInitialized = true;

      // this.status = this.hubConnection.state;
      return this.getSignalRConnection().pipe(
        map(con => {

          const options = {
            accessTokenFactory: () => con.accessToken,
          };
          this.hubConnection = new SignalR.HubConnectionBuilder()
            .withUrl(con.url, options)
            .configureLogging(SignalR.LogLevel.Information)
            .withAutomaticReconnect([0, 500, 3000, 10000, 30000, 60000])
            .build();

          const conn = from(this.hubConnection.start()).pipe(
            catchError(err => {
              this.logger.error('Error connecting to signalr', err);
              return EMPTY;
            }),
            map(() => {

              // Adding user to course group
              this.http.post(`${this.url}/${this.courseCode}/${this.courseCode + '.' + this.courseCode}`, {}).pipe(
                catchError(() => EMPTY)
              ).subscribe();

              return this.hubConnection;
            })
          );

          this.hubConnection.on('notify', data => {
            this.handleMessage(JSON.parse(data));
          });
          this.hubConnection.onclose((err) => {
            this.logger.error('Closing signal r', err);
          });

          return conn;
        }),
        mergeAll()
      );
    } else if (this.jwtToken && this.subscriptionKey && this.courseCode && this.productCode) {
      return of(this.hubConnection);
    }

  }

  private handleMessage(message: ISignalRMessage) {
    if (!message || !message.messageType) {
      return;
    }

    switch (message.messageType) {
      case 'console': {
        this.logger.info(message.data);
        break;
      }
      case 'notify': {
        const notification: INotificationMessage = message.data as any;
        if (!notification) {
          return;
        }

        if (this.notificationService[notification.level]) {
          this.notificationService[notification.level](notification.title, notification.message);
        }

        break;
      }
      case 'redux': {
        const data = message.data as any;
        const action = {
          type: data.Type || data.type,
          ...data
        };
        this.store.dispatch(action);
        break;
      }
      case 'pokemon': {
        const data = message.data as string;
        if (data === 'pikachu') {
          (window as any).pikachu();
        } else if (data === 'charizard') {
          (window as any).charizard();
        }
        break;
      }
    }
  }

  private getSignalRConnection(): Observable<ISignalRConnection> {
    return this.http.get<ISignalRConnection>(`${this.url}/${this.courseCode}`, {
      headers: {
        Authorization: `Bearer ${this.jwtToken}`,
        'Ocp-Apim-Subscription-Key': this.subscriptionKey
      }
    });
  }
}
