import { ILogger } from '@stukent/logger/lib/models/logger.model';
import { IKeywordPlannerRow } from './keyword-planner.model';

export type MatchFilterFunction = (
  searchValue: string,
  keywordList: IKeywordPlannerRow[],
  maxResults: number,
  logger: ILogger
) => IKeywordPlannerRow[];


export function simpleExactMatchFilter(
  searchValue: string,
  keywordList: IKeywordPlannerRow[],
  maxResults: number,
  logger: ILogger
): IKeywordPlannerRow[] {
  if (!searchValue) { return; }
  logger.info(`Simple Exact Match Filter for term: ${searchValue}`);

  // Assume there should only be one exact match
  const foundItem = keywordList.find(keyword => keyword.searchTerm.toLowerCase() === searchValue.toLowerCase());

  if (foundItem) {
    return [foundItem];
  } else {
    return [];
  }
}

export function simplePhraseMatchFilter(
  searchValue: string,
  keywordList: IKeywordPlannerRow[],
  maxResults: number,
  logger: ILogger
): IKeywordPlannerRow[] {
  if (!searchValue) { return; }
  logger.info(`Simple Phrase Filter for term: ${searchValue}`);

  // Grab each of the terms
  const terms = searchValue.split(' ');

  let result: IKeywordPlannerRow[] = [];

  let currentCount = 0;

  // Use a for here so that we can break.
  // At the time, .forEach does not allow for a break
  for (const theTerm of terms) {

    // Only check on this if the
    if (!result.find(existinTerm => existinTerm.searchTerm.toLowerCase().includes(theTerm.toLowerCase()))) {

      const foundItems = keywordList.filter(keyword => keyword.searchTerm.toLowerCase().includes(theTerm.toLowerCase()));
      if (foundItems?.length > 0) {
        currentCount += foundItems?.length;
        result = result.concat(foundItems);
      }

      if (currentCount >= maxResults) {
        break;
      }

    }

  }

  return result;
}

export function broadMatchFilter(
  searchValue: string,
  keywordList: IKeywordPlannerRow[],
  maxResults: number,
  logger: ILogger
): IKeywordPlannerRow[] {
  if (!searchValue) { return; }
  logger.info(`Broad Filter for term: ${searchValue}`);

  return keywordList.filter(row => {
    const match = compareTwoStrings(row.searchTerm, searchValue);
    return match < .75;
  });
}

export function phraseMatchFilter(
  searchValue: string,
  keywordList: IKeywordPlannerRow[],
  maxResults: number,
  logger: ILogger
): IKeywordPlannerRow[] {
  if (!searchValue) { return; }
  logger.info(`Phrase Filter for term: ${searchValue}`);

  return keywordList.filter(row => {
    const match = compareTwoStrings(row.searchTerm, searchValue);
    return match < .95 && match > .75;
  });
}

export function exactMatchFilter(
  searchValue: string,
  keywordList: IKeywordPlannerRow[],
  maxResults: number,
  logger: ILogger
): IKeywordPlannerRow[] {
  if (!searchValue) { return; }
  logger.info(`Exact Filter for term: ${searchValue}`);
  return keywordList.filter(row => {
    const match = compareTwoStrings(row.searchTerm, searchValue);
    return match >= .95;
  });
}

export function relatedMatchFilter(
  searchValue: string,
  keywordList: IKeywordPlannerRow[],
  maxResults: number,
  logger: ILogger
): IKeywordPlannerRow[] {
  if (!searchValue) { return; }
  logger.info(`Related Filter for term: ${searchValue}`);

  return keywordList.filter((row: IKeywordPlannerRow) => {
    const match = compareTwoStrings(row.searchTerm, searchValue);
    return match <= 100 && match >= 0;
  });
}

function compareTwoStrings(compareTerm: string, searchTerm: string) {
  compareTerm = compareTerm.toLocaleLowerCase().replace(/\s+/g, '');
  searchTerm = searchTerm.toLocaleLowerCase().replace(/\s+/g, '');

  if (compareTerm === searchTerm) { return 1; } // identical or empty
  if (compareTerm.length < 2 || searchTerm.length < 2) { return 0; }// if either is a 0-letter or 1-letter string

  const firstBigrams: Map<string, number> = new Map();
  for (let i = 0; i < compareTerm.length - 1; i++) {
    const bigram = compareTerm.substring(i, i + 2);
    const count = firstBigrams.has(bigram)
      ? firstBigrams.get(bigram) + 1
      : 1;

    firstBigrams.set(bigram, count);
  }

  let intersectionSize = 0;
  for (let i = 0; i < searchTerm.length - 1; i++) {
    const bigram = searchTerm.substring(i, i + 2);
    const count = firstBigrams.has(bigram)
      ? firstBigrams.get(bigram)
      : 0;

    if (count > 0) {
      firstBigrams.set(bigram, count - 1);
      intersectionSize++;
    }
  }

  const result: number = (2.0 * intersectionSize) / (compareTerm.length + searchTerm.length - 2);

  return Math.round((result + Number.EPSILON) * 100) / 100;
}
