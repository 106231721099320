import { Injectable } from '@angular/core';
import * as ImmersiveReader from '@microsoft/immersive-reader-sdk';
import { selectCurrentContentPage } from '../../reducers/core/selectors';
import { BaseService } from '../base.service';

interface IResults {
  token: string;
  subdomain: string;
}

@Injectable({
  providedIn: 'root'
})
export class ImmersiveReaderService {

  constructor(
    private baseService: BaseService
  ) { }

  launch() {
    let title = '';
    const sub = this.baseService.store.select(selectCurrentContentPage).subscribe(p => {
      title = p?.displayName;
    });
    sub.unsubscribe();

    const eles = document.querySelectorAll('.body .elements .item');
    const chunks = [];
    eles.forEach(ele => {
      if (ele.classList.contains('content-item')) {
        chunks.push({
          content: ele.innerHTML,
          mimeType: 'text/html'
        });
      } else if (ele.classList.contains('slides-item')) {
        const slides = ele.querySelectorAll('.slide-content');
        slides.forEach(slide => {
          chunks.push({
            content: slide.innerHTML,
            mimeType: 'text/html'
          });
        });
      }
    });

    const additionalElements = document.querySelectorAll('[data-reader]');
    additionalElements.forEach(el => {
      chunks.push({
        content: el.innerHTML,
        mimeType: 'text/html'
      });
    });

    const content = {
      title,
      chunks
    };

    this.getToken().subscribe(tok => {
      ImmersiveReader.launchAsync(tok.token, tok.subdomain, content);
    });
  }

  private getToken() {
    return this.baseService.http.get<IResults>(this.baseService.appConfig.serviceUrls.immersiveReader);
  }
}
