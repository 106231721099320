import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  ICourseProductTemplate,
  ICourseProductTemplateElement,
  ICourseProductTemplateModule,
  ICourseProductTemplatePage,
  LoadingStatus
} from '../../models';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {CourseProductTemplateService} from '../../services/course-product-template/course-product-template.service';
import {LoggerService} from '@stukent/logger';
import {
  appConfiguration,
  currentGlobalPage,
  currentLocation,
  globalPageToggled,
  selectContentTemplate,
  selectCurrentContentIteration,
  selectCurrentContentModule,
  selectCurrentContentPage,
  selectCurrentLocation,
  selectSavedAnnotations
} from '../../reducers/selectors';
import {currentUser} from '@stukent/user';
import {ToastrService} from 'ngx-toastr';

import {AnnotateService} from '../../services/annotate/annotate.service';
import {IMyAnnotations} from '../../models/annotations.model';
import {ILocationState} from '../../reducers/location/state';
import {LocationService} from '@stukent/feature-toggle';
import {selectScoresLoading, selectScoringMessage} from '../../reducers/scores/scores.selectors';
import {navigateToLocation, runRoundOrIteration, toggleGlobalPage} from '../../reducers/root';


interface IElementsConfig {
  cacheKey: string;
  elements: ICourseProductTemplateElement[];
  hasElements: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'sk-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() showPageTitle = true;
  @Input() hideRunButton = false;
  @Input() continueButtonText = 'Continue';
  @Input() backButtonText = 'Back';
  @Input() moduleNavigationName = 'Chapter';
  @Input() allowModuleNavigation = false;
  @Input() checkHasStarted = true;

  // This is unsed for annotations if enabled
  @ViewChild('contentsElement', {read: ElementRef}) contentsElement: ElementRef;
  @ViewChild('drawerElements', {read: ElementRef}) drawerElements: ElementRef;
  public template: ICourseProductTemplate;
  locationState: ILocationState;
  scoresAreLoading: boolean;
  dynamicElements: IElementsConfig;
  scorerMessage: string;
  roundStartDate: string;
  hasStarted = true;
  public currentModuleId;
  showRunSimButton = false;
  public navBarGlobalPages: ICourseProductTemplatePage[];
  public reloadUrl = window.location;
  public currentPageName: string;
  public pageOrIterationLoaded = false;
  previousGlobalPageId: any;
  globalPage: any;
  isGlobalDrawerVisible: boolean;
  // "drawerExpand": false
  public drawerWidth = '70%';
  public allowDrawerExpand = true;
  userName = 'User';

  // default drawer styling - override by page properties, ie.
  // "drawerWidth": "fit-content"
  templateError = false;
  tooSlow = false;
  tooSlowTimeoutId = null;
  errorDetails: string;
  // Hold the global elements...
  globalElements: IElementsConfig;
  drawerFullscreen = false;
  public currentPage: ICourseProductTemplatePage;
  public currentContentModule: ICourseProductTemplateModule;
  public completelyLoaded = false;
  public scorersLoaded = false;
  public loadingStatus = 'Please wait while we load your information, this should not take very long...';
  resetDrawerScrollTop: boolean;
  private subscriptions: Subscription[] = [];
  private annotationsApplied = false;
  private globalPages: ICourseProductTemplatePage[];
  // Defaults to reset back to - avoid magic strings
  private defaultDrawerWidth = '70%';
  private defaultAllowDrawerExpand = true;
  private nullGUID = '00000000-0000-0000-0000-000000000000';
  private productName: string;
  // Annotations Stuff...
  private myAnnotations: IMyAnnotations;
  private enableLogging = false;
  private componentName = 'Content Component';

  constructor(
    private changeRef: ChangeDetectorRef,
    private store: Store,
    private courseProductTemplateService: CourseProductTemplateService,
    private logger: LoggerService,
    private toastr: ToastrService,
    private annotationService: AnnotateService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewRef: ViewContainerRef,
    public locationService: LocationService
  ) {
  }

  ngOnInit() {

    // Set the Product Name based on App Configuration
    // TODO: I think this should be in the template (JKW)
    this.subscriptions.push(this.store.select(appConfiguration)
      .subscribe(appConfig => {
        this.productName = appConfig.productName;
      }));

    // Get the users name from the Profile change handler
    this.subscriptions.push(this.store.select(currentUser)
      .subscribe(user => {
        if (!user.isLoading) {
          this.logInfo('Profile changed');
          this.userName = user?.firstName;
          this.loadingStatus = `Welcome ${this.userName}. We are loading your content and information, just a bit longer...`;
        }
      }));


    // Get scores status via runRoundOrIteration action, so we can know if we have
    // content loading
    this.subscriptions.push(this.store.select(runRoundOrIteration).subscribe((x: any) => {

      this.scorersLoaded = !x?.scores?.scoring?.isLoading;

      if (!this.scorersLoaded && !x.core.location.location.iterationId) {
        this.completelyLoaded = false;
      }
    }));

    // Set the actual course product template change handler
    this.subscriptions.push(this.store.select(selectContentTemplate)
      .subscribe(template => {

        if (template?.loadingState?.status === LoadingStatus.loaded) {
          this.logInfo('content template loaded');

          this.template = template;

          if (!this.completelyLoaded) {
            this.setIsLoaded();
          }

          this.loadingStatus = `Welcome ${this.userName}. We are loading your information, just a bit longer...`;

          if (template.properties) {
            if (template.properties.enableAnnotations) {
              this.enableAnnotations();
            }

            let customCssUrl = template.properties.customCssUrl;

            if (!customCssUrl) {
              // Backwards compatible (JKW)
              customCssUrl = template.properties.simCssUrl;
            }

            if (customCssUrl) {
              this.loadCustomCss(customCssUrl);
            }

          }

        } else if (template?.loadingState?.statusCode === 418) {
          // User does not have permission to load
          if (this.tooSlowTimeoutId !== null) {
            clearTimeout(this.tooSlowTimeoutId);
            this.templateError = true;
            this.errorDetails = `Sorry ${this.userName}, ${this.productName} has not been fully setup by the instructor.
                                Please check again shortly or contact your instructor for a timeline.`;
          }
        }

        this.doChangeDetection();

      }));

    // Set the location change handler
    this.subscriptions.push(this.store.select(currentLocation)
      .subscribe(locationState => {
          // Check for invalid location

          if (!locationState.isLoading) {

            this.logInfo('Location changed');
            this.locationState = locationState;
            if (!this.completelyLoaded) {
              this.setIsLoaded();
            }

            if (this.myAnnotations) {
              this.annotationService.resetAnnotations(this.myAnnotations, locationState.location);
            }

            this.doChangeDetection();

          }
        }
      ));

    // Handle any Saved Instance Annotations changed
    this.subscriptions.push(this.store.select(selectSavedAnnotations)
      .subscribe(ma => {
        this.myAnnotations = ma;
        if (this.locationState?.location) {
          this.annotationService.resetAnnotations(ma, this.locationState?.location);
        }
      }));


    // Set the Scores Loading message
    this.subscriptions.push(this.store.select(selectScoresLoading)
      .subscribe(isLoading => {
        this.logInfo(`Loading Scores: ${isLoading}`);
        this.scoresAreLoading = isLoading;
        if (!isLoading) {
          // TODO Remove this eventually. This reloads the scores (jkw)
          // this.store.dispatch(loadScores());

        }
      }));

    this.subscriptions.push(this.store.select(selectCurrentContentPage)
      .subscribe(page => {

        if (!page) {
          return;
        }

        this.currentPage = page;

        this.pageOrIterationLoaded = true;
        if (page.properties?.toastMessage) {
          this.toastr.info(page.properties?.toastMessage, '');
        }

        this.currentPageName = page.displayName;

        this.logInfo(`Page changed: | ${page.id}`);

        if (page.iterations && page.iterations.length > 0) {
          // Iteration Page...
          const currentIteration = page.iterations.find(i => i.id === this.locationState.location.iterationId);

          if (currentIteration) {
            this.dynamicElements = {
              cacheKey: currentIteration.id,
              elements: currentIteration.elements,
              hasElements: true
            };
          }

        } else {
          // Normal Content Page
          this.dynamicElements = {cacheKey: page.id, elements: page.elements, hasElements: true};
        }

        if (!this.completelyLoaded) {
          this.setIsLoaded();
        }

        if (this.annotationService.isEnabled) {
          // Do a detect changes forcing the element to be current before annotations are applied.
          this.changeRef.detectChanges();
          this.annotationService.wrapWithWords();

          if (this.locationState?.location && this.myAnnotations) {
            this.annotationService.resetAnnotations(this.myAnnotations, this.locationState?.location);
          }
        }

        this.changeRef.detectChanges();
      }));

    this.subscriptions.push(this.store.select(selectCurrentContentIteration)
      .subscribe(iteration => {
        if (iteration && iteration.elements) {
          this.pageOrIterationLoaded = true;
          this.currentPageName = iteration.displayName;

          this.dynamicElements = {cacheKey: iteration.id, elements: iteration.elements, hasElements: true};

          this.doChangeDetection();
        }
      }));

    this.subscriptions.push(this.store.select(selectCurrentLocation)
      .subscribe(location => {
        if (location && location.moduleId && this.currentModuleId !== location.moduleId) {
          this.currentModuleId = location.moduleId;
          this.resetDrawerScrollTop = true;
        }
      }));

    this.subscriptions.push(this.store.select(selectCurrentContentModule)
      .subscribe(module => {
        if (module?.id) {

          this.currentContentModule = module;

          if (!this.completelyLoaded) {
            this.setIsLoaded();
          }

          if (module.pages?.length > 0) {
            this.globalPages = module.pages.filter(p => p.isGlobal);

            // We are taking out the cash balance report out for the nav bar, since we are going to use it
            // in the budget balance component, but we want it in the global pages to render it, that's why
            // there is a new variable to be sent as input for the nav bar
            this.navBarGlobalPages = this.globalPages.filter(p => p.elements.find(e => e.type !== 'cash-balance-report'));
          }

          const lastModuleId = this.template.modules[this.template.modules.length - 1].id;
          const isInProperties = !!module.properties?.lastPage;
          const isLastRound = module.id === lastModuleId;

          this.roundStartDate = module.properties?.startDate;

          if (this.hideRunButton) {
            this.showRunSimButton = false;
          } else {
            this.showRunSimButton = !isLastRound && !isInProperties;
          }

          if (this.checkHasStarted) {
            // Check the Has Module Started Service to ensure this module has started based on the date
            // If the service fails, don't prevent access
            this.courseProductTemplateService.hasModuleStarted(module.id)
              .subscribe(x => {
                  this.hasStarted = x;
                  this.doChangeDetection();
                },
                error => {
                  this.hasStarted = true;
                  this.doChangeDetection();
                });
          } else {
            this.hasStarted = true;
          }

          this.doChangeDetection();

        }
      }));

    // Set the "Show Progress" message  either default or from configuration
    this.subscriptions.push(this.store.select(selectScoringMessage)
      .subscribe(message => {
        this.scorerMessage = message || 'Your information is being processed, this should not take very long...';
      }));

    // Listen to a redux action to open the global page
    this.subscriptions.push(this.store.select(globalPageToggled).subscribe(isGlobalPageOpened => {
      this.logInfo(`Setting Global Page Visible: ${isGlobalPageOpened}`);

      this.isGlobalDrawerVisible = !!isGlobalPageOpened;
      this.doChangeDetection();
    }));

    // Listen to a redux action to set the current global page
    this.subscriptions.push(this.store.select(currentGlobalPage).subscribe(openedGlobalPageId => {
      if (!openedGlobalPageId || !this.globalPages) {
        return;
      }

      const foundPage = this.globalPages.find(x => x.id === openedGlobalPageId);
      if (foundPage) {
        this.globalPage = foundPage;
        // reset to default
        this.drawerWidth = this.defaultDrawerWidth;
        this.allowDrawerExpand = this.defaultAllowDrawerExpand;

        // override defaults if properties exist
        if (this.globalPage?.properties?.drawerWidth) {
          this.drawerWidth = this.globalPage?.properties?.drawerWidth;
        }

        if (this.globalPage?.properties?.drawerExpand !== undefined) {
          this.allowDrawerExpand = this.globalPage?.properties?.drawerExpand;
        }

        this.setGlobalElements();
        this.isGlobalDrawerVisible = true;
        if (this.resetDrawerScrollTop) {
          this.drawerElements.nativeElement?.scrollIntoView();
          this.resetDrawerScrollTop = false;
        }
      }
    }));

    // Set the too slow timeout that allows the user to refresh the page.
    this.setLoadingTooSlow();

  }

  ngOnDestroy() {
    // Unsubscribe
    this.subscriptions?.forEach(s => s?.unsubscribe());
    this.annotationService.destroy();
  }

  // Only made public for the tests to call
  public setGlobalElements(): void {
    if (!this.globalPage) {
      this.globalElements = {elements: [], cacheKey: '', hasElements: false};
      return;
    }

    if (this.globalPage.id !== this.previousGlobalPageId && this.globalPage.elements) {
      this.globalElements = {elements: this.globalPage.elements, cacheKey: this.globalPage.id, hasElements: true};
      this.previousGlobalPageId = this.globalPage.id;
    }

  }

  public closeGlobalDrawer() {
    this.store.dispatch(toggleGlobalPage({pageId: undefined, isOpened: false}));
  }

  // ANNOTATION STUFF
  ngAfterViewChecked() {
    if (this.annotationService.isEnabled) {
      this.enableAnnotations();
    }
  }

  public expandCollapse(): void {
    this.drawerFullscreen = !this.drawerFullscreen;
    this.doChangeDetection();
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  private doChangeDetection(): void {
    // this.changeRef.detectChanges();
  }

  private ensureValidLocation(): void {
    if (this.locationState?.location && this.template?.modules) {

      let requiresChange = false;

      const newLocation = {...this.locationState.location};

      const currentModule = this.template.modules.find(m => m.id === newLocation.moduleId);

      switch (true) {
        case !currentModule:
          requiresChange = true;
          newLocation.moduleId = this.template.modules[0].id;
          newLocation.pageId = this.template.modules.find(m => m.id === newLocation.moduleId)?.pages[0].id;
          break;

        case this.locationState.location.pageId === this.nullGUID:
          requiresChange = true;
          newLocation.pageId = currentModule?.pages[0].id;
          break;

        default:
          break;
      }

      if (requiresChange) {
        this.store.dispatch(navigateToLocation({location: newLocation}));
      }
    }

  }

  private setIsLoaded(): void {
    this.completelyLoaded = this.template
      && this.currentContentModule
      && this.currentPage
      && this.locationState
      && this.dynamicElements?.hasElements
      && this.scorersLoaded;

    this.ensureValidLocation();
  }

  private setLoadingTooSlow() {
    // Set not loaded check to 20 ish seconds
    this.tooSlowTimeoutId = setTimeout(this.wasLoadingTooSlow.bind(this), 20000);
  }

  private wasLoadingTooSlow() {
    if (this.template?.loadingState?.status !== LoadingStatus.loaded || !this.locationState) {
      this.tooSlow = true;
      this.tooSlowTimeoutId = null;
      this.logger.error('The required information did not load within the 20 second timeline, allow refresh');

      // Set a more detailed error to help our support folks
      this.errorDetails = 'Details: ';

      if (!this.userName) {
        this.errorDetails += 'There looks to be issues with your login. ';
      } else {
        if (this.template?.loadingState?.status !== LoadingStatus.loaded) {
          this.errorDetails += 'The requested content did not load. ';
        }

        if (!this.locationState) {
          this.errorDetails += 'The users instance information did not load. ';
        }
      }

      // Do a detect changes since we are in a timeout callback.
      this.doChangeDetection();
    }
  }

  private loadCustomCss(customCssUrl: string | null) {
    if (!customCssUrl) {
      return;
    }

    let link: HTMLLinkElement = document.getElementById('custom-css') as any;

    if (!link) {
      link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = customCssUrl;
      link.id = 'custom-css';
      document.head.appendChild(link);
    } else {
      link.parentElement.removeChild(link);
      this.loadCustomCss(customCssUrl);
    }
  }

  private enableAnnotations() {
    if (this.contentsElement && this.contentsElement.nativeElement && !this.annotationsApplied) {
      this.annotationService.init(this.contentsElement, this.componentFactoryResolver, this.viewRef);
      this.annotationsApplied = true;
    }
  }

}
