import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ISitemap } from '../models/sitemap';
import { LoggerService } from '@stukent/logger';
import { BUHIConfigurationService } from './configuration.service';
@Injectable({
  providedIn: 'root'
})
export class BUHISitemapService {

  private urlRoot: string;
  private headers: HttpHeaders;

  // Use a behaviorSubject for caching
  siteMap$: BehaviorSubject<ISitemap[]> = new BehaviorSubject<ISitemap[]>(null);

  private loaded = false;

  private enableLogging = false;
  private componentName = 'Scenario Overview Service';

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) { }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  loadSitemap() {
    // Check if the Scenario Overview has been loaded.. If so don't do anything...
    if (!this.loaded) {
      this.loadSitemapFromAPI();
    } else {
      this.logInfo(`Site Map already loaded`);
    }
  }

  private loadSitemapFromAPI(): void {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/sitemap`;
    this.http.get<ISitemap[]>(url, { headers: this.headers })
      .pipe(catchError(this.handleError))
      .subscribe(siteMap => {
        this.loaded = true;
        this.siteMap$.next(siteMap);
      });
  }

  // TODO REMOVE THIS ONCE MIGRATED TO BEHAVIOR SUBJECT (JKW)
  getSitemap(): Observable<ISitemap[]> {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/sitemap`;
    return this.http.get<ISitemap[]>(url, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Error) {
    return throwError(`Failed calling Site Map Data service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
