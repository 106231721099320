import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ISearchKeyword } from '../models/search-keyword';
import { LoggerService } from '@stukent/logger';
import { BUHIConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class BUHISearchKeywordsService {

  private keywordsLoaded = false;
  // Use a behavior subject to control the client subscriptions
  keywords$: BehaviorSubject<ISearchKeyword[]> = new BehaviorSubject<ISearchKeyword[]>([]);
  keywords: ISearchKeyword[] = [];
  keywordLoadStatus$: BehaviorSubject<string> = new BehaviorSubject<string>('not-loaded');

  private urlRoot: string;
  private headers: HttpHeaders;

  private enableLogging = false;
  private componentName = 'Search Keyword Service:';

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) { }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  public loadKeywords() {
    // Check if the keywords have been loaded.. If so don't do anything...
    if (!this.keywordsLoaded) {
      this.keywordsLoaded = true;
      this.getKeywords();
    } else {
      this.logInfo(`Keywords already loaded`);
    }
  }

  public getKeywordById(globalId: string): ISearchKeyword {
    return this.keywords?.find(k => k.globalId === globalId);
  }

  private async getKeywords() {

    this.keywordLoadStatus$.next('loading');

    let allLoaded = false;
    const maxRequest = 100;
    let requestCount = 0;
    let pageNumber = 0;
    const itemsPerRequest = 5000;

    while (!allLoaded) {
      requestCount++;
      pageNumber++;

      if (requestCount >= maxRequest) {
        this.logger.warn(`${this.componentName} Keyword service exceeded the maximum number of requets. Stopping.`);
        allLoaded = true;
        return;
      }

      this.logInfo(`Getting Page ${pageNumber} with ${itemsPerRequest} keywords`);

      // Syncronously load the keywords
      const loadedKeywords = await this.getKeywordBatch(pageNumber, itemsPerRequest);

      // add them to the results and do it again...
      this.keywords$.pipe(take(1)).subscribe(currentKeywords => {
        this.keywords = currentKeywords.concat(loadedKeywords);
        this.keywords$.next(this.keywords);
      });

      // If less than the number requested is returned, it should be considered done..
      if (loadedKeywords?.length < itemsPerRequest) {
        allLoaded = true;
        this.keywordLoadStatus$.next('loaded');
        this.logInfo(`All Keywords Loaded`);

        return;
      }

      this.logInfo(`Got Keyword batch`);

    }
  }

  private async getKeywordBatch(pageNumber: number, itemsToGet: number) {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/keywords/${pageNumber}?count=${itemsToGet}`;

    const result = await this.http.get<ISearchKeyword[]>(url, {
      headers: this.headers
    })
      .pipe(take(1), catchError(this.handleError)).toPromise();

    this.logInfo(`Keyword batch returned ${result?.length} keywords`);

    return result;
  }

  private handleError(error: Error) {
    return throwError(`Failed calling Search Keyword at url root ${this.urlRoot} Data service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
