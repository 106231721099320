import { Input, Directive } from '@angular/core';
import { ICourseProductTemplateElement } from '../../models/course-product-template';

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class ElementBaseComponent<T> {

  @Input()
  set ele(ele: ICourseProductTemplateElement<T>) {
    this.ele$ = this.setEle(ele);
    this.prepareElementConfig();
  }
  get ele(): ICourseProductTemplateElement<T> {
    return this.ele$;
  }

  static Base = true;
  private ele$: ICourseProductTemplateElement<T> = {} as ICourseProductTemplateElement<T>;
  config: T;

  /**
   * Force constructor
   */
  static call(context, ...args) {
    return ElementBaseComponent.apply(context, args);
  }

  static apply(context, args) {
    return this.prototype._constructor.apply(context, args) || context;
  }
  _constructor() {
  }

  constructor() {
    return this._constructor.apply(this, arguments);
  }
  /**
   * Forced constructor
   */

  /**
   * Parse out the config object
   */
  private prepareElementConfig() {
    if (this.ele$.config && typeof this.ele$.config === 'string') {
      this.config = JSON.parse(this.ele$.config);
    } else {
      this.config = this.ele$.config;
    }
  }

  private setEle(ele: ICourseProductTemplateElement<T>) {
    if (!ele) {
      return {} as ICourseProductTemplateElement<T>;
    }
    if (typeof ele === 'string') {
      return JSON.parse(ele);
    }

    return ele;
  }

}
