import { CampaignTypeEnum } from './campaign.model';
import { ITableData } from './table.model';
import { IProductGroup } from '../../buhi-ecosystem/models/product-grouping';

export interface IProductGroupTableState {
  productGroups: ISelectedProductGroup[];
}

export interface ISelectedProductGroup {
  id: string;
  maxCpc: number;
  active: boolean;
  name: string;

  campaignId: string;
  campaignName: string;
  campaignType: CampaignTypeEnum;
  adGroupId: string;
  adGroupName: string;

  prefix: string;
  productGrouping: string;
  productGroupId: string;
  productIds: string[];
}

export interface IProductGroupTableRow extends ITableData, IProductGroupMetricSet {
  name: string;
  campaignName: string;
  icon: string;
  adGroupName: string;
  maxCpc: number;

  tooltip?: string;
}

export interface IProductGroupMetricSet {
  id: string;
  costPerClick: number;
  impressions: number;
  clicks: number;
  clickThroughRate: number;
  cost: number;
  conversions: number;
  conversionRate: number;
  profit: number;
  costPerAcquisition: number;
  returnOnAdSpend: number;
  revenue: number;
  cogs: number;
  absTopPercent: number;
  topNPercent: number;
}

export const ProductGroupKpiOptions = [{
  label: 'Avg. CPC',
  value: 'costPerClick'
}, {
  label: 'Impressions',
  value: 'impressions'
}, {
  label: 'Clicks',
  value: 'clicks'
}, {
  label: 'CTR',
  value: 'clickThroughRate'
}, {
  label: 'Cost',
  value: 'cost'
}, {
  label: 'Conversions',
  value: 'conversions'
}, {
  label: 'Conversion Rate',
  value: 'conversionRate'
}, {
  label: 'Profits',
  value: 'profit'
}, {
  label: 'CPA',
  value: 'costPerAcquisition'
}, {
  label: 'ROAS',
  value: 'returnOnInvestment'
}, {
  label: 'Revenue',
  value: 'revenue'
}, {
  label: 'COGS',
  value: 'cogs'
}, {
  label: 'Abs. Top %',
  value: 'absTopPercent'
}, {
  label: 'Top %',
  value: 'topNPercent'
}];

export const TableHeaders = [
  {
    name: 'Product Group Name',
    sortFn: (a: IProductGroupFormTableRow, b: IProductGroupFormTableRow) => a.title?.localeCompare(b.title),
    sortDirections: ['descend', 'ascend', null],
    sortOrder: 'ascend'
  },
  {
    name: 'Historic CPC',
    sortFn: (a: IProductGroupFormTableRow, b: IProductGroupFormTableRow) => a.historicCpc - b.historicCpc,
    sortDirections: ['descend', 'ascend', null],
    sortOrder: 'ascend'
  },
  {
    name: 'Max CPC',
    sortFn: (a: IProductGroupFormTableRow, b: IProductGroupFormTableRow) => a.maxCpc - b.maxCpc,
    sortDirections: ['descend', 'ascend', null],
    sortOrder: null
  }
];

export interface IProductGroupFormTableRow extends IProductGroup {
  maxCpc: number;
  selected: boolean;
}
