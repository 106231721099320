import { createReducer, on, Action } from '@ngrx/store';
import { IElementInstruction } from '../../models/instructions.model';
import { addInstructions, toggleInstructions } from './element-instructions.actions';

export interface IElementInstructionState {
  elements: IElementInstruction[];
  isOpened: boolean;
  activeElementType: string;
}

const initialInstructionsState: IElementInstructionState = {
  elements: [],
  isOpened: false,
  activeElementType: null,
};

const reducer = createReducer(initialInstructionsState,
  on(addInstructions, (state, { elementType, instructions: instructions }) => {
    // Lower case the element type for ease of matching
    if (!elementType || !instructions) {
      return state;
    }
    const newInstruction: IElementInstruction = { elementType: elementType.toLowerCase(), instructions };
    const newInstructions: IElementInstruction[] = [...state.elements];
    if (!newInstructions.find(i => i.elementType === newInstruction.elementType)) {
      newInstructions.push(newInstruction);
    }
    return { ...state, elements: newInstructions, isOpened: false, activeElementType: elementType };
  }),

  on(toggleInstructions, (state, { elementType, isOpened }) => {
    return { ...state, isOpened, activeElementType: isOpened ? elementType : null };
  }),
);

export function elementInstructionsReducer(state: IElementInstructionState, action: Action) {
  return reducer(state, action);
}

export const elementInstructionsFeature = state => state.elementInstructions as IElementInstructionState;
