import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';

@Injectable()
export class InternetInterceptor implements HttpInterceptor {

    private modal: NzModalRef<any>;

    constructor(
        private modalService: NzModalService
    ) {
        window.addEventListener('offline', () => this.showOfflineDialog());
        window.addEventListener('online', () => this.hideOfflineDialog());
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // check to see if there's internet
        if (!window.navigator.onLine) {
            // if there is no internet, throw a HttpErrorResponse error
            // since an error is thrown, the function will terminate here
            this.showOfflineDialog();
            return throwError(new HttpErrorResponse({ error: 'No internet connection' }));

        } else {
            // else return the normal request
            return next.handle(request);
        }
    }

    private hideOfflineDialog() {
        if (!this.modal) {
            return;
        }

        this.modal.close();
        this.modal = undefined;
    }

    private showOfflineDialog() {
        if (this.modal) {
            return;
        }

        this.modal = this.modalService.warning({
            nzTitle: 'No Internet Connection',
            nzContent: 'No internet connection exists right now. This dialog will disappear once connection is reestablished',
            nzClosable: false,
            nzOkDisabled: true
        });
    }
}
