import { Injectable } from '@angular/core';
import { ScoringService } from '../../../services/scoring/scoring.service';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
  scoreModule,
  loadScores,
  setScores,
  scoreIteration,
  setErroredScorer,
  setSelectedModuleId,
  setScoreLoading,
  scoreItem
} from './scoring.actions';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { Store } from '@ngrx/store';
import { IModuleScore, IScore } from '../../../models/scoring';
import { ScrollService } from '../../../services/scroll/scroll.service';
import { ICourseProductTemplate } from '../../../models';
import { LoggerService } from '@stukent/logger';
import { currentCourseProduct } from '../../course/course.selectors';
import { selectContentTemplate } from '../../core/selectors';
import { toggleResults } from '../../view-toggle/root';

@Injectable()
export class ScoringEffects {

  private courseCode: string;
  private iterationsByModule: { [iterationId: string]: string; } = {};

  constructor(
    private actions$: Actions,
    private scoringService: ScoringService,
    store: Store,
    private scrollService: ScrollService,
    private logger: LoggerService
  ) {
    store.select(currentCourseProduct).subscribe(c => {
      this.courseCode = c?.courseCode;
    });

    store.select(selectContentTemplate).subscribe(content => this.sortIterations(content));
  }

  $scoreModule = createEffect(() => this.actions$.pipe(
    ofType(scoreModule),
    tap(() => this.scrollService.scroll()),
    mergeMap(({ moduleId, dryRun }) => {

      this.scoringService.scoreModule(moduleId, dryRun).pipe(take(1)).subscribe();

      const actions = [
        // Set the selected Module to the one being scored
        setSelectedModuleId({ moduleId }),
        // Set the Loading Modal to Show..
        setScoreLoading({ isLoading: true, showResultsModal: false }),
      ] as any[];

      return actions;
    }
    )
  ));

  $scoreIteration = createEffect(() => this.actions$.pipe(
    ofType(scoreIteration),
    tap(() => this.scrollService.scroll()),
    mergeMap(({ iterationId, moduleId, pageId }) =>
      this.scoringService.scoreIteration(iterationId, pageId, moduleId)
    )
  ), { dispatch: false });

  $scoreItem = createEffect(() => this.actions$.pipe(
    ofType(scoreItem),
    mergeMap(({ simulationInstanceId, moduleId, scorerId }) =>
      this.scoringService.scoreItem(simulationInstanceId, moduleId, scorerId)
    )
  ), { dispatch: false });

  $loadScores = createEffect(() => this.actions$.pipe(
    ofType(loadScores),
    mergeMap(() => {
      return this.scoringService.get(this.courseCode).pipe(
        switchMap(scores => {

          const moduleScores: IModuleScore<any>[] = [];

          if (scores) {
            const newScores: IScore<any>[] = (scores as any);
            const moduleScoresMap = {};

            for (const score of newScores) {
              const locationId = score.context.simulationInstanceLocationId;
              const moduleId = this.iterationsByModule[locationId] ? this.iterationsByModule[locationId] : locationId;

              if (!moduleId) { continue; }
              if (!moduleScoresMap[moduleId]) { moduleScoresMap[moduleId] = []; }

              moduleScoresMap[moduleId].push(score);
            }

            const values: IScore<any>[][] = Object.values(moduleScoresMap);
            let simulationInstanceId = '';

            for (const groupedScores of values) {
              let moduleId = '';

              if (groupedScores.length > 0) {
                // Check if simulationInstanceLocationId is an iteration id
                moduleId = this.iterationsByModule[groupedScores[0].context.simulationInstanceLocationId];
                if (!moduleId) { moduleId = groupedScores[0].context.simulationInstanceLocationId; }

                simulationInstanceId = groupedScores[0].context.simulationInstanceId;
              }

              moduleScores.push({
                moduleId,
                scores: groupedScores
              });
            }
          }

          scores = {
            moduleScores,
            simulationInstanceId: ''
          };

          return [
            setScores({ scores })
          ];
        }),
        catchError(() => EMPTY)
      );
    })
  ));

  $setErroredScorer = createEffect(() => this.actions$.pipe(
    ofType(setErroredScorer),
    map(({ displayName, scorerId, scorerType, errorMessage }) => {
      this.logger.error(`The scorer (Type: ${scorerType}, Name: ${displayName}, ScorerId: ${scorerId}) has returned in errored state because ${errorMessage}`, );
    })
  ), { dispatch: false });

  $scoresLoading = createEffect(() => this.actions$.pipe(
    ofType(setScoreLoading),
    mergeMap(({ isLoading, showResultsModal }) => {

      const actions = [] as any[];

      if (showResultsModal) {
        // Set the selected Module to the one being scored
        // Set the Loading Modal to Show..
        actions.push(toggleResults({ isResultsOpen: true }));
      }

      return actions;
    }
    )
  ));

  private sortIterations(content: ICourseProductTemplate) {
    if (!content || !content.modules) {
      return;
    }

    for (const mod of content.modules) {
      if (!mod.pages) {
        continue;
      }

      for (const page of mod.pages) {
        if (!page.iterations) {
          continue;
        }

        for (const iteration of page.iterations) {
          this.iterationsByModule[iteration.id] = mod.id;
        }
      }
    }
  }
}
