export interface IPPCStateItem {
    id?: string;
    active?: boolean;
}

export enum IDashboardContext {
    Campaigns = 0,
    AdGroups = 1,
    SearchAds = 2,
    Keywords = 3,
    ProductGroups = 4,
    DisplayAds = 5,
    NegativeKeywords = 6
}
