import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { loadResultsTemplate, setResultsTemplate } from './template.actions';
import { CourseProductTemplateService } from '../../../services/course-product-template/course-product-template.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class ResultsTemplateEffects {

  constructor(
    private actions$: Actions,
    private courseProductTemplateService: CourseProductTemplateService,
  ) {
  }

  $loadResults = createEffect(() => this.actions$.pipe(
    ofType(loadResultsTemplate),
    mergeMap(() =>
      this.courseProductTemplateService.getResultsTemplate().pipe(
        map(template => setResultsTemplate({ template })),
        catchError((e) => {
          return EMPTY;
        }
        )
      )
    )
  ));

}
