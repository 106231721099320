import { Injectable, ElementRef, ComponentFactoryResolver, ViewContainerRef, ComponentRef } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, throttleTime, distinctUntilChanged } from 'rxjs/operators';
import { AnnotationComponent } from '../../components/annotation/annotation.component';
import { IAnnotation, IMyAnnotations } from '../../models/annotations.model';
import { Store } from '@ngrx/store';
import { deleteAnnotation, removeTempAnnotation } from '../../reducers/annotation/root';
import { selectTempAnnotation } from '../../reducers/annotation/annotation.selectors';
import { ILocation } from '../../models/simulation-instance/location.model';
import { BaseService } from '../base.service';
import { MimicCoreConfig } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class AnnotateService {
  private subsciptions: Subscription[] = [];

  private container: ElementRef;
  private componentFactoryResolver: ComponentFactoryResolver;
  private annotationComponent: ComponentRef<AnnotationComponent>;
  private viewRef: ViewContainerRef;

  private startWordIndex = -1;
  private endWordIndex = -1;
  private startElement = '';
  private endElement = '';

  private previewText = '';
  private previewTextLength = 75;

  private ANNOTATION_ZONE = 'annotation-zone';
  private onHighlightChangeBinding = this.onHighlight.bind(this);
  private DEBOUNCE_TIME = 400;
  private insertedAnnotation = false;

  private selectionStart: Subscription;
  private mousedUp: Subscription;
  private clearTempAnnotation: Subscription;

  private enableLogging = false;
  isEnabled = true;

  constructor(
    private store: Store,
    private baseService: BaseService,
    private coreConfiguration: MimicCoreConfig
  ) {
    this.isEnabled = coreConfiguration.enableAnnotations;
  }

  // Wrap all the contents in "<span class=""word"">X</span>" tags
  wrapWithWords() {
    if (this.isEnabled && this.container) {
      this.traverse(this.container.nativeElement);
    }
  }

  destroy() {
    if (this.subsciptions) {
      this.subsciptions.forEach(s => s.unsubscribe());
    }
  }

  // Todo - Pull out stuff that stays consistent... (JKW)
  init(container: ElementRef, componentFactoryResolver: ComponentFactoryResolver, viewRef: ViewContainerRef) {
    this.container = container;
    this.componentFactoryResolver = componentFactoryResolver;
    this.viewRef = viewRef;

    const ele: HTMLElement = container.nativeElement;
    if (!ele) {
      return;
    }

    if (ele.classList.contains(this.ANNOTATION_ZONE)) {
      return;
    }

    ele.classList.add(this.ANNOTATION_ZONE);

    if (!this.selectionStart) {
      // Set a subscription for selectstart event
      this.selectionStart = fromEvent(ele, 'selectstart').subscribe(this.onStart.bind(this));
      // Add it to the subscriptiosn that get removed at destroy
      this.subsciptions.push(this.selectionStart);
    }

    if (!this.mousedUp) {
      // When they are done, consider it highlighted...
      this.mousedUp = fromEvent(document, 'mouseup').pipe(
        debounceTime(this.DEBOUNCE_TIME),
        throttleTime(this.DEBOUNCE_TIME),
        distinctUntilChanged(),
      ).subscribe(this.onHighlightChangeBinding);
      this.subsciptions.push(this.mousedUp);
    }

    if (!this.clearTempAnnotation) {

      // Subscribe to the Temp Annotations..}
      this.clearTempAnnotation = this.store.select(selectTempAnnotation).subscribe(theAnnotation => {

        if (!theAnnotation) {
          return;
        }
        const startBaseEle = document.getElementById(theAnnotation.startElementId);
        if (!startBaseEle) {
          return;
        }

        this.setAnnotation(theAnnotation);
      });

      this.subsciptions.push(this.clearTempAnnotation);
    }
  }

  resetAnnotations(annotations: IMyAnnotations, currentLocation: ILocation): void {
    const moduleId = currentLocation.moduleId;
    const pageId = currentLocation.pageId;
    const thisModule = (annotations.modules || []).find(m => m.id === moduleId);

    if (thisModule) {
      const thisPage = (thisModule.pages || []).find(p => p.id === pageId);

      for (const annotation of thisPage?.annotations || []) {
        this.setAnnotation(annotation);
      }
    }
    this.previewText = '';
  }

  removeAnnotation(annotation: IAnnotation): void {
    annotation.color = null;
    this.setAnnotation(annotation);
    this.previewText = '';
  }

  // Private Methods
  private setAnnotation(annotation: IAnnotation) {
    // Get the Starting Content Element by Id
    // This is an HTMLElement from the DOM
    const startingContentElement = document.getElementById(annotation.startElementId);

    // If that element isn't on the page right now, exit
    if (!startingContentElement) {
      return;
    }

    // Get all the word Elements in the Starting Content Element
    const startElementWords = startingContentElement.querySelectorAll('.word');

    // Go over each word span in the content element and add the annotation and color as needed
    startElementWords.forEach((startEle, idx) => {
      // If the current word isn't to the Start Index, return
      if (idx < annotation.startIndex) {
        return;
      }

      // If the current word is PAST the current element or in another one, exit
      // TODO - Figure out what this really does (JKW)
      if (annotation.startElementId === annotation.endElementId && idx > annotation.endIndex) {
        return;
      }

      // remove any annotation classes existing
      startEle.classList.remove('red', 'orange', 'purple', 'green', 'yellow', 'blue', 'annotated');

      // If there is no color, than don't add the annotation
      // This is used to "clear" the temporary annotation
      if (annotation.color) {
        // Add the correct annotation class
        startEle.classList.add('annotated');
        startEle.classList.add(annotation.color);
      }
    });

    // If the color was removed, that means that the tempAnnotation needs to be removed...
    if (!annotation.color) {
      this.store.dispatch(removeTempAnnotation());
    }
  }

  delete(location: ILocation, moduleId: string, pageId: string, annotation: IAnnotation) {
    // Remove it from the current page if that's where we are...
    if (location.moduleId === moduleId && location.pageId === pageId) {
      this.removeAnnotation(JSON.parse(JSON.stringify(annotation)));
    }
    this.store.dispatch(deleteAnnotation({ moduleId, pageId, annotationId: annotation.id }));
  }

  private setPreviewText(startElemId, startIndex, endElemId, endIndex) {
    const startingContentElement = document.getElementById(startElemId);
    const startElementWords = startingContentElement.querySelectorAll('.word');

    // Go over each word span in the content element and collect preview text
    // using the setAnnotation conditions and then a prevew text length
    startElementWords.forEach((startEle, idx) => {
      if (idx < startIndex || this.previewText?.length >= this.previewTextLength
        || (startElemId === endElemId && idx > endIndex)) {
        return;
      }

      this.previewText += startEle.textContent;
    });
    return this.previewText + '...';
  }

  // Clears any existing Selection
  private onStart() {
    this.startElement = '';
    this.endElement = '';
    this.startWordIndex = -1;
    this.endWordIndex = -1;

    // Todo - See if this is needed since we correctly scoped the service.. (JKW)
    this.removeAnnotationComponent();
  }

  private onHighlight() {

    // Get the selection object
    const selection = document.getSelection();

    // make sure it has at least a minimum stuff
    if (selection?.type !== 'Range' && !selection.anchorNode || selection.toString().length < 5) {
      return;
    }

    try {

      this.startElement = this.getElementId(selection.anchorNode.parentElement);
      this.startWordIndex = this.getIndexOfWord(selection.anchorNode.parentElement, selection.anchorNode.parentElement, this.startElement);
      this.endElement = this.getElementId(selection.focusNode.parentElement);
      this.endWordIndex = this.getIndexOfWord(selection.focusNode.parentElement, selection.focusNode.parentElement, this.endElement);

      // Flip them around if we went backward
      if (this.startWordIndex > this.endWordIndex) {
        const tempStart = this.endWordIndex;
        this.endWordIndex = this.startWordIndex;
        this.startWordIndex = tempStart;
      }

      if (this.startWordIndex !== this.endWordIndex) {
        // Show the Annotation Control
        this.renderAnnotation(selection.anchorNode);
      }
    } catch (e) {
      this.logInfo(e.toString());
    }

  }

  private removeAnnotationComponent() {
    if (this.annotationComponent) {
      this.annotationComponent.destroy();
      this.insertedAnnotation = false;
    }
  }

  private renderAnnotation(parentNode: Node) {
    console.log('render annotaion');
    if (!this.insertedAnnotation) {

      this.insertedAnnotation = true;

      // Add the component to the DOM
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AnnotationComponent);
      this.annotationComponent = this.viewRef.createComponent(componentFactory);
      const componentEle: HTMLElement = this.annotationComponent.location.nativeElement;
      parentNode.parentElement.appendChild(componentEle);

      // Set the annotation instance
      this.annotationComponent.instance.annotation.startIndex = this.startWordIndex;
      this.annotationComponent.instance.annotation.endIndex = this.endWordIndex;
      this.annotationComponent.instance.annotation.startElementId = this.startElement;
      this.annotationComponent.instance.annotation.endElementId = this.endElement;
      this.annotationComponent.instance.annotation.previewText =
        this.setPreviewText(this.startElement, this.startWordIndex, this.endElement, this.endWordIndex);
      this.annotationComponent.instance.addHighlight('yellow');

      // Show the popover
      this.annotationComponent.instance.visible = true;

      this.annotationComponent.changeDetectorRef.detectChanges();
    }
  }

  private getIndexOfWord(ele: HTMLElement, startEle: HTMLElement, contentElementId: string) {
    if (!ele) {
      return -1;
    }
    // .classList.contains(this.ANNOTATION_ZONE)
    if (ele.id === contentElementId) {
      const words = ele.querySelectorAll('.word');
      let index = -1;
      words.forEach((word, idx) => {
        if (index > -1) {
          return;
        }

        if (word === startEle) {
          index = idx;
        }
      });

      return index;
    }

    return this.getIndexOfWord(ele.parentElement, startEle, contentElementId);
  }

  private getElementId(ele: HTMLElement) {
    if (!ele) {
      return '';
    }

    if (ele.classList.contains('content-item')) {
      return ele.id;
    }

    return this.getElementId(ele.parentElement);
  }

  private traverse = (rootELement) => {
    [...rootELement.childNodes].forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const rspan = document.createElement('span');
        if (node.textContent.trim() === '') {
          return;
        }

        rspan.innerHTML = node.textContent
          .replace(/\S+ ?/g, (s) => {
            return `<span class="word">${s}</span>`;
          });
        node.parentNode.replaceChild(rspan, node);
      } else {
        this.traverse(node);
      }
    });
  }

  private logInfo(message: string) {
    if (this.enableLogging && console) {
      console.log(message);
    }
  }
}
