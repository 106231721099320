import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IDisplayAdImage } from '../models/display-ad-image';
import { LoggerService } from '@stukent/logger';
import { BUHIConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class BUHIDisplayAdImageService {

  private urlRoot: string;
  private headers: HttpHeaders = new HttpHeaders();

  // Use a behaviorSubject for caching
  displayAdImages$: BehaviorSubject<IDisplayAdImage[]> = new BehaviorSubject<IDisplayAdImage[]>([]);

  private displayAdImagesLoaded = false;

  private enableLogging = false;
  private componentName = 'Display Ad Image Service';

  constructor(

    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {

  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  loadImages() {
    if (!this.displayAdImagesLoaded) {
      this.getImagesData();
    }
    else {
      this.logInfo(`image data already loaded`);
    }
  }

  getImagesData(): void {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }

    const url = `${this.urlRoot}/products/images/display-ad`;

    this.http.get<IDisplayAdImage[]>(url, { headers: this.headers })
      .pipe(catchError(this.handleError))
      .subscribe((imageData: IDisplayAdImage[]) => {
        if (imageData.length > 0) {
          this.displayAdImages$.next(imageData);
          this.displayAdImagesLoaded = true;
        }
      });
  }

  /*
* @deprecated
* update element to use BehaviorSubject
*/
  getImages(): Observable<IDisplayAdImage[]> {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }

    const url = `${this.urlRoot}/products/images/display-ad`;

    return this.http.get<IDisplayAdImage[]>(url, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Error) {
    return throwError(`Failed calling product display image service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
