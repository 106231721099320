import { createReducer, on, Action, } from '@ngrx/store';
import { setCourseProduct } from './course.actions';
import { ICourseProduct } from '../../models/course.model';

const initialCourseProductState: ICourseProduct = {
  courseCode: null,
  productCode: null,
};

const reducer = createReducer(initialCourseProductState,
  on(setCourseProduct, (state, { newCourseCode, newProductCode }) => {
    if (newCourseCode && newProductCode) {
      return { ...state, courseCode: newCourseCode, productCode: newProductCode };
    } else {
      return state;
    }
  })
);

export function courseReducer(state: ICourseProduct, action: Action) {
  return reducer(state, action);
}

