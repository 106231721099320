import { Action, createReducer, on } from '@ngrx/store';
import * as toggleActions from './view-toggle.actions';
import { toggleGlobalPage } from './view-toggle.actions';

export interface IViewToggleState {
  isDrawerOpen: boolean;
  isResultsOpen: boolean;
  annotationsOpen: boolean;
  shouldLoadResults: boolean;
  isGlobalPageOpened: boolean;
  openedGlobalPageId?: string;
}

const initialViewToggleState: IViewToggleState = {
  isDrawerOpen: false,
  isResultsOpen: false,
  annotationsOpen: false,
  shouldLoadResults: false,
  isGlobalPageOpened: false,
};

const reducer = createReducer(initialViewToggleState,
  on(toggleActions.toggleDrawer, (state, { isDrawerOpen }) => ({ ...state, isDrawerOpen })),
  on(toggleActions.toggleResults, (state, { isResultsOpen }) => ({ ...state, isResultsOpen })),
  on(toggleActions.toggleAnnotations, (state, { isOpen }) => ({ ...state, annotationsOpen: isOpen })),
  on(toggleGlobalPage, (state, { pageId, isOpened }) => {
    if (state.openedGlobalPageId === pageId && state.isGlobalPageOpened === isOpened) {
      return state;
    } else {
      return { ...state, isGlobalPageOpened: isOpened, openedGlobalPageId: pageId };
    }
  })
);

export function viewToggleReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}
