import { createSelector } from '@ngrx/store';
import { IAppConfig } from '../../models/app-config.model';

export const configFeature = state => state.appConfiguration as IAppConfig;

export const appConfiguration = createSelector(
  configFeature,
  config => config
);

// The code below was added for Backwards-compatibility
// It can be removed once all simulation elements are updated to use appConfiguration
/**
 * @deprecated in favor of appConfiguration
 */
export const simConfig = createSelector(
  configFeature,
  config => config
);
