import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, tap, map, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import {
  setCourseProductTemplate,
  loadCourseProductTemplate,
  courseProductTemplateFailedToLoad,
  loadingCourseProductTemplate,
  loadCourseProductTemplateByCodes
} from './course-product-template.actions';
import { loadSimulationInstance } from '../simulation-instance/simulation-instance.actions';
import { CourseProductTemplateService } from '../../../services/course-product-template/course-product-template.service';
import { ElementService } from '@stukent/elements';
import { loadAnnotations } from '../../annotation/annotation.actions';
import { LoadingStatus } from '../../../models/course-product-template/course-product.model';
import { LoggerService } from '@stukent/logger';
import { MimicCoreConfig } from '../../../config';

@Injectable()
export class CourseProductTemplateEffects {

  private alreadyInjected = false;
  private isSimulationInstanceLoaded = false;

  constructor(
    private actions$: Actions,
    private elementsService: ElementService,
    private courseProductTemplateService: CourseProductTemplateService,
    public config: MimicCoreConfig,
    private logger: LoggerService
  ) {
  }

  $loadCourseProductTemplate = createEffect(() => this.actions$.pipe(
    ofType(loadCourseProductTemplate),
    switchMap(() => this.courseProductTemplateService.get().pipe(
      map(template => {
        // Check for error state
        if (template.loadingState?.status === LoadingStatus.error) {
          // It's a teapot
          return courseProductTemplateFailedToLoad({ template });
        } else {

          if (template.loadingState.status === LoadingStatus.loaded) {
            return setCourseProductTemplate({ template });
          } else {
            return loadingCourseProductTemplate();
          }
        }
      })
    ))
  ));

  $loadCourseProductTemplateByCodes = createEffect(() => this.actions$.pipe(
    ofType(loadCourseProductTemplateByCodes),
    switchMap(({ productCode, courseCode }) => this.courseProductTemplateService.getByCodes(courseCode, productCode).pipe(
      map(template => {
        // Check for error state
        if (template.loadingState?.status === LoadingStatus.error) {
          // It's a teapot
          return courseProductTemplateFailedToLoad({ template });
        } else {

          if (template.loadingState.status === LoadingStatus.loaded) {
            return setCourseProductTemplate({ template });
          } else {
            return loadingCourseProductTemplate();
          }
        }
      })
    ))
  ));

  $loadInstanceandAnnotations = createEffect(() => this.actions$.pipe(
    ofType(setCourseProductTemplate),
    switchMap(() => {
      if (this.isSimulationInstanceLoaded) {
        return [];
      }
      this.isSimulationInstanceLoaded = true;

      const returnActions = [loadSimulationInstance()];
      if (this.config.enableAnnotations) {
        // Only load annotations if enabled
        returnActions.push(loadAnnotations());
      }
      return returnActions;
    })
  ));

  $loadContentState$ = createEffect(() => this.actions$.pipe(
    ofType(setCourseProductTemplate),
    tap(() => {
      if (this.alreadyInjected) {
        return;
      }
      this.alreadyInjected = true;
    }),
    tap(() => {
      this.elementsService.init();
    }),
    catchError(() => EMPTY)
  ), { dispatch: false });


}
