import { createAction, props } from '@ngrx/store';
import { ICourseProductTemplate, ICourseProductTemplateModule } from '../../../models/course-product-template/course-product.model';

const prefix = '[Course Product Template]';

export const setCourseProductTemplate = createAction(`${prefix} Set`, props<{ template: ICourseProductTemplate }>());

export const setCourseProductTemplateWithoutInstance = createAction(`${prefix} Set without Load Instance`,
  props<{ template: ICourseProductTemplate }>());

export const updateModule = createAction(`${prefix} Update Module`, props<{ updatedModule: ICourseProductTemplateModule; }>());

export const setCourseProductTemplateModule = createAction(`${prefix} Set Module`,
  props<{ module: ICourseProductTemplateModule, moduleIndex: number }>());

export const loadCourseProductTemplate = createAction(`${prefix} Load`, props<{ productCode: string }>());

export const loadCourseProductTemplateByCodes = createAction(`${prefix} Load By Codes`,
  props<{ productCode: string, courseCode: string }>());

export const courseProductTemplateFailedToLoad = createAction(`${prefix} Failed to Load`, props<{ template: ICourseProductTemplate; }>());

export const loadingCourseProductTemplate = createAction(`${prefix} Loading`);
