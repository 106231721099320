import { ModuleState } from './module.state';
import { SimulationState } from './simulation.state';
import { StatefulElementComponent } from '../modules/stateful-element/stateful-element.component';
import { PageState } from './page.state';
import { ElementState } from './element.state';
import { ScoreState } from './score.state';
import { AnyStore } from '../models/element-state';

export class MockSimulationState extends SimulationState {
    isMock = true;
    get mockedState() { return this.state; }
    setMockStore(store: AnyStore) {
        this.updateState(store);
    }
    forceRunUpdaters() { this.runUpdaters({}, this.state); }
}

export class MockModuleState extends ModuleState {
    isMock = true;
    get mockedState() { return this.state; }
    setMockStore(store: AnyStore) {
        this.updateState(store);
    }
    forceRunUpdaters() { this.runUpdaters({}, this.state); }
}

export class MockPageState extends PageState {
    isMock = true;
    get mockedState() { return this.state; }
    setMockStore(store: AnyStore) {
        this.updateState(store);
    }
    forceRunUpdaters() { this.runUpdaters({}, this.state); }
}

export class MockElementState extends ElementState {
    isMock = true;
    get mockedState() { return this.state; }
    setMockStore(store: AnyStore) {
        this.updateState(store);
    }

    forceRunUpdaters() { this.runUpdaters({}, this.state); }
}

export class MockScoreState extends ScoreState {
    isMock = true;
    get mockedState() { return this.state; }
    setMockStore(store: AnyStore) {
        this.updateState(store);
    }

    forceRunUpdaters() { this.runUpdaters({}, this.state); }
}

export const mockSimulationStateOn = (
    component: StatefulElementComponent,
    root: AnyStore) => {

    const { simulationState: { store } } = component;
    const mock = new MockSimulationState(store, component, component.id);
    mock.setMockStore(root);
    component.simulationState = mock;
};

export const mockModuleStateOn = (
    component: StatefulElementComponent,
    root: AnyStore) => {

    const { moduleState: { store } } = component;
    const mock = new MockModuleState(store, component, component.id);
    mock.setMockStore(root);
    component.moduleState = mock;
};

export const mockPageStateOn = (
    component: StatefulElementComponent,
    root: AnyStore) => {

    const { pageState: { store } } = component;
    const mock = new MockPageState(store, component, component.id);
    mock.setMockStore(root);
    component.pageState = mock;
};

export const mockElementStateOn = (
    component: StatefulElementComponent,
    root: AnyStore) => {

    const { elementState: { store } } = component;
    const mock = new MockElementState(store, component, component.id);
    mock.setMockStore(root);
    component.elementState = mock;
};

export const mockScoreStateOn = (
    component: StatefulElementComponent,
    root: AnyStore) => {

    const { scoreState: { store } } = component;
    const mock = new MockScoreState(store, component, component.id);
    mock.setMockStore(root);
    component.scoreState = mock;
};

export const mockStateOn = (
    component: StatefulElementComponent,
    root: AnyStore) => {
    mockSimulationStateOn(component, root);
    mockModuleStateOn(component, root);
    mockPageStateOn(component, root);
    mockElementStateOn(component, root);
};
