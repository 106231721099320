import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  makeGlobal(name: string, callback: () => void) {
    (window as any)[name] = callback;
  }
}
