import { ISimulationInstanceElementTree } from './element.model';

export enum InteractionStatus {
  untouched,
  incomplete,
  complete
}

export interface ISimulationInstancePageTree {
  id: string;
  elements: ISimulationInstanceElementTree<any>[];
  isComplete: boolean;
  isDrawerViewed: boolean;
  iterations?: ISimulationInstancePageTree[];
  state?: { [key: string]: any };
}

export interface ISimulationInstancePage {
  id: string;
  moduleId: string;
  isComplete: boolean;

  order?: number;
  state?: { [key: string]: any };
}
