import { Injectable, OnDestroy } from '@angular/core';
import { ILocation } from '../../models/simulation-instance';
import { ICourseProductTemplate, ICourseProductTemplateModule } from '../../models/course-product-template/course-product.model';
import { ICourseProductTemplatePage } from '../../models/course-product-template/page.model';
import { ICourseProductTemplateElement } from '../../models/course-product-template/element.model';
import { Store } from '@ngrx/store';
import { selectContentTemplate, selectCurrentLocation } from '../../reducers/selectors';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CourseProductTemplateHelperService implements OnDestroy {

  private subscriptions: Subscription[] = [];

  private currentTemplate: ICourseProductTemplate;
  private currentLocation: ILocation;

  constructor(
    store: Store
  ) {
    this.subscriptions.push(store.select(selectContentTemplate).subscribe(cpt => {
      this.currentTemplate = cpt;
    }));

    this.subscriptions.push(store.select(selectCurrentLocation).subscribe(l => {
      this.currentLocation = l;
    }));

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Get an element by id from the course product template
   * @param elementId The element id
   */
  public getElementById(elementId: string): ICourseProductTemplateElement {
    if (!this.currentTemplate || !elementId) {
      return null;
    }

    for (const mod of this.currentTemplate.modules) {
      for (const page of mod.pages) {
        let element;
        if (page.iterations) {
          for (const iter of page.iterations) {
            element = iter.elements.find(x => x.id === elementId);

            if (element) {
              return element;
            }
          }
        }

        element = page.elements.find(x => x.id === elementId);
        if (element) {
          return element;
        }
      }
    }

    return null;
  }

  public getPreviousModule(): ICourseProductTemplateModule {
    const moduleIndex = this.currentTemplate.modules.findIndex(m => m.id === this.currentLocation.moduleId);
    if (this.currentTemplate.modules[moduleIndex - 1]) {
      return this.currentTemplate.modules[moduleIndex - 1];
    }
    return null;
  }

  /**
   * Get page from course product template by location
   * @param courseProductTemplate The current course proudct tempalte
   * @param location The location object from the sim state
   */
  public getPage() {
    if (!this.currentTemplate?.modules) {
      return null;
    }

    const round = this.currentTemplate.modules.find(m => m.id === this.currentLocation.moduleId);
    if (!round) {
      return null;
    }
    const page = round.pages.find(p => p.id === this.currentLocation.pageId);
    return page ? page : null;
  }

  /**
   * Get elements for a given page
   * @param page The page
   * @param location The location from the sim state
   */
  public getElementsForPage(page: ICourseProductTemplatePage, location: ILocation) {
    if (!page || !page.id) {
      return [];
    }
    if (page.iterations && page.iterations.length > 0) {
      const loc = location || {} as ILocation;
      let iteration = page.iterations.find(x => x.id === loc.iterationId);

      if (!iteration) {
        iteration = page.iterations[0];
      }

      if (iteration.elements) {
        return iteration.elements;
      }

    }
    return page.elements;
  }

  public getNextPage(template: ICourseProductTemplate, location: ILocation): ILocation {
    const mod = template.modules.find(x => x.id === location.moduleId);
    if (!mod) {
      return null;
    }

    const page = mod.pages.find(x => x.id === location.pageId);
    if (!page) {
      return null;
    }

    const idx = mod.pages.indexOf(page);
    if ((mod.pages.length > idx + 1) && !mod.pages[idx + 1].isGlobal) {
      return this.setPage(mod, mod.pages[idx + 1]);
    } else {

      let jump = idx + 1;
      for (let index = (idx + 1); index <= (mod.pages.length - 1); index++) {
        if (!mod.pages[index].isGlobal) {
          jump = index;
          break;
        }
      }

      return this.setPage(mod, mod.pages[jump]);
    }

    return null;
  }

  public getPreviousPage(): ILocation {
    const mod = this.currentTemplate.modules.find(x => x.id === this.currentLocation.moduleId);
    if (!mod) {
      return null;
    }

    const page = mod.pages.find(x => x.id === this.currentLocation.pageId);
    if (!page) {
      return null;
    }

    const idx = mod.pages.indexOf(page);
    if (idx - 1 > -1 && !mod.pages[idx - 1].isGlobal) {
      return this.setPage(mod, mod.pages[idx - 1]);
    } else {

      let jump = idx - 1;
      for (let index = (idx - 1); index > 0; index--) {
        if (!mod.pages[index].isGlobal) {
          jump = index;
          break;
        }
      }

      return this.setPage(mod, mod.pages[jump]);
    }

    return null;
  }

  public setPage(mod: ICourseProductTemplateModule, newPage: ICourseProductTemplatePage) {
    return {
      iterationId: '00000000-0000-0000-0000-000000000000',
      moduleId: mod.id,
      pageId: newPage.id,
      elementId: '00000000-0000-0000-0000-000000000000'
    };
  }
}
