import { MatchFilterFunction } from './filters';
import { ISearchKeyword } from '../../buhi-ecosystem/models/search-keyword';

export interface IKeywordPlannerState {
  keywordLists: IKeywordList[];
}

export interface IKeywordList {
  id: string;
  name: string;
  icon?: string;
  isStatic?: boolean;
  checked?: boolean;
  indeterminate?: boolean;
  keywordIds?: string[];
}

export interface IMatchTypeFilterItem {
  name: string;
  default: boolean;
  filterFn: MatchFilterFunction;
}

export interface IKeywordPlannerRow extends ISearchKeyword {
  isSelected?: boolean;

  searchTerm: string;
  trendingData: IKeywordTrendingItem[];
  competitionName: string;
}

export interface IKeywordTrendingItem {
  name?: string;
  series: IKeywordTrendingSeries[];
}

export interface IKeywordTrendingSeries {
  name?: string;
  value: number;
}

export enum KeywordPlannerCompetitionScale {
  high = 'High',
  medium = 'Medium',
  low = 'Low',
  none = 'None'
}

export interface IKeywordPlannerTotal {
  type: KeywordPlannerStatsDisplay;
  statDisplay: string;
  total: number;
}

export enum KeywordPlannerStatsDisplay {
  keywordDifficulty = 'Average Keyword Difficulty',
  results = 'Total Volume',
  variations = 'All Keywords'
}
