import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { IImageConfig } from '../../models/course-product-template/element.model';
import { DisplayOnlyElementBaseComponent } from '../../modules/displayOnly-element-base.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sk-image-element',
  templateUrl: './image-element.component.html',
  styleUrls: ['./image-element.component.scss']
})
export class ImageElementComponent
  extends DisplayOnlyElementBaseComponent<IImageConfig>
  implements OnInit {

  static type = 'image';
  isArray = false;

  images = [];

  ngOnInit() {

    if (this.config) {
      this.isArray = Array.isArray(this.config);

      if (this.isArray) {
        const images = { ...this.config } as unknown as any[];
        images.forEach(i => {
          if (this.config.height || this.config.width) {
            i.style = this.getStyle(this.config);
          }

          i.src = this.getSrc(this.config);
        });
      } else {

        const newImage = { ...this.config } as any;

        if (this.config.height || this.config.width) {
          newImage.style = this.getStyle(this.config);
        }

        newImage.src = this.getSrc(this.config);

        newImage.styleName = 'center';

        // Push it on the array
        this.images.push(newImage);
      }

    }

  }

  private getStyle(image: IImageConfig): any {
    return { width: image.width, height: image.height };
  }

  private getSrc(configImage: IImageConfig): string {
    // If this has no base, use the legacy one...
    // TODO go and reconfigure these guys (JKW)
    if (configImage?.src) {
      if (configImage.src.indexOf('http') === -1) {
        return (
          'https://mimiccdn.stukent.com/images/mimic/analytics/' + configImage.src
        );
      }
      return configImage.src;
    }

    return null;
  }

}
