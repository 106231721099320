import { Injectable } from '@angular/core';
import {
  ISimulationInstance,
  ILocation,
  ISimulationInstanceModule,
  ISimulationInstanceElement,
  ISimulationInstancePage,
} from '../../../models';
import { PageType } from '../../../models/simulation-element';

@Injectable({
  providedIn: 'root'
})
export class InstanceStateHelperService {

  getElementState(simState: ISimulationInstance, id: string): any {
    return simState.elements[id] ? simState.elements[id] : null;
  }

  getPreviousModule(location: ILocation, modules: ISimulationInstanceModule[]): ISimulationInstanceModule {
    const moduleIndex = modules.findIndex(m => m.id === location.moduleId);
    if (modules[moduleIndex - 1]) {
      return modules[moduleIndex - 1];
    }
    return null;
  }

  getElementsByPage(simState: ISimulationInstance, pageId: string): ISimulationInstanceElement<{state: any}>[] {
    const elements: ISimulationInstanceElement<{state: any}>[] = [];
    for (const elementId in simState.elements) {
      if (simState.elements[elementId].pageId === pageId) {
        elements.push(simState.elements[elementId]);
      }
    }

    return elements;
  }

  getElementsByModule(simState: ISimulationInstance, moduleId: string): ISimulationInstanceElement<{state: any}>[] {
    const elements: ISimulationInstanceElement<{state: any}>[] = [];
    for (const elementId in simState.elements) {
      if (simState.elements[elementId].moduleId === moduleId) {
        elements.push(simState.elements[elementId]);
      }
    }

    return elements;
  }

  getPagesByModule(simState: ISimulationInstance, moduleId: string): ISimulationInstancePage[] {
    const pages = Object.values(simState.pages)
      .filter((page: ISimulationInstancePage) => page.moduleId === moduleId)
      .sort((a: ISimulationInstancePage, b: ISimulationInstancePage) => a.order - b.order);

    return pages;
  }

  getNextModule(simState: ISimulationInstance): ISimulationInstanceModule {
    const modules = Object.values(simState.modules).sort((a, b) => a.order - b.order);
    if (!modules) { return null; }
    const nextModule = modules.find((m: ISimulationInstanceModule) => m.order === (simState.modules[simState.location.moduleId].order + 1));

    return nextModule ? nextModule : null;
  }

  getElementStateByType(
    simState: ISimulationInstance, pageId: string, elementType: string, pageType: PageType): ISimulationInstanceElement<{state: any}> {
    for (const elementId in simState.elements) {
      if (simState.elements[elementId]) {
        const tempEle = simState.elements[elementId];
        if (tempEle.type === elementType) {
          if ((pageType === PageType.ITERATION && tempEle.iterationId === pageId)
            || (pageType === PageType.PAGE && tempEle.pageId === pageId)) {
            return tempEle;
          }
        }
      }
    }

    return undefined;
  }

  getElementLocationFromState(elementState: ISimulationInstanceElement<{state: any}>): ILocation {
    return {
      moduleId: elementState.moduleId,
      pageId: elementState.pageId,
      iterationId: elementState.iterationId,
      elementId: elementState.id
    };
  }
}
