import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { addInstructions, loadInstructions } from './element-instructions.actions';
import { ElementInstructionsService } from '../../services/element-instructions/element-instructions.service';
import { LoggerService } from '@stukent/logger';

@Injectable()
export class InstructionsEffects {

  constructor(
    private actions$: Actions,
    private elementInstructionsService: ElementInstructionsService,
    private logger: LoggerService
  ) {
  }

  $loadInstructions$ = createEffect(() => this.actions$.pipe(
    ofType(loadInstructions),
    mergeMap(({ elementType }) =>
      this.elementInstructionsService.getInstructionsByElement(elementType)
        .pipe(
          map(instructions => addInstructions({ elementType, instructions })),
          catchError((err) => {
            this.logger.info(`Could not find instructions for ${elementType}`, err.message);
            return of(addInstructions({ elementType, instructions: [] }));
          }),
        )
    )
  ));

}
