import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {LoggerService} from '@stukent/logger';
import {Subscription} from 'rxjs';
import {selectInstructionsElementsByType} from '../../reducers/element-instructions/element-instructions.selectors';
import {loadInstructions, toggleInstructions} from '../../reducers/element-instructions/element-instructions.actions';
import {InteractionElementBaseComponent} from '../../modules/interaction-element-base.component';

@Component({
  selector: 'mimic-interaction-element',
  templateUrl: './interaction-element.component.html',
  styleUrls: ['./interaction-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InteractionElementComponent implements OnInit, OnDestroy {
  @Input() isComplete: boolean;
  @Input() title: string;
  @Input() description: string;

  @Input() instructionsType: string;
  @Input() elementType: string;

  @Input() theElement: InteractionElementBaseComponent<any, any>;
  @Input() showAlertMessage = false;
  @Input() alertMessage: string;

  public hasInstructions: boolean;

  private subscriptions: Subscription[] = [];
  private thisType: string;

  constructor(
    private store: Store,
    private logger: LoggerService,
    private changeRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {

    // Allow an override for specific instructionsType in case the element type is too generic
    this.thisType = this.instructionsType || this.elementType;

    if (this.thisType) {
      this.subscriptions.push(this.store.select(selectInstructionsElementsByType(this.thisType))
        .subscribe(instructions => {
          if (instructions?.instructions?.length > 0) {
            this.hasInstructions = true;
            this.changeRef.detectChanges();
          }

          else {
            // Load these instructions if not loaded already
            this.store.dispatch(loadInstructions({ elementType: this.thisType }));
          }
        })
      );
    }

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getInstructions() {
    // I'm just being super protective...
    if (this.thisType) {
      this.logger.event(`Instructions Opened`, { ElementType: this.thisType });
      this.store.dispatch(toggleInstructions({ elementType: this.thisType, isOpened: true }));
    }
  }
}
