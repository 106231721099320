import { props, createAction } from '@ngrx/store';

const prefix = '[Budget]';
export const setInitialBudget = createAction(`${prefix} Set Initial`, props<{ amount: number }>());
export const setInitialGlobalBudget = createAction(`${prefix} Set Global Initial`,
    props<{ newBudget: number, newBalance: number, newCredit: number }>());
export const addBalanceAmount = createAction(`${prefix} Add Balance`, props<{ amount: number }>());
export const subtractBalanceAmount = createAction(`${prefix} Subtract Balance`, props<{ amount: number }>());

export const recalculateBalance = createAction(`${prefix} Recalculate Balance`);
export const setBalance = createAction(`${prefix} Set Balance`, props<{ amount: number }>());
export const setCredit = createAction(`${prefix} Set Credit`, props<{ amount: number }>());
