export enum SimulationInstanceElementStatus {
  unrendered = 0,
  viewed = 1,
  incomplete = 2,
  invalid = 3,
  error = 4,
  complete = 5
}

export interface ISimulationInstanceElementTree<T> {
  id?: string;
  state: T;
  isComplete?: boolean;
  type?: string;
}

export interface ISimulationInstanceElement<T> {
  id?: string;
  moduleId?: string;
  pageId?: string;
  status?: SimulationInstanceElementStatus;
  iterationId?: string;
  isComplete?: boolean;
  type?: string;
  order?: number;
  // TODO Consider Removing once Stateful Element is gone (JKW)
  properties?: { [key: string]: any; };
  // TODO Consider Removing once Stateful Element is gone (JKW)
  stateChangeId?: string;
  timerExpired?: boolean;
  state?: T;
}
