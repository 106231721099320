import { IUser } from '@stukent/user';
import { IGenericState, AnyStore } from '../models/element-state';
import { setInstanceState } from '../reducers/core/simulation-instance/simulation-instance.actions';
import { WriteableBaseState } from './writeable-base.state';

interface IRelevantSimulationState {
  id: string;
  productCode: string;
  courseCode: string;
  state: IGenericState;
  profile: IUser;
}

export class SimulationState extends WriteableBaseState {
  id: string;
  productCode: string;
  courseCode: string;
  profile: IUser;

  getRelevantState(root: AnyStore): IRelevantSimulationState {
    if (!root?.core?.instance) {
      return this.getNullState();
    }

    const { state } = root?.core?.instance || {};

    const { id } = root.core.instance;
    let productCode = '';
    let courseCode = '';

    // Platform Core
    if (root.course) {
      productCode = root.course.productCode;
      courseCode = root.course.courseCode;
    } else {
      courseCode = (root as any).instructor?.course?.courseCode;
      productCode = (root as any).instructor?.course?.productCode;
    }

    // TODO: State at the simulation level is just empty for now.
    return { id, productCode, courseCode, state, profile: null };
  }

  getStateAction() {
    return setInstanceState;
  }

  stateToProps() {
    return {
      id: this.id,
      state: this.state
    };
  }

  setInternalState(relevantState: IRelevantSimulationState) {
    if (!relevantState) {
      return this.getNullState();
    }

    this.id = relevantState.id;
    this.productCode = relevantState.productCode;
    this.courseCode = relevantState.courseCode;
    this.state = relevantState.state;
    this.profile = relevantState.profile;
  }

  getNullState(): IRelevantSimulationState {
    return {
      id: '',
      productCode: '',
      courseCode: '',
      state: {},
      profile: null
    };
  }
}
