export interface IAbTestingInstanceElement {
  id: string;
  moduleId: string;
  pageId: string;
  iterationId?: string;
  isComplete?: boolean;
  type: string;
  order?: number;
  state?: any;
}

export enum ScoreType {
  ABTestingIteration = 'ABTestingIteration',
  ABTestingModule = 'ABTestingModule',
  SelectedABTestingIteration = 'SelectedABTestingIteration',
  ClusterRequest = 'ClusterRequest',
  DataTransformationResult = 'DataTransformationResult',
}

export interface IAbResult {
  A: IAbTabResult[];
  B: IAbTabResult[];
}

export interface IAbTabResult {
  Clicks: string;
  Conversions: string;
  TimeOnPage: string;
  ReturningCustomers: string;
  Visits: string;
  UnitsPerTransaction: string;
}

export interface IRow extends IAbTabResult {
  trial: string;
  tab: string;
  iterationId: string;
  resultType: ScoreType;
}

export interface IAbProperty {
  title: string;
  A: string;
  B: string;
}

export interface IAbSection {
  title: string;
  properties: IAbProperty[];
}

export interface ITabbedContentConfig {
  tabContent: ITabbedContentTab;
  tabs: string[];
}

export interface ITabbedContentTab {
  customerFAQ?: boolean;
  customerReview?: IReviewSection;
  description?: IDescriptionSection;
  hero?: IHeroSection;
  image?: IImageSection;
  infobox?: IInfoboxSection;
  mobileCustomerReview?: IMobileCustomerReviewSection;
  mobilePromotionBanner?: IMobilePromotionBannerSection;
  recentlyViewed?: IRecentlyViewedSection;
  relatedItems?: IRelatedItemSection;
  socialMedia?: ISocialMediaSection;
  suggestedBundle?: boolean;
  title?: ITitleSection;
  topNav?: ITopNavSection;
}

export interface ITabbedContentState {
  customerFAQ: ICustomerFAQState;
  customerReview: ICustomerReviewState;
  description: IDescriptionState;
  hero: IHeroState;
  image: IImageState;
  infobox: IInfoboxState;
  mobileCustomerReview: IMobileCustomerReviewSectionState;
  mobilePromotionBanner: IMobilePromotionBannerState;
  recentlyViewed: IRecentlyViewedState;
  relatedItems: IRelatedItemState;
  socialMedia: ISocialMediaState;
  suggestedBundle: ISuggestedBundleState;
  title: ITitleState;
  topNav: ITopNavState;
}

/* AB Testing State */

export interface IImageState {
  imageSize: string;
  imageIndex: number;
  thumbnailCount: number;
  thumbnailAlignment: string;
  imageColor: string;
  imageBorderWidth: string;
}

export interface ITitleState {
  titleStyle: string;
  productInfoColor: string;
  title: string;
  bidAmount: number;
  buttonAlignment: string;
  priceColor: string;
  buttonColor: string;
  buttonText: string;
}

export interface IDescriptionState {
  paragraphColor: string;
  columns: number;
  layout: string;
}

export interface ITopNavState {
  searchWidget: string;
  orderIndex: number;
  menuSide: string;
  cartStyle: string;
  enableOrders: boolean;
  enableProfile: boolean;
}

export interface ISocialMediaState {
  platform: string;
  enableArrows: boolean;
  slideDelay: number; // should be stored in seconds
}

export interface ICustomerFAQState {
  searchWidget: string;
  topQuestionsListCount: number;
  enableVotes: boolean;
}

export interface ISuggestedBundleState {
  enableWarranty: boolean;
  enableWishlist: boolean;
  enableCart: boolean;
  bundleSize: number;
}

export interface ICustomerReviewState {
  enableTotalReviews: boolean;
  enableReviewHistogram: boolean;
  reviewType: string;
}

export interface IInfoboxState {
  ctaButtonText: string;
  ctaButtonColor: string;
  ctaFontStyle: string;
  enableProductColor: boolean;
  enableReviewCount: boolean;
  enableShippingCost: boolean;
  enableStockInfo: boolean;
  orderQuantity: string; // 'dropdown' | 'number'
  price: string;
  priceColor: string;
  priceFontStyle: string;
  priceSize: string;
  reviewIcon: string;
  titleColor: string;
  titleFontStyle: string;
  titleSize: string;
}

export interface IRelatedItemState {
  itemCount: number;
  enableTitle: boolean;
  enableDescription: boolean;
  priceAlignment: string;
  priceColor: string;
  priceStyle: string;
}

export interface IHeroState {
  titleFont: string;
  titleSize: string;
  titleLocation: string;
  titleAlignment: string;
  descriptionFont: string;
  descriptionSize: string;
  buttonColor: string;
  buttonAlignment: string;
}

export interface IMobilePromotionBannerState {
  bannerSize: string;
  ribbonPosition: string;
  contentPositions: string;
  enableCtaButton: boolean;
}

export interface IMobileCustomerReviewSectionState {
  latestImageCount: number;
  latestReviewCount: number;
  enableThumbVoter: boolean;
  enableSlideArrows: boolean;
  enableSlideDelay: boolean;
}

export interface IRecentlyViewedState {
  itemCount: number;
  enableLastViewedDate: boolean;
  enableTimesViewed: boolean;
  enableCustomerRating: boolean;
}

/* AB Testing Config */

export interface ITopNavSection {
  menuSides: string[];
  orderIndexes: number[];
  searchWidgets: string[];
  carts: string[];
}
export interface ISocialMediaSection {
  slideDelay: number[];
}

export interface IImageSection {
  alignments: string[];
  imageSizes: string[];
  imageIndexes: number[];
  imageBorderWidths: string[];
  imageColors: string[];
  // thumbnailCount: number[]; // todo implement me
}

export interface ITitleSection {
  colors: string[];
  productPrice: number[];
  titleStyles: string[];
  titles: string[];
  priceColors: string[];
  buttonTexts: string[];
  buttonColors: string[];
  buttonAlignments: string[];
}

export interface IDescriptionSection {
  colors: string[];
  leftParagraph: string;
  rightParagraph: string;
  bulletedParagraph: string;
  layouts: string[];
  columns: number[];
}

export interface IInfoboxSection {
  titleFontStyles: string[];
  titleColor: string[];
  titleFontSizes: string[];
  visualReview: string[];
  prices: string[];
  priceColors: string[];
  priceSizes: string[];
  priceFontStyles: string[];
  orderQuantity: string[];
  ctaButtonText: string[];
  ctaButtonColor: string[];
  ctaFontStyles: string[];
}

export interface IReviewSection {
  reviewTypes: string[];
}

export interface IRelatedItemSection {
  colors: string[];
  alignments: string[];
  fontStyles: string[];
  itemCounts: number[];
}

export interface IHeroSection {
  titleText: string;
  imageUrl: string;
  descriptionText: string;
  fonts: string[];
  titleSizes: string[];
  titleLocations: string[];
  descriptionSizes: string[];
  alignments: string[];
  buttonColors: string[];
}

export interface IMobilePromotionBannerSection {
  bannerSizes: string[];
  imageUrl: string;
  ribbonPositions: string[];
  contentPositions: string[];
  contentTextTitle: string;
  contentText: string;
  contentTextSecondary: string;
  ribbonText: string;
  ctaButtonText: string;
}

export interface IRecentlyViewedSection {
  itemCounts: number[];
}

export interface IMobileCustomerReviewSection {
  latestImageCount: number[];
  latestReviewCount: number[];
}
