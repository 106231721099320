import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { tap, } from 'rxjs/operators';
import { ICourseProductTemplateElement } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ElementInstructionsService {

  constructor(
    private baseService: BaseService
  ) { }

  getUrl() {
    if (this.baseService.config.isInMemory) {
      return 'api/instructions';
    }
    return this.baseService.appConfig.serviceUrls.elementInstructions;
  }

  getInstructionsByElement(elementType: string) {

    let url = this.getUrl();
    if (!this.baseService.config.isInMemory) {
      url += `/element/${elementType}`;
    }

    return this.baseService.http.get<ICourseProductTemplateElement<any>[]>
      (url, { headers: { 'ocp-apim-subscription-key': this.baseService.appConfig.instructionsSubscriptionKey } })
      .pipe(
        tap(a => {
          this.baseService.logger.info('fetched instructions', a);
        }),
      );
  }
}
