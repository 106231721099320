import { CampaignTypeEnum } from './campaign.model';
import { ITableData } from './table.model';

export interface IAdGroupTableState {
  adGroups: IAdGroup[];
}

export interface IAdGroup {
  // * Default options
  id: string;
  name: string;
  maxCpc: number;
  campaignId: string;
  campaignType: CampaignTypeEnum;
  campaignName: string;
  active: boolean;

  // * Demographic options
  age?: string;
  gender?: string;
  parents?: string;

  // * Audience options
  siteVisitor?: string;
  inMarket?: string;
  affinityGroup?: string;
}

export interface IAdGroupTableRow extends ITableData, IAdGroupMetricSet {
  name: string;
  maxCpc: number;
  campaignName: string;
  icon: string;
}

export interface IAdGroupFormConfig {
  disableSiteVisitors?: boolean;
  audienceOptions: string[];
}

export interface IAdGroupMetricSet {
  id: string;
  costPerClick: number;
  impressions: number;
  clicks: number;
  clickThroughRate: number;
  conversions: number;
  conversionRate: number;
  revenue: number;
  cost: number;
  profit: number;
  costPerAcquisition: number;
  returnOnAdSpend: number;
}

export const AdGroupKpiOptions = [{
  label: 'CPC',
  value: 'costPerClick'
}, {
  label: 'Impressions',
  value: 'impressions'
}, {
  label: 'Clicks',
  value: 'clicks'
}, {
  label: 'CTR',
  value: 'clickThroughRate'
}, {
  label: 'Conversions',
  value: 'conversions'
}, {
  label: 'Conversion Rate',
  value: 'conversionRate'
}, {
  label: 'Cost',
  value: 'cost'
}, {
  label: 'Profit',
  value: 'profit'
}, {
  label: 'CPA',
  value: 'costPerAcquisition'
}, {
  label: 'ROAS',
  value: 'returnOnInvestment'
}];
