import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private notifications: NzNotificationService) {

  }

  error(title: string, message: string, params?: object) {
    this.notifications.error(title, message, params);
  }
}
