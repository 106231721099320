import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { reloadElementRegistry } from './elements.actions';
import { mergeMap } from 'rxjs/operators';
import { ElementService } from '@stukent/elements';

@Injectable()
export class ElementsEffects {

  constructor(
    private actions$: Actions,
    private elementService: ElementService
  ) {
  }

  reloadElementRegistry = createEffect(() => this.actions$.pipe(
    ofType(reloadElementRegistry),
    mergeMap(() => {
      this.elementService.init(false);
      return [];
    })
  ), { dispatch: false });


}
