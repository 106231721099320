import { createSelector } from '@ngrx/store';
import { IViewToggleState } from './view-toggle.reducer';

export const viewToggleFeature = state => state.viewToggle as IViewToggleState;

export const drawerOpen = createSelector(
  viewToggleFeature,
  toggleState => toggleState.isDrawerOpen
);

export const selectResultsToggle = createSelector(
  viewToggleFeature,
  toggleState => toggleState.isResultsOpen
);

export const annotationsVisible = createSelector(
  viewToggleFeature,
  toggleState => toggleState.annotationsOpen
);

export const globalPageToggled = createSelector(
  viewToggleFeature,
  current => current?.isGlobalPageOpened
);

export const currentGlobalPage = createSelector(
  viewToggleFeature,
  current => current?.openedGlobalPageId
);
