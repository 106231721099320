import { IGenericState } from '../models/element-state';
import { ICourseProductTemplatePage } from '../models';
import { safelyDispatch } from './utility';
import { WriteableBaseState } from './writeable-base.state';
import { setInstanceIterationCompletion, setInstancePageState } from '../reducers/core/simulation-instance/simulation-instance.actions';

interface IRelevantPageState {
  id: string;
  displayName: string;
  properties: IGenericState;
  iterations: Array<ICourseProductTemplatePage>;
  currentIteration: ICourseProductTemplatePage;
  state: IGenericState;
}

export class PageState extends WriteableBaseState {
  id: string;
  displayName: string;
  properties: IGenericState;
  iterations: Array<ICourseProductTemplatePage>;
  currentIteration: ICourseProductTemplatePage;

  setIterationCompletion(isComplete: boolean): Error | null {
    if (this.isMock) {
      this.iterations.forEach(i => {
        if (i.id === this.currentIteration?.id) {
          const { state: iState } = this.currentIteration;
          this.currentIteration.state = { ...iState, isComplete };
          i.state = { ...iState, isComplete };
        }
      });
      return null;
    }
    return safelyDispatch(this.store, setInstanceIterationCompletion, {
      iterationId: this.currentIteration.id,
      isComplete
    });
  }

  stateToProps() {
    return { pageId: this.id, state: this.state };
  }

  getStateAction() {
    return setInstancePageState;
  }

  setInternalState(relevantState: IRelevantPageState) {
    if (!relevantState) {
      return this.getNullState();
    }

    this.id = relevantState.id;
    this.displayName = relevantState.displayName;
    this.properties = relevantState.properties;
    this.iterations = relevantState.iterations;
    this.currentIteration = relevantState.currentIteration;
    this.state = relevantState.state;
  }

  getNullState(): IRelevantPageState {
    return {
      id: '',
      displayName: '',
      properties: {},
      iterations: [],
      currentIteration: null,
      state: {}
    };
  }

}
