import { IGenericState, AnyStore } from '../models/element-state';
import { ISimulationInstanceElement, SimulationInstanceElementStatus } from '../models';
import { safelyDispatch } from './utility';
import { WriteableBaseState } from './writeable-base.state';
import {
  setInstanceElementState,
  setInstanceElementCompletion,
} from '../reducers/core/simulation-instance/simulation-instance.actions';
import { recalculateBalance } from '../reducers/finance/budget/budget.actions';

interface IRelevantElementState {
  id: string;
  isComplete: boolean;
  element: ISimulationInstanceElement<IGenericState>;
  type: string;
  properties: IGenericState;
  state: IGenericState;
  status: SimulationInstanceElementStatus;
  timerExpired: boolean;
}

export class ElementState extends WriteableBaseState {
  id: string;
  isComplete: boolean;
  timerExpired = false;
  type: string;
  properties: IGenericState;
  status: SimulationInstanceElementStatus;

  getOtherElementState(elementId: string): ElementState {
    return new ElementState(this.store, this.component, elementId);
  }

  setCompletion(isComplete: boolean): Error | null {
    if (this.isMock) {
      this.isComplete = isComplete;
      return null;
    }

    if (this.isComplete === isComplete) {
      return;
    }

    return safelyDispatch(this.store, setInstanceElementCompletion, {
      elementId: this.id,
      isComplete
    });
  }

  allocateBudget(amount: number): Error | null {
    const [, error] = this.updatePartial({ budgetAllocation: amount });
    if (error) {
      return error;
    }

    return safelyDispatch(this.store, recalculateBalance, {});
  }

  setInternalState(relevantState: IRelevantElementState) {
    if (!relevantState) {
      return this.getNullState();
    }
    this.id = relevantState.id;
    this.isComplete = relevantState.isComplete;
    this.type = relevantState.type;
    this.properties = relevantState.properties;
    this.state = relevantState.state;
    this.status = relevantState.status;
    this.timerExpired = relevantState.timerExpired;
  }

  getRelevantState(root: AnyStore): IRelevantElementState {
    if (!this.id) {
      return this.getNullState();
    }

    const { elements } = root?.core?.instance;
    if (!elements) {
      return this.getNullState();
    }

    const element = elements[this.id] as ISimulationInstanceElement<IGenericState>;

    if (!element) {
      return this.getNullState();
    }

    return {
      id: this.id,
      element,
      isComplete: element.isComplete,
      type: element.type,
      properties: element.properties,
      state: element.state,
      status: element.status,
      timerExpired: element.timerExpired
    };
  }

  stateToProps() {
    return {
      elementId: this.id,
      state: this.state
    };
  }

  getStateAction() {
    return setInstanceElementState;
  }

  getNullState(): IRelevantElementState {
    return {
      id: '',
      isComplete: false,
      element: null,
      type: 'unknown',
      properties: {},
      state: {},
      status: SimulationInstanceElementStatus.unrendered,
      timerExpired: false
    };
  }

}
