import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, Input, OnInit, SimpleChange, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { LoggerService } from '@stukent/logger';
import {
  currentLocation,
  ILocation,
  ILocationState,
  IMenuItem,
  navigateToPage
} from '../../../../../mimic-core/src/public-api';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mimic-round-navigation',
  templateUrl: './round-navigation.component.html',
  styleUrls: ['./round-navigation.component.scss']
})
export class ModuleNavigationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() menuItems: IMenuItem[];

  location: ILocation;
  private subscriptions: Subscription[] = [];

  private enableLogging = false;
  private componentName = 'Round-Navigation';

  constructor(
    private store: Store,
    private changeRef: ChangeDetectorRef,
    private logger: LoggerService
  ) {
  }

  ngOnInit() {

    this.logInfo('Initializing');

    // Handle Location Changing
    this.subscriptions.push(this.store.select(currentLocation)
      .subscribe(this.locationChanged.bind(this)));

    this.logInfo('Initialized');

  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe);
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange; }) {

    if (changes.menuItems?.currentValue) {
      this.logInfo('Menu Items Changed');
      this.menuItems = changes.menuItems.currentValue;
      this.changeRef.detectChanges();
    }

  }

  private locationChanged(locationState: ILocationState): void {
    if (!locationState.isLoading
      && (locationState.location?.moduleId !== this.location?.moduleId
      || locationState.location?.pageId !== this.location?.pageId)
    ) {
      this.logInfo('Module or Page Location Changed');
      this.location = locationState.location;
      this.changeRef.detectChanges();
    }
  }

  public changeActiveItem(item: IMenuItem) {

    if (this.location.pageId !== item.itemId) {
      this.store.dispatch(navigateToPage({ pageId: item.itemId }));
    }
  }

  public trackById(item: IMenuItem): string {
    return item.id;
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

}
