import { UpdaterFn, IGenericState } from '../models/element-state';
import { Store } from '@ngrx/store';

export function processUpdaters(
    { updaters, oldState, newState, stateObject }: {
        updaters: UpdaterFn[];
        oldState: IGenericState;
        newState: IGenericState;
        stateObject: object;
    }): void {

    if (!updaters || !newState) { return; }
    updaters.forEach((updaterFn) => {
        updaterFn.bind(stateObject);
        updaterFn.call(stateObject, newState, oldState);
    });
}

export function cloneState(state: IGenericState, shouldFreeze = true): IGenericState {
    if (shouldFreeze) {
        return Object.freeze(JSON.parse(JSON.stringify(state || {})));
    }
    return JSON.parse(JSON.stringify(state || {}));
}

export function digState(state: Array<object> | object, address: string, defaultValue?: any): any {
    const nullValue = defaultValue || null;

    if (address === '') {
        return state;
    }

    if (Array.isArray(state)) {
        throw new Error(
            `State cannot be an array. Having an array at the root of your state is a bad idea. Consider using an object.`
        );
    }

    return get(state, address, nullValue);
}

export function mergePartialState(state: IGenericState, change: IGenericState) {
    const baseState = state || {};
    const baseChange = change || {};

    if (!baseChange) {
        return baseState;
    }

    const newState = cloneState(baseState, false);

    Object.keys(baseChange).forEach((key) => {
        newState[key] = baseChange[key];
    });

    return Object.freeze(newState);
}

export function safelyDispatch(store: Store, action, props): Error {
    try {
        store.dispatch(action(props));
        return null;
    } catch (e) {
        return e;
    }
}

export function get(obj, path, defaultValue?) {
    const travel = regexp =>
        String.prototype.split
            .call(path, regexp)
            .filter(Boolean)
            .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj);
    const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
    return result === undefined || result === obj ? defaultValue : result;
}
