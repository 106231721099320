import { Observable, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MimicCoreConfig } from '../config';
import { Store } from '@ngrx/store';
import { IAppConfig } from '../models/app-config.model';
import { ICourseProduct } from '../models/course.model';
import { LoggerService } from '@stukent/logger';
import { ZendeskService } from './zendesk/zendesk.service';
import { currentUser, IUser } from '@stukent/user';
import { ICourseProductTemplate, ICourseProductTemplateModule, ISimulationInstance } from '../models';
import {
  appConfiguration,
  currentCourseProduct,
  currentCourse,
  courseProductInstance,
  selectCurrentContentModule,
  selectContentTemplate,
} from '../reducers/selectors';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  appConfig: IAppConfig;
  course: ICourseProduct;
  courseProduct: ICourseProduct;
  content: ICourseProductTemplate;
  profile: IUser;
  instance: ISimulationInstance;
  currentModule: ICourseProductTemplateModule;
  // current: ICurrent;
  errorMessages: ErrorMessages;
  useLocalStore = false;

  constructor(
    public http: HttpClient,
    private notifications: NzNotificationService,
    private modal: NzModalService,
    public config: MimicCoreConfig,
    public store: Store,
    public modalService: NzModalService,
    public logger: LoggerService,
    public zendesk: ZendeskService
  ) {

    if (!config) {
      this.config = {
      } as any;
    }

    if (!this.appConfig) {
      this.appConfig = {
        serviceUrls: {}
      } as any;
    }

    if (Storage) {
      // Allow the use of local storage for stuff
      this.useLocalStore = true;
    }

    this.store.select(selectContentTemplate).subscribe(content => this.content = content);
    this.store.select(appConfiguration).subscribe(conf => this.appConfig = conf);
    this.store.select(currentCourseProduct).subscribe(course => this.courseProduct = course);
    this.store.select(currentCourse).subscribe(course => this.course = course);
    this.store.select(currentUser).subscribe(user => this.profile = user);
    this.store.select(courseProductInstance).subscribe(instance => this.instance = instance);
    this.store.select(selectCurrentContentModule).subscribe(m => this.currentModule = m);
  }

  // Global Error Handler for Mimic App
  handleError(err: Error | HttpErrorResponse, operation = 'perform an unknown action', isFatal = false, statusCode?: number)
    : Observable<any> {

    // Loads error messages with the current Operation in the message description.
    this.errorMessages = new ErrorMessages(operation);

    // Prefil the user message with either the Generic Error message or Fatal one
    let userMessage = isFatal ? this.errorMessages.fatalMessageTemplate : this.errorMessages.genericMessageTemplate;

    // Try and get a specific HTTP error message based on the response
    if (err && err instanceof HttpErrorResponse) {
      // HTTP Error, build based on response code and message
      userMessage += this.addHttpDetailedMessage(err, operation);

    }

    // Show a toast for Non-Fatal errors
    // Show a Modal for Fatal errors
    if (isFatal) {
      this.showErrorModal(userMessage);
    } else {
      this.showErrorToast(userMessage);
    }

    // Return a null observable
    return of(null);

  }

  addHttpDetailedMessage(httpError: HttpErrorResponse, operation: string): string {

    let detailedMessage = '';

    let httpResponseMessage = '';

    // Try and Get the actual error message
    const errorResponse = httpError.error;
    if (typeof errorResponse === 'string') {
      httpResponseMessage = errorResponse;
    } else if (errorResponse && errorResponse.message) {
      httpResponseMessage = errorResponse.message;
    } else {
      httpResponseMessage = 'An unknown error occured';
    }

    if (httpError.status >= 500 && httpError.status < 600) {

      // 500 Errors https://en.wikipedia.org/wiki/List_of_HTTP_status_codes#5xx_Server_errors
      if (httpError.status === 503) {
        // Services Unavailable
        detailedMessage = `Our service is saying it is currently
         not available. ${this.errorMessages.patience} ${this.errorMessages.patience} ${this.getReloadLink()} or ${this.errorMessages.dontContactSupport}`;
      } else if (httpError.status === 504) {
        // Gateway timeout
        detailedMessage = `It took much longer than expected. ${this.errorMessages.patience} ${this.getReloadLink()}`;
      } else {
        // Some other 500 error
        detailedMessage = this.getReloadLink();
      }

    } else if (httpError.status >= 400 && httpError.status < 500) {
      // 400 Errors https://en.wikipedia.org/wiki/List_of_HTTP_status_codes#4xx_Client_errors
      // Handle any known responses here

      // Bad Request
      if (httpError.status === 400) {
        detailedMessage = this.getReloadLink();
      }

      // Unauthorized, need to Login Again
      else if (httpError.status === 401) {
        if (httpResponseMessage.includes('token is expired') || httpResponseMessage.includes('JWT not present')) {
          // Expired Token
          detailedMessage = `So sorry, but your session has expired.
           Go ahead and ${this.getReloadLink('reload this page')} and you will be asked to login again.`;
        }
      }

      // Invalid License issue
      else if (httpError.status === 402 || httpError.status === 403) {
        detailedMessage = `We are unable to locate a valid license for you.
        Please return to the ${this.getHomeLink()} and make sure you are in the right place.`;
      }

      // No specific 401 known error..
      else {
        detailedMessage = `${this.errorMessages.unauthorizedMessageTemplate} You can ${this.getReloadLink('reload the simulation')}
       (we do make mistakes) or go back to ${this.getHomeLink()} and make sure you are in the right place.`;
      }
    }

    return ' ' + detailedMessage;
  }

  getReloadLink(message?: string): string {

    if (!message) {
      message = 'Please go ahead and click here to reload the simulation';
    }
    return `<br/></br><b><a href="${window.location}">${message}</a></b>`;
  }

  getHomeLink(): string {
    return `<a href="${this.config?.homeUrl}">Stukent Launcher</a>`;
  }

  showErrorModal(message: string) {

    this.modal.error({
      nzTitle: 'Oh no, this is not good',
      nzContent: message,
      nzClosable: false,
      nzKeyboard: false,
      nzOkType: 'link',
      nzOnOk: () => this.zendesk.show(),
      nzOkText: 'Or, try our Support Center'
    });
  }

  showErrorToast(message: string): void {
    this.notifications.error('Oh Dang!', message, { nzDuration: 10000 });
  }
}

class ErrorMessages {
  readonly fatalMessageTemplate: string = '';
  readonly genericMessageTemplate: string = '';
  readonly unauthorizedMessageTemplate: string = '';
  readonly dontContactSupport: string = 'You can contact support, but they already know ;).';
  readonly patience: string = 'Give it a minute and it should be good to go.';

  constructor(
    public operation: string) {
    this.fatalMessageTemplate = `An error occured while the system was attempting to <b>${operation}</b>.`;
    this.genericMessageTemplate = `An error occurred while we were attempting to <b>${operation}</b>.`;
    this.unauthorizedMessageTemplate = `According to our records, you are not allowed to <b>${operation}</b>.`;
  }
}
