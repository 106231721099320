import { createReducer, on, Action, On } from '@ngrx/store';
import { IScoringData } from '../state.models';
import {
  scoreModule,
  setScoreLoading,
  setScores,
  setIterationScore,
  setModuleScore,
  setRoundScore,
  setFullScore,
  setInstanceScore,
  setExpectedScores,
  setSelectedModuleId,
  scoreIteration,
  setScorersProgress,
  setScoreProgressMessage,
  setErroredScorer,
  showExistingScores,
  setScoreCompleted
} from './scoring.actions';

const initialScoringState: IScoringData = {
  isLoading: false,
  scores: {
    moduleScores: [],
    simulationInstanceId: ''
  },
  selectedModuleId: '',
  scorerProgress: {},
  totalExpectedScores: 0,
  erroredScorers: [],
};

export const scoringReducers: On<IScoringData>[] = [
  on(scoreModule, onScoreModule),
  on(scoreIteration, (state) => ({ ...state, isLoading: true })),
  on(setScores, onSetScores),
  on(setScoreLoading, onSetScoresLoading),
  on(setSelectedModuleId, (state, { moduleId }) => ({ ...state, selectedModuleId: moduleId })),
  on(setIterationScore, onSetScoreIteration),
  on(setModuleScore, onSetModuleScore),
  on(setRoundScore, onSetRoundScore),
  on(setFullScore, onSetFullScore),
  on(setInstanceScore, (state, { score }) => ({ ...state, scores: score })),
  on(setExpectedScores, onSetExpectedScores),
  on(setScorersProgress, onSetScorerProgress),
  on(setScoreProgressMessage, onSetScoreProgressMessage),
  on(setErroredScorer, onSetErroredScorer),
  on(showExistingScores, onSetShowExistingScores),
  on(setScoreCompleted, onSetScoringCompleted)
];

function initReducer() {
  return createReducer(initialScoringState, ...scoringReducers);
}

function onSetScores(state, { scores }) {
  if (scores) {
    if (!scores.moduleScores) {
      scores.moduleScores = [];
    }

    return { ...state, scores };
  } else {
    return state;
  }
}

function onSetErroredScorer(state, { displayName, scorerId, scorerType, errorMessage = '' }) {
  const newState: IScoringData = JSON.parse(JSON.stringify(state));
  if (!newState.erroredScorers) {
    newState.erroredScorers = [];
  }

  newState.erroredScorers.push({
    displayName,
    scorerId,
    scorerType,
    errorMessage
  });

  return newState;
}

function onSetScoreProgressMessage(state, { message }) {
  const newState: IScoringData = JSON.parse(JSON.stringify(state));
  newState.scoreProgressMessage = message;
  return newState;
}

function onSetScoringCompleted(state, { moduleId }) {
  if (state.selectedModuleId === moduleId) {
    const newState: IScoringData = JSON.parse(JSON.stringify(state));
    newState.scorerProgress[moduleId] = { ...newState.scorerProgress[moduleId], isComplete: true };
    return newState;
  } else {
    return state;
  }
}

function onSetScorerProgress(state, { scorerId, isComplete, moduleId }) {

  if (state.scorerProgress[moduleId]?.scoringPasses) {
    const newState: IScoringData = JSON.parse(JSON.stringify(state));

    newState.scorerProgress[moduleId].scoringPasses.forEach(pass => {
      const theScorer = pass.scorers?.find(scorer => scorer.scorerId === scorerId);
      if (theScorer) {
        theScorer.isComplete = isComplete;
      }
    });

    return newState;

  } else {
    return state;
  }

}

function onSetExpectedScores(state, { moduleId, scoringPasses }) {
  const newState: IScoringData = JSON.parse(JSON.stringify(state));
  newState.scorerProgress[moduleId] = { ...newState.scorerProgress[moduleId], scoringPasses };
  return newState;
}

/* tslint:disable-next-line */
function onSetShowExistingScores(state, { showExistingScores }) {
  return { ...state, showExistingScores };
}

function onScoreModule(state, { moduleId }) {
  const newState: IScoringData = JSON.parse(JSON.stringify(state));
  newState.isLoading = true;

  if (!newState.scores) {
    newState.scores = {
      moduleScores: [],
      simulationInstanceId: ''
    };
  }

  if (!newState.scores.moduleScores) {
    newState.scores.moduleScores = [];
  }

  const module = newState.scores.moduleScores.find(x => x.moduleId === moduleId);

  if (module) {
    const idx = newState.scores.moduleScores.indexOf(module);
    newState.scores.moduleScores.splice(idx, 1);
  }

  newState.scorerProgress = {};
  newState.scoreProgressMessage = '';
  newState.erroredScorers = [];

  return newState;
}

function onSetFullScore(state, { score }) {
  const newState: IScoringData = JSON.parse(JSON.stringify(state));

  if (!newState.scores) {
    return newState;
  }

  const module = newState.scores.moduleScores.find(x => x.moduleId === score.moduleId);

  if (module) {
    const idx = newState.scores.moduleScores.indexOf(module);
    newState.scores.moduleScores[idx] = score;
  } else {
    newState.scores.moduleScores.push(score);
  }

  newState.selectedModuleId = score.moduleId;

  return newState;
}

function onSetScoreIteration(state, { score, iterationId }) {
  const newState: IScoringData = JSON.parse(JSON.stringify(state));
  const moduleId = newState?.selectedModuleId;
  return onSetModuleScore(state, { score, moduleId });
}

function onSetModuleScore(state, { score, moduleId }) {
  const newState: IScoringData = JSON.parse(JSON.stringify(state));

  if (!score) {
    return newState;
  }

  if (!newState.scores) {
    newState.scores = {
      moduleScores: [],
      simulationInstanceId: ''
    };
  }

  const module = newState.scores.moduleScores.find(x => x.moduleId === moduleId);
  const moduleIndex = newState.scores.moduleScores.indexOf(module);

  if (module) {
    const existingScore = module.scores.find(x => x.id === score.id);

    if (existingScore) {
      const idx = module.scores.indexOf(existingScore);
      module.scores.splice(idx, 1);
    }

    module.scores.push(score);

    newState.scores.moduleScores[moduleIndex] = module;

  } else {
    newState.scores.moduleScores.push({
      moduleId,
      scoredDate: new Date(),
      scores: [score],
      weights: {},
    });
    newState.selectedModuleId = moduleId;
  }

  return newState;
}

function onSetRoundScore(state, { score, moduleId }) {
  const newState: IScoringData = JSON.parse(JSON.stringify(state));
  return newState;
}

function onSetScoresLoading(state, { isLoading, showResultsModal }) {

  if (showResultsModal) {

  }
  return { ...state, isLoading };
}

export function scoringReducer(contentState: any | undefined, action: Action) {
  return initReducer()(contentState, action);
}
