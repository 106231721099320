import { createSelector } from '@ngrx/store';
import { IAnnotationState } from './state';

export const annotationsFeature = state => state.annotations as IAnnotationState;

export const selectTempAnnotation = createSelector(
  annotationsFeature,
  state => state.tempAnnotation
);

export const selectSavedAnnotations = createSelector(
  annotationsFeature,
  state => state.myAnnotations
);

export const selectHasAnnotations = createSelector(
  annotationsFeature,
  state => {
    if ((state.myAnnotations?.modules || []).length > 0) {
      return true;
    }
    return false;
  }
);
