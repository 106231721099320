import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class SupplementalDataService {

  private loadedFiles = {};

  constructor(
    private baseService: BaseService
  ) { }

  getUrl() {
    return this.baseService.appConfig?.serviceUrls?.supplementalData || '';
  }

  get<T>(file: string): Observable<T> {

    // Check if we already have this file loaded...
    if (this.loadedFiles[file]) {
      this.baseService.logger.info('returning cached supplemental data');
      return of(this.loadedFiles[file]);
    }

    // If not, get the file...
    return this.baseService.http.get<T>(this.getUrl() + file, {
      headers: {
        'Ocp-Apim-Subscription-Key': this.baseService.appConfig?.simulationSubscriptionKey
      }
    })
      .pipe(
        tap(a => {
          this.loadedFiles[file] = a;
          if (this.baseService.appConfig?.enableLogging) {
            // Please don't log then entire file.
            this.baseService.logger.info('fetched supplemental data');
          }
        }),
        catchError(err => this.baseService.handleError(err, `fetch supplmenental data file ${file}`))
      );
  }
}
