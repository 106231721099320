import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ZendeskService {
  show() {
    // In order to show the form, we need to show the default "Help" button first and call the open function.
    const zenDeskWidget = (window as any).zEmbed;
    if (zenDeskWidget) {
      zenDeskWidget('webWidget', 'show');
      zenDeskWidget('webWidget', 'open');
    } else {
      if (console) {
        console.log('No zendesk widget found');
      }
    }
  }
}
