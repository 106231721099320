import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Injectable()
export class Unauthorizednterceptor implements HttpInterceptor {
  public static modal: NzModalRef<any, any> = undefined;

  constructor(private modalService: NzModalService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((x) => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      if (Unauthorizednterceptor.modal) {
        this.showUnauthorizedDialog();
      }
    }
    return throwError(err);
  }

  private showUnauthorizedDialog() {
    if (Unauthorizednterceptor.modal) {
      return;
    }

    const second = 1000;
    const tenSeconds = 5 * second;

    if (window.location.search) {
      Unauthorizednterceptor.modal = this.modalService.warning({
        nzTitle: 'You have been logged out.',
        // tslint:disable-next-line: max-line-length
        nzContent:
          `It looks like your session has been expired or you don\'t have access to visit this course.
           Please visit <a href="https://home.stukent.com">https://home.stukent.com</a>`,
        nzClosable: false,
        nzOkDisabled: true,
      });
    } else {
      Unauthorizednterceptor.modal = this.modalService.warning({
        nzTitle: 'You have been logged out.',
        // tslint:disable-next-line: max-line-length
        nzContent:
          'It looks like your session has been expired. Your page will be refreshed in 10 seconds.',
        nzClosable: false,
        nzOkDisabled: true,
      });

      setTimeout(() => {
        window.location.search = 'reload=1';
      }, tenSeconds);
    }
  }
}
