import { createReducer, on, Action, On } from '@ngrx/store';
import { ICourseProductTemplate, LoadingStatus } from '../../../models';
import { setResultsTemplate, loadResultsTemplate } from './template.actions';

const initialScoringTemplateState: ICourseProductTemplate = {
  courseCode: '',
  timezone: '',
  scoreFormat: '',
  modules: [],
  productCode: '',
  version: '',
  loadingState: {
    status: LoadingStatus.notLoaded
  }
};


export const scoresTemplateReducers: On<ICourseProductTemplate>[] = [
  on(setResultsTemplate, (state, { template }) => {
    return {
      ...template, loadingState: { status: LoadingStatus.loaded }
    };
  }),
  on(loadResultsTemplate, (state) => ({ ...state, loadingState: { status: LoadingStatus.loading } }))
];

function initReducer() {
  return createReducer(initialScoringTemplateState, ...scoresTemplateReducers);
}

export function templateReducer(contentState: any | undefined, action: Action) {
  return initReducer()(contentState, action);
}
