import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { ISponsorCategory, ISponsorOffer, ISponsorOfferResponse, ISponsorStrategy } from '../models/sponsor';
import { LoggerService } from '@stukent/logger';
import { BUHIConfigurationService } from './configuration.service';
export declare interface ISponsorDataSevice {
  init();
  getStrategyData(scenarioName: string): Observable<ISponsorStrategy[]>;
  getCategoryData(scenarioName: string): Observable<ISponsorCategory[]>;
  checkOffer(offer: ISponsorOffer): Observable<ISponsorOfferResponse>;
}

export const BuhiSponsorData: InjectionToken<ISponsorDataSevice> = new InjectionToken<ISponsorDataSevice>('ISponsorDataSevice');

@Injectable({
  providedIn: 'root'
})
export class BuhiSponsorDataService {

  // Use a behaviorSubject for caching
  strategies$: BehaviorSubject<ISponsorStrategy[]> = new BehaviorSubject<ISponsorStrategy[]>([]);
  categories$: BehaviorSubject<ISponsorCategory[]> = new BehaviorSubject<ISponsorCategory[]>([]);
  offerResponse$: BehaviorSubject<ISponsorOfferResponse> = new BehaviorSubject<ISponsorOfferResponse>(null);


  private strategiesLoaded = false;
  private sponsorsLoaded = false;
  private offerResponseLoaded = false;

  private enableLogging = false;
  private componentName = 'Sponsor Data Service';

  private urlRoot: string;
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  loadStrategies(scenarioName: string = 'social') {
    // Check if the sponsors have been loaded.. If so don't do anything...
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }

    if (!this.strategiesLoaded) {
      this.getStrategyData(scenarioName);
    } else {
      this.logInfo(`strategies already loaded`);
    }
  }

  loadCategoryData(scenarioName: string = 'social') {
    // Check if the sponsors have been loaded.. If so don't do anything...
    if (!this.sponsorsLoaded) {
      this.getSponsors(scenarioName);
    } else {
      this.logInfo(`sponsors already loaded`);
    }
  }

  /**
   * Returns Sponsor Strategies from the BUHI Ecosystem
   *
   * @param scenarioName defaults to 'social' if not provided
   * @returns ISponsorStrategy[]
   */
  private getStrategyData(scenarioName: string = 'social'): void {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/sponsors/strategies/${scenarioName}`;

    this.http.get<ISponsorStrategy[]>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(strategies => {
        if (strategies?.length > 0) {
          this.strategiesLoaded = true;
          this.strategies$.next(strategies);
        }
      });
  }

  /**
   * Returns Categories and their associated Sponsors from the BUHI Ecosystem
   *
   * @param scenarioName defaults to 'social' if not provided
   * @returns ISponsorCategory[]
   */
  private getSponsors(scenarioName: string = 'social') {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/sponsors/${scenarioName}`;

    this.http.get<ISponsorCategory[]>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(sponsorCategories => {
        if (sponsorCategories?.length > 0) {
          this.sponsorsLoaded = true;
          this.categories$.next(sponsorCategories);
        }
      });
  }

  public loadCheckOffer(offer: ISponsorOffer) {
    // Check if the manufacturers have been loaded.. If so don't do anything...
    if (!this.offerResponseLoaded) {
      this.checkOffer(offer);
    } else {
      this.logInfo(`negotiation response already loaded`);
    }
  }

  /**
   * Checks if Influencer Negotiation Offer has been accepted by the selected influencer
   * POST IInfluencerOffer object
   *
   * @param offer IInfluencerOffer
   * @returns IInfluencerOfferResponse
   */
  checkOffer(offer: ISponsorOffer) {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/sponsor/offer`;

    this.http.post<ISponsorOfferResponse>(url, offer, { headers: this.headers }).subscribe(response => {
      this.offerResponseLoaded = true;
      this.offerResponse$.next(response);
    });
  }

  private handleError(error) {
    return throwError(`Failed calling Sponsor Data service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }

}
