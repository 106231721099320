import { createSelector } from '@ngrx/store';
import { IScoringData } from './state.models';

export const rootScoringFeature = state => state.scores;
export const resultsTemplateFeature = state => state.scores?.template;
export const scoringFeature = state => state.scores?.scoring as IScoringData;

export const selectScoresLoading = createSelector(
  scoringFeature,
  state => state?.isLoading
);

export const selectShowExistingScores = createSelector(
  scoringFeature,
  state => state?.showExistingScores
);

export const selectScoringMessage = createSelector(
  scoringFeature,
  state => state?.scoreProgressMessage
);

export const selectSccoringFailures = createSelector(
  scoringFeature,
  state => state?.erroredScorers
);

export const scoringFailures = createSelector(
  scoringFeature,
  state => state?.erroredScorers
);

export const moduleScores = createSelector(
  scoringFeature,
  state => state?.scores?.moduleScores
);

export const scoredModules = createSelector(
  scoringFeature,
  state => state?.scores?.moduleScores
);

export const selectedModuleScores = createSelector(
  scoringFeature,
  state => state?.scores?.moduleScores?.find(s => s.moduleId === state.selectedModuleId)
);

export const selectResultsTemplate = createSelector(
  resultsTemplateFeature,
  state => state
);

export const selectScoringData = createSelector(
  scoringFeature,
  state => state
);

export const selectSelectedModuleId = createSelector(
  scoringFeature,
  score => score?.selectedModuleId
);

export const selectAllModuleScores = createSelector(
  scoringFeature,
  score => score?.scores?.moduleScores
);

export const selectModuleScores = (id: string) =>
  createSelector(selectAllModuleScores, (allScores) => allScores.find(m => m.moduleId === id));

export const selectSelectedModuleScores = createSelector(
  scoringFeature,
  scoring => {

    const selectedModuleId = scoring?.selectedModuleId;

    const currentModuleScores = scoring?.scores?.moduleScores?.find(m => m.moduleId === selectedModuleId);
    return currentModuleScores;

  }
);

export const selectModuleScorerScores = (moduleId: string, scorerId: string) =>
  createSelector(selectAllModuleScores, (allScores) => {
    const selectedModule = allScores.find(m => m.moduleId === moduleId);
    if (selectedModule) {
      return selectedModule.scores.find(s => s.scorerId === scorerId);
    }
  }
  );

export const selectSelectedModuleElements = createSelector(
  rootScoringFeature,
  state => {
    const templateModules = state.template?.modules;

    const selectedModule = getSelectedModule(templateModules, state.scoring?.selectedModuleId);

    if (selectedModule) {
      return (selectedModule.pages || []).length > 0 ? selectedModule.pages[0].elements : [];
    } else {
      return [];
    }
  }
);

export const selectScoringProgress = createSelector(
  scoringFeature,
  state => state.scorerProgress[state.selectedModuleId]
);

function getSelectedModule(templateModules, selectedModuleId: string) {
  if (templateModules && selectedModuleId) {
    return templateModules.find(m => m.id === selectedModuleId);
  } else {
    return null;
  }
}

export const selectScoringCompleted = createSelector(
  scoringFeature,
  state => !!state.scorerProgress[state.selectedModuleId]?.isComplete
);
