import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '@stukent/logger';
import { BUHIConfigurationService } from './configuration.service';

export interface IPPCAudienceOption {
  label: string;
  value: string;
}


export interface IPPCAudienceAllOption {
  key: string;
  options: IPPCAudienceOption[];
}


@Injectable({
  providedIn: 'root'
})
export class BUHIAudienceOptionsService {

  private urlRoot: string;
  private headers: HttpHeaders;


  // TODO Cache using a behaviorsubject
  audienceOptions$: BehaviorSubject<IPPCAudienceOption[]> = new BehaviorSubject<IPPCAudienceOption[]>([]);
  private audienceOptionsLoaded = false;

  private componentName = 'Audience Options Service';
  private enableLogging = false;

  constructor(

    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  loadAudienceOptions(option?: string) {
    if (!this.audienceOptionsLoaded) {
      this.getAudienceAllOptions(option);
    }
    else {
      this.logInfo(`audience options already loaded`);
    }
  }

  /*
* @deprecated
* update element to use BehaviorSubject
*/
  getAudienceOptions(option: string): Observable<IPPCAudienceOption[]> {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }

    const url = `${this.urlRoot}/audiences/options/${option}`;

    return this.http.get<IPPCAudienceOption[]>(url, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  getAudienceAllOptions(allOptions: string = 'AllOptions') {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }

    const url = `${this.urlRoot}audiences/options/${allOptions}`;

    this.http.get<IPPCAudienceOption[]>(url, { headers: this.headers })
      .pipe(catchError(this.handleError)).subscribe((options: IPPCAudienceOption[]) => {
        if (options.length > 0) {
          this.audienceOptions$.next(options);
          this.audienceOptionsLoaded = true;
        }
      });
  }

  private handleError(error: Error) {
    return throwError(`Failed calling Audience Options Data service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }

}
