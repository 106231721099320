import { createReducer, on, Action, On } from '@ngrx/store';
import { addPlugin } from './plugins.actions';
import { IElementStoreItem } from '../../../models/element-store-item.model';

export interface IPluginState {
  items: IElementStoreItem[];
}

export const initialState: IPluginState = {
  items: []
};

export const templateReducers: On<IPluginState>[] = [
  on(addPlugin, (state, { plugin }) => ({ ...state, items: [...state.items, plugin] }))
];

function initReducer() {
  return createReducer(initialState, ...templateReducers);
}

export function pluginsReducer(contentState: any | undefined, action: Action) {
  return initReducer()(contentState, action);
}
