import { Input, OnInit, OnDestroy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoggerService } from '@stukent/logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { ICourseProductTemplateElement } from '../models/course-product-template';
import { setInstanceElementCompletion, setInstanceElementState, recalculateBalance } from '../reducers/root';
import { selectElementComplete, selectElementState, balance } from '../reducers/selectors';
import { ISimulationInstanceElement } from '../models';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({ template: '' })
// tslint:disable-next-line: directive-class-suffix
export class InteractionElementBaseComponent<T, K> implements OnInit, OnDestroy {
  // This allows the element renderer to inject the configuration
  @Input()
  set ele(ele: ICourseProductTemplateElement<T>) {
    this.ele$ = this.setElement(ele);
    this.properties = this.ele?.properties;
    this.elementId = this.ele$.id;
    this.prepareElementConfig();
  }
  get ele(): ICourseProductTemplateElement<T> {
    return this.ele$;
  }

  private ele$: ICourseProductTemplateElement<T> = {} as ICourseProductTemplateElement<T>;

  public properties: { [key: string]: any };

  config: T;
  elementState: ISimulationInstanceElement<K>;
  stateObject: K;

  // Just exposes the elements Id
  protected elementId: string;

  // tracks any subscriptions
  protected subscriptions: Subscription[];

  public isComplete = false;

  // For Budget Impacting Interactions
  protected usesFinance: boolean;
  public moduleBalance: number;

  protected enableLogging = false;
  protected componentName = '';

  constructor(
    protected logger: LoggerService,
    protected store: Store) {

    return this._constructor.apply(this, arguments);
  }

  /*
* Ensures the constructor is called (JKW)
* These methods call, apply and _constructor are required due to our injecetion method
*/
  static call(context, ...args) {
    return InteractionElementBaseComponent.apply(context, args);
  }

  static apply(context, args) {
    return this.prototype._constructor.apply(context, args) || context;
  }

  _constructor(logger: LoggerService, store: Store) {
    // This constructor is used to apply the constructor parameters
    // Since most elements are "injected" into the system, the don't have the angular DI system
    // So, setting these here allows the base to have access to them since the implementing
    // Element has a DI that works correctly (JKW)
    this.logger = logger;
    this.store = store;
    this.subscriptions = [];
  }

  ngOnInit(): void {

    if (this.usesFinance) {
      // Handle balance changing...
      this.subscriptions.push(this.store.select(balance)
        .pipe(distinctUntilChanged())
        .subscribe(newBalance => {
          this.moduleBalance = newBalance;
          this.logInfo('balance updated');
        }));
    }

    // Handle Completeness changing
    // This happens before the handleElementStateChange subscription so that elements may set isComplete in their stateObjectChanged
    // Otherwise, isComplete is undefined/null and causes issues
    this.subscriptions.push(this.store.select(selectElementComplete(this.elementId))
      .pipe(distinctUntilChanged(x => x !== x))
      .subscribe(this.handleIsCompleteChange.bind(this)));

    // Subscribe to this elements state changes
    this.subscriptions.push(this.store.select(selectElementState(this.elementId))
      .pipe(distinctUntilChanged((prev, curr) => prev?.stateChangeId === curr?.stateChangeId))
      .subscribe(this.handleElementStateChange.bind(this)));

  }

  ngOnDestroy(): void {
    this.logInfo('destroying subscriptions');

    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  /**
   * Parse out the config object
   * Configuration items should be objects,
   * but if not, parse them
   */
  private prepareElementConfig() {
    if (this.ele$.config && typeof this.ele$.config === 'string') {
      this.config = JSON.parse(this.ele$.config);
    } else {
      this.config = this.ele$.config;
    }

    if (!this.config) {
      this.config = {} as T;
    }
  }

  private setElement(ele: ICourseProductTemplateElement<T>) {
    if (!ele) {
      return {} as ICourseProductTemplateElement<T>;
    }
    if (typeof ele === 'string') {
      return JSON.parse(ele);
    }

    return ele;
  }

  protected logInfo(message: string): void {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  protected setIsComplete(isComplete: boolean): void {
    if (isComplete !== this.isComplete) {
      this.store.dispatch(setInstanceElementCompletion({ elementId: this.elementId, isComplete }));
    }
  }

  private handleIsCompleteChange(isComplete: boolean): void {
    // Only trigger if the value changed
    if (this.isComplete !== !!isComplete) {
      this.isComplete = !!isComplete;
      this.logInfo(`isComplete changed to ${isComplete}`);
      this.isCompleteChanged(isComplete);
    }

  }

  private handleElementStateChange(newState: ISimulationInstanceElement<K>): void {
    if (!newState) {
      return;
    }

    this.logInfo('state changed');
    // Todo: a better implementation of this...

    const tempElementState: ISimulationInstanceElement<K> = JSON.parse(JSON.stringify(this.elementState || {}));

    this.elementState = JSON.parse(JSON.stringify(newState));

    // Todo: a better implementation of this...
    let stateChanged = false;
    if (tempElementState.status !== this.elementState.status) { stateChanged = true; }
    if (!stateChanged && tempElementState.timerExpired !== this.elementState.timerExpired) { stateChanged = true; }

    if (stateChanged) {
      this.stateChanged(this.elementState);
    }

    this.stateObject = this.elementState.state;
    this.stateObjectChanged(this.stateObject);
  }

  private dispatchOtherActions() {
    if (this.usesFinance) {
      // Recalulate the balance
      this.store.dispatch(recalculateBalance());
    }
  }

  protected setStateObject(newState: K): void {
    // This should only be used to replace an entire state

    // Check for differences
    // TODO See if this is really valuable (JKW)
    let stateChanged = false;
    if (!this.elementState?.state && newState) {
      stateChanged = true;
    } else {
      const existingState = JSON.stringify(this.elementState?.state);
      stateChanged = existingState !== JSON.stringify(newState);
    }

    if (stateChanged) {
      this.store.dispatch(setInstanceElementState({ elementId: this.elementId, state: JSON.parse(JSON.stringify(newState)) }));
    }

    this.dispatchOtherActions();

  }

  protected setStateProperty(property: string, value: any): void {

    const newState = JSON.parse(JSON.stringify(this.elementState?.state || {}));
    // This is used to replace just a single item of state if it is different
    if (newState[property] !== value) {
      newState[property] = value;
      this.setStateObject(newState);

      this.dispatchOtherActions();

    }
  }

  protected setStateProperties(properties: { property: string, value: any }[]): void {

    const newState = JSON.parse(JSON.stringify(this.elementState?.state || {}));
    // This is used to replace just a single item of state if it is different

    let atLeastOneUpdated = false;
    properties.forEach(prop => {
      if (newState[prop.property] !== prop.value) {
        atLeastOneUpdated = true;
        newState[prop.property] = prop.value;
      }
    });

    if (atLeastOneUpdated) {
      this.setStateObject(newState);
      this.dispatchOtherActions();
    }

  }

  // sets something the control implements
  protected isCompleteChanged(isComplete: boolean) { }

  // sets something the control implements
  protected stateObjectChanged(newItem: K) { }

  // sets something the control implements
  protected stateChanged(newState: ISimulationInstanceElement<K>) { }

}
