import { HttpHeaders } from '@angular/common/http';
import { IAppConfig } from '../../models';
import { IUrlConfig } from '../models/urlConfig';


export class BUHIConfigurationService {

  public urlRoot: string;
  public headers: HttpHeaders = new HttpHeaders();

  /***
   * Set the Url and Headers properties
   */
  public init(appConfiguration: IAppConfig): void {
    if (appConfiguration) {
      this.urlRoot = appConfiguration?.serviceUrls?.buhiEcosystemV2 || 'https://apif.stukent.com/ecosystem/v2';
      this.headers = this.headers.set('Ocp-Apim-Subscription-Key', appConfiguration?.ecosystemSubscriptionKey);
    }
  }

  /**
   *
   * @returns IUrlConfig Object
   */
  public get(): IUrlConfig {
    return { urlRoot: this.urlRoot, headers: this.headers };
  }
}
