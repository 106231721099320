import { Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoggerService } from '@stukent/logger';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ICourseProductTemplatePage } from '../../models/course-product-template/page.model';
import { IBudgetState, toggleGlobalPage } from '../../reducers/root';
import { finance, selectContentTemplate, selectCurrentContentModule } from '../../reducers/selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sk-budget-balance',
  templateUrl: './budget-balance.component.html',
  styleUrls: ['./budget-balance.component.scss']
})
export class BudgetBalanceComponent implements OnInit, OnDestroy {

  budget$: Observable<IBudgetState>;

  public showCredit = false;
  public budgetTitle = 'Budget';
  public balanceTitle = 'Balance';
  public creditTitle = 'Used Credit';
  public cashBalanceReport: ICourseProductTemplatePage;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store,
    private logger: LoggerService
  ) {
  }

  ngOnInit(): void {
    this.budget$ = this.store.select(finance);

    this.store.select(selectContentTemplate).pipe(take(1)).subscribe(cpt => {
      this.showCredit = !!cpt.properties?.useLineOfCredit;

      if (cpt.properties?.useGlobalBudget) {
        this.budgetTitle = 'Opening Balance';
        this.balanceTitle = 'Current Balance';
      }
    });

    const cashBalanceReportSubscription = this.store.select(selectCurrentContentModule)
      .subscribe(module => {
        if (module?.id) {
          if (module.pages?.length > 0) {
            // Taking the first element that match an element of cash-balance-report type
            this.cashBalanceReport =
              module.pages.find(p => p.isGlobal && p.elements.find(e => e.type === 'cash-balance-report'));
          }
        }
      });

    this.subscriptions.push(cashBalanceReportSubscription);
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  public onReportBalanceClick() {
    const eventProps = { pageName: this.cashBalanceReport.displayName };
    this.logger.event('Open Global Page button clicked', eventProps);
    this.store.dispatch(toggleGlobalPage({ pageId: this.cashBalanceReport.id, isOpened: true }));
  }
}
