import { ReadableBaseState } from './readable-base.state';
import { IScore, IModuleScore } from '../models';
import { cloneState } from './utility';
import { ICoreStore } from '../models/element-state';

interface IRelevantScoreState {
    hasScores: boolean;
    isLoading: boolean;
    currentModuleId: string;
    selectedModuleId: string;
    moduleIds: string[];
    scores: IModuleScore<any>[];
    state: IModuleScore<any>[];
}

export class ScoreState extends ReadableBaseState {
    isLoading = false;
    hasScores = false;
    currentModuleId: string;
    selectedModuleId: string;
    scores: IModuleScore<any>[];
    moduleIds: string[];

    getScoresForModule(id: string): IScore<any>[] {
        const scores = this.scores
            .filter((s: IModuleScore<any>) => s.moduleId === id)
            .map((moduleScore: IModuleScore<any>) => moduleScore.scores);

        let results = [];

        if (scores.length > 0) {
            results = scores.reduce((prev, curr) => prev.concat(curr));
        }

        return this.parseScores(results);
    }

    getSelectedModuleScores(): IScore<any>[] {
        return this.getScoresForModule(this.selectedModuleId);
    }

    getPreviousModuleScores(): IScore<any>[] {
        if (this.moduleIds.length === 0) { return []; }
        const currentIdx = this.moduleIds.indexOf(this.currentModuleId);
        const moduleIdx = currentIdx < 1 ? 0 : currentIdx - 1;

        return this.getScoresForModule(this.moduleIds[moduleIdx]);
    }

    getCurrentModuleScores(): IScore<any>[] {
        return this.getScoresForModule(this.currentModuleId);
    }

    getScoreByScorerId(scorerId: string, modulePosition: 'current' | 'previous' | 'selected'): IScore<any> | null {
        let scores: IScore<any>[];
        switch (modulePosition) {
            case 'current':
                scores = this.getCurrentModuleScores();
                break;
            case 'previous':
                scores = this.getPreviousModuleScores();
                break;
            case 'selected':
                scores = this.getSelectedModuleScores();
                break;
            default:
                scores = [];
        }

        return scores.find(s => s.scorerId === scorerId) || null;
    }

    getRelevantState(root: ICoreStore): IRelevantScoreState {
        if (!root.scores) {
            return this.getNullState();
        }
        const { core, scores } = root;

        const { modules } = core?.content || { modules: [] as any };
        const { scores: allScores, selectedModuleId } = scores?.scoring;
        const moduleIds = modules.map(m => m.id);

        return {
            hasScores: !!scores,
            isLoading: scores?.scoring?.isLoading,
            currentModuleId: core.location.location?.moduleId || '',
            selectedModuleId,
            moduleIds,
            scores: allScores?.moduleScores,
            state: allScores?.moduleScores,
        };
    }

    setInternalState(relevantState: IRelevantScoreState) {
        if (!relevantState) {
            return;
        }
        this.isLoading = relevantState.isLoading;
        this.hasScores = relevantState.hasScores;
        this.scores = relevantState.scores;
        this.currentModuleId = relevantState.currentModuleId;
        this.selectedModuleId = relevantState.selectedModuleId;
        this.moduleIds = relevantState.moduleIds;
    }

    getNullState(): IRelevantScoreState {
        return {
            hasScores: false,
            isLoading: false,
            scores: [],
            currentModuleId: '',
            selectedModuleId: '',
            moduleIds: [],
            state: undefined,
        };
    }

    private parseScores(scores: IScore<any>[]): IScore<any>[] {
        return scores.map((s: IScore) => {
            const newScore = cloneState(s, false);
            const result = s.result ?? {};

            if (typeof result === 'string') {
                let newResult;
                try {
                    newResult = JSON.parse(result);
                } catch (e) {
                    newResult = result;
                }
                newScore.result = newResult;
            }

            return Object.freeze(newScore as IScore<any>);
        });
    }
}
