import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoggerService } from '@stukent/logger';
import { DisplayOnlyElementBaseComponent } from '../../modules/displayOnly-element-base.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sk-pdf-viewer',
  templateUrl: './pdf-viewer-element.component.html',
  styleUrls: ['./pdf-viewer-element.component.scss']
})
export class PdfViewerElementComponent
  extends DisplayOnlyElementBaseComponent<{ src: string; }>
  implements OnInit, OnDestroy {

  static type = 'pdf-viewer';
  public zoom = 1;

  constructor(
    private changeRef: ChangeDetectorRef,
    store: Store,
    protected logger: LoggerService
  ) {
    super(logger, store);

    this.componentName = 'PDF Viewer';
    this.enableLogging = false;
  }

  ngOnInit(): void {
    this.changeRef.detectChanges();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  setZoom(amount) {
    this.zoom += amount;
    this.zoom = this.zoom < 0 ? 0 : this.zoom;

    this.changeRef.detectChanges();
  }
}
