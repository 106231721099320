import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '@stukent/logger';
import { IMediaType, ISocialMediaPostContent } from '../models/posts';
import { postContentMediaTypes } from '../defaults-from-social/buhi-he/selections';
import { prPostContentSelections } from '../scenario-files/selections/pr';
import { socialPostContentSelections } from '../scenario-files/selections/social';
import { socialPostContentSelections2022 } from '../scenario-files/selections/social-media-2022';
import { BUHIConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class BUHISocialMediaPostContentService {

  private urlRoot;
  private headers: HttpHeaders;


  // Use a behaviorSubject for caching
  mediaContent$: BehaviorSubject<ISocialMediaPostContent[]> = new BehaviorSubject<ISocialMediaPostContent[]>([]);
  mediaType$: BehaviorSubject<IMediaType[]> = new BehaviorSubject<IMediaType[]>([]);

  private mediaContentLoaded = false;
  private mediaTypeLoaded = false;

  private enableLogging = false;
  private componentName = 'Social Media Post Data Service';

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) { }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  loadMediaContentSelections(scenario: string) {
    if (!this.mediaContentLoaded) {
      this.getContentSelectionsFromBuhi(scenario);
    }
    else {
      this.logInfo(`Media Content Selections already loaded`);
    }
  }


  private getContentSelectionsFromBuhi(scenario: string) {
    if (!scenario) {
      scenario = 'social';
    }

    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }

    const url = `${this.urlRoot}/postcontent/${scenario}`;
    this.http.get<ISocialMediaPostContent[]>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(data => {
        if (data?.length > 0) {
          this.mediaContentLoaded = true;
          this.mediaContent$.next(data);
        }
      });
  }

  loadMediaTypes() {
    if (!this.mediaTypeLoaded) {
      this.getMediaTypesFromBuhi();
    }
    else {
      this.logInfo(`Media type already loaded`);
    }
  }

  private getMediaTypesFromBuhi() {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/mediatype`;
    this.http.get<IMediaType[]>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(data => {
        if (data?.length > 0) {
          this.mediaTypeLoaded = true;
          this.mediaType$.next(data);
        }
      });
  }


  /*
  * backward compatibility
  */
  getContentSelections(scenario: string): ISocialMediaPostContent[] {

    if (scenario) {
      switch (scenario) {
        case 'pr':
          return prPostContentSelections;
        case 'social':
          return socialPostContentSelections;
        case 'social-2022': // Updated content 2022
          return socialPostContentSelections2022;
        default:
          return socialPostContentSelections;
      }

    } else {
      return socialPostContentSelections;
    }
  }

  /*
  * backward compatibility
  */
  getMediaTypes(): IMediaType[] {
    return postContentMediaTypes;
  }

  private handleError(error) {
    return throwError(`Failed calling Persona Data service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }

}
