import { Input, OnInit, OnDestroy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoggerService } from '@stukent/logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { ICourseProductTemplateElement } from '../models/course-product-template';

@Component({ template: '' })
// tslint:disable-next-line: directive-class-suffix
export class DisplayOnlyElementBaseComponent<T> implements OnInit, OnDestroy {
  // This allows the element renderer to inject the configuration
  @Input()
  set ele(ele: ICourseProductTemplateElement<T>) {
    this.ele$ = this.setElement(ele);
    this.elementId = this.ele$.id;
    this.properties = this.ele$?.properties || {};
    this.prepareElementConfig();
  }
  get ele(): ICourseProductTemplateElement<T> {
    return this.ele$;
  }

  private ele$: ICourseProductTemplateElement<T> = {} as ICourseProductTemplateElement<T>;
  config: T;

  public properties: { [key: string]: any };

  // Just exposes the elements Id
  protected elementId: string;

  // tracks any subscriptions
  protected subscriptions: Subscription[] = [];

  protected enableLogging = false;
  protected componentName = '';

  constructor(
    protected logger: LoggerService,
    protected store: Store) {
    return this._constructor.apply(this, arguments);
  }

  /*
* Ensures the constructor is called (JKW)
* These methods call, apply and _constructor are required due to our injecetion method
*/
  static call(context, ...args) {
    return DisplayOnlyElementBaseComponent.apply(context, args);
  }

  static apply(context, args) {
    return this.prototype._constructor.apply(context, args) || context;
  }

  _constructor(logger: LoggerService, store: Store) {
    // This constructor is used to apply the constructor parameters
    // Since most elements are "injected" into the system, the don't have the angular DI system
    // So, setting these here allows the base to have access to them since the implementing
    // Element has a DI that works correctly (JKW)
    this.logger = logger;
    this.store = store;
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.logInfo('base initialized');
  }

  ngOnDestroy(): void {
    this.logInfo('destroying subscriptions');

    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  /**
   * Parse out the config object
   * Configuration items should be objects,
   * but if not, parse them
   */
  private prepareElementConfig() {
    if (this.ele$.config && typeof this.ele$.config === 'string') {
      this.config = JSON.parse(this.ele$.config);
    } else {
      this.config = this.ele$.config;
    }

    if (!this.config) {
      this.config = {} as T;
    }
  }

  private setElement(ele: ICourseProductTemplateElement<T>) {
    if (!ele) {
      return {} as ICourseProductTemplateElement<T>;
    }
    if (typeof ele === 'string') {
      return JSON.parse(ele);
    }

    return ele;
  }

  protected logInfo(message: string): void {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

}
