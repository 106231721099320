import { Component } from '@angular/core';

@Component({
  selector: 'mimic-app-root',
  templateUrl: './mimic-app.component.html',
  styles: ['']
})
export class MimicAppComponent {

}
