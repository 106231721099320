import { createReducer, on, Action } from '@ngrx/store';
import { IAnnotationState } from './state';
import {
  addAnnotation,
  clearTempAnnotation,
  deleteAnnotation,
  editAnnotation,
  removeTempAnnotation,
  setAnnotationInstance,
  setTempAnnotation
} from './annotation.actions';

import { IModuleAnnotations } from '../..//models/annotations.model';

const initialAnnotationState: IAnnotationState = { isLoaded: false, myAnnotations: {} };

const reducer = createReducer(initialAnnotationState,
  on(addAnnotation, (state, { annotation }) => {

    // TODO - Improve this reducer by only updating the changed item
    // But since the selector is just on myAnnotations, it really doesn't matter too much

    // Find the module and page, and add the annotation to it...
    const moduleId = annotation.location.moduleId;
    const pageId = annotation.location.pageId;

    // Location is just a temporary thing...
    const newAnnotation = JSON.parse(JSON.stringify(annotation));
    delete newAnnotation.location;

    const modules = JSON.parse(JSON.stringify(state.myAnnotations.modules || []));

    const currentModule = modules.find(m => m.id === moduleId);
    // If this module is not set, add it...
    if (!currentModule) {
      modules.push({
        id: moduleId,
        pages: [
          {
            id: pageId,
            annotations: [newAnnotation]
          }
        ]
      });

    } else {
      currentModule.pages = currentModule.pages || [];
      const page = (currentModule.pages || []).find(p => p.id === pageId);

      if (page) {
        page.annotations.push(newAnnotation);
      } else {
        currentModule.pages.push({
          id: pageId,
          annotations: [newAnnotation]
        });
      }
    }

    return {
      ...state,
      myAnnotations: { ...state.myAnnotations, modules }
    };

  }),

  // Delete an Annotation
  on(deleteAnnotation, (state, { moduleId, pageId, annotationId }) => {

    // TODO - Improve this reducer by only updating the changed item
    // But since the selector is just on myAnnotations, it really doesn't matter too much

    const modules: IModuleAnnotations[] = JSON.parse(JSON.stringify(state.myAnnotations.modules || []));

    const currentModule: IModuleAnnotations = modules.find(m => m.id === moduleId);
    // If this module is not set, add it...

    if (currentModule) {
      currentModule.pages = currentModule.pages || [];
      const page = (currentModule.pages || []).find(p => p.id === pageId);

      if (page) {
        // Actually remove the annotation
        page.annotations = page.annotations.filter(a => a.id !== annotationId);
      }

      // Remove Empty Pages
      currentModule.pages = currentModule.pages.filter(p => p.annotations?.length > 0);

    }

    // Remove Empty Modules too
    return {
      ...state,
      myAnnotations: { ...state.myAnnotations, modules: modules.filter(m => m.pages?.length > 0) }
    };

  }),

  // Handles setting the Temp Annotation
  on(setTempAnnotation, (state, { annotation }) => {
    return { ...state, tempAnnotation: annotation };
  }),

  // Handles updating existing Annotation with edited annotation
  on(editAnnotation, (state, { annotation, moduleId, pageId }) => {
    const curentState = JSON.parse(JSON.stringify(state));

    const modules = curentState.myAnnotations.modules || [];
    const moduleIndex = modules.indexOf(modules.find(m => m.id === moduleId));
    const currentModule = curentState.myAnnotations.modules[moduleIndex];

    if (currentModule) {
      const page = (currentModule.pages || []).find(p => p.id === pageId);
      if (page) {
        const pageIndex = currentModule.pages.indexOf(page);
        const annotationIndex = page.annotations.indexOf(page.annotations.find(a => a.id === annotation.id));
        // I imagine there is a better way to do this - er
        curentState.myAnnotations.modules[moduleIndex].pages[pageIndex].annotations[annotationIndex]
          = JSON.parse(JSON.stringify(annotation));
      }
    }

    return {
      ...curentState,
      myAnnotations: { ...curentState.myAnnotations }
    };
  }),

  // Handles clearing the temp annotation
  // Sets the color to null so that the highlights get removed
  on(clearTempAnnotation, (state) => {
    return { ...state, tempAnnotation: { ...state.tempAnnotation, color: null } };
  }),

  // Handles removing the temp annotation from state
  on(removeTempAnnotation, (state) => {
    return {
      ...state,
      tempAnnotation: undefined
    };
  }),

  // Handles setting annotations from the service call
  on(setAnnotationInstance, (state, { myAnnotations }) => {
    return { ...state, isLoaded: true, myAnnotations };
  })
);

export function annotationsReducer(state: IAnnotationState, action: Action) {
  return reducer(state, action);
}
