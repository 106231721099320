import { ActionReducerMap } from '@ngrx/store';
import { courseProductTemplateReducer } from './course-product-template/root';
import { simulationInstanceReducer } from './simulation-instance/root';
import { locationReducer } from '../location/root';
import { ICoreState } from './state';

export const coreReducers: ActionReducerMap<ICoreState> = {
  content: courseProductTemplateReducer,
  instance: simulationInstanceReducer,
  location: locationReducer,
};

export * from './simulation-instance/root';
export * from './course-product-template/root';
export * from './state';
