import { Injectable } from '@angular/core';
import { InjectionToken } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '@stukent/logger';
import { IOverviewData } from '../models/scenario-overview';
import { BUHIConfigurationService } from './configuration.service';

export declare interface IBUHIScenarioOverviewService {
  scenarioOverview$: BehaviorSubject<IOverviewData>;
  loadScenarioOverview();
}

export const IBUHIScenarioOverviewService: InjectionToken<IBUHIScenarioOverviewService> =
  new InjectionToken<IBUHIScenarioOverviewService>('IBUHIScenarioOverviewService');

@Injectable({
  providedIn: 'root'
})
export class BUHIScenarioOverviewService {

  private urlRoot: string;
  private headers: HttpHeaders = new HttpHeaders();

  // Use a behaviorSubject for caching
  scenarioOverview$: BehaviorSubject<IOverviewData> = new BehaviorSubject<IOverviewData>(null);

  private loaded = false;

  private enableLogging = false;
  private componentName = 'Scenario Overview Service';

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  loadScenarioOverview() {
    // Check if the Scenario Overview has been loaded.. If so don't do anything...
    if (!this.loaded) {
      this.getScenarioOverview();
    } else {
      this.logInfo(`Scenario Overview already loaded`);
    }
  }

  /**
   * Calls Buhi Ecosystem API to return Scenario Overview information as a X array
   *
   * @returns Observable<IOverviewData>
   */
  private getScenarioOverview() {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    this.http.get<IOverviewData>(`${this.urlRoot}/scenario-overview`, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(scenarioOverview => {
        if (scenarioOverview) {
          this.loaded = true;
          this.scenarioOverview$.next(scenarioOverview);
        }
      });
  }

  private handleError(error) {
    return throwError(`Failed calling Scenario Overview service: ${error.message}`);
  }
  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
