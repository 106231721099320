import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TopLevelProductObject } from '../models/product-grouping';
import { LoggerService } from '@stukent/logger';
import { BUHIConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class BUHIProductGroupingService {

  private urlRoot: string;
  private headers: HttpHeaders;


  // Use a behaviorSubject for caching
  productGroups$: BehaviorSubject<TopLevelProductObject> = new BehaviorSubject<TopLevelProductObject>(null);

  private productGroupsLoaded = false;

  private componentName = 'Product Grouping Service';
  private enableLogging = false;

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  loadProductGroups(): void {
    if (!this.productGroupsLoaded) {
      this.getProductGroupingsData();
    }
    {
      this.logInfo(`product groups already loaded`);
    }
  }

  getProductGroupingsData(): void {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/products/product-groups`;
    this.http.get<TopLevelProductObject>(url, { headers: this.headers })
      .pipe(catchError(this.handleError))
      .subscribe((productData: TopLevelProductObject) => {
        this.productGroups$.next(productData);
        this.productGroupsLoaded = true;
      });
  }

  /*
  * @deprecated
  * update element to use BehaviorSubject
  */
  getProductGroupings(): Observable<TopLevelProductObject> {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/products/product-groups`;
    return this.http.get<TopLevelProductObject>(url, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Error) {
    return throwError(`Failed calling Product Grouping Data service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
