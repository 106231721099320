import { props, createAction } from '@ngrx/store';
import { IAnnotation, IMyAnnotations } from '../../models/annotations.model';

const prefix = '[Annotations]';

export const loadAnnotations = createAction(`${prefix} Load`);

export const addAnnotation = createAction(`${prefix} Add`, props<{ annotation: IAnnotation }>());

export const editAnnotation = createAction(`${prefix} Edit`, props<{ annotation: IAnnotation, moduleId: string, pageId: string; }>());

export const deleteAnnotation = createAction(`${prefix} Delete`, props<{
  moduleId: string, pageId: string, annotationId: string
}>());

export const setTempAnnotation = createAction(`${prefix} Set Temp`, props<{ annotation: IAnnotation; }>());

export const clearTempAnnotation = createAction(`${prefix} Clear Temp`);

export const removeTempAnnotation = createAction(`${prefix} Remove Temp`);

export const setAnnotationInstance = createAction(`${prefix} Set Instance`, props<{ myAnnotations: IMyAnnotations }>());
