import { createReducer, on, Action, On } from '@ngrx/store';
import { addElement, markElementInstalled } from './elements.actions';
import { IElementStoreItem } from '../../../models/element-store-item.model';

export interface IElementsState {
  items: IElementStoreItem[];
  installed: string[];
  timeChanged: Date;
}

const initialElementsState: IElementsState = {
  items: [],
  installed: [],
  timeChanged: new Date()
};

export const templateReducers: On<IElementsState>[] = [
  on(addElement, (state, { element }) => {
    const newState = ({ ...state, items: [...state.items] });
    const found = state.items.find(x => x.elementType === element.elementType);
    if (!found) {
      newState.items.push(element);
    }
    return newState;
  }),
  on(markElementInstalled, (state, { elementType }) => ({
    ...state,
    installed: [...state.installed, elementType],
    timeChanged: new Date()
  }))
];

function initReducer() {
  return createReducer(initialElementsState, ...templateReducers);
}

export function elementsReducer(contentState: any | undefined, action: Action) {
  return initReducer()(contentState, action);
}
