import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import * as actions from './annotation.actions';
import { AnnotationsService } from '../../services/annotation/annotations.service';

@Injectable()
export class AnnotationEffects {
  constructor(
    private actions$: Actions,
    private annotationsService: AnnotationsService
  ) {
  }

  $loadAnnotations = createEffect(() => this.actions$.pipe(
    ofType(actions.loadAnnotations),
    mergeMap(() => this.annotationsService.loadAnnotations().pipe(
      map(myAnnotations => {
        return actions.setAnnotationInstance({ myAnnotations });
      })
    ))
  ));

  $addAnnotation = createEffect(() => this.actions$.pipe(
    ofType(actions.addAnnotation),
    mergeMap(({ annotation }) =>
      this.annotationsService.addAnnotation(annotation)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ),
    { dispatch: false });

  $editAnnotation = createEffect(() => this.actions$.pipe(
    ofType(actions.editAnnotation),
    mergeMap(({ annotation, moduleId, pageId }) =>
      this.annotationsService.editAnnotation(annotation, moduleId, pageId)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ),
    { dispatch: false });

  $deleteAnnotation = createEffect(() => this.actions$.pipe(
    ofType(actions.deleteAnnotation),
    mergeMap(({ moduleId, pageId, annotationId }) =>
      this.annotationsService.deleteAnnotation(moduleId, pageId, annotationId)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ),
    { dispatch: false });

}
