import { IPPCStateItem } from './state-interfaces.model';

export interface IKeywordTableState {
    keywords: IKeyword[];
}

export interface IKeyword extends IPPCStateItem {
    globalId: string;
    adGroupId: string;
    maxCpc?: number;
    // campaignId?: string;  // Campaign ID was removed on purpose.  Keywords don't belong to Campaigns (JKW)
    // Come see me if you believe I'm wrong.

    // DO NOT SAVE THE BELOW TO STATE
    name?: string;
    competition?: string;
    competitionName?: string;
    volume?: number;
    suggestedBid?: number;

}

export interface IKeywordMetricSet {
    id: string;
    impressions: number;
    clicks: number;
    impressionShare: number;
    topNPercent: number;
    absTopPercent: number;
    costPerClick: number;
    cost: number;
    clickThroughRate: number;
    conversions: number;
    conversionRate: number;
    profit: number;
    costPerAcquisition: number;
}

export const KeywordKpiOptions = [{
    label: 'Impressions',
    value: 'impressions'
}, {
    label: 'Clicks',
    value: 'clicks'
}, {
    label: 'Impr. Share',
    value: 'impressionShare'
}, {
    label: 'Top',
    value: 'topNPercent'
}, {
    label: 'Abs Top',
    value: 'absTopPercent'
}, {
    label: 'Avg. CPC',
    value: 'costPerClick'
}, {
    label: 'Total Cost',
    value: 'cost'
}, {
    label: 'CTR',
    value: 'clickThroughRate'
}, {
    label: 'Conversions',
    value: 'conversions'
}, {
    label: 'Conv. Rate',
    value: 'conversionRate'
}, {
    label: 'Profits',
    value: 'profit'
}, {
    label: 'CPA',
    value: 'costPerAcquisition'
}];
