import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { MimicAppComponent } from './mimic-app.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: MimicAppComponent,
    resolve: {
      url: 'externalUrlRedirectResolver'
    },
    data: {
      externalUrl: 'https://home.stukent.com'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot) => {
        const url = window.location.href.toLowerCase();
        const isProd = (url.indexOf('-next') === -1 && url.indexOf('//localhost') === -1);
        const redirectUrl =
          isProd ? (route.data as any).externalUrl : (route.data as any).externalUrl.replace('.stukent.com', '-staging.stukent.com');
        window.location.href = redirectUrl.replace(':courseCode', route.params.courseCode) + '?reason=invalidRoute';
      }
    }
  ]
})
export class AppRoutingModule { }
