import { Injectable } from '@angular/core';
import { ISimulationInstance, ISimulationInstanceElement, ISimulationInstanceModule } from '../../models/simulation-instance';

// @dynamic // enables lambda function in static methods. Don't remove comment!
@Injectable({
  providedIn: 'root'
})
export class SimulationInstanceHelperService {

  /**
   * Get all element states on a given page
   * @param instance The sim state
   */
  static getElementsByPage(instance: ISimulationInstance, pageId: string): ISimulationInstanceElement<any>[] {
    if (!instance) {
      return [];
    }

    return Object.values(instance.elements).filter((element: ISimulationInstanceElement<any>) => element.pageId === pageId);
  }

  /**
   * Get element state
   * @param instance The sim state
   * @param elementId The element id
   * @param type The element type to retrieve
   */
  getElementState(instance: ISimulationInstance, elementId: string, type?: string): ISimulationInstanceElement<any> {
    return instance.elements[elementId];
  }

  /**
   * Get the previous module
   * @param instance The sim state
   */
  getPreviousModule(instance: ISimulationInstance): ISimulationInstanceModule {
    const instanceModules = Object.values(instance.modules)
      .sort((a: ISimulationInstanceModule, b: ISimulationInstanceModule) => a.order - b.order);
    const moduleIndex = instanceModules.findIndex(m => m.id === instance.location.moduleId);

    return instanceModules[moduleIndex - 1] ? instanceModules[moduleIndex - 1] : null;
  }
}
