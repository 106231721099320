import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { mergeMap, withLatestFrom, switchMap, take } from 'rxjs/operators';
import {
  setLocation,
  navigateToModule,
  navigateToPage,
  navigateToIteration,
  navigateToLocation,
  removeIteration,
  setPreviousLocation,
  setInitialLocation
} from './location.actions';
import { Store, Action } from '@ngrx/store';
import { startRoundTimer, setDrawerViewed, setInstancePageState } from '../core/simulation-instance/simulation-instance.actions';
import { ScrollService } from '../../services/scroll/scroll.service';
import { selectContentTemplate, selectPreviousLocation, courseProductInstance, selectCurrentLocation } from '../selectors';
import { setInitialBudget, recalculateBalance, setCredit, setInitialGlobalBudget } from '../finance/budget/root';
import { toggleGlobalPage } from '../view-toggle/root';
import { UserProductInstanceService } from '../../services/simulation-instance/simulation-instance.service';
import { ICourseProductTemplate, ILocation, ISimulationInstance, ICourseProductTemplateModule } from '../../models';
import { LoggerService } from '@stukent/logger';
import { selectCurrentContentModule, selectCurrentPageState } from '../core/selectors';
import { setSelectedModuleId } from '../scores/root';

@Injectable()
export class LocationEffects {

  private readonly pageTop = 200;

  private readonly enableLogging = false;
  private readonly componentName = 'Location Effects';

  constructor(
    private actions$: Actions,
    private store: Store,
    private scrollService: ScrollService,
    private userProductInstanceService: UserProductInstanceService,
    private logger: LoggerService
  ) {
  }

  $setLocation = createEffect(() => this.actions$.pipe(
    ofType(setLocation),
    withLatestFrom(
      this.store.select(selectPreviousLocation),
      this.store.select(courseProductInstance),
      this.store.select(selectContentTemplate)
    ),
    mergeMap(([{ location }, previousLocation, instance, template]) => {

      this.logInfo('Set Location fired');

      this.scrollService.scroll(0, this.pageTop);

      const { actions, newModule } = this.setNewModuleActions(location, previousLocation, template, instance, false);
      const newPageActions = this.setNewPageActions(location, previousLocation, instance, newModule);
      return [...actions, ...newPageActions];

    })
  ));

  $setInitialLocation = createEffect(() => this.actions$.pipe(
    ofType(setInitialLocation),
    withLatestFrom(
      this.store.select(selectPreviousLocation),
      this.store.select(courseProductInstance),
      this.store.select(selectContentTemplate)
    ),
    mergeMap(([{ location }, previousLocation, instance, template]) => {

      this.logInfo('Set Initial Location fired');

      this.scrollService.scroll(0, this.pageTop);

      const { actions, newModule } = this.setNewModuleActions(location, previousLocation, template, instance, true);
      const newPageActions = this.setNewPageActions(location, previousLocation, instance, newModule);
      return [...actions, ...newPageActions];

    })
  ));

  $navigateToLocation = createEffect(() => this.actions$.pipe(
    ofType(navigateToLocation),
    withLatestFrom(
      this.store.select(selectPreviousLocation),
      this.store.select(courseProductInstance),
      this.store.select(selectContentTemplate)
    ),
    mergeMap(([{ location }, previousLocation, instance, template]) => {

      this.logInfo('Navigate To Location fired');

      this.scrollService.scroll(0, this.pageTop);

      this.userProductInstanceService.changeLocation(location);

      const previousLocationAction = [setPreviousLocation()];

      const { actions, newModule } = this.setNewModuleActions(location, previousLocation, template, instance, true);
      const newPageActions = this.setNewPageActions(location, previousLocation, instance, newModule);
      return [...previousLocationAction, ...actions, ...newPageActions];

    })
  ));

  $navigateToModule = createEffect(() => this.actions$.pipe(
    ofType(navigateToModule),
    withLatestFrom(
      this.store.select(selectCurrentLocation),
      this.store.select(selectPreviousLocation),
      this.store.select(courseProductInstance),
      this.store.select(selectContentTemplate)
    ),
    switchMap(([{ moduleId }, currentLocation, previousLocation, instance, template]) => {

      this.logInfo('Navigate To Module fired');

      this.scrollService.scroll(0, this.pageTop);

      const newModule = template.modules.find(m => m.id === moduleId);

      if (newModule && newModule.pages) {

        this.userProductInstanceService.changeLocation(currentLocation);

        const previousLocationAction = [setPreviousLocation()];

        const moduleItems: { actions, newModule; } = this.setNewModuleActions(currentLocation, previousLocation, template, instance, true);
        const newPageActions = this.setNewPageActions(currentLocation, previousLocation, instance, moduleItems.newModule);
        return [...previousLocationAction, ...moduleItems.actions, ...newPageActions];

      }

      return [];
    })

  ));

  $changePage = createEffect(() => this.actions$.pipe(
    ofType(navigateToPage),
    withLatestFrom(
      this.store.select(selectCurrentLocation),
      this.store.select(selectPreviousLocation),
      this.store.select(courseProductInstance),
      this.store.select(selectCurrentContentModule)
    ),
    mergeMap(([{ pageId }, currentLocation, previousLocation, instance, currentModule]) => {

      this.logInfo('Navigate to Page fired');

      this.scrollService.scroll(0, this.pageTop);

      const previousLocationAction = [setPreviousLocation()];
      const newPageActions = this.setNewPageActions(currentLocation, previousLocation, instance, currentModule);

      const finalActions = [...previousLocationAction, ...newPageActions];

      this.userProductInstanceService.changeLocation(currentLocation);

      return finalActions;

    }
    )
  ));

  $navigateToIteration = createEffect(() => this.actions$.pipe(
    ofType(navigateToIteration),
    withLatestFrom(this.store.select(selectCurrentLocation), this.store.select(selectCurrentPageState)),
    mergeMap(([{ iterationId, isLastIterationSubmitted }, currentLocation, currentPageState]) => {

      this.logInfo('Navigate To Iteration fired');

      this.scrollService.scroll(0, this.pageTop);

      let newPageState = {};
      if (currentPageState) {
        newPageState = currentPageState;
      }

      this.userProductInstanceService.changeLocation(currentLocation);

      return [
        setInstancePageState({
          pageId: currentLocation.pageId,
          state: { ...newPageState, currentIterationId: iterationId, isComplete: isLastIterationSubmitted }
        }),
        setPreviousLocation()
      ];

    }
    )
  ));

  private logInfo(message: string): void {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  private setNewModuleActions(
    newLocation: ILocation,
    previousLocation: ILocation,
    template: ICourseProductTemplate,
    instance: ISimulationInstance,
    setAsSelected: boolean
  ): { actions: Action[], newModule: ICourseProductTemplateModule; } {

    const actions: Action[] = [];

    const moduleLocationChanged = newLocation.moduleId !== previousLocation?.moduleId;

    if (moduleLocationChanged) {

      const newModule = template.modules?.find(x => x.id === newLocation.moduleId);

      if (!newModule) {
        return { actions, newModule };
      }

      if (setAsSelected) {
        // Set the select Module to show any scores needed for this round (likely AB testing)
        actions.push(setSelectedModuleId({ moduleId: newModule.id }));
      }

      const contentPage = newModule.pages?.find(x => x.id === newLocation.pageId);
      // See if the page is in the Module, if not, set it to the first one..
      if (!contentPage) {
        // Always nagivate to the first page..
        actions.push(navigateToPage({ pageId: newModule.pages[0].id }));
      }

      if (newModule.properties?.timeLimit) {
        actions.push(startRoundTimer({ moduleId: newLocation.moduleId }));
      }

      // Checks if Global Budget Should be used
      // Use -1 as a default budget here so that the budget-balance component can show a 0 budget (used when the student can use credit)
      if (template?.properties?.useGlobalBudget && !newModule.properties.excludeFromResults) {

        // Default values
        let initialBudget = template.properties?.globalBudget ?? 0;
        let initialBalance = template.properties?.globalBudget ?? 0;
        let initialCredit = 0;

        // Get saved budget data from module state if it exists
        const savedBudget = instance?.modules[newModule.id]?.state?.GlobalBudget;
        const savedBalance = instance?.modules[newModule.id]?.state?.GlobalBalance;
        const savedCredit = instance?.modules[newModule.id]?.state?.GlobalCredit;

        if (savedBudget !== undefined) {
          initialBudget = savedBudget;
        }

        if (savedBudget !== undefined) {
          initialBalance = savedBalance;
        }

        if (template?.properties?.useLineOfCredit && savedCredit !== undefined) {
          initialCredit = savedCredit;
        }

        // We do not need to call recalculate balance when using a global budget that persists round to round.
        // We just need to set starting budget, balance and credit
        actions.push(setInitialGlobalBudget({
          newBudget: initialBudget,
          newBalance: initialBalance,
          newCredit: initialCredit
        }));

      }
      // No Global Budget, use round configured budget as normal
      else {

        if (newModule?.properties?.budget > -1) {
          const initialBudget = newModule.properties && newModule.properties.budget > -1 ?
            newModule.properties.budget : -1;

          actions.push(setInitialBudget({
            amount: initialBudget
          }));

          actions.push(recalculateBalance());
        } else {
          // Set the budget to undefined...
          actions.push(setInitialBudget({
            amount: -1
          }));

        }
      }

    }

    return { actions, newModule: null };

  }

  private setNewPageActions(
    newLocation: ILocation,
    previousLocation: ILocation,
    instance: ISimulationInstance,
    currentModule: ICourseProductTemplateModule): Action[] {

    const actions: Action[] = [];

    this.logInfo('Setting Page Change Actions');

    if (newLocation.pageId !== previousLocation?.pageId) {

      this.logInfo(`Detected Page changed from: ${previousLocation?.pageId} to ${newLocation?.pageId}`);

      const contentPage = currentModule?.pages?.find(x => x.id === newLocation.pageId);

      if (!contentPage) {
        return actions;
      }

      const globalPageAutoOpenId = contentPage.properties?.autoOpenDrawerByPageId;
      const pageState = instance?.pages[contentPage?.id]?.state;

      const willOpenDrawer = globalPageAutoOpenId && !pageState?.hasBeenViewed;

      if (willOpenDrawer) {
        actions.push(toggleGlobalPage({ pageId: globalPageAutoOpenId, isOpened: true }));
        actions.push(setDrawerViewed({ pageId: contentPage.id, hasBeenViewed: true }));
      }

      if ((contentPage.iterations || []).length === 0) {
        // Remove the iteration
        actions.push(removeIteration());
      } else {
        // Set the Iteration..

        // See if the page State has a current iteration //currentIterationId
        if (pageState?.currentIterationId) {
          // Set the current Iteration Id, if it exists
          const currentIteration = contentPage.iterations.find(i => i.id === pageState.currentIterationId);

          if (currentIteration) {
            actions.push(navigateToIteration({ iterationId: currentIteration.id, isLastIterationSubmitted: pageState.isComplete }));
          } else {
            actions.push(navigateToIteration({ iterationId: contentPage.iterations[0].id, isLastIterationSubmitted: false }));
          }

        } else {
          actions.push(navigateToIteration({ iterationId: contentPage.iterations[0].id, isLastIterationSubmitted: pageState.isComplete }));
        }

      }

    }

    return actions;

  }
}
