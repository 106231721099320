import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@stukent/logger';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICompetitorData } from '../models/competitor-data';
import { BUHIConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class BUHICompetitorDataService {


  private urlRoot;
  private headers: HttpHeaders;


  private dataLoaded = false;
  private enableLogging = false;
  private componentName = 'Competitor Data Service';

  // Use a behaviorSubject for caching
  competitorData$: BehaviorSubject<ICompetitorData[]> = new BehaviorSubject<ICompetitorData[]>([]);

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {

  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  loadCompetitorData() {
    // Check if the competitor data has been loaded.. If so don't do anything...
    if (!this.dataLoaded) {
      this.getCompetitorData();
    } else {
      this.logInfo(`strategies already loaded`);
    }
  }

  /**
   * Returns Competitor Data from the BUHI Ecosystem
   *
   * @returns ICompetitorData[]
   */
  private getCompetitorData(): void {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/competitors-data`;

    this.http.get<ICompetitorData[]>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(competitorData => {
        if (competitorData?.length > 0) {
          this.dataLoaded = true;
          this.competitorData$.next(competitorData);
        }
      });
  }

  private handleError(error) {
    return throwError(`Failed calling Competitor Data service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}


