import { createAction, props } from '@ngrx/store';

const prefix = '[Instructions]';

export const loadInstructions = createAction(`${prefix} Load`, props<{ elementType: string }>());

export const addInstructions = createAction(`${prefix} Set`, props<{
    elementType: string,
    instructions: any
}>());

export const toggleInstructions = createAction(`${prefix} Toggle`, props<{ elementType: string, isOpened: boolean }>());
