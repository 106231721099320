import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sk-callback',
  template: '',
  styles: ['']
})
export class CallbackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
