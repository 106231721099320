import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';
import { DisplayOnlyElementBaseComponent } from '../../modules/displayOnly-element-base.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sk-iframe-element',
  templateUrl: './iframe-element.component.html',
})
export class IFrameElementComponent
  extends DisplayOnlyElementBaseComponent<null>
  implements OnInit
  {
  static type = 'iframe';

  public cssStyle: any = {};

  ngOnInit(): void {

    if (this.ele.properties?.style) {
      this.cssStyle = JSON.parse(JSON.stringify(this.ele.properties?.style));
    }
  }
}
