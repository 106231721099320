import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { scoreIteration } from '../../reducers/scores/scoring/scoring.actions';
import { ICourseProductTemplatePage, ISimulationInstance, ILocation } from '../../models';
import { runRoundOrIteration, setInstanceIterationCompletion } from '../../reducers/core/simulation-instance/simulation-instance.actions';
import { navigateToIteration } from '../../reducers/location/location.actions';
import { selectCurrentContentIteration, currentLocation, selectCurrentContentPage, selectCourseProductInstance } from '../../reducers/selectors';
import { LoggerService } from '@stukent/logger';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sk-run-iteration',
  templateUrl: './run-iteration-button.component.html',
  styleUrls: ['./run-iteration-button.component.scss']
})
export class RunIterationComponent
  implements OnInit, OnDestroy {

  static type = 'run-iteration';

  @Input() disabled = false;
  @Input() styleOverride;

  show: boolean;
  showAsDisabled: boolean;
  showRunIterationModal: boolean;
  runIterationButtonText = 'Run Trial';

  subscriptions: Subscription[] = [];

  private currentContentPage: ICourseProductTemplatePage;
  private currentContentIteration: ICourseProductTemplatePage;
  private currentLocation: ILocation;

  private courseProductInstance: ISimulationInstance;

  // Store Subscriptions and only subscribe if they are nothing
  private currentIterationSubscription: Subscription;
  private currentContentPageSubscription: Subscription;
  private currentCourseProdcutInstanceSubscription: Subscription;
  private currentLocationSubscription: Subscription;

  private componentName = 'Run Iteration Button';
  private enableLogging = false;

  constructor(
    protected changeRef: ChangeDetectorRef,
    protected store: Store,
    protected logger: LoggerService) {
  }

  ngOnInit(): void {

    this.logInfo('initializing');

    if (!this.currentIterationSubscription) {
      this.currentIterationSubscription = this.store.select(selectCurrentContentIteration).subscribe(i => {
        this.currentContentIteration = i;

        if (!this.currentContentPageSubscription) {

          this.currentContentPageSubscription = this.store.select(selectCurrentContentPage).subscribe(p => {
            this.currentContentPage = p;


            if (!this.currentCourseProdcutInstanceSubscription) {

              this.currentCourseProdcutInstanceSubscription = this.store.select(selectCourseProductInstance).subscribe(cpi => {
                this.courseProductInstance = cpi;


                if (!this.currentLocationSubscription) {


                  this.currentLocationSubscription = this.store.select(currentLocation).subscribe(l => {
                    this.currentLocation = l.location;

                    this.logInfo(`Location Changed to: ${JSON.stringify(this.currentLocation, null, 2)}`);
                    this.shouldShow();
                  });

                  this.subscriptions.push(this.currentLocationSubscription);


                }

              });


              this.subscriptions.push(this.currentCourseProdcutInstanceSubscription);



            }




          });

          this.subscriptions.push(this.currentContentPageSubscription);


        }



      });

      this.subscriptions.push(this.currentIterationSubscription);

    }

    this.changeRef.detectChanges();

    this.logInfo('initialized');

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private logInfo(message: string): void {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  private shouldShow(): void {
    this.show = false;
    this.showAsDisabled = false;

    // Once the current Data and Sim Instance are loaded
    if (this.currentContentIteration && this.courseProductInstance) {

      const instancePage = this.courseProductInstance.pages[this.currentLocation.pageId];
      // If the Sim Instace exists for this page AND it is NOT Complete, show the Run Trial button
      if (instancePage && !instancePage?.state?.isComplete) {
        this.show = true;
      }

      // When the page state has the "currentIterationId" value (which means this page has an iteration interaction like AB Testing)
      // and also has the "isComplete" value of true, we need to show the disabled version of the "Run Trial" button
      // so that the users would know that they have already run all available iterations.
      if (instancePage && instancePage.state?.currentIterationId && instancePage.state?.isComplete) {
        this.showAsDisabled = true;
      }

    }

    this.changeRef.detectChanges();

  }

  public runIteration() {

    // Get these values first
    const currentIterationId = this.currentLocation.iterationId;
    const nextIterationId = this.getNextIterationId(this.currentContentPage.iterations, currentIterationId);

    // The runRoundOrIteration action calls the scoreIteration action after successfully creating
    // the user's simulation "instance" data in blob storage and redis cache.
    this.store.dispatch(runRoundOrIteration({
      scoreType: 'iteration',
      moduleId: this.currentLocation.moduleId,
      pageId: this.currentLocation.pageId,
      iterationId: currentIterationId}));

    this.store.dispatch(setInstanceIterationCompletion({
      iterationId: currentIterationId,
      isComplete: true
    }));

    if (nextIterationId) {
      // Just navigate to the next iteration
      this.store.dispatch(navigateToIteration({ iterationId: nextIterationId, isLastIterationSubmitted: false }));
    } else {
      // There is no more iteration after the current iteration. We need to set isLastIterationSubmitted to true.
      this.store.dispatch(navigateToIteration({ iterationId: currentIterationId, isLastIterationSubmitted: true }));
    }

    this.showRunIterationModal = false;

  }

  private getNextIterationId(iterations: ICourseProductTemplatePage[], currentIterationId: string): string {
    if (!iterations || !currentIterationId) {
      return null;
    }

    const iterationIds = iterations.map(iteration => iteration.id);
    const currentIterationIndex = iterationIds.indexOf(currentIterationId);

    if (currentIterationIndex >= 0 && currentIterationIndex < iterationIds.length - 1) {
      const nextIterationId = iterationIds[currentIterationIndex + 1];
      return nextIterationId;
    }

    return null;
  }

  toggleModal() {
    this.showRunIterationModal = !this.showRunIterationModal;
  }

}
