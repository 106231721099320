import { ISimulationInstance, ISimulationInstanceElement, ISimulationInstancePage } from '../../../models/simulation-instance';
import { createReducer, on, Action, On } from '@ngrx/store';
import {
  setSimulationInstance,
  setInstanceElementState,
  setInstanceElementCompletion,
  setSimulationInstanceModule,
  setInstancePageState,
  setInstanceModuleState,
  setInstanceElementModuleId,
  setSimulationInstanceWithIds,
  setInstanceState,
  stopElementTimer,
  setNextInstanceElementState,
  setDrawerViewed
} from './simulation-instance.actions';

const initialState: ISimulationInstance = {
  location: {
    iterationId: '',
    moduleId: '',
    pageId: '',
    elementId: '',
  },
  createdDate: new Date(),
  licenseCode: '',
  version: '',
  isLoading: true,
  state: {},
  modules: {},
  pages: {},
  elements: {},
  id: ''
};

export const simulationInstanceReducers: On<ISimulationInstance>[] = [
  // Handles Set Simulation Instance Module
  on(setSimulationInstanceModule, (state, { module }) => {
    const instanceCopy: ISimulationInstance = JSON.parse(JSON.stringify(state));
    instanceCopy.modules[module.id] = module;
    return instanceCopy;
  }),
  // Handles Set Simulation Instance With Ids
  on(setSimulationInstanceWithIds, (state, { instance }) => ({ ...instance })),
  // Handles Set Simulation Instance
  on(setSimulationInstance, (state, { instance }) => {
    return setInstanceStateKeys(instance);
  }),
  // Handles instance element completion
  on(setInstanceElementCompletion, (state: ISimulationInstance, { elementId, isComplete }) =>
  ({
    ...state,
    elements: {
      ...state.elements,
      [elementId]: { ...state.elements[elementId], isComplete, stateChangeId: Math.random() + '' }
    }
  })),
  // Handles Element state changes
  on(setInstanceElementState, setNextInstanceElementState, (existingState: ISimulationInstance, { elementId, state }) => {
    const states = JSON.parse(JSON.stringify(existingState));

    if (!states.elements[elementId]) {
      states.elements[elementId] = { state, id: elementId };
    }

    let updatedElementState = states.elements[elementId];

    if (!updatedElementState) {
      updatedElementState = {} as ISimulationInstanceElement<any>;
    }

    updatedElementState.id = elementId;
    updatedElementState.state = state;
    updatedElementState.stateChangeId = Math.random() + '';

    // Just replace the one...
    return { ...states, elements: { ...states.elements, [elementId]: updatedElementState } };
  }),
  // Handles Page State Changes
  on(setInstancePageState, (existingState: ISimulationInstance, { pageId, state }) => {

    const existingPageState = existingState.pages[pageId];
    let updatedPageState: ISimulationInstancePage = {
      id: pageId,
      moduleId: '',
      state,
      isComplete: false,
    };

    if (existingPageState) {
      updatedPageState = JSON.parse(JSON.stringify(existingPageState));
    }

    updatedPageState.state = state;
    // Just replace the one...
    return { ...existingState, pages: { ...existingState.pages, [pageId]: updatedPageState } };
  }),
  // Handles Module State Changes
  on(setInstanceModuleState, (existingState: ISimulationInstance, { moduleId, state }) => {
    let updatedModuleState = JSON.parse(JSON.stringify(existingState.modules[moduleId]));

    if (!updatedModuleState) {
      updatedModuleState = {};
    }

    updatedModuleState.state = state;
    updatedModuleState.stateChangeId = Math.random() + '';

    // Just replace the one...
    return { ...existingState, modules: { ...existingState.modules, [moduleId]: updatedModuleState } };
  }),
  on(setInstanceElementModuleId, (existingState: ISimulationInstance, { elementId, moduleId }) => {

    // Only update if changed
    if (existingState.elements[elementId]?.moduleId !== moduleId) {
      if (!existingState.elements[elementId]) {
        return;
      }
      let updatedElementState = JSON.parse(JSON.stringify(existingState.elements[elementId]));

      if (!updatedElementState) {
        updatedElementState = {} as ISimulationInstanceElement<any>;
      }
      updatedElementState.moduleId = moduleId;
      // Just replace the one...
      return { ...existingState, elements: { ...existingState.elements, [elementId]: updatedElementState } };
    } else {
      return existingState;
    }

  }),
  // Sets the Instance State
  on(setInstanceState, (existingState: ISimulationInstance, { state, instanceId }) => {
    return { ...existingState, state };
  }),
  // Handle setting that a page drawer was viewed
  on(setDrawerViewed, (existingState: ISimulationInstance, { pageId, hasBeenViewed }) => {
    let updatedPageState = JSON.parse(JSON.stringify(existingState.pages[pageId]));

    if (!updatedPageState) {
      updatedPageState = { state: {} };
    }

    updatedPageState.stateChangeId = Math.random() + '';
    updatedPageState.state = { ...updatedPageState.state, hasBeenViewed };

    // Just update the one changed
    return { ...existingState, pages: { ...existingState.pages, [pageId]: updatedPageState } };
  }),
  // Handle stop element timer
  on(stopElementTimer, (existingState: ISimulationInstance, { elementId }) => {
    let updatedState: ISimulationInstanceElement<any>  = JSON.parse(JSON.stringify(existingState.elements[elementId]));

    if (!updatedState) {
      updatedState = { state: {} };
    }

    updatedState.timerExpired = true;

    // If the timer expired, it means the element was scored
    updatedState.state = {...updatedState.state, scoredAt: new Date()};

    return { ...existingState, elements: { ...existingState.elements, [elementId]: updatedState } };
  })
];

function setInstanceStateKeys(instance: ISimulationInstance): ISimulationInstance {

  if (!instance?.isLoading) {

    const instanceCopy: ISimulationInstance = JSON.parse(JSON.stringify(instance));
    const keys = Object.keys(instanceCopy.elements);
    const stateChange = Math.random() + '';
    for (const key of keys) {
      instanceCopy.elements[key].stateChangeId = stateChange;
    }

    return instanceCopy;
  }
  else {
    // TODO , the instance didn't load, don't fail, but what :/ (JKW)
    if (console) {
      console.warn('Setting instance state keys on an instance that is loading.  Not ideal.. '); // JKW
    }
  }
}

function initReducer() {
  return createReducer(initialState, ...simulationInstanceReducers);
}

export function simulationInstanceReducer(contentState: any | undefined, action: Action) {
  return initReducer()(contentState, action);
}
