import { createSelector } from '@ngrx/store';
import { IBudgetState } from './state';

export const financeFeature = state => state.finance.budget as IBudgetState;

export const finance = createSelector(
  financeFeature,
  financeState => financeState
);

export const budget = createSelector(
  financeFeature,
  financeState => financeState.budget
);

export const balance = createSelector(
  financeFeature,
  financeState => financeState.balance
);

export const credit = createSelector(
  financeFeature,
  financeState => financeState.credit
);
