import { createSelector } from '@ngrx/store';
import { ICourseProduct } from '../../models/course.model';

export const courseFeature = state => state.courseProduct as ICourseProduct;

export const currentCourseProduct = createSelector(
  courseFeature,
  course => course
);

export const currentCourse = createSelector(
  courseFeature,
  course => course
);

