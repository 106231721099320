import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { BUHIConfigurationService } from './configuration.service';

export declare interface IBuhiRawDataService {
  init();
  get<T>(file: string): Observable<T>;
}

export const BuhiRawData: InjectionToken<BuhiRawDataService> = new InjectionToken<BuhiRawDataService>('IBuhiInfluencerDataService');

@Injectable({
  providedIn: 'root'
})
export class BuhiRawDataService {

  private urlRoot: string;
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {

  }

  // get a file from buhi ecosystem storage
  get<T>(file: string): Observable<T> {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    return this.http.get<T>(this.urlRoot + file, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error) {
    return throwError(`Failed calling Influencer Data service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }

}
