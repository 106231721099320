import { IGenericState, UpdaterFn, AnyStore, } from '../models/element-state';
import { Store } from '@ngrx/store';
import { Subscription, ReplaySubject, Observable } from 'rxjs';
import { processUpdaters, digState } from './utility';

export abstract class ReadableBaseState {
  protected state: IGenericState = {};
  protected stateReplaySubject: ReplaySubject<IGenericState> = new ReplaySubject();
  protected updaters: UpdaterFn[];
  protected topLevelSubscription: Subscription;
  protected previousRoot = null;
  protected isMock = false;

  constructor(
    public store: Store<AnyStore>,
    public component: object,
    public id?: string
  ) {
    this.previousRoot = this.getNullState();
    this.updaters = [];
    this.topLevelSubscription = store
      .subscribe((root) => {
        this.runSubscription(this.previousRoot, root, false);
        this.previousRoot = root;
      });
  }

  destroy(): void {
    this.topLevelSubscription.unsubscribe();
  }

  observeState(): Observable<IGenericState> {
    return this.stateReplaySubject;
  }

  dig(state: IGenericState, address: string, defaultValue?: IGenericState): Partial<IGenericState> {
    return digState(state, address, defaultValue);
  }


  registerUpdater(updaterFn: UpdaterFn, fireOnEmpty = false): void {
    // fireOnEmpty is DEPRECATED, but leaving it here for backward compatibility

    this.updaters.push(updaterFn);
    this.runSubscription(this.previousRoot, this.previousRoot, true);
  }

  setInternalState(relevantState: unknown) {
    throw new Error('setInternalState must be set on a subclass');
  }

  getRelevantState(root: AnyStore): IGenericState {
    throw new Error('getRelevantState must be set on a subclass');
  }

  getNullState(): IGenericState {
    throw new Error('getNullState must be set on a subclass');
  }

  // Used for mocks. Do not use internally.
  protected updateState(storeRoot: AnyStore) {
    const relState = this.getRelevantState(storeRoot);
    this.setInternalState(relState);
    this.runUpdaters({}, relState);
  }

  protected runUpdaters(oldState: IGenericState, newState: IGenericState) {
    processUpdaters({
      updaters: this.updaters,
      oldState,
      newState,
      stateObject: this.component
    });
  }

  private runSubscription(previousRoot: AnyStore, root: AnyStore, force = false) {
    let newRelevantState: IGenericState;
    let oldRelevantState: IGenericState;

    try {
      newRelevantState = this.getRelevantState(root);
    } catch (e) {
      newRelevantState = this.getNullState();
    }

    try {
      oldRelevantState = this.getRelevantState(previousRoot);
    } catch {
      oldRelevantState = this.getNullState();
    }

    const isEqual = JSON.stringify(oldRelevantState) === JSON.stringify(newRelevantState);

    if (!isEqual || force) {
      // If it's a state with an ID field, then don't run updaters
      const nullState = this.getNullState();

      const keys = Object.keys(nullState);

      if (keys.indexOf('id') > -1 && !newRelevantState.id) {
        return;
      }

      this.setInternalState(newRelevantState);
      this.stateReplaySubject.next(this.state);

      let newState = null;
      let oldState = null;
      try {
        oldState = oldRelevantState?.state;
        newState = newRelevantState?.state;
      } catch (e) {
        const errorMessage = `Failed to get states in readable-base-state due to error: ${e}\n\nData:\n\n
                OldRelevantState:\n${JSON.stringify(oldRelevantState)}
                \n\nNewRelevantState:\n${JSON.stringify(newRelevantState)}`;

        if (console) {
          console.error(errorMessage);
        }
      }

      // This is for a config object that has no state property
      // if we don't have a state property we are just going to use
      // the whole object.
      if (!oldState && oldRelevantState) { oldState = oldRelevantState; }
      if (!newState && newRelevantState) { newState = newRelevantState; }

      try {
        this.runUpdaters(oldState, newState);
      } catch (e) {
        const errorMessage = `Failed to run updaters in readable-base-state due to error: ${e}\n\nData:\n\n
                OldRelevantState:\n${JSON.stringify(oldRelevantState)}
                \n\nNewRelevantState:\n${JSON.stringify(newRelevantState)}`;
        if (console) {
          console.error(errorMessage);
        }

      }

    }
  }
}
