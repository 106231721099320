import { ICourseProductTemplatePage } from './page.model';
import { ICourseProductTemplateElement } from './element.model';

/**
 * enum declarations
 */
export enum LoadingStatus {
  unknown = 0,
  notLoaded = 1,
  loading = 2,
  loaded = 3,
  error = 4,
}

export interface ICourseProductTemplate {
  id?: string;
  name?: string;
  version?: string;
  courseCode: string;
  productCode: string;
  modules: ICourseProductTemplateModule[];
  learningObjectives?: ILearningObjectives[];
  loadingState?: {
    status: LoadingStatus,
    statusCode?: number,
    statusMessage?: string;
    source?: string;
  };
  properties?: { [key: string]: any; };
  plugins?: string[];
  navigationElements?: ICourseProductTemplateElement[];
  courseObjective?: string;
  timezone: string;
  scoreFormat: string;
  isPreview?: boolean;
}

export interface ICourseProductTemplateModule {
  id: string;
  name: string;
  title?: string;
  displayName: string;
  order?: number;
  budget: number;
  disabled?: boolean;
  properties?: { [key: string]: any; };
  pages: ICourseProductTemplatePage[];
  learningObjectives?: ILearningObjectives[];
  plugins?: string[];
  state?: { [key: string]: any; };
  navigationElements?: ICourseProductTemplateElement[];
  courseObjective?: string;
}

export interface ILearningObjectives {
  moduleId: string;
  objectives: string[];
  moduleName: string;
  useInCertificate: boolean;
}

export interface IScoreFormat {
  name: string;
  value: string;
}

const scoreTypes: IScoreFormat[] = [
  {
    value: 'percent',
    name: 'Percentage 100%'
  },
  {
    value: 'point',
    name: 'Points 100 / 100'
  },
  {
    value: 'hidden',
    name: 'Not Shown'
  }
];

export { scoreTypes };
