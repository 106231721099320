import { ActionReducerMap } from '@ngrx/store';
import { IElementsState, elementsReducer } from './elements/elements.reducer';
import { pluginsReducer, IPluginState } from './plugins/plugins.reducer';

export interface IExtensionsState {
  elements: IElementsState;
  plugins: IPluginState;
}

export const extensionsReducers: ActionReducerMap<IExtensionsState> = {
  elements: elementsReducer,
  plugins: pluginsReducer
};

export * from './elements/root';
