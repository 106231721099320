import { createAction, props } from '@ngrx/store';
import { IAppConfig } from '../../models/app-config.model';

export const loadCoreConfig = createAction('[Core Config] Load Config', props<{ platform: string,
  area: string,
  topic: string,
  courseCode: string
}>());

export const setCoreConfig = createAction('[Core Config] Set', props<{ config: IAppConfig }>());

export const loadCoreConfigFromStorage = createAction('[Core Config] Load from Storage');

export const toggleLogging = createAction('[Core Config] Toggle Logging', props<{ enable: boolean }>());
