import { Component, OnDestroy, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { loadGuides } from '../../reducers/root';
import { ICourseProductTemplateElement, ILocation } from '../../models';
import { guides, currentLocation } from '../../reducers/selectors';
import { GlobalService } from '../../services/global/global.service';
import { LoggerService } from '@stukent/logger';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sk-guides-drawer',
  templateUrl: './guides-drawer.component.html',
  styleUrls: ['./guides-drawer.component.scss']
})
export class GuidesDrawerComponent implements OnInit, OnDestroy {

  drawerOpen = false;
  drawerFullscreen = false;

  guides: ICourseProductTemplateElement[];
  guideCacheKey: string;

  private subscriptions: Subscription[] = [];

  private currentLocation: ILocation;

  constructor(
    private changeRef: ChangeDetectorRef,
    private store: Store,
    private logger: LoggerService,
    private globalService: GlobalService
  ) {
  }

  ngOnInit(): void {

    this.subscriptions.push(this.store.select(currentLocation).subscribe(locationState => {
      if (!locationState.isLoading) {
        this.currentLocation = locationState.location;
      }
    }));

    this.subscriptions.push(this.store.select(guides).subscribe(theGuides => {
      if (theGuides?.elements?.length > 0) {
        for (const guide of theGuides.elements) {
          if (guide.pageId === this.currentLocation.pageId) {
            this.guides = guide.elements;
            break;
          }
        }
        this.guideCacheKey = `${this.currentLocation.moduleId}:${this.currentLocation.pageId}`;
      }

      this.changeRef.detectChanges();

    }));

    this.globalService.makeGlobal('openGuides', () => {
      this.getGuides(true);
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getGuides(opened: boolean) {
    if (!opened) {
      return;
    }

    // Load the guides if the page or module changed
    if (this.guideCacheKey !== `${this.currentLocation.moduleId}:${this.currentLocation.pageId}`) {
      this.store.dispatch(loadGuides({ moduleId: this.currentLocation.moduleId, pageId: this.currentLocation.pageId }));
    }
    this.drawerOpen = true;

    this.changeRef.detectChanges();
  }

  toggleDrawer(): void {
    this.drawerOpen = !this.drawerOpen;

    if (this.drawerOpen) {
      this.logger.event('Opening Guides Drawer', { moduleId: this.currentLocation.moduleId, pageId: this.currentLocation.pageId });
      this.store.dispatch(loadGuides({ moduleId: this.currentLocation.moduleId, pageId: this.currentLocation.pageId }));
    }

    this.changeRef.detectChanges();

  }

  expandDrawer(): void {
    this.drawerFullscreen = !this.drawerFullscreen;
    this.changeRef.detectChanges();

  }
}
