import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoggerService } from '@stukent/logger';
import { BUHIConfigurationService } from './configuration.service';
import { INegotiationOffer, INegotiationOfferResponse, INegotiatorCategory } from '../models/negotiator';
@Injectable({
  providedIn: 'root'
})
export class BuhiNegotiatorDataService {

  private urlRoot;
  private headers: HttpHeaders;

  negotiators$: BehaviorSubject<INegotiatorCategory[]> = new BehaviorSubject<INegotiatorCategory[]>([]);
  negotiationResponse$: BehaviorSubject<INegotiationOfferResponse> = new BehaviorSubject<INegotiationOfferResponse>(null);

  private negotiatorsLoaded = false;
  private negotiationResponseLoaded = false;

  private enableLogging = false;
  private componentName = 'Manufacturer Data Service';

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {

  }

  /**
   * Checks if a deal is reached with a negotiator
   * POST INegotiationOffer object
   *
   * @param offer INegotiationOffer
   * @returns negotiationResponseLoaded
   */
  checkOffer(offer: INegotiationOffer, scenario: string = 'athlete-negotiation') {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/${scenario}/offer`;

    this.http.post<INegotiationOfferResponse>(url, offer, { headers: this.headers }).subscribe(response => {
      this.negotiationResponseLoaded = true;
      this.negotiationResponse$.next(response);
    });
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
