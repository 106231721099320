import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IHrBenefit } from '../models/hr-benefits';
import { LoggerService } from '@stukent/logger';
import { BUHIConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class BUHIHrBenefitsService {

  private urlRoot: string;
  private headers: HttpHeaders;

  // Use a behaviorSubject for caching
  benefits$: BehaviorSubject<IHrBenefit[]> = new BehaviorSubject<
    IHrBenefit[]
  >([]);

  private benefitsLoaded = false;

  private enableLogging = false;
  private componentName = 'Products Service';

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {

  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  loadBenefits() {
    // Check if the Products have been loaded.. If so don't do anything...
    if (!this.benefitsLoaded) {
      this.getHrBenefits();
    } else {
      this.logInfo(`benefits already loaded`);
    }
  }

  /**
   * Returns HRBenefits from Ecosystem
   * @returns IHrBenefit[]
   */
  private getHrBenefits(): void {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/hr/benefits`;
    this.http.get<IHrBenefit[]>(url, { headers: this.headers })
      .pipe(catchError(this.handleError))
      .subscribe((benefits) => {
        if (benefits?.length > 0) {
          this.benefitsLoaded = true;
          this.benefits$.next(benefits);
        }
      });
  }

  private handleError(error: Error) {
    return throwError(`Failed calling BUHI HR Benefits service: ${error.message}`);
  }
  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
