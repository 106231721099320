import { ITableData } from './table.model';

export interface ICampaignTableState {
  budgetAllocation: number;
  campaigns: ICampaign[];
  shownPreviousRoundDataMissing?: boolean;
}

export interface ICampaign {
  id: string;
  campaignName: string;
  active: boolean;
  budget: number;
  maxCpc: number;
  campaignType: CampaignTypeEnum;
  campaignGoal: CampaignGoalEnum;
}

export enum CampaignTypeEnum {
  Search = 'search',
  Display = 'display',
  Shopping = 'shopping'
}

export const CampaignTypeIcons = {
  [CampaignTypeEnum.Search]: 'magnify',
  [CampaignTypeEnum.Display]: 'monitor-dashboard',
  [CampaignTypeEnum.Shopping]: 'cart-outline'
};

export enum CampaignGoalEnum {
  Sales = 'Sales',
  Leads = 'Leads',
  WebsiteTraffic = 'Website traffic',
  BrandAwareness = 'Brand awareness & reach'
}

export interface ICampaignTableRow extends ITableData, ICampaignMetricSet {

  // * This comes from the campaign in the module state
  icon: string;
  campaignName: string;
  campaignType: string;
  budget: number;
  maxCpc: number;

  // * We use this to show we don't have the other stuff
  tooltip?: string;
}

export interface ICampaignMetricSet {
  id: string;
  costPerClick: number;
  impressions: number;
  clicks: number;
  clickThroughRate: number;
  conversions: number;
  conversionRate: number;
  cost: number;
  profit: number;
  costPerAcquisition: number;
  returnOnAdSpend: number;
  revenue: number;
}

export const CampaignKpiOptions = [{
  label: 'Cost Per Click',
  value: 'costPerClick'
}, {
  label: 'Impressions',
  value: 'impressions'
}, {
  label: 'Clicks',
  value: 'clicks'
}, {
  label: 'CTR',
  value: 'clickThroughRate'
}, {
  label: 'Conversions',
  value: 'conversions'
}, {
  label: 'Conversion Rate',
  value: 'conversionRate'
}, {
  label: 'Cost',
  value: 'cost'
}, {
  label: 'Profit',
  value: 'profit'
}, {
  label: 'CPA',
  value: 'costPerAcquisition'
}, {
  label: 'ROAS',
  value: 'returnOnInvestment'
}];

