import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { LoggerService } from '@stukent/logger';
import { currentUser } from '@stukent/user';
import { IAppConfig, appConfiguration } from '../../../../../mimic-core/src/public-api';
import { FeatureArea, FeatureService } from '@stukent/feature-toggle';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mimic-product-information',
  templateUrl: './product-information.component.html',
  styleUrls: ['./product-information.component.scss']
})
export class ProductInformationComponent implements OnInit, OnDestroy {

  logoUrl: string;
  logoAlt: string;

  productDrawerVisible = false;

  showProductMenu = false;

  private subscriptions: Subscription[] = [];

  productName: string;
  hasIcon = true;
  logoIcon: string;

  private enableLogging = false;
  private componentName = 'Product Information:';

  constructor(
    private store: Store,
    private logger: LoggerService,
    public changeRef: ChangeDetectorRef,
    public featuresService: FeatureService
  ) {

  }

  ngOnInit(): void {

    // Handle Configuration Changing...
    this.subscriptions.push(this.store.select(appConfiguration)
      .subscribe(this.handleConfigChanged.bind(this)));

    // Handle Profile Changed
    // Shows or hides Product Menu for User based on features
    this.subscriptions.push(this.store.select(currentUser).subscribe(user => {
      this.featuresService.features$.subscribe(() => {
        this.showProductMenu = this.featuresService.isFeatureIsEnabled(FeatureArea.mimicApp, user, 'productsMenu');
      });

    }));

  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  private doChangeDetection() {
    this.changeRef.detectChanges();
  }

  private handleConfigChanged(config: IAppConfig): void {
    if (!config?.isLoaded) {
      return;
    }

    if (config.productName.replace('Mimic', '') !== this.productName) {
      this.logInfo(`Product Name Changed: from ${this.productName} to ${config.productName}`);
      this.productName = (config.productName || '').replace('Mimic', '');
      this.setLogoIcon(config.productCode);
    }

    this.doChangeDetection();

  }

  private setLogoIcon(productCode: string) {
    if (productCode !== '') {
      const icon = new Image();
      this.logoIcon = 'https://mimiccdn.stukent.com/mimic-logos/' + productCode + '.svg';

      icon.onload = () => {
        this.logoIcon = 'https://mimiccdn.stukent.com/mimic-logos/' + productCode + '.svg';
      };
      icon.onerror = () => {
        this.logoIcon = 'https://mimiccdn.stukent.com/mimic-logos/default-icon.svg';
      };
      icon.src = 'https://mimiccdn.stukent.com/mimic-logos/' + productCode + '.svg';
    }
  }

}
