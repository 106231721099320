import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import {
  IProductRetailer,
  IProductRetailers,
  IScenarioRetailer,
  IScenarioRetailers
} from '../models/retailers';
import { LoggerService } from '@stukent/logger';
import { catchError } from 'rxjs/operators';
import { BUHIConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class BUHIRetailersService {

  private urlRoot: string;
  private headers: HttpHeaders;

  // Use a behaviorSubject for caching
  public productRetailer$: BehaviorSubject<IProductRetailers[]> = new BehaviorSubject<
    IProductRetailers[]
  >([]);

  // Use a behaviorSubject for caching
  public scenarioRetailer$: BehaviorSubject<IScenarioRetailers[]> = new BehaviorSubject<
    IScenarioRetailers[]
  >([]);

  private productRetailersLoaded: IProductRetailers[] = [];
  private scenarioRetailersLoaded: IScenarioRetailers[] = [];

  private enableLogging = false;
  private componentName = 'Scenario Overview Service';

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {

  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  public loadRetailsForProduct(product: string) {
    // Check if the Retailers for a product have been loaded.. If so don't do anything...
    if (!this.productRetailersLoaded.find(p => p.productName.toLowerCase() === product.toLowerCase())) {
      this.getRetailersByProduct(product);
    } else {
      this.logInfo(`Retailers for Product ${product} already loaded`);
    }
  }

  public laodRetailersforScenario(scenario: string): void {
    // Check if the Retailers for a scenario have been loaded.. If so don't do anything...
    if (!this.scenarioRetailersLoaded.find(s => s.scenarioName.toLowerCase() === scenario.toLowerCase())) {
      this.getRetailersByScenario(scenario);
    } else {
      this.logInfo(`Retailers for Scenario ${scenario} already loaded`);
    }
  }

  /**
   * Returns retailers by scenario such as e-commerce-retailers, mass-retailers, etc...
   * @param scenario e-commerce-retailers, mass-retailers, etc...
   * @returns IScenarioRetailer[]
   */
  private getRetailersByScenario(scenario: string): void {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/retailers/${scenario}`;
    this.http.get<IScenarioRetailer[]>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(
        retailers => {
          this.scenarioRetailersLoaded.push({ scenarioName: scenario, retailers });
          this.scenarioRetailer$.next(this.scenarioRetailersLoaded);
        });
  }

  /**
   * Returns retailers by product such as juxta-alpha, juxta-beta, etc...
   * @param product juxta-alpha, juxta-beta, etc...
   * @returns IProductRetailer[]
   */
  private getRetailersByProduct(product: string): void {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/retailers/${product}`;
    this.http.get<IProductRetailer[]>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(retailers => {
        this.productRetailersLoaded.push({ productName: product, retailers });
        this.productRetailer$.next(this.productRetailersLoaded);
      });
  }

  private handleError(error: Error) {
    return throwError(`Failed calling BUHI retailer service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
