import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { LoggerService } from '@stukent/logger';

// Get stuff from Core
import {
  selectContentTemplate,
  ICourseProductTemplate,
  ICourseProductTemplateModule,
  ThemeService,
  ICourseProductTemplatePage,
  IMenuItem,
  LoadingStatus,
  selectCurrentContentModule,
} from '../../../../mimic-core/src/public-api';
import { currentLocation, runRoundOrIteration } from '@stukent/mimic-core';

@Component({
  changeDetection: ChangeDetectionStrategy.Default,
  selector: 'mimic-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  currentModule: ICourseProductTemplateModule;

  courseProductTemplate: ICourseProductTemplate;

  simModuleMenuItems: IMenuItem[];

  activeTopicsRounds: ICourseProductTemplateModule[];

  private enableLogging = false;
  private componentName = 'SideBar:';
  public showBudget = true;
  public contentIsLoading = false;

  constructor(
    private store: Store,
    public themeService: ThemeService,
    private logger: LoggerService,
  ) {

  }

  ngOnInit(): void {
    this.logInfo('Initializing');

    this.subscriptions.push(this.store.select(runRoundOrIteration).subscribe((x: any) => {
      if (x?.scores?.scoring?.isLoading && !this.contentIsLoading && !x.core.location.location.iterationId) {
        this.contentIsLoading = true;
      }
    }));

    // Set the location change handler
    this.subscriptions.push(this.store.select(currentLocation)
      .subscribe(locationState => {
        // Check for invalid location
        if (!locationState.isLoading && this.contentIsLoading) {
          this.contentIsLoading = false;
        }
      }
      ));

    // Handle Current x Changing
    this.subscriptions.push(this.store.select(selectCurrentContentModule).subscribe(x => {
      if (x?.id) {
        this.logInfo('Current Module Updated');
        this.currentModule = x;

        this.showBudget = !x.properties?.excludeFromResults;
        this.simModuleMenuItems = this.getModuleMenuItems(x.pages);

        // this.changeRef.detectChanges();
      }
    }));

    // Handle Course Product Template Changing...
    this.subscriptions.push(this.store.select(selectContentTemplate)
      .subscribe(this.handleCourseProductTemplateChanged.bind(this)));

    this.logInfo('Initialized');

  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName} ${message}`);
    }
  }

  private handleCourseProductTemplateChanged(template: ICourseProductTemplate) {
    if (template.loadingState?.status !== LoadingStatus.loaded) {
      return;
    }

    this.courseProductTemplate = template;
    this.logInfo(`Template Loaded with ${this.courseProductTemplate.modules.length} modules`);
    this.activeTopicsRounds = this.courseProductTemplate.modules.filter(m => !m.properties?.disableRound && !m.properties?.isHidden);

    // this.changeRef.detectChanges();

  }

  private getModuleMenuItems(pages: ICourseProductTemplatePage[]): IMenuItem[] {
    return pages.filter(x => !x.isGlobal && !x.properties?.isHidden).map(page => {
      return {
        icon: 'mdi mdi-circle-outline',
        title: page.properties?.menuName || page.displayName || page.name,
        itemId: page.id,
        children: undefined,
        elements: {
          cacheKey: Math.random() + '',
          elements: page.navigationElements || []
        }
      } as IMenuItem;

    });
  }

}
