import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '@stukent/logger';
import { ICounterNegotiationOffer, ICounterNegotiationOfferResponse, IManufacturerCategory } from '../models/manufacturer';
import { BUHIConfigurationService } from './configuration.service';
@Injectable({
  providedIn: 'root'
})
export class BuhiManufacturerDataService {

  private urlRoot;
  private headers: HttpHeaders;

  // Use a behaviorSubject for caching
  manufacturers$: BehaviorSubject<IManufacturerCategory[]> = new BehaviorSubject<IManufacturerCategory[]>([]);
  negotiationResponse$: BehaviorSubject<ICounterNegotiationOfferResponse> = new BehaviorSubject<ICounterNegotiationOfferResponse>(null);

  private manufacturersLoaded = false;
  private negotiationResponseLoaded = false;

  private enableLogging = false;
  private componentName = 'Manufacturer Data Service';

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {

  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }


  public loadManufacturersData() {
    // Check if the manufacturers have been loaded.. If so don't do anything...
    if (!this.manufacturersLoaded) {
      this.getManufacturers();
    } else {
      this.logInfo(`manufacturers already loaded`);
    }
  }


  /**
   * Returns Manufacturers
   *
   * @returns IManufacturerCategory[]
   */
  private getManufacturers() {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/manufacturers`;

    this.http.get<IManufacturerCategory[]>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(manufacturers => {
        if (manufacturers?.length > 0) {
          this.manufacturersLoaded = true;
          this.manufacturers$.next(manufacturers);
        }
      });
  }


  public loadCheckOffer(offer: ICounterNegotiationOffer) {
    // Check if the manufacturers have been loaded.. If so don't do anything...
    if (!this.negotiationResponseLoaded) {
      this.checkOffer(offer);
    } else {
      this.logInfo(`negotiation response already loaded`);
    }
  }

  /**
   * Checks if a deal is reached with manufacturer
   * POST ICounterNegotiationOffer object
   *
   * @param offer ICounterNegotiationOffer
   * @returns ICounterNegotiationOfferResponse
   */
  checkOffer(offer: ICounterNegotiationOffer) {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/counter-negotiation/offer`;

    this.http.post<ICounterNegotiationOfferResponse>(url, offer, { headers: this.headers }).subscribe(response => {
      this.negotiationResponseLoaded = true;
      this.negotiationResponse$.next(response);
    });
  }

  private handleError(error) {
    return throwError(`Failed calling Manufacturer Data service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
