import { CampaignTypeEnum } from './campaign.model';
import { ITableData } from './table.model';

export interface ISearchAdTableState {
  searchAds: ISearchAd[];
}

export interface ISearchAd {
  id: string;
  name: string;
  campaignId: string;
  campaignName: string;
  campaignType: CampaignTypeEnum;
  adGroupId: string;
  adGroupName: string;
  landingPage: string;
  headlines: string[];
  descriptions: string[];
  path1?: string;
  path2?: string;
  active: boolean;
}

export interface ISearchAdPreview {
  id: string;
  headline: string;
  description: string;
}

export interface ISearchAdTableRow extends ITableData, ISearchAdMetricSet {
  adGroupName: string;
  campaignName: string;
  icon: string;

  tooltip?: string;
}

export interface ISearchAdMetricSet {
  id: string;
  costPerClick: number;
  impressions: number;
  clicks: number;
  clickThroughRate: number;
  conversions: number;
  conversionRate: number;
  cost: number;
  profit: number;
  costPerAcquisition: number;
  returnOnAdSpend: number;
  revenue: number;
}

export interface ISearchAdFormConfig {
  buhiDomain: string;
  sitemapEcosystemFile: string;
}

export const SearchAdKpiOptions = [{
  label: 'Avg. CPC',
  value: 'costPerClick'
}, {
  label: 'Impressions',
  value: 'impressions'
}, {
  label: 'Clicks',
  value: 'clicks'
}, {
  label: 'CTR',
  value: 'clickThroughRate'
}, {
  label: 'Conversions',
  value: 'conversions'
}, {
  label: 'Conversion Rate',
  value: 'conversionRate'
}, {
  label: 'Cost',
  value: 'cost'
}, {
  label: 'Profits',
  value: 'profit'
}, {
  label: 'CPA',
  value: 'costPerAcquisition'
}, {
  label: 'ROAS',
  value: 'returnOnInvestment'
}];
