import { ActionReducerMap } from '@ngrx/store';
import { scoringReducer } from './scoring/scoring.reducers';
import { templateReducer } from './template/template.reducer';
import { IScoresState } from '././state.models';

export const scoresReducers: ActionReducerMap<IScoresState> = {
  scoring: scoringReducer,
  template: templateReducer
};

export * from './template/root';
export * from './scoring/root';
export * from '././state.models';
