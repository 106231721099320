import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule, SecurityContext } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ZendeskService } from './services/zendesk/zendesk.service';
import { NotificationService } from './services/notification/notification.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { ContentComponent } from './components/content/content.component';
import { MimicCoreConfig } from './config';
import { ContentElementComponent } from './core-elements/content-element/content-element.component';
import { MarkdownModule } from 'ngx-markdown';
import { SimulationInstanceHelperService } from './services/simulation-instance-helper/simulation-instance-helper.service';
import { CourseProductTemplateHelperService } from './services/course-product-template-helper/course-product-template-helper.service';
import { ImageElementComponent } from './core-elements/image-element/image-element.component';
import { VideoElementComponent } from './core-elements/video-element/video-element.component';
import { DownloadElementComponent } from './core-elements/download-element/download-element.component';
import { SimpleDownloadElementComponent } from './core-elements/simple-download-element/simple-download-element.component';
import { FileService } from './services/file/file.service';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  CourseProductEffects,
  CourseProductTemplateEffects,
  SimulationInstanceEffects,
  GuidesEffects,
  ScoringEffects,
  LocationEffects,
  ResultsTemplateEffects,
  ElementsEffects,
  BudgetEffects,
  AnnotationEffects,
  ConfigEffects,
  InstructionsEffects,
  coreConfigReducer,
  coreReducers,
  CoreProfileEffects,
  elementInstructionsReducer,
  extensionsReducers,
  financeReducers,
  scoresReducers,
  viewToggleReducer,
  courseReducer
} from './reducers/root';
import { LoadingOutline } from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import { ShortcutsModule } from '@stukent/shortcuts';
import { ResultsModalComponent } from './components/results-modal/results-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InteractionElementModule } from './components/interaction-element/interaction-element.module';
import { AnnotationComponent } from './components/annotation/annotation.component';
import { CoreElementsModule } from './core-elements/core-elements.module';
import { RoundTimerComponent } from './components/round-timer/round-timer.component';
import { BudgetBalanceComponent } from './components/budget-balance/budget-balance.component';
import { ElementRendererModule, ElementRendererConfig } from '@stukent/elements';
import { CacheModule } from '@stukent/cache';
import { CallbackComponent } from './components/callback/callback.component';
import { GuidesDrawerComponent } from './components/guides/guides-drawer.component';
import { RankingModule } from '@stukent/ranking';
import { ElementInstructionsComponent } from './components/element-instructions/element-instructions.component';
import { ToastrModule } from 'ngx-toastr';

import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzInputModule } from 'ng-zorro-antd/input';

import { profileReducers } from '@stukent/user';
import { MediaModule } from '@stukent/media';
import { IFrameElementComponent } from './core-elements/iframe-element/iframe-element.component';
import { annotationsReducer } from './reducers/annotation/root';
import { guidesReducer } from './reducers/guides/root';
import { InteractionElementBaseComponent } from './modules/interaction-element-base.component';
import { StatefulElementComponent } from './modules/stateful-element/stateful-element.component';
import { DisplayOnlyElementBaseComponent } from './modules/displayOnly-element-base.component';
import { InsightsElementComponent } from './modules/insights-element-base.component';
import { ContentNavigationComponent } from './components/content-navigation/content-navigation.component';
import { BUHIConfigurationService } from './buhi-ecosystem/services/configuration.service';
import { PdfViewerElementComponent } from './core-elements/pdf-viewer-element/pdf-viewer-element.component';

const icons: IconDefinition[] = [LoadingOutline];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    // Angular
    HttpClientModule,
    CommonModule,
    OverlayModule,
    FormsModule,
    ReactiveFormsModule,
    RankingModule,

    // Ant Design
    NzDrawerModule,
    NzLayoutModule,
    NzMenuModule,
    NzSpinModule,
    NzButtonModule,
    NzNotificationModule,
    NzListModule,
    NzCollapseModule,
    NzModalModule,
    NzRadioModule,
    NzSkeletonModule,
    NzInputNumberModule,
    NzSelectModule,
    NzSliderModule,
    NzPopoverModule,
    NzDropDownModule,
    NzDividerModule,
    NzProgressModule,
    NzInputModule,
    // Markdown
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE
    } as any),

    InteractionElementModule,

    // NgRx
    StoreModule.forFeature('core', coreReducers),
    StoreModule.forFeature('finance', financeReducers),
    StoreModule.forFeature('extensions', extensionsReducers),
    StoreModule.forFeature('annotations', annotationsReducer),
    StoreModule.forFeature('scores', scoresReducers),
    StoreModule.forFeature('profile', profileReducers),
    StoreModule.forFeature('elementInstructions', elementInstructionsReducer),
    StoreModule.forFeature('appConfiguration', coreConfigReducer),
    StoreModule.forFeature('guides', guidesReducer),
    StoreModule.forFeature('courseProduct', courseReducer),
    StoreModule.forFeature('viewToggle', viewToggleReducer),
    EffectsModule.forFeature([
      CourseProductEffects,
      ConfigEffects,
      CourseProductTemplateEffects,
      SimulationInstanceEffects,
      GuidesEffects,
      InstructionsEffects,
      ScoringEffects,
      LocationEffects,
      CoreProfileEffects,
      ResultsTemplateEffects,
      ElementsEffects,
      BudgetEffects,
      AnnotationEffects,
    ]),

    // Core elements
    CoreElementsModule,

    // Othter
    ShortcutsModule,
    CacheModule.forRoot({
      emptyIfFull: true
    }),
    ToastrModule.forRoot({
      closeButton: true,
      preventDuplicates: true,
      includeTitleDuplicates: true,
      enableHtml: true,
      disableTimeOut: true,
      positionClass: 'toast-top-full-width',
      iconClasses: {
        error: 'toast-error',
        info: 'toast-info',
        success: 'toast-success',
        warning: 'toast-warning',
      }
    }),

    // Element renderers
    ElementRendererModule,

    // Media
    MediaModule
  ],
  providers: [
    ZendeskService,
    NotificationService,
    SimulationInstanceHelperService,
    CourseProductTemplateHelperService,
    FileService,
    BUHIConfigurationService,
    {
      provide: NZ_ICONS,
      useValue: icons
    }
  ],
  declarations: [
    ContentComponent,
    ResultsModalComponent,
    AnnotationComponent,
    RoundTimerComponent,
    StatefulElementComponent,
    BudgetBalanceComponent,
    CallbackComponent,
    GuidesDrawerComponent,
    ElementInstructionsComponent,
    InsightsElementComponent,
    InteractionElementBaseComponent,
    DisplayOnlyElementBaseComponent,
    ContentNavigationComponent
  ],
  exports: [
    ContentComponent,
    InteractionElementModule,
    AnnotationComponent,
    CoreElementsModule,
    GuidesDrawerComponent,
    BudgetBalanceComponent,
    ResultsModalComponent,
    StatefulElementComponent,
    DisplayOnlyElementBaseComponent,
  ],
  entryComponents: [
    ContentElementComponent,
    IFrameElementComponent,
    ImageElementComponent,
    PdfViewerElementComponent,
    VideoElementComponent,
    DownloadElementComponent,
    SimpleDownloadElementComponent,
  ]
})
export class MimicCoreModule {
  static forRoot(config: MimicCoreConfig): ModuleWithProviders<MimicCoreModule> {
    return {
      ngModule: MimicCoreModule,
      providers: [
        {
          provide: MimicCoreConfig,
          useValue: config
        },
        {
          provide: ElementRendererConfig,
          useValue: {
            allowedModules: config.allowedModules,
            elements: [
              ContentElementComponent,
              IFrameElementComponent,
              DownloadElementComponent,
              SimpleDownloadElementComponent,
              ImageElementComponent,
              PdfViewerElementComponent,
              VideoElementComponent
            ]
          }
        }
      ]
    };
  }
}
