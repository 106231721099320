import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { loadCoreConfig, setCoreConfig } from './appConfig.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { IAppConfig } from '../../models/app-config.model';
import { CoreConfigurationService } from '../../services/config/config.service';
import { LoggerService } from '@stukent/logger';
import { BUHIConfigurationService } from '../../buhi-ecosystem/services/configuration.service';

@Injectable()
export class ConfigEffects {

  constructor(
    private actions$: Actions,
    private configService: CoreConfigurationService,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {
  }

  $loadCoreConfig = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCoreConfig),
      mergeMap(({ platform, area, topic, courseCode }) =>
        this.configService.getConfig(platform, area, topic, courseCode)
          .pipe(
            map((config: IAppConfig) => {
              if ((window as any).__userConfigService) {
                (window as any).__userConfigService.setUserConfig({
                  authDomain: config.authDomain,
                  clientId: config.clientId
                });
              } else {
                this.logger.error('Unable to find user config service. Was global nav configured correctly?');
              }
              this.BuhiConfigurationService.init(config);
              return setCoreConfig({ config });
            }),
            catchError(() => EMPTY)
          )
      )
    ));

}
