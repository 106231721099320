import { createAction, props } from '@ngrx/store';
import { ISimulationInstance, ISimulationInstanceModule, SimulationInstanceElementStatus } from '../../../models/simulation-instance';

const prefix = '[Simulation Instance]';

export const loadSimulationInstance = createAction(`${prefix} Load`);

export const refreshSimulationState = createAction(`${prefix} Refresh`, props<{
  locationId: string, locationType: string;
}>());

export const setSimulationInstance = createAction(`${prefix} Set`, props<{ instance: ISimulationInstance; }>());

export const setSimulationInstanceWithIds = createAction(`${prefix} Set With Ids`, props<{ instance: ISimulationInstance; }>());

export const setSimulationInstanceModule = createAction(`${prefix} Set Module`,
  props<{ module: ISimulationInstanceModule, moduleIndex: number; }>());

export const setInstanceElementState = createAction(`${prefix} Set Element State`,
  props<{ elementId: string, state: { [key: string]: any; }; }>());

export const setNextInstanceElementState = createAction(`${prefix} Set Next Element State`,
  props<{ elementId: string, state: { [key: string]: any; }; }>());

export const setInstanceState = createAction(`${prefix} Set Instance State`,
  props<{ instanceId: string, state: { [key: string]: any; }; }>());

export const setInstancePageState = createAction(`${prefix} Set Page State`,
  props<{ pageId: string, state: { [key: string]: any; }; }>());

export const setInstanceModuleState = createAction(`${prefix} Set Module State`,
  props<{ moduleId: string, state: { [key: string]: any; }; }>());

export const setIterationId = createAction(`${prefix} Set Iteration Id`, props<{ id: string; }>());

export const setInstanceElementStateSuccess = createAction(`${prefix} Set State Success`);

export const setInstanceElementStateFailed = createAction(`${prefix} Set State Failed`);

export const setInstanceElementCompletion = createAction(`${prefix} Set Element Completion`,
  props<{ elementId: string, isComplete: boolean; }>());

export const setInstanceIterationCompletion = createAction(`${prefix} Set Iteration Completion`,
  props<{ iterationId: string, isComplete: boolean; }>());

export const startRoundTimer = createAction(`${prefix} Start Round Timer`, props<{ moduleId: string; }>());

export const startElementTimer = createAction(`${prefix} Start Element Timer`, props<{
  elementId: string,
  moduleId: string,
  questionBankId: string,
  scorerId: string;
}>());

export const stopElementTimer = createAction(`${prefix} Stop Element Timer`, props<{ elementId: string; }>());

export const setInstanceElementModuleId = createAction(`${prefix} Set Element Module Id`,
  props<{ elementId: string, moduleId: string; }>());

export const setInstanceElementStatus = createAction(`${prefix} Set Element Status`,
  props<{ elementId: string, status: SimulationInstanceElementStatus; }>());

export const runRoundOrIteration = createAction(`${prefix} Save Sim Instance`,
  props<{ scoreType: string, moduleId: string, pageId: string, iterationId: string }>());

// Used in Instructor Portal to start...
export const getStudentSimInstance = createAction(`${prefix} Get Student Sim Instance`,
  props<{ studentIdentifier: string, courseCode: string, productCode: string, overrideUrl: string; }>());

export const setStudentSimInstance = createAction(`${prefix} Set Student Sim Instance`, props<{ instance: ISimulationInstance; }>());

export const setDrawerViewed = createAction(`${prefix} Set Drawer Viewed`, props<{ pageId: string, hasBeenViewed: boolean; }>());
