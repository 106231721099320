import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private baseService: BaseService
  ) { }

  getUrl() {
    return this.baseService.appConfig.serviceUrls.document;
  }

  upload(fileName: string, mimeType: string, file: Uint8Array): Observable<{ [key: string]: string }> {
    if (this.baseService.config.isTest) {
      return new Observable(observer => observer.next());
    }

    const bytes = [].slice.call(file);

    return this.baseService.http.post<{ [key: string]: string }>(`${this.getUrl()}/upload`, {
      fileName,
      file: bytes,
      uploadDateTime: new Date(),
      mimeType
    })
      .pipe(
        catchError(err => this.baseService.handleError(err, 'upload a file'))
      );
  }

  uploadViaForm(form: FormData): Observable<{ [key: string]: string }> {
    if (this.baseService.config.isTest) {
      return new Observable(observer => observer.next());
    }

    return this.baseService.http.post<{ [key: string]: string }>(`${this.getUrl()}/upload/stream`, form, {
      reportProgress: true,
      observe: 'events'
    })
      .pipe(
        // map(x => {
        //   if (x.type === HttpEventType.UploadProgress) {
        //     // {
        //     // loaded:11, // Number of bytes uploaded or downloaded.
        //     // total :11 // Total number of bytes to upload or download
        //     // }
        //     console.log(x);
        //   }

        //   if (x.type === HttpEventType.Response) {
        //     return x.body;
        //   }

        //   return undefined;
        // }),
        catchError(err => this.baseService.handleError(err, 'Upload a file'))
      );
  }

  download(id: string, containerName: string = null): Observable<Blob> {
    if (this.baseService.config.isTest) {
      return new Observable(observer => observer.next());
    }

    if (id.includes('http')) {
      return this.baseService.http.get(id, { responseType: 'blob' })
        .pipe(
          catchError(err => this.baseService.handleError(err, 'download a file'))
        );
    }

    let url = `${this.getUrl()}/download/${id}`;
    url += !!containerName ? `?containerName=${containerName}` : '';

    return this.baseService.http.get(url, { responseType: 'blob' })
      .pipe(
        catchError(err => this.baseService.handleError(err, 'download a file'))
      );
  }

  downloadShuffledCsv(fileIdentifiers: string, persist: boolean, zipName: string, eleId: string, noShuffle: string, existingZipId: string)
    : Observable<Blob> {

    // tslint:disable-next-line: max-line-length
    const url = `${this.getUrl()}/shuffle/csv/${fileIdentifiers}?persist=${persist}&zipName=${zipName}&eleId=${eleId}&courseCode=${this.baseService.courseProduct.courseCode}&productCode=${this.baseService.courseProduct.productCode}`
      + (noShuffle ? `&noShuffle=${noShuffle}` : '')
      + (!!existingZipId ? `&existingZipId=${existingZipId}` : '');

    return this.baseService.http.get(url, { responseType: 'blob' })
      .pipe(
        catchError(err => this.baseService.handleError(err, 'download a file'))
      );
  }

  downloadCsvByColumns(fileIdentifier: string, columns: string, filename = 'data.csv'): Observable<Blob> {
    const url = `${this.getUrl()}/download/csv/${fileIdentifier}?filename=${filename}&columns=${columns}`;

    return this.baseService.http.get(url, { responseType: 'blob' })
      .pipe(
        catchError(err => this.baseService.handleError(err, 'download a file'))
      );
  }

  read(file: File): Observable<string> {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    return new Observable((observer: Subscriber<string>): void => {
      reader.onload = ((ev: ProgressEvent): void => {
        let str = '';
        const bytes = new Uint8Array((ev.target as any).result);
        const length = bytes.byteLength;
        for (let i = 0; i < length; i++) {
          str += String.fromCharCode(bytes[i]);
        }

        observer.next(str);
        observer.complete();
      });

      // if failed
      reader.onerror = (error): void => {
        observer.error(error);
      };
    });
  }
}
