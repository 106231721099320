import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, Input, OnInit, SimpleChange, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { LoggerService } from '@stukent/logger';
import { currentUser } from '@stukent/user';
import {
  currentLocation,
  ICourseProductTemplateModule, ILocation, ILocationState, IMenuItem, navigateToModule,
} from '../../../../../mimic-core/src/public-api';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mimic-round-info',
  templateUrl: './round-info.component.html',
  styleUrls: ['./round-info.component.scss']
})
export class RoundInformationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() currentModule: ICourseProductTemplateModule;
  @Input() activeTopicsRounds: ICourseProductTemplateModule[];

  private subscriptions: Subscription[] = [];

  roundNumber = 1;
  roundText = '';
  hasStarted: boolean;
  roundStartDate: Date;
  roundEndDate: Date;

  showRoundMenu: boolean;
  simTopicsRounds: IMenuItem[];

  location: ILocation;

  private enableLogging = false;
  private componentName = 'Round-Info';

  constructor(
    private store: Store,
    private changeRef: ChangeDetectorRef,
    private logger: LoggerService
  ) {
  }

  ngOnInit() {

    this.logInfo('Initializing');

    // Handle Profile Changed
    // Shows or hides Product Menu for Admins
    this.subscriptions.push(this.store.select(currentUser).subscribe(user => {
      if (!user?.isLoading) {
        this.showRoundMenu = user?.roles?.includes('instructor');
        this.changeRef.detectChanges();
      }
    }));

    // Handle Location Changing
    this.subscriptions.push(this.store.select(currentLocation)
      .subscribe(this.locationChanged.bind(this)));

    this.setRoundInformation();
    this.logInfo('Initialized');

  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange; }) {

    if (changes.currentModule?.currentValue) {
      this.logInfo('Current Topic Module Changed');
      this.currentModule = changes.currentModule.currentValue;
      this.setRoundInformation();
    }

    if (changes.activeTopicsRounds?.currentValue) {
      this.logInfo('Round Info Changed');
      this.activeTopicsRounds = changes.activeTopicsRounds.currentValue;
      this.setRoundInformation();
    }

  }

  changeModule(item: IMenuItem): void {
    if (this.location.moduleId !== item.id) {
      this.store.dispatch(navigateToModule({ moduleId: item.id }));
    }

  }

  private locationChanged(locationState: ILocationState): void {
    if (!locationState.isLoading
      && (locationState.location?.moduleId !== this.location?.moduleId
        || locationState.location?.pageId !== this.location?.pageId)
    ) {
      this.logInfo('Module or Page Location Changed');
      this.location = locationState.location;
      this.changeRef.detectChanges();
    }
  }

  private setRoundInformation() {

    this.roundStartDate = null;
    this.roundEndDate = null;

    if (this.currentModule) {
      if (this.currentModule.properties?.startDate) {
        this.roundStartDate = this.currentModule.properties.startDate;
        this.hasStarted = ((new Date()).toISOString() > this.currentModule.properties.startDate);
      } else {
        this.hasStarted = true;
      }
      if (this.currentModule.properties?.endDate) {
        this.roundEndDate = this.currentModule.properties.endDate;
      }

      this.roundText = '';

      if (this.activeTopicsRounds) {

        const currentModuleIndex = this.activeTopicsRounds.findIndex(
          m => !m.properties?.excludeFromRoundCount && m.id === this.currentModule.id
          );

        if (currentModuleIndex !== -1) {
          this.roundNumber = currentModuleIndex;
          this.roundText = `Round ${this.roundNumber}`;
        }

        if (this.showRoundMenu) {
          this.createTopicsMenu();
        }
      }

      this.changeRef.detectChanges();

    }

  }


  private createTopicsMenu(): void {

    let roundNumber = 0;

    this.simTopicsRounds = this.activeTopicsRounds
      .map(simTopicModule => {

        let roundName = simTopicModule.displayName;
        if (!simTopicModule.properties?.excludeFromRoundCount) {
          roundNumber = roundNumber += 1;
          roundName = `Round ${roundNumber}: ${roundName}`;
        }

        return {
          id: simTopicModule.id,
          icon: '',
          title: roundName,
          itemId: simTopicModule.id,
          hasScores: false,
          firstPageId: simTopicModule.pages?.length > 0 ? simTopicModule.pages[0].id : null,
        } as IMenuItem;

      });
  }

  trackById(item: IMenuItem) {
    return item.id;
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

}
