import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { catchError, tap, map } from 'rxjs/operators';
import { IGuide } from '../../models/guides.model';

@Injectable({
  providedIn: 'root'
})
export class GuidesService {

  constructor(
    private baseService: BaseService
  ) { }

  getUrl() {
    if (this.baseService.config.isInMemory) {
      return 'api/guides';
    }
    return this.baseService.appConfig.serviceUrls.guides;
  }

  getGuidesByPage(moduleId: string, pageId: string) {

    let url = this.getUrl();
    if (!this.baseService.config.isInMemory) {
      url += `/${moduleId}/${pageId}`;
    }

    return this.baseService.http.get<IGuide>(url)
      .pipe(
        tap(a => {
          this.baseService.logger.info('fetched guide', a);
        }),
        map(a => ({ pageId, elements: a })),
        catchError(err => this.baseService.handleError(err, 'get the guides', true))
      );
  }
}
