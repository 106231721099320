import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '@stukent/logger';
import { BUHIConfigurationService } from './configuration.service';
import { IProductOptionCategories } from '../models/product-options';

@Injectable({
  providedIn: 'root'
})
export class BUHIProductOptionsService {

  // Use a behaviorSubject for caching
  productOptions$ = new BehaviorSubject<Record<string, IProductOptionCategories[]>>({});

  private urlRoot: string;
  private headers: HttpHeaders;
  private componentName = 'Product Options Service';
  private enableLogging = false;

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {
  }

  loadProductOptions(product: string): void {
    const cachedOptions = this.productOptions$.getValue();

    if (!cachedOptions[product]) {
      this.getProductOptionsData(product);
    }
    {
      this.logInfo(`product Options already loaded`);
    }
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  private getProductOptionsData(product: string): void {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/product/options/${product}`;
    this.http.get<IProductOptionCategories[]>(url, { headers: this.headers })
      .pipe(catchError(this.handleError))
      .subscribe((productData: IProductOptionCategories[]) => {
        const updatedOptions = {
          ...this.productOptions$.getValue(),
          [product]: productData
        };

        this.productOptions$.next(updatedOptions);
      });
  }

  private handleError(error: Error) {
    return throwError(`Failed calling Product Options Data service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
