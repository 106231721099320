import { Injectable } from '@angular/core';
import {
  ILocation,
  ICourseProductTemplate,
  ICourseProductTemplatePage,
  ICourseProductTemplateElement,
} from '../../../models';

@Injectable({
  providedIn: 'root'
})
export class ContentStateHelperService {

  getPageContent(location: ILocation, contentState: ICourseProductTemplate): ICourseProductTemplatePage {
    const currentModule = contentState.modules.find(m => m.id === location.moduleId);
    if (currentModule && currentModule.pages) {
      const page = currentModule.pages.find(p => p.id === location.pageId);
      return page ? page : null;
    }
    return null;
  }

  getPageOrIterationContent(location: ILocation, contentState: ICourseProductTemplate): ICourseProductTemplatePage {
    const page = this.getPageContent(location, contentState);

    if (page && !location.iterationId) {
      return page;
    } else if (location.iterationId && page && page.iterations) {
      const iteration = page.iterations.find(i => i.id === location.iterationId);
      return iteration ? iteration : null;
    }

    return null;
  }

  getElementContent(location: ILocation, contentState: ICourseProductTemplate): ICourseProductTemplateElement {
    const page = this.getPageOrIterationContent(location, contentState);
    let element = null;
    if (page) {
      element = page.elements.find(e => e.id === location.elementId);
    }

    if (element && element.config && typeof element.config === 'string') {
      element = JSON.parse(JSON.stringify(element));
      element.config = JSON.parse(element.config);
    }

    return element;
  }

  getElementContentByType(
    content: ICourseProductTemplate, location: ILocation, elementType: string): ICourseProductTemplateElement<any> {
    const page = this.getPageOrIterationContent(location, content);
    if (!page) { return; }
    let ele = page.elements.find((element: ICourseProductTemplateElement) => element.type === elementType);

    if (ele && ele.config && typeof ele.config === 'string') {
      ele = JSON.parse(JSON.stringify(ele));
      ele.config = JSON.parse(ele.config);
    }

    return ele ? ele : null;
  }
}
