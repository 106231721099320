import { IGenericState, AnyStore } from '../models/element-state';
import { ICourseProductTemplatePage } from '../models';
import { setInstanceModuleState } from '../reducers/core/simulation-instance/simulation-instance.actions';
import { WriteableBaseState } from './writeable-base.state';

interface IRelevantModuleState {
  id: string;
  displayName: string;
  properties: IGenericState;
  pages: Array<ICourseProductTemplatePage>;
  balance: number;
  maxBudget: number;
  state: IGenericState;
}

export class ModuleState extends WriteableBaseState {
  id: string;
  displayName: string;
  properties: IGenericState;
  pages: Array<ICourseProductTemplatePage>;
  currentPage: ICourseProductTemplatePage;
  balance: number;
  maxBudget: number;

  getStateAction() {
    return setInstanceModuleState;
  }

  stateToProps() {
    return { moduleId: this.id, state: this.state };
  }

  setInternalState(relevantState: IRelevantModuleState) {
    this.id = relevantState.id;
    this.displayName = relevantState.displayName;
    this.properties = relevantState.properties;
    this.pages = relevantState.pages;
    this.balance = relevantState.balance;
    this.maxBudget = relevantState.maxBudget;
    this.state = Object.freeze(relevantState.state || {});
  }

  getRelevantState(root: AnyStore): IRelevantModuleState {
    const { core, finance } = root as AnyStore;

    const { budget: { budget: maxBudget, balance } } = finance || { budget: { budget: undefined, balance: undefined } };

    const currentContentModule = core.content.modules.find(m => m.id === core.location.location.moduleId);
    const { id, displayName, properties, pages }
      = currentContentModule || { id: '', displayName: '', properties: {}, pages: [] };

    const { modules } = core.instance;
    const currentModule = modules[id];
    const state = currentModule?.state || {};
    return { id, displayName, properties, pages,  balance, maxBudget, state };
  }

  getNullState(): IRelevantModuleState {
    return {
      id: '',
      displayName: '',
      properties: {},
      pages: [],
      balance: 0,
      maxBudget: 0,
      state: {}
    };
  }
}
