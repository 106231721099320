import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { LoggerService } from '@stukent/logger';

// Import from Core
import {
  ICourseProductTemplate,
  IMenuItem,
  IModuleScore,
  setSelectedModuleId,
  toggleResults,
  showExistingScores,
} from '../../../../../mimic-core/src/public-api';
import { moduleScores } from '../../../../../mimic-core/src/lib/reducers/scores/scores.selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'results-selection',
  templateUrl: './results-selection.component.html',
  styleUrls: ['./results-selection.component.scss']
})
export class ResultsSelectionComponent implements OnInit, OnDestroy {
  @Input() courseProductTemplate: ICourseProductTemplate;

  scorableModules: IMenuItem[];
  scoredModuleIds: string[];
  hideResults = true;

  private subscriptions: Subscription[] = [];

  private enableLogging = false;
  private componentName = 'Results Selection';

  constructor(
    private store: Store,
    private logger: LoggerService,
    public changeRef: ChangeDetectorRef
  ) {

  }

  ngOnInit(): void {
    this.logInfo('initializing');

    this.subscriptions.push(this.store.select(moduleScores)
      .subscribe(this.onScoresChanged.bind(this)));

    this.hideResults = this.courseProductTemplate?.properties?.hideResults;

    this.changeRef.detectChanges();

    this.logInfo('initialized');

  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  private onScoresChanged(newModuleScores: IModuleScore<any>[]): void {

    this.logInfo('scores updated');

    // Set the Scored Modules
    this.scoredModuleIds = newModuleScores.filter(ms => {
      return ms.scores.some(
        s => s.context.simulationInstanceLocationType
          &&
          s.context.simulationInstanceLocationType.toLowerCase() === 'module');
    }).map(m => m.moduleId);

    this.setModuleResults();

    this.changeRef.detectChanges();

  }

  private setModuleResults(): void {

    // Load up the scored Modules
    if (this.courseProductTemplate) {

      this.logInfo('Setting Results Menu');

      // Don't show Hidden, disabled or modules that are excluded from Round Count
      const activeModules = this.courseProductTemplate.modules
        .filter(m => {
          if (!m.properties) {
            // Can't be disabled, return
            return true;
          } else {
            let excludeFromResults = m.properties?.excludeFromResults;
            if (m.properties?.excludeFromRoundCount && excludeFromResults === undefined) {
              excludeFromResults = true;
              this.logger.warn(`RESULTS SELECTION: Incorrect Configuration for Round: ${m.title}: excludeFromRoundCount was set to true, but excludeFromResults was not set.`);
            }

            if (!excludeFromResults) {
              // Check if it is disabled if not already excluded
              // Backwards compatility - Once module.Disabled is fully implemented, everything other than 'excludeFromResults = m?.disabled' can be removed (NH)
              if (m.disabled !== undefined){
                excludeFromResults = m?.disabled;
              }
              else {
                excludeFromResults = m.properties.disableRound;
              }
            }

            return !excludeFromResults;
          }
        }
        );

      // Map active Modules to IMenuItems for display purposes
      this.scorableModules = activeModules
        .map(m => {
          return {
            id: m.id,
            icon: 'mdi mdi-circle-outline',
            title: m.displayName,
            itemId: m.id,
            hasScores: this.moduleHasScores(m.id)
          } as IMenuItem;
        });

      this.changeRef.detectChanges();

    }

  }

  private moduleHasScores(id: string): boolean {

    if (this.scoredModuleIds) {
      // Set the has Scores flag
      return this.scoredModuleIds.find(moduleId => moduleId === id) !== undefined;
    } else {
      return false;
    }
  }

  openResultsModule(roundId: string) {
    this.store.dispatch(setSelectedModuleId({ moduleId: roundId }));
    this.store.dispatch(toggleResults({ isResultsOpen: true }));
    this.store.dispatch(showExistingScores({ showExistingScores: true }));
  }

  trackById(item: IMenuItem) {
    return item.id;
  }
}
