import { createAction, props } from '@ngrx/store';
import { ILocation } from '../../models/simulation-instance';

const prefix = `[Location]`;

export const setLocation = createAction(`${prefix} Set`, props<{ location: ILocation }>());

export const setInitialLocation = createAction(`${prefix} Set Initial`, props<{ location: ILocation; }>());

export const setPreviousLocation = createAction(`${prefix} Set Previous`);

export const navigateToLocation = createAction(`${prefix} Navigate to Location`, props<{ location: ILocation }>());

export const navigateToModule = createAction(`${prefix} Navigate To Module`, props<{ moduleId: string}>());

export const navigateToPage = createAction(`${prefix} Navigate To Page`, props<{ pageId: string; }>());

export const navigateToIteration = createAction(`${prefix} Navigate To Iteration`,
    props<{ iterationId: string, isLastIterationSubmitted: boolean }>());

export const removeIteration = createAction(`${prefix} Remove Iteration`);
