import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAppConfig } from '../../models/app-config.model';
import { MimicCoreConfig } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class CoreConfigurationService {
  url = 'https://api.stukent.com/config';

  constructor(
    private http: HttpClient,
    private config: MimicCoreConfig
  ) { }

  getConfig(platform: string, area: string, topic: string, courseCode: string): Observable<IAppConfig> {
    this.config.isProduction = this.isProduction();
    const env = this.config.isProduction ? 'production' : 'next';

    console.warn(`Loading Core Configuration from: ${this.config.platform || platform}`);

    return this.http.get<IAppConfig>(`${this.url}/${this.config.platform || platform}/${area}/${topic}/${courseCode}/${env}`, {
      headers: {
        'Ocp-Apim-Subscription-Key': this.config.configKey
      }
    });
  }

  isProduction() {
    const currentUrl = window.location.href;
    if (currentUrl.indexOf('-staging') === -1 && currentUrl.indexOf('-next') === -1 && currentUrl.indexOf('localhost') === -1) {
      return true;
    } else {
      return false;
    }
  }

}
