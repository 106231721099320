import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoggerService } from '@stukent/logger';

import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { MetricsService } from '../metrics/metrics.service';
import { BUHIProductGroupingService } from '../../buhi-ecosystem/services/product-grouping.service';
import { selectCurrentContentModule, selectContentTemplateProperty, selectElementStateObject } from '../../reducers/core/selectors';
import { setInstanceElementState } from '../../reducers/core/simulation-instance/simulation-instance.actions';
import { IProductGrouping } from '../../buhi-ecosystem/models/product-grouping';
import { IKeywordList, IKeywordPlannerState } from '../../models/ppc/keyword-planner.model';
import { ICampaign, ICampaignTableState, CampaignTypeEnum, CampaignGoalEnum } from '../../models/ppc/campaign.model';
import { IAdGroupTableState, IAdGroup } from '../../models/ppc/ad-group.model';
import { recalculateBalance } from '../../reducers/finance/budget/budget.actions';
import {
  IKeywordTableState,
  ISearchAdTableState,
  IProductGroupTableState,
  INegativeKeywordState,
  IDisplayAdTableState,
  ISearchAd,
  IDisplayAd,
  ISelectedProductGroup,
  IKeyword
} from '../../models/ppc';

// This holds the items to remove
// This allows us to determine WHAT to remove, and update each state, once
// This would go away if the states where hiegherarchical... (JKW)
interface IItemsToRemove {
  adGroupIds: string[];
  searchAdIds: string[];
  displayAdIds: string[];
  shoppingAdIds: string[];
  keywordIds: IKeywordCompositeKey[];
  negativeKeywordIds: IKeywordCompositeKey[];
}

interface IKeywordCompositeKey {
  globalId: string;
  adGroupId: string;
}

@Injectable({
  providedIn: 'root'
})
export class PPCHelperService implements OnDestroy {

  // Limits
  public totalKeywordsAllowed = 1000;
  public totalAdGroupKeywordsAllowed = 400;

  public totalNegativeKeywordsAllowed = 1500;

  // Basic State Information
  public currentModuleId: string;

  // Holder for BUHI Product Grouping Data
  public productGroupings: IProductGrouping[];

  // Keyword List Items
  private keywordListElementId: string;
  public keywordLists$: BehaviorSubject<IKeywordList[]> = new BehaviorSubject<IKeywordList[]>([]);

  // Related ElementStates and Ids
  // Holds ID's to other States

  // Campaigns
  public campaignElementId: string;
  public campaignState: ICampaignTableState;
  public campaignState$: BehaviorSubject<ICampaignTableState> = new BehaviorSubject<ICampaignTableState>(null);

  // Ad Groups
  public adGroupElementId: string;
  public adGroupState: IAdGroupTableState;
  public adGroupState$: BehaviorSubject<IAdGroupTableState> = new BehaviorSubject<IAdGroupTableState>(null);

  // Search Ads
  public searchAdElementId: string;
  public searchAdState: ISearchAdTableState;
  public searchAdState$: BehaviorSubject<ISearchAdTableState> = new BehaviorSubject<ISearchAdTableState>(null);

  // Display Ad Groups
  public displayAdElementId: string;
  public displayAdState: IDisplayAdTableState;
  public displayAdState$: BehaviorSubject<IDisplayAdTableState> = new BehaviorSubject<IDisplayAdTableState>(null);

  // Shopping Ad Groups
  public shoppingAdElementId: string;
  public shoppingAdState: IProductGroupTableState;
  public shoppingAdState$: BehaviorSubject<IProductGroupTableState> = new BehaviorSubject<IProductGroupTableState>(null);

  // Keywords
  public keywordsElementId: string;
  public keywordsState: IKeywordTableState;
  public keywordsState$: BehaviorSubject<IKeywordTableState> = new BehaviorSubject<IKeywordTableState>(null);

  // Negative Keywords
  public negativeKeywordsElementId: string;
  public negativeKeywordsState: INegativeKeywordState;
  public negativeKeywordsState$: BehaviorSubject<INegativeKeywordState> = new BehaviorSubject<INegativeKeywordState>(null);

  // Previous Round Data
  public missingPreviousRoundData$:
    BehaviorSubject<{ missingCampaigns: ICampaign[], missingAdGroups: IAdGroup[], missingSearchAds: ISearchAd[] }>
    = new BehaviorSubject<{ missingCampaigns: ICampaign[], missingAdGroups: IAdGroup[], missingSearchAds: ISearchAd[] }>(null);

  private itemsToRemove: IItemsToRemove;

  private subscriptions: Subscription[] = [];

  private enableLogging = false;

  constructor(
    private store: Store,
    private logger: LoggerService,
    private metricsService: MetricsService,
    private productGroupingService: BUHIProductGroupingService
  ) {

    // Setup the Items ToRemove
    this.itemsToRemove = this.resetItemsToRemove();

    // Get the keyword State element Id from the Course Product Template Properties
    this.subscriptions.push(this.store.select(selectContentTemplateProperty('keywordListElementId')).subscribe(kid => {
      if (kid) {
        this.keywordListElementId = kid;
        this.logInfo(`course product template loaded: loading keywordlistelement: ${kid}`);
        // Load the Keyword Planner Lists from the Id configured
        // This overrides the current element state with the configured Id
        this.subscriptions.push(this.store.select(
          selectElementStateObject(kid))
          .pipe(distinctUntilChanged())
          .subscribe(keywordState => {
            if (keywordState?.keywordLists) {
              this.keywordLists$.next(JSON.parse(JSON.stringify(keywordState?.keywordLists)));
            }
          }));
      }
    }));

    // Related State Data
    this.subscriptions.push(this.store.select(selectCurrentContentModule)
      .subscribe(m => {

        if (m.id && m.properties) {

          this.currentModuleId = m.id;

          this.metricsService.initializeForModule(m.id);

          const itemElements = m.properties.campaignItemElementIds || {};

          this.campaignElementId = itemElements.campaignId;
          if (this.campaignElementId) {
            // Load Campaign State and signal behaviorsubject
            this.subscriptions.push(this.store.select(selectElementStateObject(this.campaignElementId))
              .pipe(distinctUntilChanged())
              .subscribe((itemState: ICampaignTableState) => {
                this.campaignState = JSON.parse(JSON.stringify(itemState));
                // HACK This should not fire!!!

                // Ensure there is a value
                if (!this.campaignState?.campaigns) {
                  this.campaignState.campaigns = [];
                }

                const tempAllocation = this.getAllocatedBudget(this.campaignState.campaigns);

                if (tempAllocation !== itemState.budgetAllocation) {
                  // WEEEUU WEEEUU WEEUUU (JKW)
                  this.logger.warn(`Budget was adjusted post state update!!! Was ${itemState.budgetAllocation} is now ${tempAllocation}`);
                  this.updateCampaignState();
                  return;
                }

                // Signal the Behavior Subject
                this.campaignState$.next(this.campaignState);
              }));
          } else {
            this.logger.error('Invalid Configuration for PPC. Missing Campaign Element Id in Module Properties');
          }

          // Load the Ad Groups state
          this.adGroupElementId = itemElements.adGroupId;

          if (this.adGroupElementId) {
            this.subscriptions.push(this.store.select(selectElementStateObject(this.adGroupElementId))
              .pipe(distinctUntilChanged())
              .subscribe((itemState: IAdGroupTableState) => {
                this.adGroupState = JSON.parse(JSON.stringify(itemState));

                // Default to empty array
                if (!this.adGroupState?.adGroups) {
                  this.adGroupState.adGroups = [];
                }

                // Signal the Behavior Subject
                this.adGroupState$.next(this.adGroupState);
              }));
          } else {
            this.logger.error('Invalid Configuration for PPC. Missing Ad Group Element Id in Module Properties');
          }

          // Load the Search Ad state
          this.searchAdElementId = itemElements.searchAdId;

          if (this.searchAdElementId) {
            this.subscriptions.push(this.store.select(selectElementStateObject(this.searchAdElementId))
              .pipe(distinctUntilChanged())
              .subscribe((itemState: ISearchAdTableState) => {
                this.searchAdState = JSON.parse(JSON.stringify(itemState));

                // Default to empty array
                if (!this.searchAdState?.searchAds) {
                  this.searchAdState.searchAds = [];
                }

                // Signal the Behavior Subject
                this.searchAdState$.next(this.searchAdState);
              }));
          } else {
            this.logger.error('Invalid Configuration for PPC. Missing Search Ad Element Id in Module Properties');
          }

          // Load the Display Ad state
          this.displayAdElementId = itemElements.displayAdId;

          if (this.displayAdElementId) {
            this.subscriptions.push(this.store.select(selectElementStateObject(this.displayAdElementId))
              .pipe(distinctUntilChanged())
              .subscribe((itemState: IDisplayAdTableState) => {
                this.displayAdState = JSON.parse(JSON.stringify(itemState));
                // Signal the Behavior Subject

                // Default to empty array
                if (!this.displayAdState?.displayAds) {
                  this.displayAdState.displayAds = [];
                }

                this.displayAdState$.next(this.displayAdState);
              }));
          }

          // Load the Shopping Ad state
          this.shoppingAdElementId = itemElements.shoppingAdId;

          if (this.shoppingAdElementId) {

            // Also Load the Product Groups
            productGroupingService.productGroups$.subscribe(groupings => {
              this.productGroupings = groupings?.productGroups;
            });

            productGroupingService.loadProductGroups();

            this.subscriptions.push(this.store.select(selectElementStateObject(this.shoppingAdElementId))
              .pipe(distinctUntilChanged())
              .subscribe((itemState: IProductGroupTableState) => {
                this.shoppingAdState = JSON.parse(JSON.stringify(itemState));
                // Signal the Behavior Subject

                // Default to empty array
                if (!this.shoppingAdState?.productGroups) {
                  this.shoppingAdState.productGroups = [];
                }

                this.shoppingAdState$.next(this.shoppingAdState);
              }));
          }

          // Get the keyword State element Id from the Course Product Template Properties
          this.keywordsElementId = itemElements.keywordId;

          if (this.keywordsElementId) {
            this.subscriptions.push(this.store.select(selectElementStateObject(this.keywordsElementId))
              .pipe(distinctUntilChanged())
              .subscribe((itemState: IKeywordTableState) => {

                this.keywordsState = JSON.parse(JSON.stringify(itemState));
                // Default to empty array
                if (!this.keywordsState?.keywords) {
                  this.keywordsState.keywords = [];
                }
                // Signal the Behavior Subject
                this.keywordsState$.next(this.keywordsState);
              }));
          } else {
            this.logger.error('Invalid Configuration for PPC. Missing Keywords Element Id in Module Properties');
          }

          // Get the Negative keyword State element Id from the Course Product Template Properties
          this.negativeKeywordsElementId = itemElements.negativeKeywordId;

          if (this.negativeKeywordsElementId) {
            this.subscriptions.push(this.store.select(selectElementStateObject(this.negativeKeywordsElementId))
              .pipe(distinctUntilChanged())
              .subscribe((itemState: INegativeKeywordState) => {
                this.negativeKeywordsState = JSON.parse(JSON.stringify(itemState));

                // Default to empty array
                if (!this.negativeKeywordsState?.negativeKeywords) {
                  this.negativeKeywordsState.negativeKeywords = [];
                }

                // Signal the Behavior Subject
                this.negativeKeywordsState$.next(this.negativeKeywordsState);
              }));
          }

        }
      }));

  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private logInfo(message: string): void {
    if (this.enableLogging) {
      this.logger.info(message);
    }
  }

  public updateKeywordLists(lists: IKeywordList[]): void {
    // Only save the lists and keywords on lists
    this.logInfo('updating keyword list state');

    const newState: IKeywordPlannerState = {
      keywordLists: lists
    };

    // Set the keyword planner state
    this.store.dispatch(setInstanceElementState({ elementId: this.keywordListElementId, state: newState }));

  }

  // Check for Missing Previous Items
  public checkMissingPreviousRoundData(
    previousCampaignElementId: string,
    previousCAdGroupElementId: string,
    previousSearchAdElementId: string
  ): void {
    var missingCampaigns: ICampaign[] = [];
    var missingAdGroups: IAdGroup[] = [];
    var missingSearchAds: ISearchAd[] = [];

    if (previousCampaignElementId) {

      this.subscriptions.push(this.store.select(selectElementStateObject(previousCampaignElementId))
        .pipe(take(1))
        .subscribe((itemState: ICampaignTableState) => {

          // Only if previous campaigns are found
          if (itemState?.campaigns) {

            const previousCampaignState = JSON.parse(JSON.stringify(itemState));
            // Ensure there is a value
            if (previousCampaignState?.campaigns) {

              if (this.campaignState?.campaigns) {
                // Find the missing ones
                // Ones that exist in current State, but not previous, by ID
                itemState?.campaigns.forEach(c => {
                  // A-1 means it wasnt found
                  if (this.campaignState.campaigns.findIndex(ec => ec.id === c.id) === -1) {
                    // Add it to the missing ones
                    missingCampaigns.push(c);
                  }
                });

              } else {
                // just copy them all
                missingCampaigns = missingCampaigns.concat(previousCampaignState.campaigns);
              }

            }

          }

          if (previousCAdGroupElementId) {

            this.subscriptions.push(this.store.select(selectElementStateObject(previousCAdGroupElementId))
              .pipe(take(1))
              .subscribe((itemState: IAdGroupTableState) => {

                // Only if previous campaigns are found
                if (itemState?.adGroups) {

                  const previousAdGroupState = JSON.parse(JSON.stringify(itemState));
                  // Ensure there is a value
                  if (previousAdGroupState?.adGroups) {

                    if (this.adGroupState?.adGroups) {
                      // Find the missing ones
                      // Ones that exist in current State, but not previous, by ID
                      itemState?.adGroups.forEach(ag => {
                        // A-1 means it wasnt found
                        if (this.adGroupState.adGroups.findIndex(eag => eag.id === ag.id) === -1) {
                          // Add it to the missing ones
                          missingAdGroups.push(ag);
                        }
                      });

                    } else {
                      // just copy them all
                      missingAdGroups = missingAdGroups.concat(previousAdGroupState.adGroups);
                    }

                  }

                }

                if (previousSearchAdElementId) {
                  this.subscriptions.push(this.store.select(selectElementStateObject(previousSearchAdElementId))
                    .pipe(take(1))
                    .subscribe((itemState: ISearchAdTableState) => {

                      // Only if previous campaigns are found
                      if (itemState?.searchAds) {

                        const previousSearchAdState = JSON.parse(JSON.stringify(itemState));
                        // Ensure there is a value
                        if (previousSearchAdState?.searchAds) {

                          if (this.searchAdState?.searchAds) {
                            // Find the missing ones
                            // Ones that exist in current State, but not previous, by ID
                            itemState?.searchAds.forEach(sa => {
                              // A-1 means it wasnt found
                              if (this.searchAdState.searchAds.findIndex(esa => esa.id === sa.id) === -1) {
                                // Add it to the missing ones
                                missingSearchAds.push(sa);
                              }
                            });

                          } else {
                            // just copy them all
                            missingSearchAds = missingSearchAds.concat(previousSearchAdState.searchAds);
                          }

                        }

                      }

                      // Signal the Behavior Subject
                      this.missingPreviousRoundData$.next({ missingCampaigns, missingAdGroups, missingSearchAds });

                    }));
                } else {
                  // Signal the Behavior Subject
                  this.missingPreviousRoundData$.next({ missingCampaigns, missingAdGroups, missingSearchAds });
                }

              }));
          } else {
            // Signal the Behavior Subject
            this.missingPreviousRoundData$.next({ missingCampaigns, missingAdGroups, missingSearchAds });
          }

        }));

    }
  }

  // Ad Group item GETers
  public getCampaignType(adGroupId: string): CampaignTypeEnum {
    return this.getCampaign(this.getAdGroup(adGroupId)?.campaignId)?.campaignType;
  }

  // Item GETers
  public getCampaign(campaignId: string): ICampaign {
    if (campaignId && this.campaignState) {
      return this.campaignState.campaigns.find(c => c.id === campaignId);
    } else {
      return null;
    }
  }

  public getAdGroup(adGroupId: string): IAdGroup {
    if (adGroupId && this.adGroupState) {
      return this.adGroupState.adGroups.find(ag => ag.id === adGroupId);
    } else {
      return null;
    }
  }

  public getSearchAd(searchAdId: string): ISearchAd {
    if (searchAdId && this.searchAdState) {
      return this.searchAdState.searchAds.find(sa => sa.id === searchAdId);
    } else {
      return null;
    }
  }

  public getDisplayAd(displayAdId: string): IDisplayAd {
    if (displayAdId && this.displayAdState) {
      return this.displayAdState.displayAds.find(da => da.id === displayAdId);
    } else {
      return null;
    }
  }

  public getShoppingAds(shoppingAdId: string): ISelectedProductGroup {
    if (shoppingAdId && this.shoppingAdState) {
      return this.shoppingAdState.productGroups.find(sha => sha.id === shoppingAdId);
    } else {
      return null;
    }
  }

  // Is Active(ers)
  // These have overloaded State Parameters for use in the Results display (JKW)
  public isCampaignActive(campaignId: string, campaignState: ICampaignTableState = null): boolean {

    if (!campaignState) {
      // Default to current Module State
      campaignState = this.campaignState;
    }

    return campaignState?.campaigns?.find(c => c.id === campaignId)?.active || false;
  }

  public isAdGroupActive(adGroupId: string, adGroupState: IAdGroupTableState = null, campaignState: ICampaignTableState = null): boolean {

    if (!adGroupState) {
      // Default to current Module State
      adGroupState = this.adGroupState;
    }

    const ag = adGroupState?.adGroups?.find(adGroup => adGroup.id === adGroupId);
    if (ag?.active) {
      // See if the Campaign is active
      return this.isCampaignActive(ag.campaignId, campaignState);
    }

    return ag?.active;
  }

  // Name GETers
  public getAdGroupAndCampaignNames(adGroupId: string): { adGroupName: string, campaignName: string } {

    const ag = this.getAdGroup(adGroupId);

    if (ag) {
      return { adGroupName: ag.name, campaignName: this.getCampaign(ag.campaignId)?.campaignName };
    }

    return { adGroupName: '', campaignName: '' };
  }

  // Item DELETErs
  public deleteCampaign(campaignId: string): void {

    // Reset Items to Remove
    this.itemsToRemove = this.resetItemsToRemove();

    // Get the Children to Delete
    this.determineItemsToDelete(campaignId, null, null, null, null, null, null);

    // Update the Child States
    this.deleteItems();

    // Remove the campaign
    this.campaignState.campaigns = this.campaignState.campaigns.filter(c => c.id !== campaignId);
    this.updateCampaignState();

  }

  public deleteAdGroup(adGroupId: string): void {

    // Reset Items to Remove
    this.itemsToRemove = this.resetItemsToRemove();

    // Remove the Ad Groups
    this.determineItemsToDelete(null, adGroupId, null, null, null, null, null);

    // Update the States
    this.deleteItems();

  }

  public deleteAdGroups(adGroupIds: string[]): void {

    // Reset Items to Remove
    this.itemsToRemove = this.resetItemsToRemove();

    // Remove the Ad Groups
    this.itemsToRemove.adGroupIds = adGroupIds;

    // Update the States
    this.deleteItems();

  }

  public deleteSearchAd(searchAdId: string): void {

    // Reset Items to Remove
    this.itemsToRemove = this.resetItemsToRemove();

    // Remove the Search Ad and Children
    this.determineItemsToDelete(null, null, searchAdId, null, null, null, null);

    // Update the States
    this.deleteItems();

  }

  public deleteSearchAds(searchAdIds: string[]): void {

    // Reset Items to Remove
    this.itemsToRemove = this.resetItemsToRemove();

    // Remove the Search Ads
    this.itemsToRemove.searchAdIds = searchAdIds;

    // Update the States
    this.deleteItems();

  }

  public deleteDisplayAd(displayAdId: string): void {

    // Reset Items to Remove
    this.itemsToRemove = this.resetItemsToRemove();

    // Remove the Display Ad
    this.determineItemsToDelete(null, null, null, displayAdId, null, null, null);

    // Update the States
    this.deleteItems();

  }

  public deleteDisplayAds(displayAdIds: string[]): void {

    // Reset Items to Remove
    this.itemsToRemove = this.resetItemsToRemove();

    // Remove the Display Ads
    this.itemsToRemove.displayAdIds = displayAdIds;

    // Update the States
    this.deleteItems();

  }

  public deleteShoppingAd(shoppingAdId: string): void {

    // Reset Items to Remove
    this.itemsToRemove = this.resetItemsToRemove();

    // Remove the Shopping Ad
    this.determineItemsToDelete(null, null, null, null, shoppingAdId, null, null);

    // Update the States
    this.deleteItems();

  }

  public deleteShoppingAds(shoppingAdIds: string[]): void {

    // Reset Items to Remove
    this.itemsToRemove = this.resetItemsToRemove();

    // Remove the Shopping Ads
    this.itemsToRemove.shoppingAdIds = shoppingAdIds;

    // Update the States
    this.deleteItems();

  }

  public deleteKeyword(key: IKeywordCompositeKey): void {

    // Reset Items to Remove
    this.itemsToRemove = this.resetItemsToRemove();

    // Remove the Keyword
    this.determineItemsToDelete(null, null, null, null, null, key, null);

    // Update the States
    this.deleteItems();

  }

  public deleteKeywords(keywordIds: IKeywordCompositeKey[]): void {

    // Reset Items to Remove
    this.itemsToRemove = this.resetItemsToRemove();

    // Remove the Keyword Ids
    this.itemsToRemove.keywordIds = keywordIds;

    // Update the States
    this.deleteItems();

  }

  public deleteNegativeKeyword(key: IKeywordCompositeKey): void {

    // Reset Items to Remove
    this.itemsToRemove = this.resetItemsToRemove();

    // Remove the Negative Keywords
    this.determineItemsToDelete(null, null, null, null, null, null, key);

    // Update the States
    this.deleteItems();

  }

  public deleteNegativeKeywords(negativeKeywordIds: IKeywordCompositeKey[]): void {

    // Reset Items to Remove
    this.itemsToRemove = this.resetItemsToRemove();

    // Remove the Negative Keywords
    this.itemsToRemove.negativeKeywordIds = negativeKeywordIds;

    // Update the States
    this.deleteItems();

  }

  private determineItemsToDelete(
    campaignId: string,
    adGroupId: string,
    searchAdId: string,
    displayAdId: string,
    shoppingAdId: string,
    keywordKey: IKeywordCompositeKey,
    negativeKeywordKey: IKeywordCompositeKey
  ): void {

    // Use 10 to be super pretective
    // If a campaign Id is given set all Ad Groups for Deletion
    if (campaignId && campaignId.length > 10) {
      this.itemsToRemove.adGroupIds = this.adGroupState.adGroups
        .filter(ag => ag.campaignId === campaignId)
        .map(ag => ag.id);
    }

    // Remove a specific Ad Group
    if (adGroupId && adGroupId.length > 10) {
      // Don't Add it if it already is set to remove
      if (!this.itemsToRemove.adGroupIds.find(agId => agId === adGroupId)) {
        this.itemsToRemove.adGroupIds.push(adGroupId);
      }
    }

    // Based on Add Groups to Delete, set the children to delete
    if (this.itemsToRemove.adGroupIds.length > 0) {

      // Remove Search Ad Ids
      if (this.searchAdState?.searchAds) {
        this.itemsToRemove.searchAdIds = this.searchAdState.searchAds
          .filter(sa => this.itemsToRemove.adGroupIds.includes(sa.adGroupId))
          .map(sa => sa.id);
      }

      // Remove Display Ad Ids
      if (this.displayAdState?.displayAds) {
        this.itemsToRemove.displayAdIds = this.displayAdState.displayAds
          .filter(da => this.itemsToRemove.adGroupIds.includes(da.adGroupId))
          .map(da => da.id);
      }

      // Remove Shopping Ad Ids
      if (this.shoppingAdState?.productGroups) {
        this.itemsToRemove.shoppingAdIds = this.shoppingAdState.productGroups
          .filter(sha => this.itemsToRemove.adGroupIds.includes(sha.adGroupId))
          .map(sha => sha.id);
      }

      // Remove Keyword Ids
      if (this.keywordsState?.keywords) {
        this.itemsToRemove.keywordIds = this.keywordsState.keywords
          .filter(kw => this.itemsToRemove.adGroupIds.includes(kw.adGroupId))
          .map(kw => ({ globalId: kw.globalId, adGroupId: kw.adGroupId }));
      }

      // Remove Negative Keyword Ids
      if (this.negativeKeywordsState?.negativeKeywords) {
        this.itemsToRemove.negativeKeywordIds = this.negativeKeywordsState.negativeKeywords
          .filter(nkw => this.itemsToRemove.adGroupIds.includes(nkw.adGroupId))
          .map(nkw => ({ globalId: nkw.globalId, adGroupId: nkw.adGroupId }));
      }

    }

    // Remove a specific Search Ad
    if (searchAdId && searchAdId.length > 10) {
      // Don't Add it if it already is set to remove
      if (!this.itemsToRemove.searchAdIds.find(saId => saId === searchAdId)) {
        this.itemsToRemove.searchAdIds.push(searchAdId);
      }
    }

    // Remove a specific Display Ad
    if (displayAdId && displayAdId.length > 10) {
      // Don't Add it if it already is set to remove
      if (!this.itemsToRemove.displayAdIds.find(daId => daId === displayAdId)) {
        this.itemsToRemove.displayAdIds.push(displayAdId);
      }
    }

    // Remove a specific Shopping Ad
    if (shoppingAdId && shoppingAdId.length > 10) {
      // Don't Add it if it already is set to remove
      if (!this.itemsToRemove.shoppingAdIds.find(shaId => shaId === shoppingAdId)) {
        this.itemsToRemove.shoppingAdIds.push(shoppingAdId);
      }
    }

    // Remove a specific Keyword
    if (keywordKey?.globalId.length > 10) {
      // Don't Add it if it already is set to remove
      if (!this.itemsToRemove.keywordIds
        .find(kwKey => kwKey.globalId === keywordKey.globalId && kwKey.adGroupId === keywordKey.adGroupId)) {
        this.itemsToRemove.keywordIds.push(keywordKey);
      }
    }

    // Remove a specific Negative Keyword
    if (negativeKeywordKey?.globalId.length > 10) {
      // Don't Add it if it already is set to remove
      if (!this.itemsToRemove.negativeKeywordIds
        .find(nkwKey => nkwKey.globalId === negativeKeywordKey.globalId && nkwKey.adGroupId === negativeKeywordKey.adGroupId)) {
        this.itemsToRemove.negativeKeywordIds.push(negativeKeywordKey);
      }
    }


  }
  // END Item DELETErs

  // State Updaters
  public updateCampaignState(): void {

    const newState: ICampaignTableState =
    {
      campaigns: this.campaignState.campaigns,
      shownPreviousRoundDataMissing: this.campaignState.shownPreviousRoundDataMissing,
      budgetAllocation: this.getAllocatedBudget(this.campaignState.campaigns)
    };

    // Ensure Budget has a numeric value
    // This shouldn't be required, but has caused scoring failures (JKW)
    newState.budgetAllocation = newState.budgetAllocation || 0;

    // ensure budget on each Camapign
    if (newState.campaigns) {
      newState.campaigns.forEach(c => c.budget = c.budget || 0);
    }

    this.setStateObject(this.campaignElementId, newState);

    this.store.dispatch(recalculateBalance());

  }

  public updateAdGroupState(): void {

    const newState: IAdGroupTableState =
    {
      adGroups: this.adGroupState.adGroups
    };

    this.setStateObject(this.adGroupElementId, newState);

  }

  public updateSearchAdState(): void {

    const newState: ISearchAdTableState =
    {
      searchAds: this.searchAdState.searchAds
    };

    // Ensure Landing Page name is valid has caused scoring issues (JKW)
    newState.searchAds.forEach(a => {
      a.landingPage = a.landingPage || '';
    });

    this.setStateObject(this.searchAdElementId, newState);

  }

  public updateDisplayAdState(): void {

    const newState: IDisplayAdTableState =
    {
      displayAds: this.displayAdState.displayAds
    };


    this.setStateObject(this.displayAdElementId, newState);

  }

  public updateShoppingAdState(): void {

    const newState: IProductGroupTableState =
    {
      productGroups: this.shoppingAdState.productGroups
    };

    this.setStateObject(this.shoppingAdElementId, newState);

  }

  public updateKeywordsState(): void {

    // Only take the data we need (JKW)
    const newKeywords: IKeyword[] = this.keywordsState?.keywords
      .map(kw => ({
        globalId: kw.globalId,
        active: kw.active,
        adGroupId: kw.adGroupId,
        maxCpc: kw.maxCpc || undefined,
      }) as IKeyword);

    const newState: IKeywordTableState =
    {
      keywords: newKeywords.slice(0, this.totalKeywordsAllowed)
    };

    this.setStateObject(this.keywordsElementId, newState);

  }

  public updateNegativeKeywordsState(): void {

    // Only take the data we need (JKW)
    const newKeywords: IKeyword[] = this.negativeKeywordsState?.negativeKeywords
      .map(kw => ({
        globalId: kw.globalId,
        active: kw.active,
        adGroupId: kw.adGroupId,
      }) as IKeyword);

    const newState: INegativeKeywordState =
    {
      // PROTECTION!!!!
      negativeKeywords: newKeywords.slice(0, this.totalNegativeKeywordsAllowed)

    };

    // Make sure that negative keywords is an array and not null
    if (!newState.negativeKeywords) {
      newState.negativeKeywords = [];
    }

    this.setStateObject(this.negativeKeywordsElementId, newState);

  }

  protected setStateObject(elementId: string, newState: any): void {
    // This should only be used to replace an entire state

    // Check for differences
    // TODO (JKW) See if this is really valuable (JKW)
    const stateChanged = true; // false
    /*
    if (!this.elementState?.state && newState) {
        stateChanged = true;
    } else {
        const existingState = JSON.stringify(this.elementState?.state);
        stateChanged = existingState !== JSON.stringify(newState);
    }
    */

    if (stateChanged) {
      this.store.dispatch(setInstanceElementState({ elementId, state: newState }));
    }

  }

  private deleteItems(): void {

    // This ensures that "update state" is only called once regardless of how many items are removed (JKW)

    // Remove ad groups
    if (this.itemsToRemove.adGroupIds.length > 0) {
      this.adGroupState.adGroups = this.adGroupState.adGroups.filter(ag => !this.itemsToRemove.adGroupIds.includes(ag.id));
      this.store.dispatch(setInstanceElementState({ elementId: this.adGroupElementId, state: this.adGroupState }));
      this.itemsToRemove.adGroupIds = [];
    }

    // Remove Search Ads
    if (this.itemsToRemove.searchAdIds.length > 0) {
      this.searchAdState.searchAds = this.searchAdState.searchAds.filter(sa => !this.itemsToRemove.searchAdIds.includes(sa.id));
      this.store.dispatch(setInstanceElementState({ elementId: this.searchAdElementId, state: this.searchAdState }));
      this.itemsToRemove.searchAdIds = [];
    }

    // Remove Display Ads
    if (this.itemsToRemove.displayAdIds.length > 0) {
      this.displayAdState.displayAds = this.displayAdState.displayAds.filter(sa => !this.itemsToRemove.displayAdIds.includes(sa.id));
      this.store.dispatch(setInstanceElementState({ elementId: this.displayAdElementId, state: this.displayAdState }));
      this.itemsToRemove.displayAdIds = [];
    }

    // Remove Shopping Ads
    if (this.itemsToRemove.shoppingAdIds.length > 0) {
      this.shoppingAdState.productGroups = this.shoppingAdState.productGroups.filter(sha =>
        !this.itemsToRemove.shoppingAdIds.includes(sha.id));
      this.store.dispatch(setInstanceElementState({ elementId: this.shoppingAdElementId, state: this.shoppingAdState }));
      this.itemsToRemove.shoppingAdIds = [];
    }

    // Remove Keywords
    if (this.itemsToRemove.keywordIds.length > 0) {
      this.keywordsState.keywords = this.keywordsState.keywords
        .filter(kw => !this.itemsToRemove.keywordIds
          .find(kwKey => kwKey.globalId === kw.globalId && kwKey.adGroupId === kw.adGroupId));
      this.store.dispatch(setInstanceElementState({ elementId: this.keywordsElementId, state: this.keywordsState }));
      this.itemsToRemove.keywordIds = [];
    }

    // Remove Negative Keywords
    if (this.itemsToRemove.negativeKeywordIds.length > 0) {
      this.negativeKeywordsState.negativeKeywords = this.negativeKeywordsState.negativeKeywords
        .filter(nkw => !this.itemsToRemove.negativeKeywordIds
          .find(nkwKey => nkwKey.globalId === nkw.globalId && nkwKey.adGroupId === nkw.adGroupId));
      this.store.dispatch(setInstanceElementState({ elementId: this.negativeKeywordsElementId, state: this.negativeKeywordsState }));
      this.itemsToRemove.negativeKeywordIds = [];
    }

    // Reset Items to Remove
    this.itemsToRemove = this.resetItemsToRemove();
  }

  private resetItemsToRemove(): IItemsToRemove {
    return {
      adGroupIds: [],
      searchAdIds: [],
      displayAdIds: [],
      shoppingAdIds: [],
      keywordIds: [],
      negativeKeywordIds: []
    };
  }

  // Budget Helpers
  private getAllocatedBudget(campaigns: ICampaign[]) {
    if (!campaigns) {
      return 0;
    }
    return campaigns.reduce((acc, c) => {
      if (c.active) {
        acc += c.budget;
      }
      return acc;
    }, 0);
  }

}
