import { Action, createReducer, on } from '@ngrx/store';
import { IAppConfig } from '../../models/app-config.model';
import { loadCoreConfigFromStorage, setCoreConfig, toggleLogging } from './appConfig.actions';

export const initialAppConfigurationState: IAppConfig = {
  serviceUrls: {},
  enableLogging: false,
  settings: {
    apiTimeoutThreshold: 5000,
  },
  isLoaded: false
};

const reducer = createReducer(initialAppConfigurationState,
  on(setCoreConfig, (_, { config }) => {
    const newState = { ...config };
    localStorage.config = JSON.stringify(config);
    newState.isLoaded = true;
    return newState;
  }),
  on(loadCoreConfigFromStorage, () => {
    if (!localStorage.config) {
      return initialAppConfigurationState;
    }
    return JSON.parse(localStorage.config);
  }),
  on(toggleLogging, (state, { enable }) => ({ ...state, enableLogging: enable }))
);

export function coreConfigReducer(state: IAppConfig, action: Action) {
  return reducer(state, action);
}
