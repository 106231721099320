import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { catchError, map, tap } from 'rxjs/operators';
import { ISimulationScores } from '../../models/scoring';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScoringService {

  constructor(private baseService: BaseService) { }

  getUrl(): string {
    return this.baseService.appConfig.serviceUrls.scoring;
  }

  scoreModule(moduleId: string, dryRun: boolean) {
    return this.baseService.http
      .post(`${this.getUrl()}/${this.baseService.courseProduct.courseCode}/module?dryRun=${dryRun ? 1 : 0}`, { moduleId })
      .pipe(
        tap(a => {
          if (this.baseService.appConfig.enableLogging) {
            this.baseService.logger.info('fetched sim state', a);
          }
        }),
        catchError(err => this.baseService.handleError(err, 'getting your score for the round', true))
      );
  }

  scoreIteration(iterationId: string, pageId: string, moduleId: string) {
    return this.baseService.http
      .post(`${this.getUrl()}/${this.baseService.courseProduct.courseCode}/iteration`, { iterationId, moduleId, pageId })
      .pipe(
        tap(a => {
          if (this.baseService.appConfig.enableLogging) {
            this.baseService.logger.info('fetched sim state', a);
          }
        }),
        catchError(err => this.baseService.handleError(err, 'getting your score for the round', true))
      );
  }

  scoreItem(simulationInstanceId: string, moduleId: string, scorerId: string) {
    return this.baseService.http
      .post(`${this.getUrl()}/${this.baseService.courseProduct.courseCode}/item`, { simulationInstanceId, moduleId, scorerId })
      .pipe(
        tap(a => {
          if (this.baseService.appConfig.enableLogging) {
            this.baseService.logger.info('fetched sim state', a);
          }
        }),
        catchError(err => this.baseService.handleError(err, 'getting your score for the element', true))
      );
  }

  get(courseCode: string): Observable<ISimulationScores> {
    return this.baseService.http
      .get(`${this.getUrl()}/${courseCode}`)
      .pipe(
        tap((res: ISimulationScores) => {
          if (this.baseService.appConfig.enableLogging) {
            this.baseService.logger.info('fetched scores', res);
          }
        }),
        map(res => res),
        // catchError(err => this.baseService.handleError(err, 'get your scores'))
      );
  }

}
