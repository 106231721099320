import { ChangeDetectorRef, Component, OnDestroy, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ICourseProductTemplateModule } from '../../models';
import { selectCurrentModuleState, selectCurrentContentModule } from '../../reducers/selectors';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sk-round-timer',
  templateUrl: './round-timer.component.html',
  styleUrls: ['./round-timer.component.scss'],
})
export class RoundTimerComponent implements OnDestroy, OnInit {

  private timerSubscription: Subscription;
  private currentModuleSubscription: Subscription;

  private timerInterval;
  public timerDisplayValue: string;
  private timerRunning = false;

  private currentModule: ICourseProductTemplateModule;
  public timeLimit: number;
  private moduleState: any;

  constructor(
    private store: Store,
    private changeRef: ChangeDetectorRef) {

  }

  ngOnInit() {

    // Set the timer when the content module changes
    this.currentModuleSubscription = this.store.select(selectCurrentContentModule).subscribe(m => {
      if (m?.id) {
        this.currentModule = m;
        this.setTimer();
      }
    });

    // Set the module state when the instance changes...
    this.timerSubscription = this.store.select(selectCurrentModuleState)
      .subscribe(moduleState => {
        this.moduleState = moduleState;
        this.setTimer();
      });
  }

  private setTimer(): void {

    if (!this.moduleState || !this.currentModule?.properties) {
      // Nothing to do...
      this.removeTimer();

      return;
    }

    // Get the Timer Start from the Module state if it exists..
    const timerStart = this.moduleState.timerStart;

    // Get the Timer Start from the Module settings if it exists..
    const timeLimit = this.currentModule.properties.timeLimit;

    // Only if both exist...
    if (timerStart && timeLimit) {

      if (!this.timerRunning) {
        this.timerRunning = true;
        this.timerInterval = setInterval(this.updateClock.bind(this, timerStart, timeLimit), 1000);
      }

    } else {
      this.removeTimer();
    }

  }

  private updateClock(timerStart, timeLimit) {

    const startAsDate = new Date(timerStart);
    const endAsDate = new Date(startAsDate.getTime() + (timeLimit * 60 * 1000));

    let currentTimeRemaining = new Date(endAsDate.getTime() - Date.now()).getTime();

    if (currentTimeRemaining < 0) {
      currentTimeRemaining = 0;
    }

    let hourDisplay = '00';
    let minuteDisplay = '00';
    let secondDisplay = '00';

    if (currentTimeRemaining > 0) {
      const hoursRemaining = Math.floor(currentTimeRemaining / 60 / 60 / 1000).toString();
      const minutesRemaining = Math.floor((currentTimeRemaining % (60 * 60 * 1000)) / 60 / 1000).toString();
      const secondsRemaining = Math.floor((currentTimeRemaining % (60 * 1000)) / 1000).toString();

      hourDisplay = hoursRemaining.length < 2 ? '0' + hoursRemaining : hoursRemaining;
      minuteDisplay = minutesRemaining.length < 2 ? '0' + minutesRemaining : minutesRemaining;
      secondDisplay = secondsRemaining.length < 2 ? '0' + secondsRemaining : secondsRemaining;
    }

    this.timerDisplayValue = `${hourDisplay}:${minuteDisplay}:${secondDisplay}`;

    this.changeRef.detectChanges();

  }

  private removeTimer(): void {
    this.timerRunning = false;
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }

    this.timerDisplayValue = null;
    this.changeRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.currentModuleSubscription) {
      this.currentModuleSubscription.unsubscribe();
    }
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }
}
