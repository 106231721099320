export const environment = {
  isDevelopment: window.location.href.indexOf('localhost') > -1,
  isTest: false,
  isProduction: window.location.href.indexOf('-staging') === -1 &&
                window.location.href.indexOf('-next') === -1 &&
                window.location.href.indexOf('localhost') === -1,
  isNext: window.location.href.indexOf('-staging') > -1 || window.location.href.indexOf('-next') > -1,
  configKey: 'cd93e94c952e45d7aa96364d760d2ad8',
  platform: 'mimic'
};
