import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppConfig } from '../models/app-config.model';
import { LoggerService } from '@stukent/logger';
import { currentUser } from '@stukent/user';
import { appConfiguration } from '../reducers/appConfig/appConfig.selectors';

enum urlMatchType {
  contains,
  startsWith,
  endsWith
}

interface IURLWhiteList {
  urlPart: string;
  matchType: urlMatchType;
}

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  token: string;
  config: IAppConfig;
  urlWhiteList: IURLWhiteList[];

  constructor(
    private store: Store,
    private logger: LoggerService
  ) {

    // TODO, get the whitelist from somewhere better...
    this.urlWhiteList = [
      { urlPart: 'https://api.stukent.com/config/mimic', matchType: urlMatchType.startsWith },
      { urlPart: 'https://api.stukent.com/config/edify', matchType: urlMatchType.startsWith },
      { urlPart: 'https://api.stukent.com/features', matchType: urlMatchType.startsWith },
      { urlPart: 'https://apif.stukent.com/original/config/mimic', matchType: urlMatchType.startsWith },
      { urlPart: 'https://apif.stukent.com/original/config/edify', matchType: urlMatchType.startsWith },
      { urlPart: 'https://apif.stukent.com/original/features', matchType: urlMatchType.startsWith },

      { urlPart: '.js', matchType: urlMatchType.endsWith },
      { urlPart: 'elements.json', matchType: urlMatchType.endsWith },
      { urlPart: '/metrics/', matchType: urlMatchType.contains },
      { urlPart: '/ranking/', matchType: urlMatchType.contains },
      { urlPart: '/ecosystem/', matchType: urlMatchType.contains },
      { urlPart: '/media/', matchType: urlMatchType.contains }
    ];

    this.store.select(currentUser).subscribe(user => {
      if (user.jwt) {
        this.token = user.jwt;
      }
    });
    this.store.select(appConfiguration).subscribe(x => this.config = x);
  }

  sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available

    if (!this.isInWhitelist(request.url)) {

      const debug401 = false;

      if (!this.token && debug401) {
        // TODO, Find and eliminate race condition for profile subscription setting JWT token...
        this.logger.warn('401 DEBUG: no token for ' + request.url + ', sleeping for 100ms');

        // Sleep for a time, then try again...
        this.sleep(100).then(() => {
          this.logger.warn(`DEBUG: token after sleep
                    : ${request.url} | Token exists now: ${this.token ? 'exists' : 'does not exist'}`);
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this.token}`,
              'Ocp-Apim-Subscription-Key': request.headers.get('Ocp-Apim-Subscription-Key') || this.config.subscriptionKey
            }
          });
          return next.handle(request);
        });

      } else {
        // Token exists, just run normally

        if (!this.token && debug401) {
          this.logger.warn('401 DEBUG: no token for ' + request.url + '.');
        }

        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
            'Ocp-Apim-Subscription-Key': request.headers.get('Ocp-Apim-Subscription-Key') || this.config.subscriptionKey
          }
        });

        return next.handle(request);
      }
    } else {

      // not white listed, but will still require a token for metrics (and ranking)
      if (this.token && (request.url.indexOf('/ranking/') > 0 || request.url.indexOf('/metrics/') > 0)) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
            'Ocp-Apim-Subscription-Key': this.config.rankingSubscriptionKey
          }
        });
      }

      // use the Ecosystem key
      if (this.token && request.url.indexOf('/ecosystem/') > 0) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
            'Ocp-Apim-Subscription-Key': this.config.ecosystemSubscriptionKey
          }
        });
      }

      // use the Media key
      if (this.token && request.url.indexOf('/media/') > 0) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token}`,
            'Ocp-Apim-Subscription-Key': this.config.mediaServicesSubscriptionKey
          }
        });
      }

      // No token needed
      return next.handle(request);
    }

  }

  isInWhitelist(url: string): boolean {
    if (this.urlWhiteList && this.urlWhiteList.length > 0) {
      return this.urlWhiteList.find(wl => {
        switch (wl.matchType) {
          case urlMatchType.startsWith:
            // starts with
            return url.startsWith(wl.urlPart);
          case urlMatchType.endsWith:
            // ends with
            return url.endsWith(wl.urlPart);
          case urlMatchType.contains:
            // check contains...
            return url.includes(wl.urlPart);
          default:
            // code block
            return false;
        }
      }) !== undefined;
    } else {
      return false;
    }
  }
}
