import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICoreValue } from '../models/core-value';
import { LoggerService } from '@stukent/logger';
import { BUHIConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class BUHICoreValuesService {

  private urlRoot: string;
  private headers: HttpHeaders = new HttpHeaders();


  // Use a behaviorSubject for caching
  coreValues$: BehaviorSubject<ICoreValue[]> = new BehaviorSubject<ICoreValue[]>([]);

  private coreValuesLoaded = false;

  private componentName = 'Core Values Service';
  private enableLogging = false;

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {

  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  loadCoreValues() {
    if (!this.coreValuesLoaded) {
      this.getCoreValuesData();
    }
    else {
      this.logInfo(`core values already loaded`);
    }
  }

  private getCoreValuesData(): void {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }

    const url = `${this.urlRoot}/core-values`;

    this.http.get<ICoreValue[]>(url, { headers: this.headers })
      .pipe(catchError(this.handleError))
      .subscribe((coreValues: ICoreValue[]) => {
        if (coreValues.length > 0) {
          this.coreValues$.next(coreValues);
          this.coreValuesLoaded = true;
        }
      });
  }

  /*
 * @deprecated
 * update element to use BehaviorSubject
 */
  getCoreValues(): Observable<ICoreValue[]> {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }

    const url = `${this.urlRoot}/core-values`;
    return this.http.get<ICoreValue[]>(url, { headers: this.headers })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: Error) {
    return throwError(`Failed calling BUHI core value service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
