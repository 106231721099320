import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { ShortcutsService } from '@stukent/shortcuts';
import { LoggerService } from '@stukent/logger';
import { ActivatedRoute } from '@angular/router';
import {
  loadCoreConfig,
  appConfiguration,
  MimicCoreConfig,
  selectResultsToggle,
  setCourseProduct,
  selectContentTemplate,
  LoadingStatus,
  ICourseProductTemplate,
  currentCourseProduct,
  loadCourseProductTemplate,
  SignalRService,
  IAppConfig
} from '../../../../mimic-core/src/public-api';
import { currentUser } from '@stukent/user';

@Component({
  selector: 'mimic-base',
  templateUrl: './mimic-base.component.html',
  styleUrls: ['./mimic-base.component.scss']
})
export class MimicBaseComponent implements OnInit {

  private appConfig: IAppConfig;
  private userJwt: string;

  private courseCode: string;
  private productCode: string;
  private loadedProduct: string;

  private subscriptions: Subscription[] = [];

  isResultsModalVisible = false;
  courseProductTemplate: ICourseProductTemplate;
  productHasRanking = false;

  private enableLogging = false;
  private componentName = 'Mimic Base:';

  constructor(
    private changeRef: ChangeDetectorRef,
    private store: Store,
    private logger: LoggerService,
    private shortcutService: ShortcutsService,
    private route: ActivatedRoute,
    private coreConfig: MimicCoreConfig,
    private signalRService: SignalRService,
  ) {
  }

  ngOnInit() {

    this.shortcutService.init();

    // Set global error handler
    window.onerror = (e: any) => {
      if (e.stack) {
        this.logger.warn(e.stack);
      }
      this.logger.error(e.message);
    };

    // Get the Course Code from the URL Parameters
    this.route.params.subscribe(params => {
      this.logInfo(`Loading config for: ${this.coreConfig.platform} ${params.courseCode} - ${params.area} - ${params.topic}`);

      if (params.courseCode) {
        // Ask for the configuration now that we have the platform, area and topic...
        this.courseCode = params.courseCode;

        this.logInfo(`Route Detected Course Code: ${this.courseCode}`);

        this.store.dispatch(loadCoreConfig({
          platform: this.coreConfig.platform,
          courseCode: this.courseCode,
          area: params.area || 'na',
          topic: params.topic || params.productCode
        }));

        // Get the Product Code from the Configuration
        this.store.select(appConfiguration).subscribe(appConfig => {
          // Set the App Insights Key
          this.appConfig = appConfig;

          if (appConfig?.isLoaded) {
            this.logInfo(`Got config for: ${params.courseCode} - Logging Key: ${appConfig.applicationInsightsKey}`);

            this.logger.init({
              key: appConfig.applicationInsightsKey
            });
          }

          if (appConfig.productCode) {
            this.productCode = appConfig.productCode;
            this.store.dispatch(setCourseProduct({ newProductCode: this.productCode, newCourseCode: this.courseCode }));
          }
        });

      }
    });

    // Handle View Toggle of Results
    this.subscriptions.push(this.store.select(selectResultsToggle)
      .subscribe(open => {
        this.logInfo('Setting Results Visisble to: ' + open);
        this.isResultsModalVisible = open;
        this.changeRef.detectChanges();
      }));

    // Handle Course Product Template Changing...
    this.subscriptions.push(this.store.select(selectContentTemplate)
      .subscribe(template => {
        if (template.loadingState.status === LoadingStatus.loaded) {
          this.courseProductTemplate = template;
          this.productHasRanking = this.courseProductTemplate?.properties?.hasRanking || false;
        }
      }));

    // TODO Load the Course Product Template a better way (JKW)
    // Thinking a LoadingState
    this.subscriptions.push(this.store.select(currentUser).subscribe(user => {
      // When the user has a JWT, subscribe to the product code and load the template
      if (!this.loadedProduct && user.jwt && user.jwt.length > 10) {
        this.logInfo(`Loaded User in: ${this.productCode}`);
        this.userJwt = user.jwt;

        if (this.productCode) {
          this.logInfo(`Calling Load Course Product Template for existing code: ${this.productCode}`);
          this.loadedProduct = this.productCode;
          this.store.dispatch(loadCourseProductTemplate({ productCode: this.productCode }));
          this.loadSignalR();
        } else {
          // ask fo the Curren Product Code
          this.subscriptions.push(this.store.select(currentCourseProduct).subscribe(cp => {
            // Load the course Product Template
            if (cp.productCode && cp.productCode.length > 0) {
              this.logInfo(`Calling Load Course Product Template for: ${cp.productCode}`);
              this.loadedProduct = cp.productCode;
              this.store.dispatch(loadCourseProductTemplate({ productCode: cp.productCode }));
              this.loadSignalR();
            }

          }));
        }
      }
    }));

  }

  private loadSignalR(): void {
    try {
      this.signalRService.setup(this.userJwt, this.appConfig.subscriptionKey);
    } catch (e) {
      console.error('Unable to setup signalR connection because ', e);
    }
  }

  private logInfo(message: string): void {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName} ${message}`);
    }
  }

}
