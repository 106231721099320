import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { IInfluencerCategory, IInfluencerOffer, IInfluencerOfferResponse, IInfluencerStrategy } from '../models/influencer';
import { LoggerService } from '@stukent/logger';
import { BUHIConfigurationService } from './configuration.service';

export declare interface IBuhiInfluencerDataService {
  init();
  getStrategyData(scenarioName: string): Observable<IInfluencerStrategy[]>;
  getCategoryData(scenarioName: string): Observable<IInfluencerCategory[]>;
  checkOffer(offer: IInfluencerOffer): Observable<IInfluencerOfferResponse>;
}

export const BuhiInfluencerData: InjectionToken<IBuhiInfluencerDataService> = new InjectionToken<IBuhiInfluencerDataService>('IBuhiInfluencerDataService');

@Injectable({
  providedIn: 'root'
})
export class BuhiInfluencerDataService {

  private urlRoot;
  private headers: HttpHeaders;

  // Use a behaviorSubject for caching
  strategies$: BehaviorSubject<IInfluencerStrategy[]> = new BehaviorSubject<IInfluencerStrategy[]>([]);
  categories$: BehaviorSubject<IInfluencerCategory[]> = new BehaviorSubject<IInfluencerCategory[]>([]);
  offerResponse$: BehaviorSubject<IInfluencerOfferResponse> = new BehaviorSubject<IInfluencerOfferResponse>(null);

  // keeping around for backwards compatibility
  influencerCategories$: BehaviorSubject<IInfluencerCategory[]> = new BehaviorSubject<IInfluencerCategory[]>([]);

  private strategiesLoaded = false;
  private influencersLoaded = false;
  private offerResponseLoaded = false;

  private enableLogging = false;
  private componentName = 'Influencer Data Service';

  constructor(

    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  loadStrategies(scenarioName: string = 'social') {
    // Check if the influencer strategies have been loaded.. If so don't do anything...
    if (!this.strategiesLoaded) {
      this.getStrategyData(scenarioName);
    } else {
      this.logInfo(`strategies already loaded`);
    }
  }

  loadCategoryData(scenarioName: string = 'social') {
    // Check if the influencers have been loaded.. If so don't do anything...
    if (!this.influencersLoaded) {
      this.getInfluencers(scenarioName);
    } else {
      this.logInfo(`influencers already loaded`);
    }
  }

  // keeping around for backwards compatibility
  loadInfluencers(scenarioName: string = 'social') {
    // Check if the influencers have been loaded.. If so don't do anything...
    if (!this.influencersLoaded) {
      this.getInfluencers(scenarioName);
    } else {
      this.logInfo(`influencers already loaded`);
    }
  }

  /**
   * Returns Influencer Strategies from the BUHI Ecosystem
   *
   * @param scenarioName defaults to 'social' if not provided
   * @returns IInfluencerStrategy[]
   */
  private getStrategyData(scenarioName: string = 'social'): void {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/influencer/strategies/${scenarioName}`;

    this.http.get<IInfluencerStrategy[]>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(strategies => {
        if (strategies?.length > 0) {
          this.strategiesLoaded = true;
          this.strategies$.next(strategies);
        }
      });
  }


  /**
   * Returns Categories and their associated Influencers from the BUHI Ecosystem
   *
   * @param scenarioName defaults to 'social' if not provided
   * @returns IInfluencerCategory[]
   */
  private getInfluencers(scenarioName: string = 'social') {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/influencers/${scenarioName}`;

    this.http.get<IInfluencerCategory[]>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(influencerCatetories => {
        if (influencerCatetories?.length > 0) {
          this.influencersLoaded = true;
          this.categories$.next(influencerCatetories);
          this.influencerCategories$.next(influencerCatetories);
        }
      });
  }

  public loadCheckOffer(offer: IInfluencerOffer) {
    // Check if the manufacturers have been loaded.. If so don't do anything...
    if (!this.offerResponseLoaded) {
      this.checkOffer(offer);
    } else {
      this.logInfo(`negotiation response already loaded`);
    }
  }

  /**
   * Checks if Influencer Negotiation Offer has been accepted by the selected influencer
   * POST IInfluencerOffer object
   *
   * @param offer IInfluencerOffer
   * @returns IInfluencerOfferResponse
   */
  checkOffer(offer: IInfluencerOffer) {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/influencer/offer`;

    this.http.post<IInfluencerOfferResponse>(url, offer, { headers: this.headers }).subscribe(response => {
      this.offerResponseLoaded = true;
      this.offerResponse$.next(response);
    });
  }

  private handleError(error) {
    return throwError(`Failed calling Influencer Data service: ${error.message}`);
  }
  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
