import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IMetricReport } from '../../models/metrics/metric-report.model';
import { BaseService } from '../base.service';
import { IMetricAggregation } from '../../models';
import { AggregationsService } from './aggregations.service';

export type MetricSetLevel = 'PpcAdGroup' | 'PpcCampaign' | 'PpcDisplayAd' | 'PpcKeyword' | 'PpcProductGroup' | 'PpcSearchAd';

@Injectable({
  providedIn: 'root'
})
export class MetricsService {

  private roundMetrics: IMetricAggregation;

  public isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private currentModuleId = null;

  constructor(
    private aggregationsService: AggregationsService,
    private baseService: BaseService
  ) { }

  getUrl() {
    return this.baseService.appConfig?.serviceUrls?.metricReport || '';
  }

  get(report: string, queries: string[][]): Observable<IMetricReport> {

    const parsedQuery = queries.map(q => q.map(qu => encodeURIComponent(qu)).join('=')).join('&');

    const url = `${this.getUrl()}/${report}/${this.baseService.instance.id}/?${parsedQuery}`;

    return this.baseService.http.get<IMetricReport>(url, {
      headers: {
        'Ocp-Apim-Subscription-Key': this.baseService.appConfig.metricReportSubscriptionKey
      }
    })
      .pipe(
        tap(a => {
          if (this.baseService.appConfig?.enableLogging) {
            this.baseService.logger.info(`fetched metrics: ${report}`);
          }
        }),
        catchError(err => this.baseService.handleError(err, `fetch metrics for ${report}`))
      );
  }


  initializeForModule(moduleId: string) {
    if (moduleId !== this.currentModuleId) {
      this.currentModuleId = moduleId;
      this.getMetrics();
    }
  }

  getForLevel<T>(level: MetricSetLevel, nullMetrics: any): T[] {
    if (!this.roundMetrics?.metaData) {
      return [];
    }

    const metrics: T[] = [];

    const metricDefinitions = this.roundMetrics?.metaData.metricDefinitions;

    this.roundMetrics.aggregations[level]?.forEach(leveledMetricValue => {
      const metricsObject: T = { ...nullMetrics, id: leveledMetricValue.levelId };

      leveledMetricValue.data.forEach(data => {
        const definition = metricDefinitions.find(d => d.id === data.id);
        if (!definition) { throw new Error(`Invalid metric definition id ${data.id}`); }

        metricsObject[definition.name] = data.value;

      });

      metrics.push(metricsObject);

    });

    return metrics;
  }

  private getMetrics(): void {

    const previousModuleId = this.aggregationsService.getForPreviousModule();

    this.aggregationsService.roundAggregations$.subscribe(roundMetrics => {
      const previousModuleMetrics = roundMetrics.find(rm => rm.moduleId === previousModuleId);
      if (previousModuleMetrics) {
        this.roundMetrics = previousModuleMetrics.roundMetrics;
        this.isLoaded$.next(true);
      }
    });
  }

}
