import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { catchError } from 'rxjs/operators';

import { LoggerService } from '@stukent/logger';
import { IPersona, IPersonas } from '../models/persona';
import { BUHIConfigurationService } from './configuration.service';

export declare interface IBuhiPersonaDataService {
  getPersonas(): Observable<IPersona[]>;
}

export const BuhiPersonaData: InjectionToken<IBuhiPersonaDataService> = new InjectionToken<IBuhiPersonaDataService>('IBuhiPersonaDataService');

@Injectable({
  providedIn: 'root'
})
export class BuhiPersonaDataService {

  private urlRoot;
  private headers: HttpHeaders;

  // Use a behaviorSubject for caching
  personas$: BehaviorSubject<IPersona[]> = new BehaviorSubject<IPersona[]>([]);

  private personasLoaded = false;

  private enableLogging = false;
  private componentName = 'Persona Data Service';

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  // Used to load default personas
  loadPersonas() {
    // Check if the personas have been loaded.. If so don't do anything...
    if (!this.personasLoaded) {
      this.getPersonas();
    } else {
      this.logInfo(`personas already loaded`);
    }
  }

  /**
   * Returns {Personas} from the BUHI Ecosystem
   *
   * @returns IPersona[]
   */
  private getPersonas() {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/personas`;

    this.http.get<IPersonas>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(data => {
        if (data?.personas?.length > 0) {
          this.personasLoaded = true;
          this.personas$.next(data.personas);
        }
      });
  }

  // added to handle scenarios
  loadPersonasByScenario(scenarioName: string = 'default') {
    // Check if the personas have been loaded.. If so don't do anything...
    if (!this.personasLoaded) {
      this.getPersonasByScenario(scenarioName);
    } else {
      this.logInfo(`personas already loaded`);
    }
  }

  /**
   * Returns {Personas} from the BUHI Ecosystem
   *
   * @returns IPersona[]
   */
  private getPersonasByScenario(scenarioName: string = 'default') {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    const url = `${this.urlRoot}/personas/${scenarioName}`;

    this.http.get<IPersonas>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(data => {
        if (data?.personas?.length > 0) {
          this.personasLoaded = true;
          this.personas$.next(data.personas);
        }
      });
  }

  private handleError(error) {
    return throwError(`Failed calling Persona Data service: ${error.message}`);
  }
  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
