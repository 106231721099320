import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FileService } from '../../services/file/file.service';
import { Store } from '@ngrx/store';
import { LocationService } from '@stukent/feature-toggle';
import {
  selectCourseProductInstanceState
} from '../../reducers/selectors';
import { InteractionElementBaseComponent } from '../../modules/interaction-element-base.component';
import { LoggerService } from '@stukent/logger';

export interface IDownloadConfig {
  text: string;
  url?: string;
  fileName: string;
  icon?: string;
  shuffleCsv?: boolean;
  sourceFiles?: string[];
  sourceFilesNoShuffle?: string[];
  containerName?: string;
  columns?: string[];
}

export interface IDownloadState {
  shuffleId?: string;
  documentId?: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sk-download-element',
  templateUrl: './download-element.component.html',
  styleUrls: ['./download-element.component.scss']
})
export class DownloadElementComponent
  extends InteractionElementBaseComponent<IDownloadConfig, IDownloadState>
  implements OnInit, OnDestroy {

  static type = 'download';

  @Input() disabled = false;
  @Input() overrideUrl: string;
  @Input() isPrimary: boolean;

  private containerName: string = undefined;
  private documentId: string;
  private shuffleId: string;

  constructor(
    private changeRef: ChangeDetectorRef,
    private fileService: FileService,
    store: Store,
    private locationService: LocationService,
    logger: LoggerService
  ) {
    super(logger, store);
    this.enableLogging = false;
    this.componentName = 'Download Interaction';
  }

  ngOnInit(): void {

    this.logInfo('Initializing');
    if (this.config?.containerName) {

      this.containerName = this.config.containerName;
      // If in Next, affix to the container name
      if (this.locationService.isNext) {
        this.containerName += '-next';
      }

    }

    // Subscribe to the Course Product Instance changes..
    this.subscriptions.push(this.store.select(selectCourseProductInstanceState)
      .subscribe(this.handleInstanceStateChange.bind(this)));

    super.ngOnInit();

    this.changeRef.detectChanges();
    this.logInfo('Initialized');

  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }

    super.ngOnDestroy();
  }

  openDocument() {
    const url = this.overrideUrl || this.config?.url;

    if (this.documentId) {
      this.downloadFileById();

    } else if (this.config?.shuffleCsv) {
      this.downloadShuffledCsv();

    } else if (!!this.config?.columns) {
      this.downloadCsvByColumns();

    } else if (url && url?.indexOf('/') === -1) {
      this.subscriptions.push(this.downloadFile(url).subscribe(file => {
        if (!file || file instanceof HttpErrorResponse) { return; }
        this.download(file);
      }));

    } else {
      const a = document.createElement('a');
      a.setAttribute('download', `${this.config.fileName}`);
      a.href = url;
      a.target = '_blank';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.parentElement.removeChild(a);
    }

    // Update complete state if it isn't already complete
    // If being used in the instructor portal (or locally), don't set isComplete state
    if (!this.locationService.isInstructorPortal) { this.setIsComplete(true); }

  }

  private download(file: Blob): void {

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result + '';
      const a = document.createElement('a');
      a.setAttribute('download', `${this.config.fileName}`);
      a.href = 'data:text/plain;charset=utf-8;base64,' + base64data.split('base64,')[1];
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.parentElement.removeChild(a);
    };

  }

  private downloadFileById(): void {
    this.subscriptions.push(this.downloadFile(this.documentId, this.containerName).subscribe(file => {
      if (!file || file instanceof HttpErrorResponse) { return; }
      this.download(file);
    }));
  }

  private downloadShuffledCsv(): void {
    const sourceFiles = this.config.sourceFiles.join();
    const sourceFilesNoShuffle = this.config.sourceFilesNoShuffle ? this.config.sourceFilesNoShuffle.join() : '';
    const persist = !this.shuffleId;

    this.subscriptions.push(this.downloadShuffledCsvFromService(
      sourceFiles,
      persist,
      this.config.fileName,
      this.elementId,
      sourceFilesNoShuffle,
      this.shuffleId)
      .subscribe(file => {
        if (!file || file instanceof HttpErrorResponse) { return; }
        this.download(file);
      }));
  }

  private downloadCsvByColumns(): void {
    const sourceFile = this.config.sourceFiles && this.config.sourceFiles[0] ? this.config.sourceFiles[0] : null;
    const columns = this.config.columns ? encodeURI(this.config.columns.join()) : null;
    if (!sourceFile || !columns) { return; }

    this.subscriptions.push(this.downloadCsvByColumnsFromService(sourceFile, columns, this.config.fileName).subscribe(file => {
      if (!file || file instanceof HttpErrorResponse) { return; }
      this.download(file);
    }));
  }

  protected isCompleteChanged(isComplete: boolean): void {
    // tslint:disable-next-line: no-unused-expression
    this.changeRef.detectChanges;
  }

  /**
   * This function is used to detect the element state change when the Result Panel is opened.
   * One of the examples where this is needed is the Digital Analytics sim (SIM-DMA).
   * In SIM-DMA, the download element is used inside the Result Template and the element state gets set on the server side
   * at the end of the scorer process. When the Result Panel is opened, we can detect the element state change and
   * set the documentId or shuffleId values saved in the element state.
   */
   protected stateObjectChanged(newState: IDownloadState): void {

    if (newState?.shuffleId) {
      this.shuffleId = newState.shuffleId;
    }

    if (newState?.documentId) {
      this.documentId = newState.documentId;
    }

    this.changeRef.detectChanges();

  }

  /**
   * This function is used to detect the element state change when changing locations (pages).
   * When changing locations (pages), we can detect the element state change
   * and set the documentId or shuffleId values saved in the element state.
   */
  private handleInstanceStateChange(instanceState: any) {
    if (!this.shuffleId && instanceState?.shuffleId) {
      this.shuffleId = instanceState.shuffleId;
    }
    if (!this.documentId && instanceState?.documentId) {
      this.documentId = instanceState.documentId;
    }
  }

  private downloadFile(url: string, containerName?: string) {
    if (containerName) {
      return this.fileService.download(url, containerName);
    }
    return this.fileService.download(url);
  }

  private downloadShuffledCsvFromService(
    fileIdentifiers: string,
    persist: boolean,
    zipName: string,
    eleId: string,
    noShuffle: string,
    existingZipId: string
  ) {
    return this.fileService.downloadShuffledCsv(fileIdentifiers, persist, zipName, eleId, noShuffle, existingZipId);
  }

  private downloadCsvByColumnsFromService(fileIdentifier: string, columns: string, filename = 'data.csv') {
    return this.fileService.downloadCsvByColumns(fileIdentifier, columns, filename);
  }
}
