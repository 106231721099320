import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ShortcutsService } from '@stukent/shortcuts';
import { ICourseProductTemplateModule, ISimulationInstanceElement, SimulationInstanceElementStatus } from '../../models';
import { navigateToPage } from '../../reducers/location/location.actions';
import { LoggerService } from '@stukent/logger';
import { finance, courseProductInstance, selectCurrentContentModule, selectContentTemplate } from '../../reducers/selectors';
import { IBudgetState, runRoundOrIteration } from '../../reducers/root';

export class RunSimulationCustomContent {
  text: string;
  buttonText?: string;
  icon?: string;
  iconState?: string;
}

interface IMissingStateElement {
  id: string;
  type: string;
  name: string;
  moduleId: string;
  pageId: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sk-run-simulation',
  templateUrl: './run-simulation-button.component.html',
  styleUrls: ['./run-simulation-button.component.scss']
})
export class RunSimulationComponent implements OnDestroy, OnInit {

  static type = 'run-simulation';

  @Input() disabled = false;
  @Input() styleOverride;

  showSubmitForScoringModal: boolean;
  runModuleContent: RunSimulationCustomContent;
  isBudgetRound: boolean;

  financeState: Observable<IBudgetState>;

  // Temporary for list of interaction elements that need to be completed
  requiredInteractions: IMissingStateElement[] = [];
  optionalInteractions: IMissingStateElement[] = [];

  incompleteRequiredInteractions: IMissingStateElement[] = [];
  incompleteOptionalInteractions: IMissingStateElement[] = [];

  private moduleInstanceElements: ISimulationInstanceElement<any>[] = [];

  allBudgetAllocated: boolean;
  allTasksComplete: boolean;
  hasIncompleteRequiredTasks: boolean;
  showBudgetWarningMessage = true;

  private currentContentModule: ICourseProductTemplateModule;

  // tracks any subscriptions
  private subscriptions: Subscription[] = [];

  private enableLogging = false;
  private componentName = 'Run Simulation Button';

  constructor(
    private store: Store,
    private logger: LoggerService,
    private change: ChangeDetectorRef,
    private shortcuts: ShortcutsService
  ) {
  }

  ngOnInit(): void {

    // set button and modal customization and the interaction elements that are required or optional
    this.subscriptions.push(this.store.select(selectCurrentContentModule).subscribe(this.setupFromModuleInformation.bind(this)));

    // Get the budget for showing not all budget used
    // This is an observable so that the front end can access it via | aysnc pipe
    this.financeState = this.store.select(finance);

    // Actually subscribe to the finanace state
    this.subscriptions.push(this.financeState.subscribe(this.handleBudgetChanged.bind(this)));

    // Register Key shortcuts
    this.shortcuts.register('meta+Enter', this.toggleModal.bind(this));
    this.shortcuts.register('ctrl+Enter', this.toggleModal.bind(this));

    // Keep an eye on all the interaction elements changing
    this.subscriptions.push(this.store.select(courseProductInstance).subscribe(i => {
      this.moduleInstanceElements = [];
      // Go through each key in the bigObject:
      if (i && i.elements) {
        for (const key of Object.keys(i.elements)) {
          const element = i.elements[key];
          if (element) {
            this.moduleInstanceElements.push(element);
          }
        }
      }
    }));

    this.subscriptions.push(this.store.select(selectContentTemplate).subscribe(t => {
      if (t && Object.keys(t.properties).find(k => k === 'showBudgetWarningMessage')) {
        this.showBudgetWarningMessage = t.properties.showBudgetWarningMessage;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach(s => s.unsubscribe());
  }

  private logInfo(message: string): void {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  private setupFromModuleInformation(contentModule: ICourseProductTemplateModule): void {

    this.currentContentModule = contentModule;

    const moduleProperties = this.currentContentModule?.properties || {};

    this.runModuleContent = {
      buttonText: (moduleProperties.scoreModuleButtonText || 'Run Simulation'),
      text: moduleProperties.scoreModuleBodyText || null,
      icon: moduleProperties.scoreModuleBodyIcon || null,
      iconState: moduleProperties.scoreModuleBodyIconState || 'good'
    };

    this.requiredInteractions = [];
    this.optionalInteractions = [];

    if (this.currentContentModule?.pages) {

      this.currentContentModule.pages.forEach(p => {
        p.elements?.forEach(e => {
          // Check for Required Interactions..
          const potentialItem: IMissingStateElement = {
            id: e.id,
            name: e.incompleteMessage || e.config?.title,
            type: e.type,
            moduleId: this.currentContentModule.id,
            pageId: p.id
          };

          if (e.isRequired?.length > 0) {
            this.requiredInteractions.push(potentialItem);
          } else if (e.isInteraction?.length > 0) {
            this.optionalInteractions.push(potentialItem);
          }
        });
      });


      if (this.currentContentModule?.navigationElements) {
        this.currentContentModule.navigationElements.forEach(n => {
          const partialId = n.id.split('-');
          const potentialItem: IMissingStateElement = {
            id: n.id,
            name: n.incompleteMessage || `${n.type}-${partialId?.shift()}`,
            type: n.type,
            moduleId: this.currentContentModule.id,
            pageId: null
          };

          if (n.isRequired?.length > 0) {
            this.requiredInteractions.push(potentialItem);
          } else if (n.isInteraction?.length > 0) {
            this.optionalInteractions.push(potentialItem);
          }
        });
      }
    }

    this.change.detectChanges();
  }

  private handleBudgetChanged(newBudgetState: IBudgetState): void {

    this.allBudgetAllocated = true;

    if (newBudgetState) {
      if (newBudgetState.budget < 0) {
        this.isBudgetRound = false;
        return;
      } else {
        this.isBudgetRound = true;

        if (newBudgetState.balance > 0) {
          this.allBudgetAllocated = false;
          this.logInfo('All budget is NOT allocated');
        } else {
          this.logInfo('All budget is allocated');
          this.allBudgetAllocated = true;
        }
      }

      this.change.detectChanges();

    }

  }

  private setCompletionInformation(): void {

    // This checks for the optional and required work.
    this.allTasksComplete = true;
    this.hasIncompleteRequiredTasks = false;

    const required = [];
    const optional = [];

    this.requiredInteractions.forEach(ie => {
      // Check for completion of the Required Element id's
      if (this.moduleInstanceElements && this.moduleInstanceElements.length > 0) {
        const foundElement = this.moduleInstanceElements.find(e => e.id === ie.id);

        if (foundElement) {
          if (foundElement.isComplete || foundElement.state?.isComplete ||
            foundElement.status === SimulationInstanceElementStatus.complete) {
            // Complete. Use affirmative instead of falsy
          } else {
            required.push(ie);
          }
        } else {
          // No instance
          required.push(ie);
        }
      } else {
        // No instance
        required.push(ie);
      }

    });

    this.optionalInteractions.forEach(ie => {
      // Check for completion of the id's

      if (this.moduleInstanceElements && this.moduleInstanceElements.length > 0) {
        const foundElement = this.moduleInstanceElements.find(e => e.id === ie.id);

        if (foundElement) {
          if (foundElement.isComplete || foundElement.state?.isComplete) {
            // Complete. Use affirmative instead of falsy
          } else {
            optional.push(ie);
          }
        } else {
          // No instance
          optional.push(ie);
        }
      } else {
        // No instance
        optional.push(ie);
      }

    });

    this.allTasksComplete = !(required.length > 0 || optional.length > 0);
    this.hasIncompleteRequiredTasks = required.length > 0;
    // Use temp arrays to avoid change detection cycles
    this.incompleteRequiredInteractions = required;
    this.incompleteOptionalInteractions = optional;
  }

  navigateToPage(pageId: string): void {

    this.showSubmitForScoringModal = false;
    this.store.dispatch(navigateToPage({ pageId }));

  }

  submitModuleForScoring(): void {
    // The runRoundOrIteration action calls the scoreIteration action after successfully creating
    // the user's simulation "instance" data in blob storage and redis cache.
    this.store.dispatch(runRoundOrIteration({
      scoreType: 'module',
      moduleId: this.currentContentModule.id,
      pageId: null,
      iterationId: null
    }));

    this.showSubmitForScoringModal = false;
  }

  toggleModal(): void {

    if (!this.showSubmitForScoringModal) {
      this.logInfo('Checking for completion state');
      this.setCompletionInformation();
    }

    this.showSubmitForScoringModal = !this.showSubmitForScoringModal;

    this.logInfo(`Modal is showing: ${this.showSubmitForScoringModal}`);

    this.change.detectChanges();

  }

}
