import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseService } from '../base.service';
import { ICourseProductTemplate, LoadingStatus, ICourseProductTemplateModule } from '../../models/course-product-template/course-product.model';
import { catchError } from 'rxjs/operators';
import { LocalStorageService } from '@stukent/cache';
import { BehaviorSubject } from 'rxjs';
import { ISearchResult } from '../../models/course-product-template/search-results';
import { ILocation } from '../../models/simulation-instance/location.model';
import { ICourseProductTemplatePage } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class CourseProductTemplateService {

  private readonly locatStorageKeyTemplate = 'courseProductTemplate-';

  private initialState: ICourseProductTemplate = {
    courseCode: '',
    timezone: '',
    scoreFormat: '',
    modules: [],
    productCode: '',
    version: '',
    loadingState: {
      status: LoadingStatus.notLoaded
    }
  };

  public overrideBaseUrl: string;

  // Use a behavior subject to control the client subscriptions
  courseProductTemplate$: BehaviorSubject<ICourseProductTemplate> = new BehaviorSubject<ICourseProductTemplate>(this.initialState);

  // Search term is the key to this dictionary of Search Results
  public emptySearchResults: ISearchResult = { term: null, modules: [] };

  searchResults$: BehaviorSubject<ISearchResult> = new BehaviorSubject<ISearchResult>(this.emptySearchResults);

  searchResultHistory$: { [key: string]: ISearchResult; } = {};

  constructor(
    private baseService: BaseService,
    private cacheService: LocalStorageService
  ) {
  }

  getBaseUrl() {
    if (this.baseService.config.isInMemory) {
      return 'api/content';
    }

    if (this.overrideBaseUrl) {
      return this.overrideBaseUrl;
    } else {
      return this.baseService.appConfig.serviceUrls.courseProductTemplateBase;
    }

  }

  get(): Observable<ICourseProductTemplate> {
    const baseUrl = this.getBaseUrl();
    const { courseCode, productCode } = this.baseService.courseProduct;

    let endpoint = `${baseUrl}/${productCode}`;

    if (!this.baseService.config.isInMemory) {
      endpoint += `/${courseCode}`;
    }

    if (this.baseService.useLocalStore) {
      // See if the template is in local storage and return it if it is.
      // TODO put a versioning call here to invalidate the template.

      // Until we have a versioning, I'm going to load, then update
      const localTemplateString = this.cacheService.get(`${this.locatStorageKeyTemplate}${courseCode}-${productCode}`);
      if (localTemplateString) {
        const localTemplate: ICourseProductTemplate = JSON.parse(localTemplateString);

        localTemplate.loadingState = {
          status: LoadingStatus.loaded,
          source: 'LocalStorage'
        };

        this.courseProductTemplate$.next(localTemplate);
      }
    }

    this.baseService.http.get<ICourseProductTemplate>(endpoint).subscribe(resp => {

      const result = resp;

      // Mark it as Preview if it matches the preview course code
      resp.isPreview = resp.courseCode === this.baseService.config.previewCourseCode;

      if (this.baseService.config.isInMemory && !this.baseService.config.isTest) {
        this.baseService.logger.info('fetched course product template', result);
      }

      if (this.baseService.useLocalStore && result.loadingState?.status !== LoadingStatus.error) {
        // Put the template is in local storage.
        // A week
        const expiresInHours = 24 * 7;
        const cacheItemName = `${this.locatStorageKeyTemplate}${courseCode}-${productCode}`;
        this.cacheService.save(cacheItemName, JSON.stringify(result), { expiresInHours });
      }

      if (!resp.loadingState) {
        resp.loadingState = {
          status: LoadingStatus.loaded,
        };
      }
      resp.loadingState.source = 'API';

      this.courseProductTemplate$.next(result);

    });

    return this.courseProductTemplate$;
  }

  getByCodes(courseCode: string, productCode: string): Observable<ICourseProductTemplate> {
    const baseUrl = this.getBaseUrl();

    let endpoint = `${baseUrl}/${productCode}`;

    if (!this.baseService.config.isInMemory) {
      endpoint += `/${courseCode}`;
    }

    if (this.baseService.useLocalStore) {
      // See if the template is in local storage and return it if it is.
      // TODO put a versioning call here to invalidate the template.

      // Until we have a versioning, I'm going to load, then update
      const localTemplateString = this.cacheService.get(`${this.locatStorageKeyTemplate}${courseCode}-${productCode}`);
      if (localTemplateString) {
        const localTemplate: ICourseProductTemplate = JSON.parse(localTemplateString);

        localTemplate.loadingState = {
          status: LoadingStatus.loaded,
          source: 'LocalStorage'
        };

        this.courseProductTemplate$.next(localTemplate);
      }
    }

    this.baseService.http.get<ICourseProductTemplate>(endpoint).subscribe(resp => {

      const result = resp;
      if (this.baseService.config.isInMemory && !this.baseService.config.isTest) {
        this.baseService.logger.info('fetched course product template', result);
      }

      if (this.baseService.useLocalStore && result.loadingState?.status !== LoadingStatus.error) {
        // Put the template is in local storage.
        // A week
        const expiresInHours = 24 * 7;
        const cacheItemName = `${this.locatStorageKeyTemplate}${courseCode}-${productCode}`;
        this.cacheService.save(cacheItemName, JSON.stringify(result), { expiresInHours });
      }

      if (!resp.loadingState) {
        resp.loadingState = {
          status: LoadingStatus.loaded,
        };
      }
      resp.loadingState.source = 'API';

      this.courseProductTemplate$.next(result);

    });

    return this.courseProductTemplate$;
  }

  getResultsTemplate(): Observable<ICourseProductTemplate> {
    const resultsTemplateUrl = this.baseService.appConfig.serviceUrls.resultsTemplate;
    const { courseCode } = this.baseService.courseProduct;

    return this.baseService.http.get<ICourseProductTemplate>(`${resultsTemplateUrl}/${courseCode}`);
  }

  hasModuleStarted(moduleId: string): Observable<boolean> {
    if (!moduleId) { return of(); }
    const baseUrl = this.getBaseUrl();

    const { courseCode, productCode } = this.baseService.courseProduct;

    const endpoint = `${baseUrl}/course/product/template/modules/${productCode}/${courseCode}/${moduleId}`;

    return this.baseService.http.get<boolean>(endpoint)
      .pipe(
        catchError(err => this.baseService.handleError(err, 'check if the module has started'))
      );
  }

  search(term: string, template: ICourseProductTemplate, currentLocation: ILocation): void {

    this.searchResults$.next(this.emptySearchResults);

    // No good template, term, or location to search
    if (!template || !term || !currentLocation) {
      return;
    }

    const maxPerModule = 10;

    const startTime = performance.now();

    this.baseService.logger.info(`Template Search term changed to: ${term}`);

    const searchRegex = new RegExp(`(${term})`, 'gi');

    // Check if this search occured already...
    if (this.searchResultHistory$[term]) {
      // just return this term...
      this.searchResults$.next(this.searchResultHistory$[term]);
      this.baseService.logger.info(`Retuned results from history for term: ${term}`);
      console.log(`Searching current module took ${performance.now() - startTime} milliseconds`);
      return;
    }

    const tempSearchResults = JSON.parse(JSON.stringify(this.emptySearchResults));

    // Search other Modules
    template.modules.forEach(m => {
      const pages = [];
      let moduleOccurences = 0;

      m.pages.forEach(p => {

        const elements = [];

        let pageOccurences = 0;

        const pageContentElements = p.elements.filter(ce => ce.type === 'content');

        if (pageContentElements?.length > 0) {

          pageContentElements.forEach(ce => {

            const matches = ce.contents.match(searchRegex);

            if (matches?.length > 0) {
              pageOccurences += matches.length;
              moduleOccurences += matches.length;

              // Only add up to the Max Occurences per Module
              if (moduleOccurences <= maxPerModule) {
                elements.push({
                  context: this.getContext(ce.contents, term, searchRegex),
                  linkTo: ce.id,
                  occurrences: matches.length
                });
              }
            }
          });

        }

        if (elements.length > 0) {
          pages.push({
            id: p.id,
            occurrences: pageOccurences,
            name: p.displayName.split(':')[1] || p.name.split(':')[1] || p.name,
            elements
          });
        }
      });

      // If we found elements in the pages
      if (pages.length > 0) {
        // Add this module and page to the results
        tempSearchResults.modules.push({
          id: m.id,
          name: m.displayName.split(':')[0] || m.name.split(':')[0] || m.name,
          occurrences: moduleOccurences,
          pages,
        });
      }
    });

    this.searchResults$.next(tempSearchResults);

    this.searchResultHistory$[term] = tempSearchResults;

    console.log(`Searching current module took ${performance.now() - startTime} milliseconds`);

  }

  private getContext(content: string, searchTerm: string, searchRegex: RegExp): string {

    const LEADING_TEXT_SIZE = 30;
    const TRAILING_TEXT_SIZE = 45;

    const foundIndex = content.search(searchRegex);

    const startIndex = foundIndex - LEADING_TEXT_SIZE;
    const endIndex = foundIndex + TRAILING_TEXT_SIZE;

    const highlighted = content.substring(startIndex, endIndex).replace(
      searchRegex,
      '<span class="highlight">$1</span>',
    );

    const beginEllipsis = startIndex < 0 ? '' : '...';
    const endEllipsis = endIndex > content.length ? '' : '...';

    return `${beginEllipsis}${highlighted}${endEllipsis}`;

  }

  // Basic helpers
  public getNextOrPreviousModule(
    modules: ICourseProductTemplateModule[],
    currentLocation: ILocation,
    previous: boolean = false
  ): ICourseProductTemplateModule {

    if (!modules || modules.length === 0 || !currentLocation) {
      return null;
    }

    const module = modules.find(x => x.id === currentLocation.moduleId);
    if (!module) {
      return null;
    }

    const moduleIndex = modules.indexOf(module);

    if (previous) {
      if (moduleIndex - 1 < 0) {
        return null;
      }

      return modules[moduleIndex - 1];

    } else {
      if (modules.length < moduleIndex + 1) {
        return null;
      }

      return modules[moduleIndex + 1];
    }

  }

  public getNextOrPreviousPage(
    pages: ICourseProductTemplatePage[],
    currentLocation: ILocation,
    previous: boolean = false
  ): ICourseProductTemplatePage {

    if (!pages || pages.length === 0 || !currentLocation) {
      return null;
    }

    const page = pages.find(x => x.id === currentLocation.pageId);
    if (!page) {
      return null;
    }

    const pageIndex = pages.indexOf(page);

    if (previous) {

      if (pageIndex - 1 < 0) {
        return null;
      }

      return pages[pageIndex - 1];

    } else {

      if (pages.length < pageIndex + 1) {
        return null;
      }

      return pages[pageIndex + 1];
    }
  }
}
