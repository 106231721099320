import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, distinctUntilChanged, shareReplay, withLatestFrom, mergeMap } from 'rxjs/operators';
import {
  recalculateBalance,
  setBalance,
  addBalanceAmount,
  subtractBalanceAmount,
  setCredit,
  setInitialGlobalBudget
} from './budget.actions';
import { Action, Store } from '@ngrx/store';
import { setInstanceModuleState } from '../../core/simulation-instance/simulation-instance.actions';
import {
  selectContentTemplate,
  selectCurrentContentModuleId,
  selectCurrentModuleState,
  selectInstanceElements
} from '../../selectors';
import { budget } from './budget.selectors';

@Injectable()
export class BudgetEffects {

  private theBudget = 0;
  private startingRoundCredit = 0;

  constructor(
    private actions$: Actions,
    private store: Store
  ) {
    store.select(budget).pipe(
      map(x => x),
      distinctUntilChanged((a, b) => a === b),
      shareReplay()
    ).subscribe(x => this.theBudget = x);

  }

  $setInitialGlobalBudget = createEffect(() => this.actions$.pipe(
    ofType(setInitialGlobalBudget),
    withLatestFrom(
      this.store.select(selectCurrentContentModuleId),
      this.store.select(selectCurrentModuleState),
      this.store.select(selectContentTemplate)
    ),
    mergeMap(([{ newBudget, newBalance, newCredit }, currentModuleId, currentModuleState, cpt]) => {

      // When we set the initial budget, we should also save that data to module state for global budget if applicable
      if (cpt.properties?.useGlobalBudget && currentModuleId) {
        // While the current credit balance changes, the initial starting credit should only be updated when the module changes
        if (currentModuleState?.startingRoundCredit) {
          this.startingRoundCredit = currentModuleState.startingRoundCredit;
        }
        else {
          this.startingRoundCredit = newCredit;
        }

        return [setInstanceModuleState({
          moduleId: currentModuleId,
          state: {
            ...currentModuleState,
            GlobalBudget: newBudget,
            GlobalBalance: newBalance,
            GlobalCredit: newCredit,
            startingRoundCredit: this.startingRoundCredit
          }
        })];

      }

      return [];
    })
  ));

  /*
  * Removed setSimulationInstanceWithIds as an action trigger because it is not needed.
  * When the sim loads or refreshes, the location effect fires recalculateBalance anyways.
  * Also, because the way global budget was designed and saves data, we do not want to recalculateBalance on a page refresh.
  */
  $recalculateBalance = createEffect(() => this.actions$.pipe(
    ofType(
      recalculateBalance,
      addBalanceAmount,
      subtractBalanceAmount
    ),
    withLatestFrom(
      this.store.select(selectCurrentContentModuleId),
      this.store.select(selectCurrentModuleState),
      this.store.select(selectInstanceElements),
      this.store.select(selectContentTemplate)
    ),
    mergeMap(([{ }, currentModuleId, currentModuleState, elements, cpt]) => {
      const actions = [];

      // If budget is used, it should not be negative (note the default value for budget is -1 when not in use)
      if (this.theBudget < 0) {
        return actions;
      }

      const currentModuleElements = elements.filter(x => x.moduleId === currentModuleId);

      let finalAmountSpent = 0;

      // This simply adds all the FinalPurchaseAmount properties for elements where "wasPurchased=true"
      // AND
      // Deducts the total from the modules budget
      currentModuleElements.forEach(element => {
        if ((element.state?.wasPurchased && element.state?.finalPurchaseAmount)) {
          finalAmountSpent += element.state.finalPurchaseAmount;
        }
        else if (element.state?.budgetAllocation) {
          finalAmountSpent += element.state.budgetAllocation;
        }
      });

      let newBalance = this.theBudget - finalAmountSpent;
      let creditAmount = this.startingRoundCredit;

      // If we do use credit and the new Balance is negative, the negative balance goes to credit and our normal balance is $0
      if (cpt.properties?.useLineOfCredit && newBalance < 0) {
        creditAmount += Math.abs(newBalance);
        newBalance = 0;
      }

      // call actions to set the new balance and credit
      actions.push(setBalance({ amount: newBalance }));
      actions.push(setCredit({ amount: creditAmount }));

      // If the Sim is using a global budget as well, sets the current module state currentGlobalBalance so it can be persisted
      if (cpt.properties?.useGlobalBudget && currentModuleId) {
        actions.push(setInstanceModuleState({
          moduleId: currentModuleId,
          state: {
            ...currentModuleState,
            GlobalBudget: this.theBudget,
            GlobalBalance: newBalance,
            GlobalCredit: creditAmount
          }
        }));
      }

      return actions;

    })
  ));

}
