import { createReducer, on, Action } from '@ngrx/store';
import {
  setLocation,
  navigateToPage,
  navigateToIteration,
  removeIteration,
  navigateToLocation,
  setPreviousLocation,
  navigateToModule,
  setInitialLocation
} from './location.actions';
import { ILocationState } from './state';

const initialLocationState: ILocationState = {
  location: {
    iterationId: '',
    moduleId: '',
    pageId: '',
    elementId: '',
  },
  isLoading: true,
};

const reducer = createReducer(initialLocationState,
  on(setInitialLocation, (state, { location }) => {

    // There must be a Module and Page Id at least...
    if (!location?.moduleId || !location?.pageId) {
      if (!state.isLoading) {
        return { ...state, isLoading: true };
      } else {
        // Nothing changed
        return state;
      }
    }

    return { ...state, location, isLoading: false };
  }),
  on(setLocation, (state, { location }) => {

    // There must be a Module and Page Id at least...
    if (!location?.moduleId || !location?.pageId) {
      if (!state.isLoading) {
        return { ...state, isLoading: true };
      } else {
        // Nothing changed
        return state;
      }
    }

    // Don't allow Set Location to override iteration
    const newLocation = { ...location };
    newLocation.iterationId = state.location.iterationId;
    return { ...state, location: newLocation, isLoading: false };
  }),
  on(navigateToLocation, (state, { location }) => {
    return { ...state, location };
  }),
  on(navigateToModule, (state, { moduleId }) => {
    return { ...state, location: { ...state.location, moduleId } };
  }),
  on(navigateToPage, (state, { pageId }) => {
    return { ...state, location: { ...state.location, pageId } };
  }),
  on(navigateToIteration, (state, { iterationId }) => {
    return { ...state, location: { ...state.location, iterationId } };
  }),
  on(removeIteration, (state) => {
    return { ...state, location: { ...state.location, iterationId: undefined } };
  }),
  on(setPreviousLocation, (state) => {
    return { ...state, previous: state.location };
  }),

);

export function locationReducer(location: ILocationState, action: Action) {
  return reducer(location, action);
}
