import {
  Component,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DisplayOnlyElementBaseComponent } from '../../modules/displayOnly-element-base.component';
import { LoggerService } from '@stukent/logger';
import { Store } from '@ngrx/store';

interface IContentConfig {
  markdownUrl?: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'mimic-content-element',
  templateUrl: './content-element.component.html'
})
export class ContentElementComponent
  extends DisplayOnlyElementBaseComponent<IContentConfig> {
  static type = 'content';

  constructor(
    protected logger: LoggerService,
    protected store: Store
  ) {
    super(logger, store);
    this.componentName = 'Static Content';
    this.enableLogging = false;
  }

}
