import { createReducer, on, Action } from '@ngrx/store';
import { setGuides, toggleGuides } from './guides.actions';
import { IGuideState } from './guides.state';

const initialGuidesState: IGuideState = {
  elements: [],
  isOpened: false
};

const reducer = createReducer(initialGuidesState,
  on(setGuides, (state, { guide }) => ({ isOpened: true, elements: [guide, ...state.elements] })),
  on(toggleGuides, (state, { isOpened }) => ({ isOpened, elements: state.elements }))
);

export function guidesReducer(state: any | undefined, action: Action) {
  return reducer(state, action);
}

