import { IGenericState, } from '../models/element-state';
import { mergePartialState, safelyDispatch, cloneState, processUpdaters, } from './utility';
import { ReadableBaseState } from './readable-base.state';

export abstract class WriteableBaseState extends ReadableBaseState {

    updatePartial(s: Partial<IGenericState>): [IGenericState, Error | null] {
        if (this.isMock) {
            return this.updatePartialMocked(s);
        }
        const oldState = cloneState(this.state);
        this.state = mergePartialState(this.state, s);
        const action = this.getStateAction();
        const props = this.stateToProps();

        const error = safelyDispatch(this.store, action, props);

        if (error) {
            // TODO, this needs to be logged.
            return [oldState, error];
        }
        // Immediately run updaters to hydrate the state object
        // this.runUpdaters(oldState, this.state);
        return [this.state, null];
    }

    updatePartialAndWait(s: Partial<IGenericState>): [IGenericState, Error | null] {
        if (this.isMock) {
            return this.updatePartialMocked(s);
        }
        const oldState = cloneState(this.state);
        this.state = mergePartialState(this.state, s);
        const action = this.getStateAction();
        const props = this.stateToProps();
        const error = safelyDispatch(this.store, action, props);
        if (error) {
            return [oldState, error];
        }
        return [this.state, null];
    }

    replaceState(s: IGenericState): [IGenericState, Error | null] {
        if (this.isMock) {
            return this.replaceStateMocked(s);
        }
        const oldState = cloneState(this.state);
        this.state = s;

        const action = this.getStateAction();
        const props = this.stateToProps();
        const error = safelyDispatch(this.store, action, props);
        if (error) {
            return [oldState, error];
        }
        // Immediately run updaters to hydrate the state object
        this.runUpdaters(oldState, this.state);
        return [this.state, null];
    }

    replaceStateAndWait(s: IGenericState): [IGenericState, Error | null] {
        if (this.isMock) {
            return this.replaceStateMocked(s);
        }
        const oldState = cloneState(this.state);
        this.state = s;

        const action = this.getStateAction();
        const props = this.stateToProps();
        const error = safelyDispatch(this.store, action, props);
        if (error) {
            return [oldState, error];
        }
        return [this.state, null];
    }

    getStateAction() {
        throw new Error('getStateAction must be set a private method on a subclass');
    }

    stateToProps() {
        throw new Error('stateToProps must be set a private method on a subclass');
    }

    private updatePartialMocked(s: Partial<IGenericState>): [IGenericState, Error | null] {
        const oldState = cloneState(this.state);
        this.state = mergePartialState(this.state, s);
        processUpdaters({
            updaters: this.updaters,
            oldState,
            newState: this.state,
            stateObject: this.component
        });
        return [this.state, null];
    }

    private replaceStateMocked(s: IGenericState): [IGenericState, Error | null] {
        const oldState = cloneState(this.state);
        this.state = s;
        processUpdaters({
            updaters: this.updaters,
            oldState,
            newState: this.state,
            stateObject: this.component
        });
        return [this.state, null];
    }

}
