import { ICourseProductTemplate, LoadingStatus } from '../../../models/course-product-template/course-product.model';
import { createReducer, Action, on } from '@ngrx/store';
import {
  setCourseProductTemplate,
  setCourseProductTemplateModule,
  courseProductTemplateFailedToLoad,
  loadCourseProductTemplate,
  updateModule,
  setCourseProductTemplateWithoutInstance
} from './course-product-template.actions';

export const initialState: ICourseProductTemplate = {
  courseCode: '',
  timezone: '',
  scoreFormat: '',
  modules: [],
  productCode: '',
  version: '',
  loadingState: {
    status: LoadingStatus.notLoaded
  }
};

const reducer = createReducer(
  initialState,
  on(loadCourseProductTemplate, (state, { productCode }) => {
    return { ...state, productCode, loadingState: { status: LoadingStatus.loading } };
  }),
  on(updateModule, (state, { updatedModule }) => {

    const updatedModules = JSON.parse(JSON.stringify(state.modules));
    const moduleIndex = updatedModules.findIndex(m => m.id === updatedModule.id);
    updatedModules[moduleIndex] = updatedModule;

    if (moduleIndex > -1) {
      return { ...state, modules: updatedModules };
    } else {
      return state;
    }
  }),
  on(setCourseProductTemplate, (state, { template }) => {
    const cloned: ICourseProductTemplate = JSON.parse(JSON.stringify(template));

    let modulesCopy = [];
    for (const mod of cloned.modules) {
      if (mod.id && !modulesCopy.find(m => m.id === mod.id)) {
        modulesCopy = [...modulesCopy, { ...mod }];
      }
    }

    if (modulesCopy.length > 0) {
      cloned.modules = modulesCopy;
    }

    cloned.loadingState = {
      status: LoadingStatus.loaded
    };

    return cloned;
  }),
  on(setCourseProductTemplateWithoutInstance, (state, { template }) => {
    return { ...template, status: LoadingStatus.loaded };
  }),
  on(setCourseProductTemplateModule, (state, { module, moduleIndex }) => {
    const cloned: ICourseProductTemplate = JSON.parse(JSON.stringify(state));

    let modulesCopy = [];
    for (const mod of cloned.modules) {
      if (!modulesCopy.find(m => m.id === mod.id)) {
        modulesCopy = [...modulesCopy, mod];
      }
    }

    if (!modulesCopy.find(m => m.id === module.id)) {
      modulesCopy = [...modulesCopy, { ...module, order: moduleIndex }];
    }

    if (modulesCopy.length > 0) {
      modulesCopy.sort((a, b) => a.order - b.order);
      cloned.modules = modulesCopy;
    }
    return cloned;
  }),
  on(courseProductTemplateFailedToLoad, (state, { template }) => {
    // Just return the template with the error.
    // If we want to do something else, we can
    template = { ...template, modules: [], loadingState: template.loadingState || { status: LoadingStatus.unknown } };
    return template;
  }),


);

export function courseProductTemplateReducer(
  contentState: any | undefined,
  action: Action
) {
  return reducer(contentState, action);
}
