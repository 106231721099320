import { Input, OnInit, OnDestroy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoggerService } from '@stukent/logger';
import { Subscription } from 'rxjs/internal/Subscription';
import { IModuleScore, IScore } from '../models';
import { ICourseProductTemplateElement } from '../models/course-product-template';
import { selectSelectedModuleScores, selectModuleScores } from '../reducers/selectors';

@Component({ template: '' })
export class InsightsElementComponent<T, K> implements OnInit, OnDestroy {
  // This allows the element renderer to inject the configuration
  @Input()
  set ele(ele: ICourseProductTemplateElement<T>) {
    this.ele$ = this.setElement(ele);
    this.elementId = this.ele$.id;
    this.prepareElementConfig();
  }
  get ele(): ICourseProductTemplateElement<T> {
    return this.ele$;
  }

  private ele$: ICourseProductTemplateElement<T> = {} as ICourseProductTemplateElement<T>;
  config: T;
  currentModuleScoreData: IModuleScore<K>;
  selectedScore: IScore<K>;
  results: K;

  // For backwards compatibility
  resultsType: string;

  protected store: Store;
  protected logger: LoggerService;

  // Just exposes the elements Id
  protected elementId: string;
  protected scorerId: string;

  // Allows the developer to forcefully override the module Id they get scores from
  protected moduleIdOfScores: string;

  // tracks any subscriptions
  protected subscriptions: Subscription[];

  // Insights can be faulted and have information
  protected isFaulted: boolean;
  protected faultedReason: string;

  protected enableLogging = false;
  protected componentName = 'Insights Base';

  constructor(
    logger: LoggerService,
    store: Store
  ) {
    return this._constructor.apply(this, arguments);
  }

  /*
  * Ensures the constructor is called (JKW)
  * These methods call, apply and _constructor are required due to our injecetion method
  */
  static call(context, ...args) {
    return InsightsElementComponent.apply(context, args);
  }

  static apply(context, args) {
    return this.prototype._constructor.apply(context, args) || context;
  }

  _constructor(logger: LoggerService, store: Store) {
    // This constructor is used to apply the constructor parameters
    // Since most elements are "injected" into the system, the don't have the angular DI system
    // So, setting these here allows the base to have access to them since the implementing
    // Element has a DI that works correctly (JKW)
    this.logger = logger;
    this.store = store;
    this.subscriptions = [];
  }

  ngOnInit(): void {

    this.logInfo('base initializing');

    // Allow the developer to override both then configuration and the current module Id:
    // But if they didn't, set it from the configuration, or get the current
    if (!this.moduleIdOfScores) {
      this.moduleIdOfScores = (this.config as any).moduleId;
    }

    // Allow the configuration to override the Module Id to show results from
    if (this.moduleIdOfScores) {
      // Show the scores for the overriden Module Id
      this.subscriptions.push(this.store.select(selectModuleScores(this.moduleIdOfScores))
        .subscribe(this.handleScoresChange.bind(this)));

    } else {
      // Show the currently selected Module Id
      this.subscriptions.push(this.store.select(selectSelectedModuleScores)
        .subscribe(this.handleScoresChange.bind(this)));
    }
  }

  ngOnDestroy(): void {
    this.logInfo('destroying subscriptions');

    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  /**
   * Parse out the config object
   * Configuration items should be objects,
   * but if not, parse them
   */
  private prepareElementConfig() {
    if (this.ele$.config && typeof this.ele$.config === 'string') {
      this.config = JSON.parse(this.ele$.config);
    } else {
      this.config = this.ele$.config;
    }

    if (!this.config) {
      this.config = {} as T;
    }
  }

  private setElement(ele: ICourseProductTemplateElement<T>) {
    if (!ele) {
      return {} as ICourseProductTemplateElement<T>;
    }
    if (typeof ele === 'string') {
      return JSON.parse(ele);
    }

    return ele;
  }

  protected logInfo(message: string): void {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  /**
   * This is the handler used in the redux
   * It sets the variables in the component and the calls the overridable methods
   */
  private handleScoresChange(newScoreData: IModuleScore<K>): void {

    this.currentModuleScoreData = newScoreData;

    this.moduleScoresChanged(this.currentModuleScoreData);

    // BACKWARDS COMPATIBLE WITH RESULT TYPE
    // USED WHEN NO SCORERID IS CONFIGURED, BUT THE ELEMENT HAS A RESULTS TYPE
    if (!this.scorerId) {
      // Try insightScorerId or scorerId
      this.scorerId = (this.config as any).insightScorerId || (this.config as any).scorerId;
    }

    if (!this.scorerId) {

      if (!this.resultsType) {
        this.resultsType = (this.config as any).resultType;
      }

      // Get the scorerId from the resultsType
      this.scorerId = this.currentModuleScoreData?.scores.find(s => s.resultType === this.resultsType)?.scorerId;
    }

    if (this.currentModuleScoreData?.scores && this.scorerId) {
      // Get this elements score
      this.selectedScore = this.currentModuleScoreData?.scores.find(s => s.scorerId === this.scorerId);

      this.isFaulted = !!this.selectedScore?.isFaulted;
      this.faultedReason = this.selectedScore?.errorReason;

      this.scoreChanged(this.selectedScore);

      if (this.selectedScore?.result) {
        if (typeof this.selectedScore.result === 'string') {
          this.results = JSON.parse(this.selectedScore.result);
        }
        else {
          this.results = this.selectedScore.result;
        }
        this.resultsChanged(this.results);
      }

    }

  }

  // sets something the control implements
  protected moduleScoresChanged(newScoreData: IModuleScore<K>) { }

  // sets something the control implements
  protected resultsChanged(newResults: K) { }

  // sets something the control implements
  protected scoreChanged(newScore: IScore) { }

}
