import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadingStatus } from '../../models/course-product-template/course-product.model';
import { selectContentTemplate } from '../../reducers/core/selectors';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  themeColors = [];

  constructor(private store: Store) {
    this.store.select(selectContentTemplate).subscribe(template => {
      if (template?.loadingState?.status !== LoadingStatus.loaded) {
        const colors = template?.properties?.themeColors;
        this.themeColors = colors || ['gray'];
      }
    });
  }

  setLogoBackgroundColor() {
    if (this.themeColors.length === 1) {
      return this.themeColors[0];
    } else {
      return `linear-gradient(to right, ${this.themeColors[0]}, ${this.themeColors[1]})`;
    }
  }
}
