import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retryWhen, delay, take } from 'rxjs/operators';
import { BaseService } from '../base.service';
import {
  ISimulationInstance,
  ILocation,
  SimulationInstanceElementStatus,
  ISimulationInstanceElement
} from '../../models/simulation-instance';

@Injectable({
  providedIn: 'root'
})
export class UserProductInstanceService {

  private alreadyLoaded = false;

  constructor(
    private baseService: BaseService
  ) { }

  getUrl() {
    if (!this.baseService.config) {
      this.baseService.appConfig = {

      } as any;
      return '';
    }
    if (this.baseService.config.isInMemory) {
      return 'api/state';
    }
    return this.baseService.appConfig?.serviceUrls?.userProductInstance;
  }

  get(): Observable<ISimulationInstance> {
    let url = this.getUrl();
    // Try if it is null...
    if (url?.length < 1) {
      url = this.getUrl();
    }

    // Warn if still null and return
    if (url?.length < 1) {
      this.baseService.logger.warn('SimulationInstance URL in the AppConfig is not set, returning empty instance');
      return of({ id: null, isLoading: true, location: null, modules: null, pages: null, elements: null });
    }

    if (!this.baseService.config.isInMemory) {
      url = `${url}/template/${this.baseService.courseProduct.productCode}/${this.baseService.courseProduct.courseCode}`;
    }

    return this.baseService.http.get<ISimulationInstance>(url)
      .pipe(
        // TODO: Remove. Temporary so we can release to production. This should allow 2 failures, then throw
        retryWhen(errors => errors.pipe(delay(100), take(2))),
        catchError(err => this.baseService.handleError(err, 'get your progress', true))
      );
  }

  getStudentInstance(
    studentIdentifier: string,
    productCode: string,
    courseCode: string,
    urlOverride: string
  ): Observable<ISimulationInstance> {
    let url = this.baseService.appConfig?.serviceUrls?.instanceManagement;

    if (urlOverride?.length > 0) {
      url = urlOverride;
    }

    // TODO: This url is incorrect. Is this being used? It should be {url}/instance/{productCode}/{courseCode}/{studentIdentifier}
    return this.baseService.http.get<ISimulationInstance>(`${url}/${productCode}/${courseCode}/${studentIdentifier}`);
  }

  getStudentInstanceElement(
    elementId: string
  ): Observable<ISimulationInstanceElement<any>> {
    // Intentionally not using getUrl() here because we want this to always get the most recent data from the API

    const { productCode, courseCode } = this.baseService.courseProduct;
    const studentIdentifier = this.baseService.profile.email;

    const url = this.baseService.appConfig?.serviceUrls?.userProductInstance;
    return this.baseService.http.get<ISimulationInstanceElement<any>>(`${url}/element/${productCode}/${courseCode}/${studentIdentifier}/${elementId}`);
  }

  public changeLocation(location: ILocation): void {
    if (this.baseService.config.isInMemory) {
      return; // new Observable(observer => observer.next());
    }

    const url = `${this.getUrl()}/location/${this.baseService.courseProduct.productCode}/${this.baseService.courseProduct.courseCode}`;

    this.baseService.http.post<ILocation>(url, location)
      .pipe(
        catchError(err => this.baseService.handleError(err, 'save your new location'))
      ).subscribe();

    // return new Observable(observer => observer.next());
  }

  onLoad(): Observable<boolean> {
    if (this.alreadyLoaded) {
      return of(true);
    }

    this.alreadyLoaded = true;

    if (this.baseService.config.isTest) {
      return of(true);
    }

    const url = `${this.getUrl()}/load/${this.baseService.courseProduct.productCode}/${this.baseService.courseProduct.courseCode}`;
    return this.baseService.http.post(url, {})
      .pipe(
        catchError(err => {
          this.baseService.handleError(err, 'requesting to load your simulation.'); return of(false);
        }),
        map(() => true)
      );
  }

  setState(type: string, id: string, state: { [key: string]: any; }) {
    if (!type || !id) {
      return throwError('Missing type or id');
    }

    const url = `${this.getUrl()}/${this.baseService.courseProduct.productCode}/${this.baseService.courseProduct.courseCode}/state/${type}`;
    return this.baseService.http.put(url, {
      id,
      state
    })
      .pipe(
        catchError(err => this.baseService.handleError(err, 'save the information you entered'))
      );
  }

  setStatus(type: string, id: string, status: SimulationInstanceElementStatus) {
    if (!type || !id) {
      return throwError('Missing type or id');
    }

    const url = `${this.getUrl()}/element/${this.baseService.courseProduct.productCode}/${this.baseService.courseProduct.courseCode}/${id}/status`;
    return this.baseService.http.put(url, {
      id,
      status
    })
      .pipe(
        catchError(err => this.baseService.handleError(err, 'update element status'))
      );
  }

  setInstanceElementCompletion(elementId: string, isComplete: boolean) {
    const url = `${this.getUrl()}/element/${this.baseService.courseProduct.productCode}/${this.baseService.courseProduct.courseCode}/${elementId}?completed=${isComplete}`;
    return this.baseService.http.put(url, {})
      .pipe(
        catchError(err => this.baseService.handleError(err, 'update the completion status of an element'))
      );
  }

  setInstanceIterationCompletion(iterationId: string, isComplete: boolean) {
    const url = `${this.getUrl()}/page/${this.baseService.courseProduct.productCode}/${this.baseService.courseProduct.courseCode}/${iterationId}?completed=${isComplete}`;
    return this.baseService.http.put(url, {})
      .pipe(
        catchError(err => this.baseService.handleError(err, 'update the completion status of an element'))
      );
  }

  startRoundTimer(moduleId: string) {
    const url = `${this.getUrl()}/timer/${this.baseService.courseProduct.productCode}/${this.baseService.courseProduct.courseCode}/${moduleId}`;
    return this.baseService.http.post(url, {})
      .pipe(
        catchError(err => this.baseService.handleError(err, 'start a timer for this round'))
      );
  }

  startElementTimer(elementId: string, moduleId: string, questionBankId: string, scorerId: string) {
    const { courseCode, productCode } = this.baseService.course;
    const url = `${this.getUrl()}/timer/${productCode}/${courseCode}/${moduleId}/${elementId}/${questionBankId}`;

    const body = {
      scorerId
    };

    return this.baseService.http.post(url, body)
      .pipe(
        catchError(err => this.baseService.handleError(err, `start a timer for element ${elementId}`))
      );
  }

  saveSimulationInstance(instance: ISimulationInstance, moduleId: string, iterationId: string) {
    if (!instance) {
      return throwError('Missing simulation instance');
    }

    // The baseService.appConfig.serviceUrls.scoring may have the productCode appened to the end.
    // If so, we simply remove the product code to create a valid scoring API base url.
    const productCodePath = `/${this.baseService.courseProduct.productCode}`;
    const scoringServiceUrl = this.baseService.appConfig.serviceUrls.scoring.endsWith(productCodePath)
      ? this.baseService.appConfig.serviceUrls.scoring.slice(0, -(productCodePath.length))
      : this.baseService.appConfig.serviceUrls.scoring;

    const url = iterationId
      ? `${scoringServiceUrl}/${instance.id}/${moduleId}/${iterationId}`
      : `${scoringServiceUrl}/${instance.id}/${moduleId}`;

    return this.baseService.http.post(url, { instance })
    .pipe(
      catchError(err => this.baseService.handleError(err, 'save the simulation instance'))
    );
  }
}
