/**
 * Allowed Modules
 */
import * as AngularCore from '@angular/core';
import * as AngularCommon from '@angular/common';
import * as AngularRouter from '@angular/router';
import * as BrowserModule from '@angular/platform-browser';
import * as NgZorroAntdModule from 'ng-zorro-antd';
import * as NgrxStore from '@ngrx/store';
import * as EffectsModule from '@ngrx/effects';
import * as FormsModule from '@angular/forms';
import * as RxJsModule from 'rxjs';
import * as RxJsOperators from 'rxjs/operators';
import * as MarkdownModule from 'ngx-markdown';
import * as AngularAnimations from '@angular/animations';
import * as AngularCommonHttp from '@angular/common/http';
import * as NgxChartsModule from '@swimlane/ngx-charts';
import * as PdfViewerModule from 'ng2-pdf-viewer';
import * as MimicCoreModules from '../../mimic-core/src/public-api';
import * as UUIDModule from 'uuid';
import * as BrowserAnimationsModule from '@angular/platform-browser/animations';
import * as StukentLoggerModule from '@stukent/logger';
import * as DragDropModule from '@angular/cdk/drag-drop';
import * as NgxQuillModule from 'ngx-quill';
import * as CommonComponents from '@stukent/components';
import * as StukentUserModule from '@stukent/user';
import * as Cache from '@stukent/cache';
import * as FeatureToggleModule from '@stukent/feature-toggle';
import * as StukentMediaModule from '@stukent/media';
import * as StukentRankingModule from '@stukent/ranking';
import * as NgxToastrModule from 'ngx-toastr';
import * as StukentElementRendererModule from '@stukent/elements';

export const allowedModules = {  // this is the list of modules accessible by plugins
  '@angular/core': AngularCore,
  '@angular/common': AngularCommon,
  '@angular/common/http': AngularCommonHttp,
  '@angular/router': AngularRouter,
  '@angular/animations': AngularAnimations,
  '@angular/platform-browser/animations': BrowserAnimationsModule,
  '@angular/platform-browser': BrowserModule,
  '@ngrx/store': NgrxStore,
  '@ngrx/effects': EffectsModule,
  '@angular/forms': FormsModule,
  '@angular/cdk/drag-drop': DragDropModule,
  rxjs: RxJsModule,
  'rxjs/operators': RxJsOperators,
  'ngx-markdown': MarkdownModule,
  '@swimlane/ngx-charts': NgxChartsModule,
  'ng2-pdf-viewer': PdfViewerModule,
  uuid: UUIDModule,
  'ngx-quill': NgxQuillModule,
  'ng-zorro-antd': NgZorroAntdModule,
  'ngx-toastr': NgxToastrModule,
  '@stukent/mimic-core': MimicCoreModules,
  '@stukent/logger': StukentLoggerModule,
  '@stukent/feature-toggle': FeatureToggleModule,
  '@stukent/user': StukentUserModule,
  '@stukent/components': CommonComponents,
  '@stukent/cache': Cache,
  '@stukent/media': StukentMediaModule,
  '@stukent/ranking': StukentRankingModule,
  '@stukent/elements': StukentElementRendererModule,
} as any;

const zorroKeys = Object.keys(NgZorroAntdModule);

for (const key of zorroKeys) {
  if (key.includes('Module')) {
    const mod = key.toLowerCase().replace('nz', '').replace('module', '').trim();
    let modDash = key.replace('Nz', '').replace('Module', '').trim().match(/[A-Z][a-z]+/g);

    if (modDash) {
      modDash = modDash.join('-').toLowerCase() as any;
      allowedModules[`ng-zorro-antd/${modDash}`] = NgZorroAntdModule[key];
    }

    allowedModules[`ng-zorro-antd/${mod}`] = NgZorroAntdModule[key];
  }
}

(window as any).allowedModules = allowedModules;
