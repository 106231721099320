import { Store } from '@ngrx/store';
import { AnyStore } from '../models/element-state';
import { ReadableBaseState } from './readable-base.state';

export class ConfigurationState extends ReadableBaseState {
  config: any;

  constructor(
    public store: Store<AnyStore>,
    public component: object,
    public id?: string,
    public initialConfig?: any
  ) {
    super(store, component, id);
    if (initialConfig) {
      this.config = initialConfig;
    }
    this.prepairConfig();
  }

  prepairConfig() {
    if (!this.config) {
      this.config = {};
      return;
    }

    if (typeof this.config === 'string') {
      this.config = JSON.parse(this.config);
    }
  }

  getRelevantState(root) {
    if (!this.id) {
      return this.getNullState();
    }

    let config = this.getNullState();
    // TODO This can be replaced with a Selector (JKW)

    outmostLoop: for (const module of root.core?.content?.modules ?? []) {
      for (const page of module.pages ?? []) {
        for (const iteration of page.iterations ?? []) {
          for (const element of iteration.element ?? []) {
            if (element.id === this.id) {
              config = element.config;
              break outmostLoop;
            }
          }
        }
        for (const element of page.elements) {
          if (element.id === this.id) {
            config = element.config;
            break outmostLoop;
          }
        }
      }
    }

    if (typeof config === 'string') { config = JSON.parse(config); }

    return config;
  }

  getNullState() {
    return {};
  }

  setInternalState(config: any) {
    this.config = config;
  }
}
