import { createSelector } from '@ngrx/store';
import { IElementInstruction } from '../../models/instructions.model';
import { elementInstructionsFeature } from './element-instructions.reducer';

export const instructions = createSelector(
  elementInstructionsFeature,
  state => state
);

export const instructionsIsOpened = createSelector(
  elementInstructionsFeature,
  state => state.isOpened
);

export const instructionsActiveElementType = createSelector(
  elementInstructionsFeature,
  state => state.activeElementType
);

/*
* @deprecated
* use selectInstructionsElements instead
*/
export const instructionsElements = createSelector(
  elementInstructionsFeature,
  state => state.elements
);

export const selectInstructionsElements = createSelector(
  elementInstructionsFeature,
  state => state.elements
);

export const selectInstructionsElementsByType = (elementType: string) => createSelector(
  elementInstructionsFeature,
  state => state.elements?.find(i => i.elementType === elementType.toLowerCase())
);

export const instructionsActiveElements = createSelector(
  instructionsActiveElementType,
  instructionsElements,
  (activeType: string, elements: IElementInstruction[]) => {
    if (activeType && elements) {
      return elements.find(i => i.elementType === activeType);
    } else {
      return null;
    }
  }
);
