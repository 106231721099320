import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from '@stukent/cache';

enum urlMatchType {
  contains,
  startsWith,
  endsWith
}

interface IURLWhiteList {
  urlPart: string;
  matchType: urlMatchType;
}

@Injectable()
export class ImpersonationInterceptor implements HttpInterceptor {

  token: string;
  urlWhiteList: IURLWhiteList[];

  constructor(
    private cookie: CookieService
  ) {
    // TODO, get the whitelist from somewhere better...
    this.urlWhiteList = [
      { urlPart: 'https://api.stukent.com/config/mimic', matchType: urlMatchType.startsWith },
      { urlPart: 'https://apif.stukent.com/original/config/mimic', matchType: urlMatchType.startsWith },
      { urlPart: '.js', matchType: urlMatchType.endsWith },
      { urlPart: 'elements.json', matchType: urlMatchType.endsWith }
    ];
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.cookie.get('act_as_user_claims') && !this.isInWhitelist(request.url)) {
      request = request.clone({
        withCredentials: true
      });
    }
    return next.handle(request);
  }

  isInWhitelist(url: string): boolean {
    if (this.urlWhiteList && this.urlWhiteList.length > 0) {
      return this.urlWhiteList.find(wl => {
        switch (wl.matchType) {
          case urlMatchType.startsWith:
            // starts with
            return url.startsWith(wl.urlPart);
          case urlMatchType.endsWith:
            // ends with
            return url.endsWith(wl.urlPart);
          case urlMatchType.contains:
            // check contains...
            return url.includes(wl.urlPart);
          default:
            // code block
            return false;
        }
      }) !== undefined;
    } else {
      return false;
    }
  }
}
