import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FileService } from '../../services/file/file.service';
import { Subscription } from 'rxjs';
import { IDownloadConfig } from '../download-element/download-element.component';
import { DisplayOnlyElementBaseComponent } from '../../modules/displayOnly-element-base.component';
import { LoggerService } from '@stukent/logger';
import { Store } from '@ngrx/store';
import { LocationService } from '@stukent/feature-toggle';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'sk-simple-download-element',
  templateUrl: './simple-download-element.component.html',
  styleUrls: ['./simple-download-element.component.scss']
})
export class SimpleDownloadElementComponent
  extends DisplayOnlyElementBaseComponent<IDownloadConfig>
  implements OnInit, OnDestroy {

  static type = 'statelessdownload';

  @Input() disabled = false;
  @Input() overrideUrl: string;
  @Input() isPrimary: boolean;

  private containerName: string = undefined;
  private documentId: string;

  private downloadSubscription: Subscription;

  constructor(
    private changeRef: ChangeDetectorRef,
    protected logger: LoggerService,
    protected store: Store,
    private fileService: FileService,
    private locationService: LocationService
  ) {
    super(logger, store);
    this.componentName = 'Simple Download';
    this.enableLogging = false;
  }

  ngOnInit(): void {

    if (this.config && this.config.containerName) {
      this.containerName = this.locationService.isProductStaging ? `${this.config.containerName}-next` : this.config.containerName;
    }

  }

  ngOnDestroy(): void {
    if (this.downloadSubscription) {
      this.downloadSubscription.unsubscribe();
    }
  }

  openDocument() {
    const url = this.overrideUrl || this.config?.url;

    if (this.documentId) {
      this.downloadFileById();

    } else if (url && url?.indexOf('/') === -1) {
      this.downloadSubscription = this.downloadFile(url).subscribe(file => {
        if (!file || file instanceof HttpErrorResponse) { return; }
        this.download(file);
      });

    } else {
      const a = document.createElement('a');
      a.setAttribute('download', `${this.config.fileName}`);
      a.href = url;
      a.target = '_blank';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.parentElement.removeChild(a);
    }

  }

  private download(file: Blob): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result + '';
      const a = document.createElement('a');
      a.setAttribute('download', `${this.config.fileName}`);
      a.href = 'data:text/plain;charset=utf-8;base64,' + base64data.split('base64,')[1];
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.parentElement.removeChild(a);
    };
  }

  private downloadFileById(): void {
    this.downloadSubscription = this.downloadFile(this.documentId, this.containerName).subscribe(file => {
      if (!file || file instanceof HttpErrorResponse) { return; }
      this.download(file);
    });
  }

  private downloadFile(url: string, containerName?: string) {
    if (containerName) {
      return this.fileService.download(url, containerName);
    }
    return this.fileService.download(url);
  }

}
