import { createReducer, on, Action, ActionReducerMap } from '@ngrx/store';
import { setInitialBudget, setBalance, setCredit, setInitialGlobalBudget } from './budget.actions';
import { IBudgetState } from './state';

const initialBudgetState: IBudgetState = {
  budget: -1, // Use -1 here so that the budget-balance component can show a 0 budget (used when the student can use credit)
  balance: 0,
  credit: 0
};

export interface IFinanceState {
  budget: IBudgetState;
}

export const financeReducers: ActionReducerMap<IFinanceState> = {
  budget: budgetReducer
};

const reducer = createReducer(initialBudgetState,
  on(setInitialBudget, (state, { amount }) => ({ ...state, budget: amount, balance: amount })),
  on(setInitialGlobalBudget, (state, { newBudget, newBalance, newCredit }) => (
    { ...state,
      budget: newBudget,
      balance: newBalance,
      credit: newCredit
    }
    )),
  on(setBalance, (state, { amount }) => ({ ...state, balance: amount })),
  on(setCredit, (state, { amount }) => ({ ...state, credit: amount }))
);

export function budgetReducer(state: IBudgetState, action: Action) {
  return reducer(state, action);
}
