import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { BehaviorSubject } from 'rxjs';
import { LoggerService } from '@stukent/logger';

export interface IStukentProduct {
  productPart: string;
  areaPart: string;
  code: string;
  title: string;
  status?: string;
}

export interface IStukentProductCategory {
  areaPart: string;
  title: string;
  icon?: string;
  products: IStukentProduct[];
}

@Injectable({
  providedIn: 'root'
})
export class StukentProductsService {

  // Use a behaviorSubject for caching
  products$: BehaviorSubject<IStukentProductCategory[]> = new BehaviorSubject<IStukentProductCategory[]>([]);

  private productsLoaded = false;

  constructor(
    private baseService: BaseService,
    private logger: LoggerService
  ) { }

  /**
   * Calls Mimic Products API to return Product information as a IMimicAppProduct array
   *
   * @returns Observable<IMimicAppProduct[]>
   */
  loadProducts() {
    // Check if the products have been loaded.. If so don't do anything...
    if (!this.productsLoaded) {
      this.getProducts();
    } else {
      this.logger.info('Mimic products already loaded');
    }
  }

  getUrl() {
    if (this.baseService.config.isInMemory) {
      return 'api/products';
    }
    return 'https://apif.stukent.com/products/v1/mimic';
  }

  private mapCategoryTitle(areaPart: string): string {
    switch (areaPart) {
      case 'marketing':
        return 'Marketing';
        break;
      case 'supplychain':
        return 'Supply Chain';
        break;
      case 'certification':
        return 'Certifications';
        break;
      case 'sales':
        return 'Sales';
        break;
      case 'business':
        return 'Business';
        break;
    }
  }

  private formatIntoCategories(products: IStukentProduct[]): IStukentProductCategory[] {

    const formattedCategories: IStukentProductCategory[] = [];

    products.forEach(p => {
      let category: IStukentProductCategory = formattedCategories.find(c => c.areaPart === p.areaPart);
      if (category == null) {
        category = {
          title: this.mapCategoryTitle(p.areaPart),
          areaPart: p.areaPart,
          products: []
        };

        formattedCategories.push(category);
      }

      category.products.push(p);

    });

    return formattedCategories;
  }

  private getProducts(): void {
    const url = this.getUrl();
    this.baseService.http
      .get<IStukentProduct[]>(url)
      .subscribe((products) => {
        if (products?.length > 0) {
          this.productsLoaded = true;
          this.products$.next(this.formatIntoCategories(products));
        }
      });
  }
}
