import { IMediaType } from '../../models/posts';

export const postContentMediaTypes: IMediaType[] = [{
  lookupKey: 'advertisement',
  displayValue: 'Advertisement'
},
{
  lookupKey: 'image',
  displayValue: 'Pictures'
},
{
  lookupKey: 'video',
  displayValue: 'Videos'
},
{
  lookupKey: 'article',
  displayValue: 'Blog Posts'
},
{
  lookupKey: 'infographic',
  displayValue: 'Infographic'
},
{
  lookupKey: 'meme',
  displayValue: 'Meme'
},
{
  lookupKey: 'holiday advertisement',
  displayValue: 'Advertisement'
},
{
  lookupKey: 'holiday image',
  displayValue: 'Holiday Image'
}
];
