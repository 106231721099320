import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IMetricAggregation } from '../../models/metrics/metric-aggregation.model';
import { BaseService } from '../base.service';
import { LoggerService } from '@stukent/logger';

interface IModuleMetrics {
    moduleId: string;
    roundMetrics: IMetricAggregation;
}

@Injectable({
    providedIn: 'root'
})
export class AggregationsService {

    constructor(
        private baseService: BaseService,
        private logger: LoggerService
    ) { }

    // Stores aggeregations by round
    public roundAggregations$: BehaviorSubject<IModuleMetrics[]> = new BehaviorSubject<IModuleMetrics[]>([]);

    private roundAggregationsLoaded: IModuleMetrics[] = [];

    getUrl() {
        return this.baseService.appConfig?.serviceUrls?.metricAggregation || '';
    }

    getForPreviousModule(): string {
        const moduleIds = this.baseService.content.modules.map(m => m.id);
        const currentModuleIdx = moduleIds.indexOf(this.baseService.currentModule.id);
        if (currentModuleIdx === 0) {
            return;
        }

        const previousModuleId = moduleIds[currentModuleIdx - 1];

        const previousModuleMetrics = this.roundAggregationsLoaded.find(r => r.moduleId === previousModuleId);

        if (!previousModuleMetrics) {
            this.logger.info(`Loading Metrics for module ${previousModuleId}`);
            this.get(previousModuleId);
        } else {
            this.logger.info(`Metrics for module ${previousModuleId} already loaded`);
        }

        return previousModuleId;

    }

    private get(moduleId: string = null): void {
        const simulationInstanceLocationId = moduleId || this.baseService.currentModule.id;
        const simulationInstanceId = this.baseService.instance.id;

        const url = `${this.getUrl()}/${simulationInstanceId}/${simulationInstanceLocationId}`;

        this.baseService.http.get<IMetricAggregation>(url, {
            headers: {
                // This is not a typo, Report and Aggregation use the same subscription key
                'Ocp-Apim-Subscription-Key': this.baseService.appConfig.metricReportSubscriptionKey
            }
        })
            .pipe(
                tap(a => {
                    if (this.baseService.appConfig?.enableLogging) {
                        this.baseService.logger.info(`fetched metric aggretation`);
                    }
                }),
                catchError(err => this.baseService.handleError(err, `fetch metric aggregation for sim id ${simulationInstanceId} and module id ${simulationInstanceLocationId}`))
            ).subscribe(data => {
                this.roundAggregationsLoaded.push({ moduleId, roundMetrics: data });
                this.roundAggregations$.next(this.roundAggregationsLoaded);
            });
    }
}
