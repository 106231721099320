import { createAction, props } from '@ngrx/store';
import { ISimulationScores, IModuleScore, IScore } from '../../../models/scoring';

const prefix = '[Scoring]';

export const scoreModule = createAction(`${prefix} Score Module`,
  props<{
    moduleId: string,
    dryRun?: boolean,
    preventAdvanceModule?: boolean;
  }>());

export const scoreIteration = createAction(`${prefix} Score Iteration`,
  props<{
    moduleId: string,
    iterationId: string,
    pageId: string,
    dryRun?: boolean;
  }>());

export const scoreItem = createAction(`${prefix} Score Individual Item`,
  props<{
    simulationInstanceId: string,
    moduleId: string,
    scorerId: string;
  }>());

export const showExistingScores = createAction(`${prefix} Show Existing Scores`,
  props<{ showExistingScores: boolean; }>());

export const loadScores = createAction(`${prefix} Load Scores`);

export const setScores = createAction(`${prefix} Set Scores`,
  props<{ scores: ISimulationScores; }>());

export const setScoreLoading = createAction(`${prefix} Set Loading`,
  props<{ isLoading: boolean; showResultsModal: boolean }>());

export const setModuleScore = createAction(`${prefix} Set Module Score`,
  props<{ score: IScore, moduleId: string; }>());

export const setIterationScore = createAction(`${prefix} Set Iteration Score`,
  props<{ score: IScore, iterationId: string; }>());

export const setRoundScore = createAction(`${prefix} Set Round Adjusted Score`,
  props<{ score: number, moduleId: string; }>());

export const xincRoundScore = createAction(`${prefix} Inc Round Adjusted Score`,
  props<{ score: number, moduleId: string; }>());

export const setFullScore = createAction(`${prefix} Set Full Score`,
  props<{ score: IModuleScore<any>; }>());

export const setInstanceScore = createAction(`${prefix} Set Instance Score`,
  props<{ score: ISimulationScores; }>());

export const setExpectedScores = createAction(`${prefix} Set Expected Scores`,
  props<{ moduleId: string; scoringPasses: any }>());

export const setSelectedModuleId = createAction(`${prefix} Set Selected Module Id`,
  props<{ moduleId: string; }>());

export const setScorersProgress = createAction(`${prefix} Set Scorers Progress`,
  props<{
    scorerId: string;
    isComplete: boolean;
    moduleId: string;
  }>());

export const setScoreProgressMessage = createAction(`${prefix} Set Score Progress Message`,
  props<{ message: string; }>());

export const setErroredScorer = createAction(`${prefix} Set Errored Scorer`,
  props<{
    displayName: string;
    scorerId: string;
    scorerType: string;
    errorMessage: string;
  }>());

export const setScoreCompleted = createAction(`${prefix} Completed`,
  props<{ moduleId: string; }>());
