import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IBuhiProduct } from '../models';
import { LoggerService } from '@stukent/logger';
import { IFeature } from '../models/features';
import { BUHIConfigurationService } from './configuration.service';
@Injectable({
  providedIn: 'root',
})
export class BUHIProductsService {
  private urlRoot;
  private headers: HttpHeaders;

  // Use a behaviorSubject for caching
  products$: BehaviorSubject<IBuhiProduct[]> = new BehaviorSubject<
    IBuhiProduct[]
  >([]);

  private productsLoaded = false;

  // Use a behaviorSubject for caching
  // THis should likely be a dictionary of subjects so that each "productName" can have it's own features
  productFeatures$: BehaviorSubject<IFeature[]> = new BehaviorSubject<
    IFeature[]
  >([]);

  private productFeaturesLoaded = false;

  private enableLogging = false;
  private componentName = 'Products Service';

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) { }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  private getProducts(): void {

    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }

    const url = `${this.urlRoot}/products`;
    this.http
      .get<IBuhiProduct[]>(url, { headers: this.headers })
      .pipe(catchError(this.handleError))
      .subscribe((products) => {
        if (products?.length > 0) {
          this.productsLoaded = true;
          this.products$.next(products);
        }
      });
  }

  /**
   * Calls Buhi Ecosystem API to return Product information as a IBuhiProduct array
   *
   * @returns Observable<IBuhiProduct[]>
   */
  loadProducts() {
    // Check if the Products have been loaded.. If so don't do anything...
    if (!this.productsLoaded) {
      this.getProducts();
    } else {
      this.logInfo(`products already loaded`);
    }
  }

  // featuresFile is the json file name to read in
  private getProductFeatures(productName: string): void {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }

    const url = `${this.urlRoot}/product/features/${productName}`;

    this.http
      .get<IFeature[]>(url, { headers: this.headers })
      .pipe(catchError(this.handleError)).subscribe(features => {
        if (features?.length > 0) {
          this.productFeaturesLoaded = true;
          this.productFeatures$.next(features);
        }
      });
  }

  // featuresFile is the json file name to read in
  loadProductFeatures(productName: string = 'features') {
    // Check if the Products have been loaded.. If so don't do anything...
    if (!this.productFeaturesLoaded) {
      this.getProductFeatures(productName);
    } else {
      this.logInfo(`product features for ${productName} already loaded`);
    }
  }

  private handleError(error: Error) {
    return throwError(
      `Failed calling Product or Product Features Data service: ${error.message}`
    );
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
