import { Component, OnInit, Pipe, PipeTransform, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { IVideoConfig } from '../../models/course-product-template/element.model';
import { Store } from '@ngrx/store';
import { DomSanitizer } from '@angular/platform-browser';
import { DisplayOnlyElementBaseComponent } from '../../modules/displayOnly-element-base.component';
import { LoggerService } from '@stukent/logger';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-video-element',
  templateUrl: './video-element.component.html',
  styleUrls: ['./video-element.component.scss']
})
export class VideoElementComponent
  extends DisplayOnlyElementBaseComponent<IVideoConfig>
  implements OnInit {

  static type = 'video';

  youtubeSrc: string;

  constructor(
    protected logger: LoggerService,
    protected store: Store) {
    super(logger, store);
  }

  ngOnInit(): void {
    if (this.config && this.config.youtube) {
      this.youtubeSrc = `https://www.youtube.com/embed/${this.config.youtube.embedCode}`;
    }
  }
}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
