import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { MimicAppComponent } from './mimic-app.component';
import { StoreModule } from '@ngrx/store';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, CommonModule } from '@angular/common';
import en from '@angular/common/locales/en';
import {
  MimicCoreModule,
  ContentElementComponent,
  ImageElementComponent,
  VideoElementComponent,
  DownloadElementComponent,
  SimpleDownloadElementComponent,
  BUHICompetitorDataService,
  BUHIDisplayAdImageService,
  BUHISocialMediaChannelService,
  BUHISocialMediaMarketDataService,
  BUHISearchKeywordsService,
  BUHIProductsService,
  BUHIScenarioOverviewService,
  BUHIProductGroupingService,
  JwtInterceptor,
  ImpersonationInterceptor,
  Unauthorizednterceptor,
  IgnoreInterceptor,
  InternetInterceptor,
  CallbackComponent,
  ZendeskService,
  AggregationsService,
  BuhiSponsorDataService,
  BUHISitemapService,
  BUHIRetailersService,
  BuhiRawDataService,
  BUHIProductOptionsService,
  BUHISocialMediaPostContentService,
  BuhiManufacturerDataService,
  BuhiInfluencerDataService,
  BUHICoreValuesService,
  BUHIConfigurationService,
  BUHIAudienceOptionsService,
  MetricsService,
  PPCHelperService,
  PdfViewerElementComponent
} from '../../mimic-core/src/public-api';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { allowedModules } from './available-modules';
import { LoggerModule } from '@stukent/logger';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MediaModule } from '@stukent/media';
import { ToastrModule } from 'ngx-toastr';
import { RankingModule } from '@stukent/ranking';
import { ShortcutsModule } from '@stukent/shortcuts';
import { CacheModule } from '@stukent/cache';
import { ElementRendererModule } from '@stukent/elements';
import { IconModule } from '@ant-design/icons-angular';
import { RouterModule } from '@angular/router';
import { MimicBaseComponent } from './components/mimic-base/mimic-base.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { ResultsSelectionComponent } from './components/side-bar/results-selection/results-selection.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { ModuleNavigationComponent } from './components/side-bar/round-navigation/round-navigation.component';
import { RoundInformationComponent } from './components/side-bar/round-info/round-info.component';
import { ProductInformationComponent } from './components/side-bar/product-information/product-information.component';
import { ProductsDrawerComponent } from './components/general/products-drawer/products-drawer.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import {NzSkeletonModule} from 'ng-zorro-antd';

registerLocaleData(en);

@NgModule({
  declarations: [
    MimicAppComponent,
    MimicBaseComponent,
    SideBarComponent,
    ResultsSelectionComponent,
    ModuleNavigationComponent,
    RoundInformationComponent,
    ProductInformationComponent,
    ProductsDrawerComponent,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CommonModule,
        StoreModule.forRoot([], {
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true
            }
        }),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            name: 'Mimic App',
            features: {
                dispatch: true,
                pause: false,
                skip: false,
                test: false,
                export: false,
                jump: false,
            },
            maxAge: 30,
            logOnly: environment.isProduction,

        }),
        ElementRendererModule,
        MimicCoreModule.forRoot({
            platform: 'mimic',
            homeUrl: '',
            isTest: environment.isTest,
            isProduction: environment.isProduction,
            isNext: environment.isNext,
            isInMemory: false,
            configKey: environment.configKey,
            elements: [
                ContentElementComponent,
                ImageElementComponent,
                PdfViewerElementComponent,
                VideoElementComponent,
                DownloadElementComponent,
                SimpleDownloadElementComponent,
            ],
            allowedModules,
            enableAnnotations: false,
        }),
        RankingModule,
        CacheModule.forRoot({
            emptyIfFull: true
        }),
        ShortcutsModule,
        ToastrModule.forRoot({
            closeButton: true,
            preventDuplicates: true,
            includeTitleDuplicates: true,
            enableHtml: true,
            disableTimeOut: true,
            positionClass: 'toast-top-full-width',
            iconClasses: {
                error: 'toast-error',
                info: 'toast-info',
                success: 'toast-success',
                warning: 'toast-warning',
            }
        }),
        IconModule,
        BrowserAnimationsModule,
        MediaModule,
        NzDropDownModule,
        NzPopoverModule,
        NzDrawerModule,
        NzCollapseModule,
        // If test, ignore logging
        // Production / next, app insights
        // Development console
        LoggerModule.forRoot({
            loggerTypes: environment.isTest ? ['ignore'] : (
                environment.isDevelopment || environment.isNext ? ['console'] : ['application insights', 'console']
            )
        }),

        // Routing
        RouterModule.forChild([
            {
                path: ':productCode/:courseCode',
                pathMatch: 'full',
                component: MimicBaseComponent
            },
            {
                path: ':area/:topic/:courseCode',
                pathMatch: 'full',
                component: MimicBaseComponent
            },
            {
                path: 'callback',
                pathMatch: 'full',
                component: CallbackComponent
            }
        ]),
        NzSkeletonModule,
    ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    BUHICompetitorDataService,
    BUHIDisplayAdImageService,
    BUHISocialMediaChannelService,
    BUHISocialMediaMarketDataService,
    BUHISocialMediaPostContentService,
    BuhiSponsorDataService,
    BuhiManufacturerDataService,
    BuhiInfluencerDataService,
    BUHICoreValuesService,
    BUHIConfigurationService,
    BUHIAudienceOptionsService,
    BUHISearchKeywordsService,
    BUHIProductsService,
    BUHIScenarioOverviewService,
    BUHIProductGroupingService,
    BUHIProductOptionsService,
    BuhiSponsorDataService,
    BUHISitemapService,
    BUHIRetailersService,
    BuhiRawDataService,
    ZendeskService,
    AggregationsService,
    MetricsService,
    PPCHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IgnoreInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InternetInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Unauthorizednterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ImpersonationInterceptor,
      multi: true
    }
  ],
  bootstrap: [MimicAppComponent],
})
export class AppModule { }
