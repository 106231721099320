import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { IAnnotation, IMyAnnotations } from '../../models/annotations.model';
import { Store } from '@ngrx/store';
import { editAnnotation, deleteAnnotation, removeTempAnnotation } from '../../reducers/annotation/annotation.actions';

@Injectable({
  providedIn: 'root'
})
export class AnnotationsService {

  constructor(
    private store: Store,
    private baseService: BaseService
  ) { }

  loadAnnotations() {
    const url = this.baseService.appConfig.serviceUrls.annotation;
    return this.baseService.http.get<IMyAnnotations>(`${url}`);
  }

  addAnnotation(annotation: IAnnotation) {
    const url = this.baseService.appConfig.serviceUrls.annotation;
    return this.baseService.http.post(`${url}/add`, annotation);
  }

  editAnnotation(annotation: IAnnotation, moduleId: string, pageId: string) {
    const url = this.baseService.appConfig.serviceUrls.annotation;
    const editingAnnotation = JSON.parse(JSON.stringify(annotation));

    editingAnnotation.location = {
      moduleId,
      pageId
    };

    return this.baseService.http.post(`${url}/edit`, editingAnnotation);
  }

  deleteAnnotation(moduleId: string, pageId: string, annotationId: string) {
    const url = this.baseService.appConfig.serviceUrls.annotation;
    return this.baseService.http.delete(`${url}/${moduleId}/${pageId}/${annotationId}`);
  }

  edit(annotation: IAnnotation, moduleId: string, pageId: string) {
    this.store.dispatch(editAnnotation({
      annotation,
      moduleId,
      pageId
    }));
  }

  delete(moduleId: string, pageId: string, annotation: IAnnotation) {
    this.removeHighlight(annotation);
    this.store.dispatch(deleteAnnotation({
      moduleId,
      pageId,
      annotationId: annotation.id
    }));
  }

  private removeHighlight(annotation) {
    const startingContentElement = document.getElementById(annotation.startElementId);
    if (!startingContentElement) {
      return;
    }

    const startElementWords = startingContentElement.querySelectorAll('.word');
    startElementWords.forEach((startEle, idx) => {
      if (idx < annotation.startIndex) {
        return;
      }

      // If the current word is PAST the current element or in another one, exit
      // TODO - Figure out what this really does (JKW)
      if (annotation.startElementId === annotation.endElementId && idx > annotation.endIndex) {
        return;
      }

      // remove any annotation classes existing
      startEle.classList.remove('red', 'orange', 'purple', 'green', 'yellow', 'blue', 'annotated');
    });
  }

}
