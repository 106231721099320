import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { MimicCoreConfig } from '../config';
import { LoggerService } from '@stukent/logger';

@Injectable()
export class IgnoreInterceptor implements HttpInterceptor {

  token: string;

  constructor(
    private config: MimicCoreConfig,
    private logger: LoggerService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.config.allowHttpCallsInTest && (this.config.isTest || this.config.isInMemory)) {
      this.logger.info('Ignoring route ' + request.url);
      return EMPTY;
    }
    return next.handle(request);
  }
}
