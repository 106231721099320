import { Injectable } from '@angular/core';
import { ISocialMediaChannel } from '../models/common';
import { InjectionToken } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LoggerService } from '@stukent/logger';
import { BUHIConfigurationService } from './configuration.service';

export declare interface IBUHISocialMediaChannelService {
  channels$: BehaviorSubject<ISocialMediaChannel[]>;
  init();
  loadChannels(): void;
}

export const BuhiSocialMediaChannels: InjectionToken<IBUHISocialMediaChannelService> = new InjectionToken<IBUHISocialMediaChannelService>('IBUHISocialMediaChannelService');

@Injectable({
  providedIn: 'root'
})
export class BUHISocialMediaChannelService {

  private urlRoot: string;
  private headers: HttpHeaders;


  // Use a behaviorSubject for caching
  channels$: BehaviorSubject<ISocialMediaChannel[]> = new BehaviorSubject<ISocialMediaChannel[]>([]);

  private channelsLoaded = false;

  private enableLogging = false;
  private componentName = 'Social Channels Service';

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private BuhiConfigurationService: BUHIConfigurationService
  ) {
  }

  private logInfo(message: string) {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

  private getChannels(): void {
    if (!this.urlRoot || !this.headers) {
      this.getFromConfig();
    }
    this.http.get<ISocialMediaChannel[]>(`${this.urlRoot}/socialmedia/channels`, { headers: this.headers })
      .pipe(
        catchError(this.handleError)
      ).subscribe(channels => {
        if (channels?.length > 0) {
          this.channelsLoaded = true;
          this.channels$.next(channels);
        }
      });
  }

  /**
   * Calls Buhi Ecosystem API to return Social Media Channel information
   * as a ISocialMediaChannels which is a collection of ISocialMediaChannel[]
   */
  public loadChannels() {
    // Check if the keywords have been loaded.. If so don't do anything...
    if (!this.channelsLoaded) {
      this.getChannels();
    } else {
      this.logInfo(`channels already loaded`);
    }
  }

  private handleError(error) {
    return throwError(`Failed calling Social Media Channels Data service: ${error.message}`);
  }

  private getFromConfig() {
    this.urlRoot = this.BuhiConfigurationService.urlRoot;
    this.headers = this.BuhiConfigurationService.headers;
  }
}
