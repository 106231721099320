import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
  mergeMap,
  catchError,
  map,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import {
  setSimulationInstance,
  setInstanceElementState,
  setInstanceElementCompletion,
  setInstanceIterationCompletion,
  setInstancePageState,
  setInstanceModuleState,
  setInstanceState,
  setSimulationInstanceModule,
  loadSimulationInstance,
  startRoundTimer,
  setInstanceElementModuleId,
  setSimulationInstanceWithIds,
  setInstanceElementStatus,
  getStudentSimInstance,
  setStudentSimInstance,
  refreshSimulationState,
  startElementTimer,
  setNextInstanceElementState,
  setDrawerViewed,
  runRoundOrIteration
} from './simulation-instance.actions';
import { UserProductInstanceService } from '../../../services/simulation-instance/simulation-instance.service';
import { setInitialLocation } from '../../location/location.actions';
import { Store } from '@ngrx/store';
import { setCourseProductTemplate, setCourseProductTemplateModule } from '../course-product-template/course-product-template.actions';
import { ISimulationInstance, ISimulationInstanceElement } from '../../../models';
import { recalculateBalance } from '../../finance/budget/budget.actions';
import { loadScores, scoreIteration, scoreModule } from '../../scores/scoring/scoring.actions';
import { LoggerService } from '@stukent/logger';
import { courseProductInstance, selectContentTemplate, selectCurrentContentModuleId } from '../selectors';

@Injectable()
export class SimulationInstanceEffects {

  constructor(
    private actions$: Actions,
    private userProductInstanceService: UserProductInstanceService,
    private logger: LoggerService,
    private store: Store
  ) {
  }

  private isSimulationInstanceSet = false;
  private totalModulesSet = 0;

  private enableLogging = false;
  private componentName = 'Instance Effects';

  $saveSimulationInstance = createEffect(() => this.actions$.pipe(
    ofType(runRoundOrIteration),
    withLatestFrom(this.store.select(courseProductInstance)),
    mergeMap(([{ scoreType, moduleId, pageId, iterationId }, currentInstance]) => {

      this.logger.info(`Scoring ${scoreType} with Instance`);

      if (currentInstance?.location) {
        this.userProductInstanceService.saveSimulationInstance(currentInstance, moduleId, iterationId).subscribe(result => {
          if (result) {
            this.logger.info(`Instance saved successfully`);
            if (scoreType === 'iteration') {
              this.logger.info(`Dispatching scoreIteration action`);
              this.store.dispatch(scoreIteration({ moduleId, iterationId, pageId }));
            } else {
              this.logger.info(`Dispatching scoreModule action`);
              this.store.dispatch(scoreModule({ moduleId }));
            }
          } else {
            return [];
          }
        });
        return [];
      }
      else {
        this.logger.error('There was not a User Product Instance sent into the saveSimulationInstance effect.');
        return [];
      }
    })
  ));

  $setSimulationInstance = createEffect(() => this.actions$.pipe(
    ofType(setSimulationInstance),
    mergeMap(({ instance }) => {

      if (instance?.location) {

        this.isSimulationInstanceSet = true;

        this.logInfo(`Instance Effects: setting location to: ${JSON.stringify(instance.location, null, 2)}`);
        return [
          setInitialLocation({ location: instance.location }),
          loadScores()
        ];
      }
      else {
        this.logger.error('There was not a User Product Instance sent into the setSimulationInstance effect.');
        return [];
      }

    })
  ));

  $setSimulationInstanceIds = createEffect(() => this.actions$.pipe(
    ofType(setCourseProductTemplate, setCourseProductTemplateModule, setSimulationInstance),
    withLatestFrom(this.store.select(courseProductInstance), this.store.select(selectContentTemplate)),
    mergeMap(([{ }, currentInstance, contentTemplate]) => {

      if (currentInstance?.location) {
        const instance = JSON.parse(JSON.stringify(currentInstance)) as ISimulationInstance;

        const actions = [];

        // Add the module and the page ids
        for (const module of contentTemplate?.modules) {
          for (const page of module.pages) {
            if (page.iterations) {
              for (const iteration of page.iterations) {
                for (const element of iteration.elements) {
                  if (instance.elements[element.id]) {
                    instance.elements[element.id].pageId = iteration.id;
                    instance.elements[element.id].moduleId = module.id;
                    instance.elements[element.id].stateChangeId = Math.random() + '';
                  }
                }

                if (instance.pages[iteration.id]) {
                  instance.pages[iteration.id].moduleId = module.id;
                  instance.pages[iteration.id].id = page.id;
                }
              }
            }

            for (const element of page.elements) {
              if (instance.elements[element.id]) {
                instance.elements[element.id].pageId = page.id;
                instance.elements[element.id].moduleId = module.id;
                instance.elements[element.id].stateChangeId = Math.random() + '';
              }
            }

            if (instance.pages[page.id]) {
              instance.pages[page.id].moduleId = module.id;
            }
          }
        }

        actions.push(setSimulationInstanceWithIds({ instance }));

        return actions;

      } else {
        return [];
      }

    })
  ));

  $refreshSimulationState = createEffect(() => this.actions$.pipe(
    ofType(refreshSimulationState),
    mergeMap(({ locationId, locationType }) => {
      // The idea here is that the refreshSimulationState action can refresh an element, page, or module (locationType).
      // Currently we only have need for element so if anything else is called, don't do anything.

      if (locationType === 'element') {
        return this.userProductInstanceService.getStudentInstanceElement(locationId).pipe(
          mergeMap((element: ISimulationInstanceElement<any>) => {
            return [setInstanceElementState({ elementId: locationId, state: element.state })];
          })
        );
      }
      return [];
    })
  ));

  $setSimulationInstanceModule = createEffect(() => this.actions$.pipe(
    ofType(setSimulationInstanceModule),
    mergeMap(() => {
      this.totalModulesSet++;
      if (this.isSimulationInstanceSet || this.totalModulesSet <= 2) {
        return [];
      }

      this.logger.warn('SignalR didn\'t return the [Simulation Instance] Set command, retrieving manually');
      this.isSimulationInstanceSet = true;

      return this.userProductInstanceService.get().pipe(
        mergeMap((instance) => [setSimulationInstance({ instance })])
      );
    })
  ));

  $loadSimulationInstance = createEffect(() => this.actions$.pipe(
    ofType(loadSimulationInstance),
    mergeMap(() => {
      return this.userProductInstanceService.get().pipe(
        mergeMap(instance => {
          this.isSimulationInstanceSet = true;
          return [setSimulationInstance({ instance })];
        })
      );
    })
  ));

  $setElementState = createEffect(() => this.actions$.pipe(
    ofType(setInstanceElementState, setNextInstanceElementState),
    mergeMap(({ elementId, state }) => {

      return this.userProductInstanceService.setState('element', elementId, state)
        .pipe(
          catchError(() => EMPTY)
        );
    })
  ), { dispatch: false });

  // This is incase the module location changes before updating state finishes
  $recalculateBalanceAfterStateChange = createEffect(() => this.actions$.pipe(
    ofType(setNextInstanceElementState),
    switchMap(({ elementId }) => {
      return [
        recalculateBalance()
      ];
    })
  ));

  setElementStatus = createEffect(() => this.actions$.pipe(
    ofType(setInstanceElementStatus),
    mergeMap(({ elementId, status }) => this.userProductInstanceService.setStatus('element', elementId, status)
      .pipe(
        catchError(() => EMPTY)
      ))
  ), { dispatch: false });

  $setElementStateModuleId = createEffect(() => this.actions$.pipe(
    ofType(setInstanceElementState),
    withLatestFrom(this.store.select(selectCurrentContentModuleId)),
    switchMap(([{ elementId }, currentModuleId]) => {

      // TODO WHat is this for??? (JKW)
      return [
        setInstanceElementModuleId({
          elementId,
          moduleId: currentModuleId
        })
      ];
    })
  ));

  $setPageState = createEffect(() => this.actions$.pipe(
    ofType(setInstancePageState),
    mergeMap(({ pageId, state }) =>
      this.userProductInstanceService.setState('page', pageId, state)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  /* TODO Implement
  $setInstancePageStateProperty = createEffect(() => this.actions$.pipe(
    ofType(setInstancePageStateProperty),
    withLatestFrom(this.store.select(selectCurrentPageState)),
    mergeMap(([{ pageId, name, value }, currentpageState]) =>
      [setInstancePageState({ pageId, state: currentpageState})]
    )
  ));
  */

  $setDrawerViewed = createEffect(() => this.actions$.pipe(
    ofType(setDrawerViewed),
    withLatestFrom(this.store.select(courseProductInstance)),
    map(([{ pageId, hasBeenViewed }, instance]) => {
      const pageState = instance?.pages[pageId]?.state;
      return setInstancePageState({ pageId, state: { ...pageState, hasBeenViewed } });
    })
  ));

  $setModuleState = createEffect(() => this.actions$.pipe(
    ofType(setInstanceModuleState),
    mergeMap(({ moduleId, state }) =>
      this.userProductInstanceService.setState('module', moduleId, state)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  $setInstanceState = createEffect(() => this.actions$.pipe(
    ofType(setInstanceState),
    mergeMap(({ instanceId, state }) =>
      this.userProductInstanceService.setState('instance', instanceId || '00000000-0000-0000-0000-000000000000', state)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });


  $setInstanceElementCompletion = createEffect(() => this.actions$.pipe(
    ofType(setInstanceElementCompletion),
    mergeMap(({ elementId, isComplete }) =>
      this.userProductInstanceService.setInstanceElementCompletion(elementId, isComplete)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  $setInstanceIterationCompletion = createEffect(() => this.actions$.pipe(
    ofType(setInstanceIterationCompletion),
    mergeMap(({ iterationId, isComplete }) =>
      this.userProductInstanceService.setInstanceIterationCompletion(iterationId, isComplete)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  $startRoundTimer = createEffect(() => this.actions$.pipe(
    ofType(startRoundTimer),
    mergeMap(({ moduleId }) =>
      this.userProductInstanceService.startRoundTimer(moduleId)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  $startElementTimer = createEffect(() => this.actions$.pipe(
    ofType(startElementTimer),
    mergeMap(({ elementId, moduleId, questionBankId, scorerId }) =>
      this.userProductInstanceService.startElementTimer(elementId, moduleId, questionBankId, scorerId)
        .pipe(
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  $getStudentSimInstance = createEffect(() => this.actions$.pipe(
    ofType(getStudentSimInstance),
    mergeMap(({ studentIdentifier, courseCode, productCode, overrideUrl }) =>
      this.userProductInstanceService.getStudentInstance(studentIdentifier, productCode, courseCode, overrideUrl)
        .pipe(
          map(instance => setStudentSimInstance({ instance })),
          catchError(() => EMPTY)
        )
    )
  ), { dispatch: false });

  private logInfo(message: string): void {
    if (this.enableLogging) {
      this.logger.info(`${this.componentName}: ${message}`);
    }
  }

}
