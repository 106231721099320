import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { DownloadElementComponent } from './download-element/download-element.component';
import { SimpleDownloadElementComponent } from './simple-download-element/simple-download-element.component';
import { ContentElementComponent } from './content-element/content-element.component';
import { ImageElementComponent } from './image-element/image-element.component';
import { RunIterationComponent } from './run-iteration-button/run-iteration-button.component';
import { RunSimulationComponent } from './run-simulation-button/run-simulation-button.component';
import { SafePipe, VideoElementComponent } from './video-element/video-element.component';
import { CommonModule } from '@angular/common';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { MarkdownModule } from 'ngx-markdown';
import { InteractionElementModule } from '../components/interaction-element/interaction-element.module';
import { IFrameElementComponent } from './iframe-element/iframe-element.component';
import { PdfViewerElementComponent } from './pdf-viewer-element/pdf-viewer-element.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    DownloadElementComponent,
    SimpleDownloadElementComponent,
    ContentElementComponent,
    IFrameElementComponent,
    ImageElementComponent,
    PdfViewerElementComponent,
    RunIterationComponent,
    RunSimulationComponent,
    VideoElementComponent,
    SafePipe
  ],
  imports: [
    CommonModule,

    // Ant Design
    NzDrawerModule,
    NzLayoutModule,
    NzMenuModule,
    NzSpinModule,
    NzButtonModule,
    NzIconModule,
    NzNotificationModule,
    NzListModule,
    NzCollapseModule,
    NzModalModule,
    NzRadioModule,
    NzSkeletonModule,
    NzInputNumberModule,
    NzSelectModule,
    NzSliderModule,
    NzPopoverModule,

    // Markdown parsing
    MarkdownModule,
    InteractionElementModule,
    PdfViewerModule
  ],
  exports: [
    DownloadElementComponent,
    SimpleDownloadElementComponent,
    ContentElementComponent,
    IFrameElementComponent,
    ImageElementComponent,
    PdfViewerElementComponent,
    RunIterationComponent,
    RunSimulationComponent,
    VideoElementComponent
  ]
})
export class CoreElementsModule { }
