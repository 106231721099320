import { createAction, props } from '@ngrx/store';

export enum Actions {
  TOGGLE_DRAWER= '[Progress] Toggle Drawer',
  TOGGLE_RESULTS= '[Results] Toggle Open',
  TOGGLE_ANNOTATIONS = '[Drawers] Toggle Annotation',
  TOGGLE_GLOBAL = '[Drawers] Toggle Global Page',
}

export const toggleDrawer = createAction(Actions.TOGGLE_DRAWER, props<{ isDrawerOpen: boolean }>());

export const toggleResults = createAction(Actions.TOGGLE_RESULTS, props<{ isResultsOpen: boolean }>());

export const toggleAnnotations = createAction(Actions.TOGGLE_ANNOTATIONS, props<{ isOpen: boolean; }>());

export const toggleGlobalPage = createAction(Actions.TOGGLE_GLOBAL, props<{ pageId: string, isOpened: boolean; }>());
